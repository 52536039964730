import React, { useState, useEffect } from "react";
import "./adminReport.module.scss";
import "../../assets/css/style.scss";
import {
  Paper,
  makeStyles,
  TableHead,
  Switch,
  Select,
  MenuItem,
  TableSortLabel,
  Table,
  TableBody,
  TableCell,
  TableRow,
  TableContainer,
} from "@material-ui/core";
import axios from "axios";
import {
  fetchAuthToken,
  fetchOrganisationId,
  paginateTable,
} from "../../utils/Helpers";
import SnackbarComponent from "../../components/SnackbarComponent";
import DeleteDialogBox from "../../components/DeleteDialog/DeleteDialogBox";
import PaginationComponent from "../../components/PaginatorComponent";
import { EXTRICATOR_BASE_URL } from "../../utils/constants";

/**
 * Returns a formatted string representing the given date in a human-readable format.
 *
 * @param {Date} data The date to format.
 * @returns {string} A string representing the formatted date.
 */
const formatDate = (data) => {
  return new Date(data).toString().split(" GMT")[0];
};
// Define a custom hook to generate styling classes for the table rows

const useRowStyles = makeStyles({
  // Styling for the root element of the table row component

  root: {
    "& > *": {
      borderBottom: "unset",
    },
    backgroundColor: "#04234F",
    border: "1px solid #103261",
  },
  // Styling for table cells that contain user information

  cellRootUser: {
    color: "#fff",
    fontWeight: "100 !important",
    padding: "0 16px 0px 20px",
    cursor: "default",
  },
  // Styling for hyperlinks in table cells that contain user information

  cellRootUserLink: {
    color: "#fff",
    fontWeight: "800",
  },
  // Styling for the toggle switches in the table cells

  cellRootSwitch: {
    color: "#fff",
    fontWeight: "100",
    fontSize: "12px",
    padding: "0px",
  },
  // Styling for the root element of a label component

  labelRoot: {
    maxWidth: "unset !important",
    width: "unset !important",
    margin: "0px",
  },
  // Styling for the child row of a collapsible table

  rowChild: {
    backgroundColor: "rgb(19, 86, 177)",
  },
  // Styling for the table element

  table: {
    minWidth: 650,
  },
  // Styling for the root element of the table head component in a child row

  childTableHeadRoot: {
    backgroundColor: "#0E4B9C",
  },
  // Styling for the body element of the table

  tableBody: {
    backgroundColor: "#0E3C7A",
  },
  // Styling for the root element of the table component in a file table

  fileTableRoot: {
    color: "#fff",
  },
  // Styling for the root element of the table component in a file table

  fileTableRootHead: {
    color: "#fff",
    fontWeight: "800",
    fontSize: "12px",
  },
  // Styling for the root element of the select component

  selectRoot: {
    color: "#fff",
    fontSize: "14px",
  },
  // Styling for the arrow icon in the select component

  selectArrow: {
    height: "40px",
  },
});
// Define styles for the table

const tableStyles = makeStyles((theme) => ({
  // Style for the root element of the table container

  tcRoot: {
    borderRadius: "0px",
    overflow: "hidden",
    backgroundColor: "#04234F",
    height: "32em",
  },
  // Style for the root element of the table header

  thRoot: {
    backgroundColor: "#01132B",
  },
  // Style for table header cells

  tcHead: {
    color: "#fff",
    fontSize: "14px",
    fontWeight: "800",
    paddingLeft: "0px",
  },

  tCellHead: {
    padding: "0 16px 0px 20px",
  },
  tCellHead1: {
    padding: "0 16px 0px 0",
  },
  // Style for table header cells with minimum width
  tcHeadMin: {
    color: "#fff",
    fontSize: "14px",
    fontWeight: "800",
  },
  // Style for table header cells that contain actions

  tcHeadAction: {
    color: "#fff",
    fontSize: "14px",
    fontWeight: "800",
  },

  circleColor: {
    stroke: "#35F8F1",
  },
  actionRoot: {
    padding: "8px 20px !important",
  },
  noFileTableRoot: {
    border: "none",
    color: "#fff",
  },
}));

// Define UserDetails component

const UserDetails = (props) => {
  // Destructure props for easy access

  const {
    loaderCallback,
    dateSet,
    refreshUsers,
    userRoles,
    handleErrorCallback,
    ownRole,
  } = props;
  const date = new Date();
  const classes = tableStyles();
  const rowClasses = useRowStyles();
  const [rows, setRowsData] = useState([]);
  const [open, setOpen] = React.useState(false);
  const [internalTableData, setInternalTableData] = React.useState([]);
  const [expandedRow, setExpandedRow] = React.useState("");
  const authToken = fetchAuthToken();
  const ogi = fetchOrganisationId();
  const [toastColor, setToastColor] = React.useState("success");
  const [successMsg, setSuccessMsg] = React.useState("");
  const [snackOpen, setSnackOpen] = React.useState("");
  const [deletableFileId, setDeletableFileId] = useState(null);
  const [deleteFileProgress, setDeleteFileProgress] = useState(false);
  const [age, setAge] = React.useState("");
  const [totalPages, setTotalPages] = React.useState([]);
  const [page, setPage] = React.useState(1);
  const [fullUsersList, setFullUsersList] = React.useState([]);
  const [goInputFocused, setGoInputFocused] = React.useState(false);
  const [pageRows, setPageRows] = React.useState(9);
  const [orderBy, setOrderBy] = React.useState("calories");
  const [order, setOrder] = React.useState("asc");
  const [rowsPerPage, setRowsPerPage] = React.useState(5);

  useEffect(() => {
    // If expandedRow exists, call getRoleBillingDetails with the given parameters, else call getroleManagementData

    if (expandedRow) {
      getRoleBillingDetails(expandedRow, dateSet.from, dateSet.to);
    } else {
      getroleManagementData(dateSet.from, dateSet.to);
    }
  }, [expandedRow, dateSet, refreshUsers]);

  useEffect(() => {
    // Call formatUsersList with the fullUsersList and current page number whenever page changes

    formatUsersList(fullUsersList);
  }, [page]);

  function descendingComparator(a, b, orderBy) {
    // Compare two objects based on the given order-by property in descending order

    if (b[orderBy] < a[orderBy]) {
      return -1;
    }
    if (b[orderBy] > a[orderBy]) {
      return 1;
    }
    return 0;
  }

  function getComparator(order, orderBy) {
    // Get the comparator function based on the given order and order-by properties

    return order === "desc"
      ? (a, b) => descendingComparator(a, b, orderBy)
      : (a, b) => -descendingComparator(a, b, orderBy);
  }

  function stableSort(array, comparator) {
    // Sort the given array based on the given comparator function

    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
      const order = comparator(a[0], b[0]);
      if (order !== 0) return order;
      return a[1] - b[1];
    });
    return stabilizedThis.map((el) => el[0]);
  }

  const handleRequestSort = (property) => (event) => {
    // Handle sorting of the table data by property

    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const getroleManagementData = async (from = "null", to = "null") => {
    // Get user details from the backend API

    loaderCallback(true);
    axios({
      method: "POST",
      url: `${EXTRICATOR_BASE_URL}/get_users_details/`,
      headers: {
        Authorization: `Bearer ${authToken}`,
      },
      data: {
        ogi: ogi,
        From: from == null ? "null" : from,
        to: to == null ? "null" : to,
      },
    })
      .then(function (response) {
        loaderCallback(false);

        // Format user details and set the state

        var userDetails = response.data.data.map((item) => ({
          ...item,
          isExpanded: false,
          userRoles: userRoles,
        }));
        formatUsersList(userDetails);
        setFullUsersList(userDetails);
        setPage(1);
      })
      .catch(function (error) {
        if (error.response) {
          if (error.response.data.non_field_errors) {
            handleErrorCallback(error.response.data.non_field_errors[0]);
          }
        }
        loaderCallback(false);
      });
  };

  const formatUsersList = (userDetails) => {
    // Paginates the table data and sets it to state

    var paginatedData = paginateTable(userDetails, page, pageRows);
    if (paginatedData.paginated_data.length > 0) {
      setRowsData(paginatedData.paginated_data);
    }
    setTotalPages(paginatedData.totalPages);
  };

  const getRoleBillingDetails = async (user_id, from = null, to = null) => {
    // Gets the user's billing details and sets the data to state

    loaderCallback(true);
    axios({
      method: "GET",
      url: `${EXTRICATOR_BASE_URL}/get_specific_user_billing_details/?from=${from}&to=${to}&user_id=${user_id}&ogi=${ogi}`,
      headers: {
        Authorization: `Bearer ${authToken}`,
      },
    })
      .then(function (response) {
        loaderCallback(false);
        setOpen(!open);
        setInternalTableData(response.data.billing_data);
      })
      .catch(function (error) {
        if (error.response) {
          if (error.response.data.non_field_errors) {
            handleErrorCallback(error.response.data.non_field_errors[0]);
          }
        }
        loaderCallback(false);
      });
  };

  const handleChange = (event) => {
    // Handles the change in input

    setAge(event.target.value);
  };

  const handlePageChange = async (event, value) => {
    // Handles the change in page

    event.stopPropagation();
    setPage(value);
  };
  // Function to handle closing of the Snackbar

  const handleSnackClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setSnackOpen(false);
    setSuccessMsg("");
  };
  // Function to handle blocking/unblocking a user's access

  const handleBlockUnblock = (event, row) => {
    loaderCallback(true);
    axios({
      method: "POST",
      url: `${EXTRICATOR_BASE_URL}/remove_access/`,
      headers: {
        Authorization: `Bearer ${authToken}`,
      },
      data: {
        ogi: ogi,
        user_id: row.user_id,
      },
    })
      .then(function (response) {
        loaderCallback(false);
        var userRowsTemp = [...rows];
        for (let i = 0; i < userRowsTemp.length; i++) {
          if (row.user_id == userRowsTemp[i].user_id) {
            userRowsTemp[i].status = !userRowsTemp[i].status;
          }
        }
        setRowsData(userRowsTemp);
      })
      .catch(function (error) {
        setToastColor("error");
        setSnackOpen(true);
        setSuccessMsg(error.response.data.non_field_errors[0]);
        loaderCallback(false);
      });
  };

  const confirmDelete = (file_id) => {
    setDeleteFileProgress(true);
    let reqBody = { user_id: file_id, ogi: ogi };
    axios({
      method: "post",
      url: `${EXTRICATOR_BASE_URL}/delete_user/`,
      data: reqBody,
      headers: { Authorization: `Bearer ${authToken}` },
    })
      .then((res) => {
        setDeleteFileProgress(false);
        setDeletableFileId(null);
        setToastColor("success");
        setSnackOpen(true);
        setSuccessMsg(res.data.message);
        getroleManagementData(dateSet.from, dateSet.to);
      })
      .catch((error) => {
        if (error.response) {
          if (error.response.data && error.response.data.non_field_errors) {
            setToastColor("error");
            setSnackOpen(true);
            setSuccessMsg(error.response.data.non_field_errors[0]);
          }
        }
        setDeletableFileId(null);
        setDeleteFileProgress(false);
      });
  };

  const toggleAccess = (access, user) => {
    var rowsTemp = [...rows];

    loaderCallback(true);
    axios({
      method: "POST",
      url: `${EXTRICATOR_BASE_URL}/update_user_role/`,
      headers: {
        Authorization: `Bearer ${authToken}`,
      },
      data: {
        email: user.email,
        ogi: ogi,
        role_id: access.id,
      },
    })
      .then((response) => {
        loaderCallback(false);
        setToastColor("success");
        setSuccessMsg(response.data.message);
        setSnackOpen(true);
        getroleManagementData(dateSet.from, dateSet.to);
      })
      .catch(function (error) {
        loaderCallback(false);
        if (error.response.data) {
          setToastColor("error");
          setSuccessMsg(error.response.data.non_field_errors[0]);
          setSnackOpen(true);
        } else {
          setToastColor("error");
          setSuccessMsg("Something went wrong please try again");
          setSnackOpen(true);
        }
      });
  };

  const handlePaginationCallback = (focused) => setGoInputFocused(focused);

  return (
    <div id="role_management">
      <DeleteDialogBox
        open={deletableFileId}
        handleClose={() => setDeletableFileId(null)}
        content="Are you sure you want to delete this User?"
        inProgress={deleteFileProgress}
        handleConfirm={() => confirmDelete(deletableFileId)}
        dialogTitle="Confirmation"
      />
      <SnackbarComponent
        isOpen={snackOpen}
        message={successMsg}
        color={toastColor}
        handleClose={handleSnackClose}
      />
      <TableContainer component={Paper} classes={{ root: classes.tcRoot }}>
        <Table aria-label="collapsible table">
          <TableHead classes={{ root: classes.thRoot }}>
            <TableRow>
              <TableCell
                classes={{ head: classes.tcHead, root: classes.tCellHead }}
              >
                <TableSortLabel
                  active={orderBy === "user_name"}
                  direction={orderBy === "user_name" ? order : "asc"}
                  onClick={handleRequestSort("user_name")}
                >
                  User Name
                </TableSortLabel>
              </TableCell>
              <TableCell
                classes={{ head: classes.tcHeadMin, root: classes.tCellHead }}
              >
                <TableSortLabel
                  active={orderBy === "form"}
                  direction={orderBy === "form" ? order : "asc"}
                  onClick={handleRequestSort("form")}
                >
                  Form
                </TableSortLabel>
              </TableCell>
              <TableCell
                classes={{ head: classes.tcHeadMin, root: classes.tCellHead }}
              >
                <TableSortLabel
                  active={orderBy === "invoice"}
                  direction={orderBy === "invoice" ? order : "asc"}
                  onClick={handleRequestSort("invoice")}
                >
                  Invoice
                </TableSortLabel>
              </TableCell>
              <TableCell
                classes={{ head: classes.tcHeadMin, root: classes.tCellHead }}
              >
                <TableSortLabel
                  active={orderBy === "receipt"}
                  direction={orderBy === "receipt" ? order : "asc"}
                  onClick={handleRequestSort("receipt")}
                >
                  Receipt
                </TableSortLabel>
              </TableCell>
              <TableCell
                classes={{
                  head: classes.tcHeadAction,
                  root: classes.tCellHead,
                }}
              >
                <TableSortLabel
                  active={orderBy === "role"}
                  direction={orderBy === "role" ? order : "asc"}
                  onClick={handleRequestSort("role")}
                >
                  Role
                </TableSortLabel>
              </TableCell>
              <TableCell
                classes={{
                  head: classes.tcHeadAction,
                  root: classes.tCellHead,
                }}
                colSpan={5}
              >
                <TableSortLabel
                  active={orderBy === "last_login"}
                  direction={orderBy === "last_login" ? order : "asc"}
                  onClick={handleRequestSort("last_login")}
                >
                  Last LogIn
                </TableSortLabel>
              </TableCell>
              <TableCell
                classes={{
                  head: classes.tcHeadAction,
                  root: classes.tCellHead1,
                }}
                colSpan={4}
              ></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {rows.length > 0
              ? stableSort(rows, getComparator(order, orderBy)).map(
                  (row, index) => (
                    <React.Fragment key={row.name}>
                      <TableRow className={rowClasses.root}>
                        <TableCell className={rowClasses.cellRootUser}>
                          {row.user_name}
                        </TableCell>
                        <TableCell
                          className={rowClasses.cellRootUser}
                          align="left"
                        >
                          {row.form}
                        </TableCell>
                        <TableCell
                          className={rowClasses.cellRootUser}
                          align="left"
                        >
                          {row.invoice}
                        </TableCell>
                        <TableCell
                          className={rowClasses.cellRootUser}
                          align="left"
                        >
                          {row.receipt}
                        </TableCell>
                        <TableCell
                          className={rowClasses.cellRootUser}
                          align="left"
                        >
                          {row.role}
                        </TableCell>
                        <TableCell
                          className={rowClasses.cellRootUser}
                          align="left"
                          colSpan={5}
                        >
                          {formatDate(row.last_login)}
                        </TableCell>

                        {row.role !== "Owner" ? (
                          <>
                            {ownRole !== "Admin" ? (
                              <>
                                <TableCell
                                  className={rowClasses.cellRootSwitch}
                                  colSpan={2}
                                >
                                  Unblock
                                  <Switch
                                    checked={!row.status}
                                    onChange={(event) =>
                                      handleBlockUnblock(event, row)
                                    }
                                    name="checkedA"
                                    inputProps={{
                                      "aria-label": "secondary checkbox",
                                    }}
                                  />
                                  Block
                                </TableCell>
                                <TableCell
                                  className={rowClasses.cellRootUser}
                                  align="left"
                                >
                                  <span
                                    className="fileaction-span"
                                    onClick={() =>
                                      setDeletableFileId(row.user_id)
                                    }
                                  >
                                    <i
                                      style={{ fontSize: "18px" }}
                                      className="fa fa-trash"
                                    ></i>
                                    &nbsp;
                                    <span style={{ fontWeight: "unset" }}>
                                      Delete
                                    </span>
                                  </span>
                                </TableCell>
                              </>
                            ) : null}
                            <TableCell
                              className={rowClasses.cellRootUser}
                              align="left"
                            >
                              <Select
                                disableUnderline={true}
                                classes={{
                                  root: rowClasses.selectRoot,
                                  icon: rowClasses.selectArrow,
                                }}
                                labelId="demo-simple-select-label"
                                id="demo-simple-select"
                                value={row.role + " Access"}
                                onChange={handleChange}
                              >
                                {userRoles.map((access) => (
                                  <MenuItem
                                    onClick={() => toggleAccess(access, row)}
                                    value={access.value}
                                  >
                                    {access.label}
                                  </MenuItem>
                                ))}
                              </Select>
                            </TableCell>
                          </>
                        ) : (
                          <TableCell
                            className={rowClasses.cellRootSwitch}
                            colSpan={3}
                          ></TableCell>
                        )}
                      </TableRow>
                    </React.Fragment>
                  )
                )
              : null}
          </TableBody>
        </Table>
      </TableContainer>
      <div className="col-12">
        <PaginationComponent
          place="admin_users"
          onPageChange={(event, item) => handlePageChange(event, item)}
          data={totalPages}
          callback={handlePaginationCallback}
        />
      </div>
    </div>
  );
};
export default UserDetails;
