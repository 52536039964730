// Fetches the authentication token from local storage, or session storage if not found

export const fetchAuthToken = () => {
  try {
    const serializedState = localStorage.getItem("auth_token");
    if (!serializedState) {
      const sessionToken = sessionStorage.getItem("auth_token");
      return sessionToken;
    }
    return serializedState;
  } catch (e) {
    return undefined;
  }
};
// Fetches the email of the currently logged in user from local storage, or session storage if not found

export const fetchLoggedInUserEmail = () => {
  try {
    const serializedState = localStorage.getItem("company_id");
    if (!serializedState) {
      const sessionCompId = sessionStorage.getItem("company_id");
      return sessionCompId;
    }
    return serializedState;
  } catch (e) {
    return undefined;
  }
};
// Fetches whether the logged in user is a super user from local storage, or session storage if not found

export const getIsSuperUser = () => {
  try {
    var isSuperUser = localStorage.getItem("super_user");
    if (!isSuperUser) {
      isSuperUser = sessionStorage.getItem("super_user");
    }
    return isSuperUser;
  } catch (e) {
    return undefined;
  }
};
// Fetches the full name of the currently logged in user from local storage, or session storage if not found

export const fetchLoggedInUserName = () => {
  var val = localStorage.getItem("auth_token"),
    firstName = "",
    lastName = "";
  if (val) {
    firstName = localStorage.getItem("first_name");
    lastName = localStorage.getItem("last_name");
  } else {
    firstName = sessionStorage.getItem("first_name");
    lastName = sessionStorage.getItem("last_name");
  }
  var namePair = {
    fname: firstName.toString(),
    lname: lastName.toString() !== "null" ? lastName.toString() : "",
  };
  return namePair.fname + " " + namePair.lname;
};
// Helper function for paginateTable, returns a subset of the data for the specified page

const pagination = (tableSet, rows, page) => {
  var trimStart = (page - 1) * rows; // 4-1* 1
  var trimEnd = trimStart + rows;
  var trimmedData = tableSet.slice(trimStart, trimEnd);
  var pages = Math.ceil(tableSet.length / rows);

  return {
    querySet: trimmedData,
    pages: pages,
  };
};
// Paginates the given table data and returns a subset of rows for the specified page, along with the total number of pages

export const paginateTable = (tableSet, page, rows) => {
  var data = pagination(tableSet, rows, page);
  var temp = [];
  for (let i = 1; i <= data.pages; i++) {
    temp.push(i);
  }
  return {
    paginated_data: data.querySet,
    totalPages: temp,
  };
};

// Clears local storage and session storage, and redirects the user to the home page

export const handleLogout = async (routeProps) => {
  await localStorage.clear();
  sessionStorage.clear();
  routeProps.replace({ pathname: "/" });
};
// Formats a timestamp string into a more readable date string

export const getTimeRecord = (time) => {
  var date = new Date(`${time}`);
  var strDate = `${date}`;
  return strDate.slice(0, 24);
};
// Sets the active section in local storage, or session storage if not logged in

export const setActiveSection = (value) => {
  var val = localStorage.getItem("auth_token");
  if (val) {
    localStorage.setItem("section_active", value);
  } else {
    sessionStorage.setItem("section_active", value);
  }
};
// Removes the active section from local storage, or session storage if not logged in

export const removeSectionStorage = () => {
  var activeSection = localStorage.getItem("section_active");
  if (activeSection) {
    localStorage.removeItem("section_active");
  } else {
    sessionStorage.removeItem("section_active");
  }
};
// Fetches the active section from local storage, or session storage if not logged in

export const fetchActiveSection = () => {
  var val = localStorage.getItem("auth_token");
  var section = null;
  if (val) {
    section = localStorage.getItem("section_active");
  } else {
    section = sessionStorage.getItem("section_active");
  }
  return section;
};
// Fetches the organisation role from local storage, or session storage if not found

export const fetchOrgRole = () => {
  var section = localStorage.getItem("org_role");
  if (!section) {
    section = sessionStorage.getItem("org_role");
  }
  return section;
};
// Fetches the organisation ID from local storage, or session storage if not found

export const fetchOrganisationId = () => {
  try {
    const serializedState = localStorage.getItem("ogi");
    if (!serializedState) {
      const sessionOgi = sessionStorage.getItem("ogi");
      return sessionOgi;
    }
    return serializedState;
  } catch (e) {
    return undefined;
  }
};

// Fetches the user company from local storage, or session storage if not found

export const fetchUserCompany = () => {
  try {
    var company = localStorage.getItem("user_company");
    if (!company) {
      company = sessionStorage.getItem("user_company");
    }
    return company;
  } catch (e) {
    return undefined;
  }
};
// Checks the type of organization error and redirects the user accordingly

export const checkOrganizationError = async (error, routeProps) => {
  if (
    error == "Organisation details not matched with user" ||
    error == "Your permission has been revoked, please re-login" ||
    error == "Your permission has been revoked, please login again"
  ) {
    await localStorage.removeItem("auth_token");
    sessionStorage.removeItem("auth_token");
    routeProps.replace({ pathname: "/Login", state: { error_code: error } });
  } else if (
    error == "you are not admin" ||
    error ==
      "Your permission has been revoked, please re-access your organization"
  ) {
    routeProps.replace({
      pathname: "/admin_landing",
      state: { error_code: error },
    });
  }
};
// Returns a promise that resolves to the dashboard route based on the active section

export const getDashboardRoutePromise = async () =>
  new Promise(function (resolve, reject) {
    const section = fetchActiveSection();
    if (section) {
      if (section.includes("FORM")) {
        resolve("/dashboard_forms");
      } else if (section.includes("INVOICE")) {
        resolve("/dashboard_invoices");
      } else if (section.includes("RECEIPT")) {
        resolve("/dashboard_receipts");
      } else if (section.includes("UTILITY")) {
        resolve("/dashboard_utilities");
      } else if (section.includes("PORDER")) {
        resolve("/dashboard_porders");
      } else if (section.includes("PASSPORT")) {
        resolve("/dashboard_passports");
      }
    } else {
      reject("/dashboard_forms");
    }
  });


// Fetches the user id from local storage, or session storage if not found
export const fetchUserID = () => {
  try {
    var user_id = localStorage.getItem("user_id");
    if (!user_id) {
      user_id = sessionStorage.getItem("user_id");
    }
    return user_id;
  } catch (e) {
    return undefined;
  }
};