import React, { useState, useEffect } from "react";
import "./adminReport.module.scss";
import "../../assets/css/style.scss";
import { Link } from "react-router-dom";
import Images from "../../assets/asset_imports";
import {
  Chip,
  Tab,
  Tabs,
  Box,
  CircularProgress,
  Backdrop,
} from "@material-ui/core";
import theme_colors from "../../utils/theme";
import PropTypes from "prop-types";
import $ from "jquery";
import axios from "axios";
import {
  fetchAuthToken,
  paginateTable,
  fetchOrganisationId,
  checkOrganizationError,
  getDashboardRoutePromise,
} from "../../utils/Helpers";
import SnackbarComponent from "../../components/SnackbarComponent";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import AppBar from "@material-ui/core/AppBar";
import LogoutBar from "../../components/LogoutBar";
import DeleteDialogBox from "../../components/DeleteDialog/DeleteDialogBox";
import AdminAddUser from "../../components/AdminAddUser";
import UserDetails from "./UserDetails";
import Billing from "../Account/Billing";
import CheckMobileDevice from "../../components/CheckMobileDevice";
import { EXTRICATOR_BASE_URL } from "../../utils/constants";
/**
 * This component is used to render a tab panel for Material-UI Tabs component
 * @param {*} props - Properties passed to the component
 * @param {*} props.children - Child nodes to be rendered
 * @param {*} props.index - Index of the panel
 * @param {*} props.value - Current value of the panel
 * @param {*} props.other - Any other properties to be passed to the component
 * @returns - The rendered tab panel
 */
function TabPanel(props) {
  const { children, value, index, ...other } = props;
  // Return the tab panel with a Box containing the children if the value matches the index

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`scrollable-auto-tabpanel-${index}`}
      aria-labelledby={`scrollable-auto-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}
// Declare the prop types for the component
TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};
/**
 * Returns accessibility props for the tab with the given index.
 * @param {number} index - The index of the tab.
 * @returns {object} An object with `id` and `aria-controls` properties.
 */
function a11yProps(index) {
  return {
    id: `scrollable-auto-tab-${index}`,
    "aria-controls": `scrollable-auto-tabpanel-${index}`,
  };
}

const accordionStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  root: {
    color: "#fff",
    width: "100%",
    background: "transparent",
    boxShadow: "none",
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    fontWeight: theme.typography.fontWeightRegular,
    color: "#fff",
  },
  content: {
    color: "#062241",
    background: "rgba(255,255,255,0.1)",
    fontWeight: "600",
    padding: "6px",
    alignItems: "center",
    cursor: "pointer",
    justifyContent: "space-between",
    paddingLeft: "40px",
  },
  contentList: {
    backgroundColor: "transparent",
    fontSize: "12px",
  },
  arrowIcon: {
    padding: "0",
  },
  badgeRoot: {
    background: theme_colors.secondary,
    width: "33px",
    height: "17px",
    color: theme_colors.primary,
  },
  backdrop: {
    zIndex: 10,
    color: "#fff",
  },
  badgeLabel: {
    color: theme_colors.primary,
  },
  poweredBy: {
    borderRight: "1px solid #c7c7c7",
  },
  emailChip: {
    backgroundColor: "#0B346B",
    cursor: "pointer",
    borderRadius: 3,
  },
  emailLogo: {
    background: "#86b5e0",
    color: `${theme_colors.primary} !important`,
  },
  appbarRoot: {
    padding: "0 5px",
    background: "transparent",
    boxShadow: "none",
  },
  indicator: {
    backgroundColor: "transparent !important",
    height: "0px !important",
  },
  tabRoot: {
    width: "180px",
  },
  importBtn: {
    backgroundColor: "#35F8F1",
    borderRadius: "6px",
    "&:hover": {
      backgroundColor: theme_colors.primary,
      color: "#FFF",
    },
  },
  saveBtn: {
    background: "#35F8F1 0% 0% no-repeat padding-box",
    borderRadius: "3px",
    opacity: 1,
    height: "38px",
    width: "132px",
  },
  disabledBtn: {
    border: " 1px solid #35F8F1",
  },
  removeBtn: {
    backgroundColor: "transparent",
    color: "#35F8F1",
    border: "1px solid #35F8F1",
    width: "132px",
    height: "38px",
    borderRadius: "3px",
    "&:hover": {
      backgroundColor: theme_colors.primary,
      color: "#FFF",
    },
  },

  logOutBtn: {
    backgroundColor: theme_colors.primary,
    color: "#FFF",
    borderRadius: "2px",
    "&:hover": {
      backgroundColor: theme_colors.primary,
      color: "#FFF",
    },
  },

  reviewBtn: {
    backgroundColor: theme_colors.primary,
    background: "rgb(2,27,65)",
    background:
      "linear-gradient(0deg, rgba(2,27,65,1) 49%, rgba(35,67,115,1) 100%)",
    borderRadius: "10px",
    padding: "10px 20px",
    "&:hover": {
      backgroundColor: theme_colors.primary,
      color: "#FFF",
    },
  },
  backdrop: {
    zIndex: 10,
    color: "#fff",
  },
  accountTab: {
    height: "26px",
    minHeight: "unset",
  },
  tabGroup: {
    height: "30px",
  },
  tabGroupRoot: {
    minHeight: "unset !important",
  },
  panelRoot: {
    padding: "0px",
  },
}));

const CustomTab = ({ title, icon, showChip, classes }) => (
  <div className="custom-tab">
    <img src={icon} />
    {title}
    {showChip && (
      <Chip
        size="small"
        label="1"
        style={{ marginLeft: "25px" }}
        classes={{ root: classes.badgeRoot, label: classes.badgeLabel }}
      />
    )}
  </div>
);

const AdminReport = (props) => {
  // get authentication token and organization id from storage

  let authToken = fetchAuthToken();
  let ogi = fetchOrganisationId();
  // get organization access from storage, checking both local and session storage

  var orgRole = localStorage.getItem("org_access");
  if (!orgRole) {
    orgRole = sessionStorage.getItem("org_access");
  }
  // create a new date object

  const date = new Date();
  // set initial state values

  const [deleteFileProgress, setDeleteFileProgress] = useState(false);
  const [newUserDialogOpen, setNewUserDialogOpen] = useState(false);
  const [dateErrMessage, setDateErrMessage] = useState("");
  const [dateRange, setDateRange] = useState({ from: null, to: null });
  const [value, setValue] = React.useState();
  const [days, setDays] = React.useState([1]);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [avatarUpdated, setAvatarUpdated] = React.useState(false);
  const [renameId, setRenameId] = React.useState(null);
  const [fullInvoiceModelsList, setFullInvoiceModelsList] = React.useState([]);
  const [totalModelPages, setTotalModelPages] = React.useState([]);
  const [modelsPage, setModelsPage] = React.useState(1);
  const [logOutBtn, showLogoutBtn] = React.useState(0);
  const [profileMenuShow, setProfileMenuShow] = React.useState(false);
  const [successMsg, setSuccessMsg] = React.useState("");
  const [snackOpen, setSnackOpen] = React.useState(false);
  const [toastColor, setToastColor] = React.useState("");
  const [open, setOpen] = React.useState(false);
  const [rows, setRows] = React.useState(5);
  const [modelType, setModelType] = React.useState("INVOICE");
  const [barUpdate, setBarUpdate] = useState(false);
  // create a styles object using makeStyles hook

  const classes = accordionStyles();
  // set initial values for invoiceModels and isLoadingPassword

  const [invoiceModels, setInvoiceModels] = React.useState([]);
  const [isLoadingPassword, setLoadingPassword] = React.useState(false);
  // set initial value for deletableFileId

  const [deletableFileId, setDeletableFileId] = useState(null);
  // set initial values for tooltip, tooltipDate, years, and refreshUsers

  const [tooltip, setTooltip] = React.useState(false);
  const [tooltipDate, setTooltipDate] = React.useState("");
  const [years, setYears] = React.useState([]);
  const [refreshUsers, setRefreshUsers] = React.useState(0);
  const [accessList, setAccessList] = React.useState([]);
  const [newUserAccessList, setNewUserAccessList] = React.useState([]);
  // set initial value for backRoute

  const [backRoute, setBackroute] = React.useState("/dashboard_forms");

  // handle browser back button
  const [locationKeys, setLocationKeys] = useState([]);
  // set initial state for form input fields

  const [form, setState] = useState({
    firstName: "",
    lastName: "",
    oldPassword: "",
    newPassword: "",
    confirmPassword: "",
    email: "",
  });
  // Set initial state for 'from' date object

  const [from, setFrom] = React.useState({
    day: null,
    month: null,
    year: null,
  });
  // Set initial state for 'to' date object

  const [to, setTo] = React.useState({
    day: null,
    month: null,
    year: null,
  });
  // Format invoice model data for display

  const formatModelsData = (modelData) => {
    // Paginate table data based on current page and rows per page

    var paginatedData = paginateTable(modelData, modelsPage, rows);
    // Set the invoice models state to the paginated data

    setInvoiceModels(paginatedData.paginated_data);
    // Set the total number of model pages state based on the paginated data

    setTotalModelPages(paginatedData.totalPages);
  };
  // Show an error message for 3 seconds if 'dateErrMessage' state is not empty

  useEffect(() => {
    if (dateErrMessage) {
      setTimeout(() => {
        setDateErrMessage("");
      }, 3000);
    }
  }, [dateErrMessage]);

  //-------------------------------------------
  useEffect(() => {
    // Listen for changes to the browser history

    return props.history.listen((location) => {
      // If the history action is "PUSH", add the new location key to the list of keys

      if (props.history.action === "PUSH") {
        setLocationKeys([location.key]);
      }
      // If the history action is "POP", check if it was a forward or back event

      if (props.history.action === "POP") {
        // If the previous key is the same as the current key, it was a forward event

        if (locationKeys[1] === location.key) {
          setLocationKeys(([_, ...keys]) => keys);

          // Handle forward event
        } else {
          // If the previous key is different from the current key, it was a back event

          setLocationKeys((keys) => [location.key, ...keys]);

          // Handle back event by redirecting to the account page
          props.history.push("/account");
        }
      }
    });
  }, [locationKeys]);

  //--------------------------------------------
  // Sets up the dashboard route based on user permissions

  const getDashboardRoute = () => {
    // Calls a function that returns a promise to get the dashboard route

    var sectionPromise = getDashboardRoutePromise();
    // When the promise is fulfilled, sets the back route to the returned section

    sectionPromise
      .then((section) => setBackroute(section))
      // Logs an error if the promise is rejected

      .catch((e) => console.log("GET_DASHBOARD_ROUTE_ERR---", e));
  };
  // React hook that runs after the component mounts

  useEffect(() => {
    // Initializes an array of days of the month

    let days = [];
    // Creates a new date object

    const date = new Date();
    // Adds each day of the month (1-31) to the days array

    for (let i = 1; i < 32; i++) {
      if (i < 10) days.push(`0${i}`);
      else days.push(i);
    }
    // Sets the component's value state to the value passed in through props

    if (props.location.state < 2) {
      setValue(props.location.state);
    } else {
      setValue(0);
    }

    // Sets the component's days state to the days array

    setDays(days);
    // Calls a function to set up the year set

    setYearset();
    // Fetches user roles for the component's access list state

    fetchUserRoles();
    // Sets up the dashboard route

    getDashboardRoute();
  }, []);
  // function to set the yearSet for the datepicker component

  const setYearset = async () => {
    var date = new Date();
    // get the register year from local/session storage

    var registerYear = await localStorage.getItem("register_year");
    if (!registerYear) {
      registerYear = await sessionStorage.getItem("register_year");
    }
    // create an array of years from the register year up to the current year

    var yearSet = [];
    for (let i = registerYear - 1; i < date.getFullYear(); i++) {
      yearSet.push(i + 1);
    }
    // set the yearSet state variable

    setYears(yearSet);
  };
  // function to handle errors returned by the API

  const onError = (error) => {
    // if the error includes a message to pay a bill, display an error toast

    if (error.includes("Kindly pay the bill")) {
      setToastColor("error");
      setSuccessMsg(error);
      setSnackOpen(true);
      // otherwise, check if the error is an organization error and redirect to login if necessary
    } else {
      checkOrganizationError(error, props.history);
    }
  };
  // function to handle closing of the calendar popup

  const handleCalandarClose = (flag) => {
    setAnchorEl(null);
    // if the user reset the calendar or there is an error message, reset the from/to date state variables
    if (flag == "reset" || dateErrMessage) {
      setFrom({
        ...from,
        day: null,
        month: null,
        year: null,
      });

      setTo({
        ...to,
        day: null,
        month: null,
        year: null,
      });
      setTooltipDate("");
      setDateRange({ from: null, to: null });
      setDateErrMessage("");
    }
    // if a date is selected, set the tooltip to show

    if (tooltipDate) {
      setTooltip(true);
    }
  };
  // Handle tab change

  const handleTabChange = (event, newValue) => {
    if (newValue == 0) {
      // Navigate to billings_details with state 0

      props.history.push({ pathname: "/billings_details", state: 0 });
    } else if (newValue == 1) {
      // Navigate to user_details with state 1

      props.history.push({ pathname: "/user_details", state: 1 });
    }
    // Set the new tab value

    setValue(newValue);
  };
  // Fetch rejected models based on the model_type

  const getRejectedModels = (model_type) => {
    axios({
      method: "GET",
      url: `${EXTRICATOR_BASE_URL}/get_rejected_model/?model_type=${model_type}&ogi=${ogi}`,
      headers: {
        Authorization: `Bearer ${authToken}`,
      },
    })
      .then(function (response) {
        // Format the model data and set the state variables

        formatModelsData(response.data);
        setFullInvoiceModelsList(response.data);
      })
      .catch(function (error) {
        if (error.response) {
          if (error.response.data.non_field_errors) {
            if (error.includes("Kindly pay the bill")) {
              // Display an error message if the user needs to pay the bill

              setToastColor("error");
              setSuccessMsg(error.response.data.non_field_errors[0]);
              setSnackOpen(true);
            } else {
              // Check for organization error and handle it accordingly

              checkOrganizationError(
                error.response.data.non_field_errors[0],
                props.history
              );
            }
          }
        }
      });
  };
  // Function to close the user account menu

  const closeProfileMenu = () => {
    if (profileMenuShow || $(".account-menu").hasClass("show")) {
      setProfileMenuShow(false);
      $(".account-menu").removeClass("show");
    }
  };
  // Function to handle clicks on the screen and show/hide logout button

  const handleScreenClick = (flag) => {
    if (flag) {
      showLogoutBtn(!logOutBtn);
    } else {
      if (logOutBtn) {
        showLogoutBtn(false);
      }
    }
  };
  // Function to handle closing of snackbars

  const handleSnackClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setSnackOpen(false);
    setSuccessMsg("");
  };

  const showHideLogoutBtn = (flag) => {
    if (flag) {
      showLogoutBtn(!logOutBtn);
    } else {
      if (logOutBtn) {
        showLogoutBtn(false);
      }
    }
  };

  const fetchUserRoles = () => {
    // Fetch user roles from API and set access list and new user access list

    axios({
      method: "get",
      url: `${EXTRICATOR_BASE_URL}/get_role_details/`,
      headers: {
        Authorization: `Bearer ${authToken}`,
      },
    })
      .then((response) => {
        var listTemp = response.data.roles;
        var tempArr = [];
        for (let i = 0; i < listTemp.length; i++) {
          let obj = {};
          obj["id"] = listTemp[i].id;
          obj["value"] = listTemp[i].role_name;
          obj["label"] = listTemp[i].role_name;
          obj["isChecked"] = false;
          tempArr.push(obj);
        }
        setAccessList(tempArr);
        setNewUserAccessList(tempArr);
      })
      .catch((error) => {
        // Handle error
      });
  };
  // Function to delete a rejected model

  const confirmDelete = (modelId) => {
    setDeleteFileProgress(true);
    axios({
      method: "post",
      url: `${EXTRICATOR_BASE_URL}/delete_rejected_model/`,
      data: { model_id: modelId },
      headers: { Authorization: `Bearer ${authToken}` },
    })
      .then((res) => {
        // Reload models data after successful deletion

        if (modelType === "INVOICE") {
          getRejectedModels("INVOICE");
        } else if (modelType === "RECEIPT") {
          getRejectedModels("RECEIPT");
        }
        // Reset state variables after deletion

        setDeleteFileProgress(false);
        setDeletableFileId(null);
        // Show success message in toast

        setToastColor("success");
        setSnackOpen(true);
        setSuccessMsg(res.data.message);
      })
      .catch((error) => {
        // Handle errors in case of unsuccessful deletion

        if (error.response) {
          if (error.response.data && error.response.data.non_field_errors) {
            setToastColor("error");
            setSnackOpen(true);
            setSuccessMsg(error.response.data.non_field_errors[0]);
          }
        }
        setDeletableFileId(null);
        setDeleteFileProgress(false);
      });
  };

  // sets the state variable 'open' to a boolean value, which determines whether a loading overlay is shown

  const handleShowLoader = (val) => setOpen(val);
  // resets the 'from' and 'to' state variables to null and closes the calendar popover

  const handleChangeDateSet = (val) => {
    if (val) {
      handleCalandarClose("reset");
    }
  };
  // closes the 'new user' dialog and handles the result if provided

  const handleGetResult = (obj) => {
    setNewUserDialogOpen(false);
    if (obj) {
      setSnackOpen(true);
      setToastColor(obj.color);
      setSuccessMsg(obj.msg);
      setRefreshUsers(refreshUsers + 1);
    }
  };
  // displays the 'new user' dialog and sets the 'newUserAccessList' state variable to a copy of the 'accessList' variable with all items unchecked

  const showAddUserPopup = () => {
    var accessListTemp = [...accessList];
    accessListTemp.map((item) => (item.isChecked = false));
    setNewUserAccessList(accessListTemp);
    setNewUserDialogOpen(true);
  };

  return (
    <div style={{ overflow: "auto" }} onClick={() => handleScreenClick()}>
      <Backdrop
        className={classes.backdrop}
        open={open}
        onClick={() => setOpen(false)}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <CheckMobileDevice />
      {newUserDialogOpen && (
        <AdminAddUser
          action="create"
          open={newUserDialogOpen}
          modelType={"INVOICE"}
          userRoles={newUserAccessList}
          handleClose={handleGetResult}
        />
      )}

      <DeleteDialogBox
        open={deletableFileId}
        handleClose={() => setDeletableFileId(null)}
        content="Are you sure you want to delete this document?"
        inProgress={deleteFileProgress}
        handleConfirm={() => confirmDelete(deletableFileId)}
        dialogTitle="Confirmation"
      />

      <div className="dashboard_container">
        <SnackbarComponent
          isOpen={snackOpen}
          message={successMsg}
          color={toastColor}
          handleClose={handleSnackClose}
        />
        <div
          className="menu-side"
          style={{ backgroundImage: Images.data_center }}
        >
          <div>
            <div className="image-full">
              <div className="form-group d-flex justify-content-center mt-4 banner-head">
                <Link to={backRoute} style={{ width: "100%" }}>
                  <img
                    src={Images.app_logo}
                    className="img-responsive"
                    alt=""
                  />
                </Link>
              </div>
            </div>
            <AppBar position="static" classes={{ root: classes.appbarRoot }}>
              <div
                style={{
                  width: "9px",
                  position: "absolute",
                  height: "47px",
                  left: 0,
                  background: "#35F8F1 0% 0% no-repeat padding-box",
                  borderRadius: "0px 2px 2px 0px",
                  opacity: 1,
                  top:
                    value === 0
                      ? 78    
                      : value === 1
                      ? 127
                      : value === 3
                      ? 214
                      : 163,
                }}
              ></div>
              <Tabs
                value={value}
                orientation="vertical"
                onChange={handleTabChange}
                textColor="primary"
                variant="scrollable"
                scrollButtons="auto"
                fullWidth
                centered
                classes={{
                  indicator: classes.indicator,
                }}
                aria-label="scrollable auto tabs example"
              >
                <Tab
                  classes={{ root: classes.tabRoot }}
                  label={
                    <CustomTab
                      icon={value === 0 ? Images.bill_active : Images.bill}
                      title="Billing Details"
                    />
                  }
                  {...a11yProps(0)}
                />
                <Tab
                  classes={{ root: classes.tabRoot }}
                  label={
                    <CustomTab
                      icon={
                        value === 1 ? Images.userLogo_active : Images.userLogo
                      }
                      title="User Details"
                    />
                  }
                  {...a11yProps(1)}
                />
              </Tabs>
            </AppBar>
          </div>
        </div>
        <div
          className="invoice-side"
          onClick={closeProfileMenu}
          style={{ height: "100vh", overflow: "hidden" }}
        >
          <div
            className="basic-search my-3"
            style={{ position: "relative", padding: "0px 10px" }}
          >
            <div className="control-bar justify-content-between">
              <span
                className="d-flex align-items-center"
                style={{ fontSize: "14px", cursor: "pointer" }}
                onClick={() => props.history.goBack()}
              >
                {}
                <span className="material-icons mr-1">arrow_back</span>
                Back
              </span>
              <div className="d-flex align-items-center">
                {/* {orgRole !== "Admin" && (
                  <button
                    type="button"
                    className="btn add-user mr-3"
                    onClick={showAddUserPopup}
                  >
                    <i className="fa fa-plus mr-1" aria-hidden="true"></i>Add
                    Users
                  </button>
                )} */}
                <LogoutBar
                  btnShow={logOutBtn}
                  isAccounts={false}
                  isAdmin={true}
                  callback={showHideLogoutBtn}
                  updateBar={barUpdate}
                  sendCallback={(val) => setAvatarUpdated(val)}
                ></LogoutBar>
              </div>
            </div>
          </div>
          {value == 1 && (
            <UserDetails
              dateSet={dateRange}
              refreshUsers={refreshUsers}
              userRoles={accessList}
              ownRole={orgRole}
              loaderCallback={(val) => handleShowLoader(val)}
              sendClearDateCallback={handleChangeDateSet}
              handleErrorCallback={onError}
            />
          )}
          {value == 0 && (
            <div
              className="section-container"
              style={{ display: "flex", justifyContent: "flex-end" }}
            >
              <Billing
                loaderCallback={(val) => handleShowLoader(val)}
                handleErrorCallback={onError}
              />
            </div>
          )}
        </div>
      </div>
    </div>
  );
};
export default AdminReport;
