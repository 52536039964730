import React, { useState } from "react";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  CircularProgress,
} from "@material-ui/core";
import {
  makeStyles,
  createTheme,
  ThemeProvider,
} from "@material-ui/core/styles";
import axios from "axios";
import { fetchAuthToken, fetchOrganisationId } from "../../utils/Helpers";
import SnackbarComponent from "../SnackbarComponent";
import { EXTRICATOR_BASE_URL, modelNameRegex } from "../../utils/constants";
import "./createmodel.module.scss";

const themeDialog = createTheme({
  overrides: {
    MuiDialog: {
      paper: {
        backgroundColor: "#011B3E !important",
      },
    },
    MuiDialogTitle: {
      root: {
        padding: "5px 10px",
      },
    },
    MuiTypography: {
      root: {
        color: "#aaa",
        fontSize: "14px !important",
      },
    },
    MuiDialogContentText: {
      root: {
        color: "#fff",
        fontSize: "16px !important",
      },
    },
    MuiDialogContent: {
      root: {
        width: "500px",
      },
    },
    MuiButton: {
      label: {
        textTransform: "capitalize",
      },
      text: {
        borderColor: "1px solid #35F8F1 !important",
      },
    },
  },
});

const modelStyles = makeStyles((theme) => ({
  label1: {
    color: "#35F8F1 !important",
    fontWeight: "600",
  },
  btnRoot2: {
    border: "1px solid #35F8F1",
    padding: "3px 33px",
  },
  label2: {
    color: "#011B3E !important",
  },
  circleColor: {
    stroke: "#35F8F1",
  },
  actionRoot: {
    padding: "8px 20px 20px 20px !important",
  },
}));

export default function CreateModelDialog({
  open,
  handleClose,
  handleConfirm,
  modelType,
  action,
}) {
  // Get styles from the modelStyles function
  const classes = modelStyles();
  // Get the auth token using the fetchAuthToken function
  var authToken = fetchAuthToken();
  // Initialize state variables using the useState hook
  const [modelName, setModelName] = useState("");
  const [loading, setLoading] = useState(false);
  const [errMsg, setErrMsg] = useState("");
  const [snackOpen, setSnackOpen] = useState(false);
  const [snackContent, setSnackContent] = useState({
    msg: "",
    color: "",
  });

  // This function is used to handle the form submission when creating or renaming a model
  const handleSubmitModel = (event) => {
    // Prevent the default form submission behavior
    event.preventDefault();
    // Get the organisation ID using the fetchOrganisationId function
    let ogi = fetchOrganisationId();

    // Initialize request body and API URL based on the action type
    var reqBody = {};
    var apiUrl = "";
    if (action === "create") {
      apiUrl = `${EXTRICATOR_BASE_URL}/create_rejected_model/`;
      reqBody = {
        model_name: modelName,
        model_type: modelType,
        ogi: ogi,
      };
    } else {
      apiUrl = `${EXTRICATOR_BASE_URL}/rename_rejected_model/`;
      reqBody = {
        model_id: open,
        model_name: modelName,
        ogi: ogi,
      };
    }
    // Check if the model name is valid and not empty
    if (modelName.trim() && !modelNameRegex.test(modelName)) {
      // Set the loading state to true while the request is being processed
      setLoading(true);
      // Send a POST request to the server with the request body and API URL
      axios({
        method: "post",
        url: apiUrl,
        data: reqBody,
        headers: {
          Authorization: `Bearer ${authToken}`,
        },
      })
        .then((response) => {
          
          // Set the loading state to false and close the dialog with a success message
          setLoading(false);
          handleClose();
          handleConfirm(modelName);
          setSnackOpen(true);
          setSnackContent({ msg: response.data.message, color: "success" });
        })
        .catch((error) => {
          // Set the loading state to false and show an error message in the dialog
          setLoading(false);
          if (error.response) {
            setSnackOpen(true);
            setSnackContent({
              msg: error.response.data.error,
              color: "error",
            });
          }
        });
    } else {
      // Show an error message if the model name is empty or contains special characters
      setErrMsg(`* Please enter valid Model name.
                *model name should not contain special characters!`);
    }
  };

  // This function is used to handle changes in the input field for the model name
  const handleModelInput = (event) => {
    // Update the modelName state with the new value
    setModelName(event.target.value);
    // Clear the error message if it exists
    if (errMsg) {
      setErrMsg("");
    }
  };

  // This function is used to handle the close event of the Snackbar component
  const handleSnackClose = (event, reason) => {
    // If the reason for closing is clickaway, do nothing
    if (reason === "clickaway") {
      return;
    }

    // Clear the modelName state and close the Snackbar component
    // setModelName("");
    setSnackOpen(false);
    setSnackContent({ msg: "", color: "" });
  };

  return (
    <>
      <SnackbarComponent
        isOpen={snackOpen}
        message={snackContent.msg}
        color={snackContent.color}
        handleClose={handleSnackClose}
      />
      <ThemeProvider theme={themeDialog}>
        <Dialog
          open={open}
          onClose={handleClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <form onSubmit={handleSubmitModel} autoComplete="off">
            <DialogContent>
              <DialogContentText id="alert-dialog-description">
                {action == "create" ? "Add a new model" : "Rename model"}
              </DialogContentText>
              <div className="mb-1">
                <input
                  className="form-control"
                  autoComplete="off"
                  type="text"
                  maxLength="25"
                  onChange={handleModelInput}
                  placeholder="Enter new model name"
                />
              </div>
              <a className="text-danger">{errMsg}</a>
            </DialogContent>
            <DialogActions classes={{ root: classes.actionRoot }}>
              {loading ? (
                <div className="col-sm-2">
                  <CircularProgress
                    classes={{ circle: classes.circleColor }}
                    size={30}
                    color="secondary"
                  />
                </div>
              ) : (
                <>
                  <button
                    type="button"
                    className="btn cancel-btn"
                    onClick={handleClose}
                  >
                    Cancel
                  </button>
                  <button
                    type="submit"
                    className="btn done-btn"
                    onClick={handleSubmitModel}
                  >
                    Done
                  </button>
                </>
              )}
            </DialogActions>
          </form>
        </Dialog>
      </ThemeProvider>
    </>
  );
}
