import React, { useEffect } from "react";
import Images from "../../../assets/asset_imports";
import "../dashboard.module.scss";
import { AppBar, Tabs, Tab } from "@material-ui/core";
import {
  makeStyles,
  createMuiTheme,
  ThemeProvider,
} from "@material-ui/core/styles";
import { fetchUserCompany } from "../../../utils/Helpers";
// Creating styles for the category component using Material-UI's makeStyles

const CategoryStyles = makeStyles((theme) => ({
  appbarRoot: {
    padding: "0 15px",
    background: "transparent",
    boxShadow: "none",
    marginTop: "15px",
  },
  tabRoot: {
    width: "180px",
    fontWeight: "600",
  },
  indicator: {
    backgroundColor: "transparent !important",
    height: "0px !important",
  },
}));
// Creating a card style for the organization component

const orgnCard = {
  display: "flex",
  flexDirection: "column",
  marginBottom: "10px",
  backgroundColor: "rgba(1,19,43,0.9)",
  "box-shadow": "-4px 7px 12px -1px rgba(1,19,43,0.67)",
  "-webkit-box-shadow": "-4px 7px 12px -1px rgba(1,19,43,0.67)",
  "-moz-box-shadow": "-4px 7px 12px -1px rgba(1,19,43,0.67)",
};
// Creating a Material-UI theme for the section component

const sectionTheme = createMuiTheme({
  overrides: {
    MuiAppBar: {
      root: {
        padding: "0px !important",
      },
    },
  },
});
// Creating a custom tab component for the category component

const CustomTab = ({ title, icon, isSelected }) => (
  <div
    className="customs-tab review-div d-flex"
    style={{ fontWeight: isSelected ? "600" : "200", height: "46px" }}
  >
    <div
      style={{
        width: "10px",
        height: "46px",
        backgroundColor: isSelected ? "aqua" : "transparent",
        borderRadius: "0px 2px 2px 0px",
      }}
    ></div>
    <img src={icon} className="ml-2" />
    {title}
  </div>
);

const FileCategoryComponent = (props) => {
  var companyActive = fetchUserCompany();

  let { docType, defaultActiveTab, docStatus, reAuthenticate } = props;
  const [value, setValue] = React.useState(1);
  const [formValue, setFormValue] = React.useState(1);
  const [passportValue, setPassportValue] = React.useState(1);


  /**

useEffect hook that updates value and formValue based on docType.
@param {string} docType - The type of the document.
*/
  useEffect(() => {
    setValue(1);
    setFormValue(1);
    setPassportValue(1)
  }, [docType]);
  /**

useEffect hook that updates formValue or value based on docStatus.
If docStatus is null, re-authenticates the user.
@param {string} docStatus - The status of the document.
*/
  useEffect(() => {
    if (docStatus !== null) {
      if (docStatus.includes("PROCESSING")) {
        if (docType == "FORM") {
          setFormValue(0);
        } else if (docType == "PASSPORT") {
          setPassportValue(0);
        } else {
          setValue(0);
        }
      }
    } else {
      reAuthenticate(true);
    }
  }, [docStatus]);
  /**

useEffect hook that updates the formValue or value based on the document type and the default active tab.
@param {string} props.defaultActiveTab - The default active tab of the component.
*/

  useEffect(() => {
    if (docType == "FORM") {
      if (defaultActiveTab == "FORMS_PROCESSING") {
        setFormValue(0);
      } else if (defaultActiveTab == "FORMS_REVIEW") {
        setFormValue(1);
      } else if (defaultActiveTab == "FORMS_PARSED") {
        setFormValue(2);
      } else if (defaultActiveTab == "FORMS_DELETED") {
        setFormValue(3);
      }
    } else if (docType == "INVOICE") {
      if (defaultActiveTab == "INVOICE_PROCESSING") {
        setValue(0);
      } else if (defaultActiveTab == "INVOICE_REVIEW") {
        setValue(1);
      } else if (defaultActiveTab == "INVOICE_PARSED") {
        setValue(2);
      } else if (defaultActiveTab == "INVOICE_REJECTED") {
        setValue(3);
      } else if (defaultActiveTab == "INVOICE_DELETE") {
        setValue(4);
      }
    } else if (docType == "RECEIPT") {
      if (defaultActiveTab == "RECEIPT_PROCESSING") {
        setValue(0);
      } else if (defaultActiveTab == "RECEIPT_REVIEW") {
        setValue(1);
      } else if (defaultActiveTab == "RECEIPT_PARSED") {
        setValue(2);
      } else if (defaultActiveTab == "RECEIPT_REJECTED") {
        setValue(3);
      } else if (defaultActiveTab == "RECEIPT_DELETE") {
        setValue(4);
      }
    } else if (docType == "UTILITY") {
      if (defaultActiveTab == "UTILITY_PROCESSING") {
        setValue(0);
      } else if (defaultActiveTab == "UTILITY_REVIEW") {
        setValue(1);
      } else if (defaultActiveTab == "UTILITY_PARSED") {
        setValue(2);
      } else if (defaultActiveTab == "UTILITY_REJECTED") {
        setValue(3);
      } else if (defaultActiveTab == "UTILITY_DELETE") {
        setValue(4);
      }
    } else if (docType == "PORDER") {
      if (defaultActiveTab == "PORDER_PROCESSING") {
        setValue(0);
      } else if (defaultActiveTab == "PORDER_REVIEW") {
        setValue(1);
      } else if (defaultActiveTab == "PORDER_PARSED") {
        setValue(2);
      } else if (defaultActiveTab == "PORDER_REJECTED") {
        setValue(3);
      } else if (defaultActiveTab == "PORDER_DELETE") {
        setValue(4);
      }
    } else if (docType == "PASSPORT") {
      if (defaultActiveTab == "PASSPORT_PROCESSING") {
        setPassportValue(0);
      } else if (defaultActiveTab == "PASSPORT_REVIEW") {
        setPassportValue(1);
      } else if (defaultActiveTab == "PASSPORT_DELETE") {
        setPassportValue(2);
      }
    }
  }, [props.defaultActiveTab]);

  const classes = CategoryStyles();
  /**

Returns an object with id and aria-controls properties based on the provided index.
@param {number} index - The index used to generate the id and aria-controls properties.
@returns {object} - An object with id and aria-controls properties.
*/
  function a11yProps(index) {
    return {
      id: `scrollable-auto-tab-${index}`,
      "aria-controls": `scrollable-auto-tabpanel-${index}`,
    };
  }
  /**

Function that handles changes in the value of the tabs and updates the active tab.
@param {object} event - The event object.
@param {number} newValue - The new value of the tabs.
*/
  const handleChange = (event, newValue) => {
    if (props.docType == "INVOICE") {
      setValue(newValue);
      if (newValue === 0) {
        props.handleCategoryClick("INVOICE_PROCESSING");
      } else if (newValue === 1) {
        props.handleCategoryClick("INVOICE_REVIEW");
      } else if (newValue === 2) {
        props.handleCategoryClick("INVOICE_PARSED");
      } else if (newValue === 3) {
        props.handleCategoryClick("INVOICE_REJECTED");
      } else if (newValue === 4) {
        props.handleCategoryClick("INVOICE_DELETE");
      }
    } else if (props.docType == "RECEIPT") {
      setValue(newValue);
      if (newValue === 0) {
        props.handleCategoryClick("RECEIPT_PROCESSING");
      } else if (newValue === 1) {
        props.handleCategoryClick("RECEIPT_REVIEW");
      } else if (newValue === 2) {
        props.handleCategoryClick("RECEIPT_PARSED");
      } else if (newValue === 3) {
        props.handleCategoryClick("RECEIPT_REJECTED");
      } else if (newValue === 4) {
        props.handleCategoryClick("RECEIPT_DELETE");
      }
    } else if (props.docType == "FORM") {
      setFormValue(newValue);

      if (newValue === 0) {
        props.handleCategoryClick("FORMS_PROCESSING");
      } else if (newValue === 1) {
        props.handleCategoryClick("FORMS_REVIEW");
      } else if (newValue === 2) {
        props.handleCategoryClick("FORMS_PARSED");
      } else if (newValue === 3) {
        props.handleCategoryClick("FORMS_DELETED");
      }
    } else if (props.docType == "UTILITY") {
      setValue(newValue);
      if (newValue === 0) {
        props.handleCategoryClick("UTILITY_PROCESSING");
      } else if (newValue === 1) {
        props.handleCategoryClick("UTILITY_REVIEW");
      } else if (newValue === 2) {
        props.handleCategoryClick("UTILITY_PARSED");
      } else if (newValue === 3) {
        props.handleCategoryClick("UTILITY_REJECTED");
      } else if (newValue === 4) {
        props.handleCategoryClick("UTILITY_DELETE");
      }
    } else if (props.docType == "PORDER") {
      setValue(newValue);
      if (newValue === 0) {
        props.handleCategoryClick("PORDER_PROCESSING");
      } else if (newValue === 1) {
        props.handleCategoryClick("PORDER_REVIEW");
      } else if (newValue === 2) {
        props.handleCategoryClick("PORDER_PARSED");
      } else if (newValue === 3) {
        props.handleCategoryClick("PORDER_REJECTED");
      } else if (newValue === 4) {
        props.handleCategoryClick("PORDER_DELETE");
      }
    } else if (props.docType == "PASSPORT") {
      setPassportValue(newValue);
      if (newValue === 0) {
        props.handleCategoryClick("PASSPORT_PROCESSING");
      } else if (newValue === 1) {
        props.handleCategoryClick("PASSPORT_REVIEW");
      } else if (newValue === 2) {
        props.handleCategoryClick("PASSPORT_DELETE");
      }
    }

  };

  return (
    <ThemeProvider theme={sectionTheme}>
      {props.docType !== "FORM" && props.docType !== "PASSPORT" && 
        <AppBar position="static" classes={{ root: classes.appbarRoot }}>
          {/* <div style={orgnCard}>
            <label
              className="mb-0"
              style={{ fontSize: "9px", margin: "10px 0 0 10px" }}
            >
              Your Organization
            </label>
            <label style={{ marginLeft: "10px" }}>{companyActive}</label>
          </div> */}
          <Tabs
            value={value}
            orientation="vertical"
            onChange={handleChange}
            textColor="primary"
            variant="scrollable"
            scrollButtons="auto"
            fullWidth
            centered
            classes={{
              indicator: classes.indicator,
            }}
            aria-label="scrollable auto tabs example"
          >
            <Tab
              classes={{ root: classes.tabRoot }}
              label={
                <CustomTab
                  classes={classes}
                  isSelected={value == 0}
                  icon={
                    value == 0
                      ? Images.processing_active
                      : Images.processing_inactive
                  }
                  title="Processing"
                />
              }
              {...a11yProps(0)}
            />
            <Tab
              classes={{ root: classes.tabRoot }}
              label={
                <CustomTab
                  classes={classes}
                  isSelected={value == 1}
                  icon={
                    value == 1
                      ? Images.dashboard_to_review
                      : Images.dashboard_to_review_active
                  }
                  title="To Review"
                />
              }
              {...a11yProps(1)}
            />
            <Tab
              classes={{ root: classes.tabRoot }}
              label={
                <CustomTab
                  isSelected={value == 2}
                  icon={
                    value == 2
                      ? Images.dashboard_parsed_checked
                      : Images.dashboard_checked
                  }
                  title="Parsed"
                />
              }
              {...a11yProps(2)}
            />

            <Tab
              classes={{ root: classes.tabRoot }}
              label={
                <CustomTab
                  isSelected={value == 3}
                  icon={value === 3 ? Images.rejected_active : Images.rejected}
                  title="Rejected"
                />
              }
              {...a11yProps(3)}
            />

            <Tab
              classes={{ root: classes.tabRoot }}
              label={
                <CustomTab
                  isSelected={value == 4}
                  icon={
                    value === 4
                      ? Images.dashboard_delete_active
                      : Images.dashboard_delete
                  }
                  title="Deleted"
                />
              }
              {...a11yProps(4)}
            />
          </Tabs>
        </AppBar>
      }
      {props.docType == "FORM" && 
        <AppBar position="static" classes={{ root: classes.appbarRoot }}>
          {/* <div style={orgnCard}>
            <label style={{ fontSize: "9px", margin: "10px 0 0 10px" }}>
              Your Organization
            </label>
            <label style={{ marginLeft: "10px" }}>{companyActive}</label>
          </div> */}
          <Tabs
            value={value}
            orientation="vertical"
            onChange={handleChange}
            textColor="primary"
            variant="scrollable"
            scrollButtons="auto"
            fullWidth
            centered
            classes={{
              indicator: classes.indicator,
            }}
            aria-label="scrollable auto tabs example"
          >
            <Tab
              classes={{ root: classes.tabRoot }}
              label={
                <CustomTab
                  classes={classes}
                  isSelected={formValue == 0}
                  icon={
                    formValue == 0
                      ? Images.processing_active
                      : Images.processing_inactive
                  }
                  title="Processing"
                />
              }
              {...a11yProps(0)}
            />
            <Tab
              classes={{ root: classes.tabRoot }}
              label={
                <CustomTab
                  isSelected={formValue == 1}
                  icon={
                    formValue == 1
                      ? Images.dashboard_to_review
                      : Images.dashboard_to_review_active
                  }
                  title="To Review"
                />
              }
              {...a11yProps(1)}
            />
            <Tab
              classes={{ root: classes.tabRoot }}
              label={
                <CustomTab
                  isSelected={formValue == 2}
                  icon={
                    formValue == 2
                      ? Images.dashboard_parsed_checked
                      : Images.dashboard_checked
                  }
                  title="Parsed"
                />
              }
              {...a11yProps(2)}
            />

            <Tab
              classes={{ root: classes.tabRoot }}
              label={
                <CustomTab
                  isSelected={formValue == 3}
                  icon={
                    formValue === 3
                      ? Images.dashboard_delete_active
                      : Images.dashboard_delete
                  }
                  title="Deleted"
                />
              }
              {...a11yProps(3)}
            />
          </Tabs>
        </AppBar>
      }
      {props.docType == "PASSPORT" && 
        <AppBar position="static" classes={{ root: classes.appbarRoot }}>
          {/* <div style={orgnCard}>
            <label style={{ fontSize: "9px", margin: "10px 0 0 10px" }}>
              Your Organization
            </label>
            <label style={{ marginLeft: "10px" }}>{companyActive}</label>
          </div> */}
          <Tabs
            value={value}
            orientation="vertical"
            onChange={handleChange}
            textColor="primary"
            variant="scrollable"
            scrollButtons="auto"
            fullWidth
            centered
            classes={{
              indicator: classes.indicator,
            }}
            aria-label="scrollable auto tabs example"
          >
            <Tab
              classes={{ root: classes.tabRoot }}
              label={
                <CustomTab
                  classes={classes}
                  isSelected={passportValue == 0}
                  icon={
                    passportValue == 0
                      ? Images.processing_active
                      : Images.processing_inactive
                  }
                  title="Processing"
                />
              }
              {...a11yProps(0)}
            />
            <Tab
              classes={{ root: classes.tabRoot }}
              label={
                <CustomTab
                  isSelected={passportValue == 1}
                  icon={
                    passportValue == 1
                      ? Images.dashboard_to_review
                      : Images.dashboard_to_review_active
                  }
                  title="To Review"
                />
              }
              {...a11yProps(1)}
            />
            
            <Tab
              classes={{ root: classes.tabRoot }}
              label={
                <CustomTab
                  isSelected={passportValue == 2}
                  icon={
                    passportValue === 2
                      ? Images.dashboard_delete_active
                      : Images.dashboard_delete
                  }
                  title="Deleted"
                />
              }
              {...a11yProps(2)}
            />
          </Tabs>
        </AppBar>
      }
      
    </ThemeProvider>
  );
};

export default FileCategoryComponent;
