import React, { useState } from "react";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  CircularProgress,
  DialogTitle,
} from "@material-ui/core";
import {
  makeStyles,
  createTheme,
  ThemeProvider,
} from "@material-ui/core/styles";
import "./deletedialog.module.scss";

const themeDialog = createTheme({
  overrides: {
    MuiDialog: {
      paper: {
        backgroundColor: "#011B3E !important",
      },
    },
    MuiDialogTitle: {
      root: {
        padding: "5px 10px",
      },
    },
    MuiTypography: {
      root: {
        color: "#aaa",
        fontSize: "14px !important",
      },
    },
    MuiDialogContentText: {
      root: {
        color: "#fff",
        fontSize: "16px !important",
      },
    },
  },
});

const buttonStyles = makeStyles((theme) => ({
  label1: {
    color: "#35F8F1 !important",
  },
  btnRoot2: {
    backgroundColor: "#35F8F1",
  },
  label2: {
    color: "#011B3E !important",
  },
  circleColor: {
    stroke: "#35F8F1",
  },
}));

export default function DeleteDialogBox({
  open,
  handleClose,
  content,
  handleConfirm,
  dialogTitle,
  inProgress,
}) {
  // Define a constant variable 'classes' using the 'buttonStyles' function.
  const classes = buttonStyles();

  // Defines the action to be performed when the OK button is clicked.
  // Invokes the 'handleConfirm()' function with a 'true' argument.
  const handleOkBtnAction = () => {
    handleConfirm(true);
  };

  return (
    <ThemeProvider theme={themeDialog}>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <form className="delete-file" onSubmit={handleOkBtnAction}>
          <DialogTitle
            style={{ padding: "15px 15px 5px 15px" }}
            id="alert-dialog-title"
          >
            {dialogTitle}
          </DialogTitle>
          <DialogContent style={{ padding: "8px 15px" }}>
            <DialogContentText
              style={{ marginBottom: 0 }}
              id="alert-dialog-description"
            >
              {content}
            </DialogContentText>
          </DialogContent>
          <DialogActions style={{ padding: "12px 20px 20px 20px" }}>
            {inProgress ? (
              <div className="col-sm-2">
                <CircularProgress
                  classes={{ circle: classes.circleColor }}
                  size={30}
                  color="secondary"
                />
              </div>
            ) : (
              <>
                <button
                  type="button"
                  className="btn cancel-btn"
                  onClick={() => handleClose()}
                >
                  Cancel
                </button>
                <button
                  type="button"
                  className="btn done-btn"
                  onClick={() => handleOkBtnAction()}
                >
                  Confirm
                </button>
              </>
            )}
          </DialogActions>
        </form>
      </Dialog>
    </ThemeProvider>
  );
}
