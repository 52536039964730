import React from "react";
import Routes from "./views/App";
import { BrowserRouter as Router } from "react-router-dom";
// Defines the main App component, which wraps the Routes component with a Router component

const App = () => (
  <Router>
    <Routes />
  </Router>
);
// Exports the App component as the default export of this module

export default App;
