/*
 * HomePage
 *
 * This is the first thing users see of our App, at the '/' route
 *
 */

import React from "react";
import "./Format_invoice.module.scss";
import Images from "../../assets/asset_imports";

const FormatInvoice = () => {
  return (
    <div id="invoice" className="container">
      <div className="invoice overflow-auto">
        <div style={{ minWidth: "600px" }}>
          <header>
            <div className="row">
              <div className="col">
                <a target="_blank" href="/">
                  <img
                    src={Images.app_logo_inv}
                    height={100}
                    width={250}
                    data-holder-rendered="true"
                  />
                </a>
              </div>
              <div className="col company-details">
                <div className="parallelogram">
                  <div className="triangle-down"></div>
                  <h1>INVOICE</h1>
                  <div className="triangle-up"></div>
                </div>
              </div>
            </div>
          </header>
          <main>
            <div className="row contacts">
              <div className="col invoice-to">
                <div className="text-gray-light">Invoice to:</div>
                <h5 className="to">Dwayne Clark</h5>
                <div className="address col-5">
                  796 Silver Harbour, Dummy street area,Lorem ipsum TX 79273, US
                </div>
                <div className="email">
                  <a href="mailto:john@example.com">john@example.com</a>
                </div>
              </div>
              <div className="col-3 mr-4 invoice-details">
                <ul className="list-group">
                  <li className="list-group-item d-flex justify-content-between align-items-center date">
                    Invoice#:
                    <span>01/10/2018</span>
                  </li>
                  <li className="list-group-item d-flex justify-content-between align-items-center date">
                    Date
                    <span>30/10/2018</span>
                  </li>
                </ul>
              </div>
            </div>
            <table border="0" cellspacing="0" cellpadding="0" id="particulars">
              <thead>
                <tr>
                  <th>SL</th>
                  <th className="text-left">Item Description</th>
                  <th className="text-right">From</th>
                  <th className="text-right">To</th>
                  <th className="text-right">Total</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td className="unit">1</td>
                  <td className="text-left">Oodles tech,Agicent,Kony</td>
                  <td className="unit">1/09/2018</td>
                  <td className="qty">30/09/2018</td>
                  <td className="unit">$50.00</td>
                </tr>
                {[1, 2, 3, 4].map((item) => {
                  return (
                    <tr style={{ height: "50px" }}>
                      <td className="unit"></td>
                      <td className="text-left"></td>
                      <td className="unit"></td>
                      <td className="qty"></td>
                      <td className="unit"></td>
                    </tr>
                  );
                })}
              </tbody>
              <tfoot id="table_foot">
                <tr>
                  <td colspan="2">
                    <ul className="list-group">
                      <li className="list-group-item d-flex justify-content-between align-items-center foot-list">
                        Account#:
                        <span>0123 4567 8901</span>
                      </li>
                    </ul>
                  </td>
                  <td colspan="2">Sub Total</td>
                  <td>$50.00</td>
                </tr>
                <tr>
                  <td colspan="2">
                    <ul className="list-group">
                      <li className="list-group-item d-flex justify-content-between align-items-center foot-list">
                        A/C Name
                        <span>Lorem Ipsum</span>
                      </li>
                    </ul>
                  </td>
                  <td colspan="2">Tax</td>
                  <td>0.00%</td>
                </tr>
                <tr>
                  <td colspan="3">
                    <ul className="list-group">
                      <li className="list-group-item d-flex justify-content-between align-items-center foot-list">
                        Bank Details
                        <span>add your bank details</span>
                      </li>
                    </ul>
                  </td>
                  <td colspan="1" style={{ borderTop: "2px solid #000" }}>
                    Total:
                  </td>
                  <td style={{ borderTop: "2px solid #000" }}>$50.00</td>
                </tr>
              </tfoot>
            </table>
          </main>
          <footer>
            Invoice was created on a computer and is valid without the signature
            and seal.
          </footer>
        </div>
        <div></div>
      </div>
    </div>
  );
};

export default FormatInvoice;
