import React from "react";
import "./terms.module.scss";
import Images from "../../assets/asset_imports";
import FooterComponent from "../../components/footer-component";

export default function TrasnactionalPolicy(props) {
  return (
    <div className="col-12 mains_container p-0">
      <nav className="navbar px-5 py-3" style={{ backgroundColor: "#031B40" }}>
        <a className="navbar-brand" href="/">
          <img
            src={Images.app_logo}
            className="img-responsive"
            style={{ width: "75%", height: "40px" }}
            alt=""
          />
        </a>
      </nav>
      <div className="col-12 tnc_container px-5">
        <br />
        <h1 className="c5" id="h.h6is553xzpfh">
          <span className="c7 c2">Transnational Currency</span>
        </h1>
        <p className="c5">
          <span className="c0">
            Regardless of our location, all payments required for subscribing
            our services shall be made in United States Dollar (USD)
            irrespective of the countries in which sale(s) are made. Whenever
            for the purpose of calculating any sums outstanding during the
            subscription of our services conversion from South African Rand
            (ZAR) shall be made to USD.
          </span>
        </p>
        <p className="c4">
          <span className="c0"></span>
        </p>
        <p className="c5">
          <span className="c0">
            The local currency shall be converted into USD based on the rate of
            exchange published by Wall Street Journal, U.S on the date of
            subscribing or on the last working day to which such payment
            pertains. The USD equivalent of the local currency will be the
            payable amount for the buyer.
          </span>
        </p>
        <p className="c4">
          <span className="c0"></span>
        </p>
      </div>
      <FooterComponent />
    </div>
  );
}
