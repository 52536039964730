import React from "react";
import "./footer.module.scss";
import { Link } from "react-router-dom";
// This is the FooterComponent component, which is a reusable component for displaying the footer of the application

export default function FooterComponent(props) {
  return (
    <footer>
      <div className="pb-4">
        <div
          className="col-12 footer-intro container text-center form-group"
          style={{ paddingTop: "2em" }}
        >
          <h4>
            Smart and Reliable Invoice Data Capture with up to 98% Accuracy
          </h4>
        </div>

        <div
          className="col-12 disclaimer"
          style={{ fontSize: "12px", paddingBottom: 10 }}
        >
          &copy; 2023 Neural-Xtractor. All right reserved.
        </div>
        {/* <div className="d-flex justify-content-center mt-3">
          <div className="col-lg-8 col-md-8 mb-4 mb-md-0">
            <div className="row policies">
              <Link
                to="/terms_conditions"
                target="_blank"
                rel="noopener noreferrer"
              >
                Terms & Conditions
              </Link>
              <h6>|</h6>
              <Link
                to="/privacy_policy"
                target="_blank"
                rel="noopener noreferrer"
              >
                Privacy Policy
              </Link>
              <h6>|</h6>
              <Link
                to="/transaction_policy"
                target="_blank"
                rel="noopener noreferrer"
              >
                Transnational Currency
              </Link>
              <h6>|</h6>
              <Link
                to="/shipping_policy"
                target="_blank"
                rel="noopener noreferrer"
              >
                Shipping/Delivery Policy
              </Link>
              <h6>|</h6>
              <Link
                to="/cancellation_policy"
                target="_blank"
                rel="noopener noreferrer"
              >
                Cancellation Policy
              </Link>
              <h6>|</h6>
              <Link
                to="/return_policy"
                target="_blank"
                rel="noopener noreferrer"
              >
                Return Policy
              </Link>
              <h6>|</h6>
              <Link
                to="/refund_policy"
                target="_blank"
                rel="noopener noreferrer"
              >
                Refund Policy
              </Link>
              {/* <h6>|</h6>
              <a
                href="https://www.innovsoltech.com/jurisdiction-clause.php"
                target="_blank"
                rel="noopener noreferrer"
              >
                Regulation Laws and Jurisdiction
              </a>
              <h6>|</h6>
              <a
                href="https://www.innovsoltech.com/about.php"
                target="_blank"
                rel="noopener noreferrer"
              >
                About Us
              </a>
              <h6>|</h6>
              <a
                href="https://www.innovsoltech.com/contact.php"
                target="_blank"
                rel="noopener noreferrer"
              >
                Contact Us
              </a> 
            </div>
          </div>
        </div> */}
      </div>
    </footer>
  );
}
