import React from "react";
import { jsPlumb } from "jsplumb";
import $ from "jquery";
import "./PassportDetails.module.scss";
import LogoutBar from "../../components/LogoutBar";
import CustomDropDown from "../../components/CustomDropDown";
import Images from "../../assets/asset_imports";
import {
  checkOrganizationError,
  fetchActiveSection,
  fetchAuthToken,
  fetchOrganisationId,
} from "../../utils/Helpers";
import axios from "axios";
import {
  CircularProgress,
  Backdrop,
  Tooltip,
  makeStyles,
} from "@material-ui/core";
import DeleteDialogBox from "../../components/DeleteDialog/DeleteDialogBox";
import DocNotFoundDialog from "../../components/NotFoundDialog";
import CreateModelDialog from "../../components/CreateModelDialog/CreateModelDialog";
import CheckMobileDevice from "../../components/CheckMobileDevice";
import SnackbarComponent from "../../components/SnackbarComponent";
import { EXTRICATOR_BASE_URL } from "../../utils/constants";
/**

This component defines the styles for the backdrop used in modals
*/
const styles = (theme) => ({
  backdrop: {
    zIndex: 10,
    color: "#fff",
  },
});
/**

Width and height for the SVG element
*/
var svgWidth = 870;

var svgHeight = 1150;
/**

Custom styles for the Bootstrap tooltip component
*/
const useStylesBootstrap = makeStyles((theme) => ({
  arrow: {
    color: theme.palette.common.black,
  },
  tooltip: {
    backgroundColor: theme.palette.common.black,
  },
}));
/**

Wrapper component for the Bootstrap tooltip component with custom styles
*/
function BootstrapTooltip(props) {
  const classes = useStylesBootstrap();
  return <Tooltip classes={classes} placement="top" {...props} arrow />;
}
/**

Styles for the nodes in the SVG element
*/
const nodeStyle = {
  fill: "transparent",
  radius: 6,
  outlineStroke: "#2F72FF",
  outlineWidth: 20,
  strokeWidth: 5,
};
class PassportDetails extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      previewImg: [],
      zoomFactor: 1,
      toastColor: "success",
      successMsg: "",
      snackOpen: false,
      valueFocussed: null,
      editChangeCount: 0,
      isLoading: false,
      vendorName: "",
      fileName: "",
      invalidInvoice: false,
      leftKeys: [],
      rightKeys: [],
      customLeftKeys: [],
      linking: [],
      customName: [],
      leftKeyEditId: null,
      inputFocused: false,
      customRightKeys: [],
      deletedLeftKeys: [],
      selectedLeftKey: null,
      selectedCustomLeftKey: null,
      focusRightKey: null,
      customInputFocused: null,
      customLeftKeyText: "",
      inputFocusCustom: false,
      alteredKey: null,
      avatar:
        "https://www.gravatar.com/avatar/205e460b479e2e5b48aec07710c08d50",
      isCustomDeleted: false,
      showInvoiceDetails: true,
      showAmounts: false,
      pageValue: 1,
      invoiceImage: "",
      invoiceDetailsMap: "",
      boundsData: "",
      leftSourceId: 1,
      leftSourceIndex: 0,
      authenticationToken: "",
      dialogOpen: false,
      newModelDialogOpen: false,
      showLineItems: false,
      lineTable: false,
      userEmail: "",
      showLogoutBtn: false,
      lineItemslist: [],
      deleteHovered: false,
      totalRows: "0",
      lineItemTableData: [],
      maxRows: 0,
      activeSection: "",
      newModel: "",
      modalMenuOpen: null,
      selectedModel: null,

    //   passportNumber: null,
    //   givenNames: null,
    //   familyName: null,
    //   nationality: null,
    //   issueCountry: null,
    //   dateOfBirth: null,
    //   expirationDate: null,
    //   sex: null,
    passportData: [],
    passportDataKeys: [],
    passportDataValues: [],
    };
    this.globalLeftClickedKey = null;
    this.leftKeysArr = [];
    this.connection = null;
    this.ogi = fetchOrganisationId();
    this.activeSection = fetchActiveSection();
    this.docTopOffset = 0;
    this.bodyHeight = $("body").height();
  }

  componentDidMount() {
    // Disable body overflow and initialize jsPlumbInstance

    $("body").css("overflow", "hidden");
    this.jsPlumbInstance = jsPlumb.getInstance({
      Container: $("#drag_canvas"),
      ConnectionsDetachable: false,
      Anchor: ["RightMiddle", "LeftMiddle"],
      EndpointStyle: { fill: "transparent", radius: 0 },
      Connector: [
        "Straight",
        {
          stub: [70, 35],
          gap: [6, 6],
          cornerRadius: 10,
          alwaysRespectStubs: false,
        },
      ],
      PaintStyle: {
        strokeWidth: 3,
        stroke: "#2F72FF",
        transition: ".2s",
      },
    });

    this.handleResolutionChange(this);
    this.fetchDocument();
  }

  componentWillUnmount() {
    // Remove jsPlumbInstance endpoints and event listeners, and enable body overflow

    if (this.jsPlumbInstance) {
      this.jsPlumbInstance.deleteEveryEndpoint();
      this.jsPlumbInstance = null;
    }
    $(document).off("mousedown");
    $(document).off("mousemove");
    $(document).off("mouseup");

    $("body").css("overflow-x", "unset");
  }

  handleDrawBox = (self) => {
    // Define variables to store the widget, cursor positions, and other state

    $(function () {
      var widget;
      var x;
      var y;
      var finX;
      var finY;
      var ismousedown = false;
      var coords = {};
      var allowDraw = true;
      var incidentPage = 0;
      // Add event listeners for mouse down, move, and up

      $(document).on({
        mousedown: function (event) {
          // Get the page number of the clicked element

          incidentPage = Number($(event.target).attr("title")) + 1;
          // Check if the clicked element has class "formwrapper"

          if ($(event.target).attr("class") == "formwrapper") {
            // Store initial cursor position and create a new widget element

            x = event.pageX;
            y = event.pageY;
            coords.x1 = event.offsetX;
            coords.y1 = event.offsetY;
            $("body").append(
              '<div class="widget" style="top:' +
                y +
                "px; left: " +
                x +
                'px;"></div>'
            );
            widget = $(".widget").last();
            ismousedown = true;
          }
        },
        mousemove: function (event) {
          if (ismousedown == true) {
            // Update the widget dimensions and position while mouse is moved

            finX = event.pageX;
            finY = event.pageY;

            widget.width(finX - x > 0 ? finX - x : x - finX);
            widget.height(finY - y > 0 ? finY - y : y - finY);
            finX - x > 0
              ? widget.css({ left: `${x}px`, right: "" })
              : widget.css({
                  right: `${$("#drag_canvas").width() - x}px`,
                  left: "",
                });
            finY - y > 0
              ? widget.css({ top: `${y}px`, bottom: "" })
              : widget.css({
                  bottom: `${$("body").height() - y - 3}px`,
                  top: "",
                });

            widget.css({
              width: (finX - x > 0 ? finX - x : x - finX) + "!important",
              height: (finY - y > 0 ? finY - y : y - finY) + "!important",
              display: "block",
              border: "2px dashed #ccc",
            });
          }
        },
        mouseup: function (event) {
          if (ismousedown == true) {
            // On mouse up, finalize the widget and send the coordinates to another function

            ismousedown = false;
            widget.css({
              background: "#222",
              border: "0",
              cursor: "move",
            });

            finX = event.pageX;
            finY = event.pageY;

            coords.width = Math.abs(finX - x) + 4;
            coords.height = Math.abs(finY - y) + 4;

            if (coords.width != 4 && coords.height != 4) {
              coords.x2 = coords.x1 + finX - x;
              coords.y2 = coords.y1 + finY - y;
              //for creating the bounding box calculations it require that x2 > x1
              if (coords.x2 < coords.x1)
                [coords.x1, coords.x2] = [coords.x2, coords.x1];
              //for creating the bounding box calculations it requirs that y2 > y1
              if (coords.y2 < coords.y1)
                [coords.y1, coords.y2] = [coords.y2, coords.y1];

              $(".widget").remove();
              if (allowDraw) {
                self.fetchCoordinatesValue(coords, incidentPage);
                widget = null;
                x = null;
                y = null;
                finX = null;
                finY = null;
                ismousedown = false;
                coords = {};
              }
            }
          }
        },
      });
    });
  };
  // function to fetch coordinates value and save it as a new custom right key

  fetchCoordinatesValue = async (coord, pageNumber) => {
    // show loading spinner

    this.setState({
      isLoading: true,
    });
    // extract doc_id from URL params and fetch auth token

    let { doc_id } = this.props.match.params;
    var date = new Date();
    var authToken = await fetchAuthToken();
    // create a bounding box object with normalized coordinates

    const boundingBox = {
      x1: coord.x1 / svgWidth,
      y1: coord.y1 / svgHeight,
      x2: coord.x2 / svgWidth,
      y2: coord.y1 / svgHeight,
      x3: coord.x2 / svgWidth,
      y3: coord.y2 / svgHeight,
      x4: coord.x1 / svgWidth,
      y4: coord.y2 / svgHeight,
    };
    // create a new custom right key object with the extracted value and bounding box

    const rightKey = {
      ExtricatorRightkey: `ExtricatorNewRightkey${date.getTime()}_${pageNumber}`,
      value: "",
      table_data: false,
      custom_box: true,
      page_number: pageNumber,
      boundingBoxes: [boundingBox],
    };
    // send a POST request to extract data from the bounding box

    axios({
      method: "post",
      url: `${EXTRICATOR_BASE_URL}/extract_data_coordniate/`,
      data: { ...rightKey, file_id: doc_id, ogi: this.ogi },
      headers: {
        Authorization: `Bearer ${authToken}`,
      },
    })
      .then((res) => {
        // update the right key object with the extracted value

        rightKey.value = res.data.value; //"//Set response Value"
        rightKey.key = `CustomBoundBox${date.getTime()}`;
        // update the state with the new custom right key

        let rightKeysCustom = [...this.state.customRightKeys];

        for (let i = 0; i < rightKeysCustom.length; i++) {
          if (i === pageNumber - 1) {
            rightKeysCustom[i].push(rightKey);
          }
        }
        this.allowDraw = false;
        this.setState({ customRightKeys: rightKeysCustom, isLoading: false });
      })
      .catch((error) => {
        // handle error response

        if (error.response) {
          if (error.response.data && error.response.data.non_field_errors) {
            this.allowDraw = false;
            this.setState({
              isLoading: false,
              errorMsg: error.response.data.non_field_errors[0],
            });
          }
        }
      });
  };

  handleResolutionChange = (self) => {
    $(window).resize(function () {
      if (self.connection && self.jsPlumbInstance) {
        if (self.connection.source && self.connection.source) {
          self.jsPlumbInstance.deleteConnection(self.connection);
        }
      }
    });
  };
  /**
   * Deletes a connection in jsPlumbInstance
   */
  deleteConnection = () => {
    if (this.jsPlumbInstance) {
      this.jsPlumbInstance.deleteConnection(this.connection);
    }
  };
  /**
   * Fetches the document from the server, including the invoice image and some data related to the document
   */
  fetchDocument = async () => {
    var authToken = await fetchAuthToken();
    this.setState({
      isLoading: true,
      authenticationToken: authToken,
    });
    let { doc_id } = this.props.match.params;

    let reqBody = {
      file_id: doc_id,
      ogi: this.ogi,
    };

    //To fetch the Invoice image
    axios({
      method: "post",
      url: `${EXTRICATOR_BASE_URL}/open_doc/`,
      data: reqBody,
      headers: {
        Authorization: `Bearer ${authToken}`,
      },
    })
      .then((res) => {
        this.setState({
          isLoading: false,
        });
        var imageURLs = [];
        imageURLs = res.data.file_link.split(",");
        this.setState(
          {
            previewImg: res.data.file_link.split(","),
            vendorName: res.data.vendor_name, //edit her instead of original_file_name
            fileName: res.data.original_file_name,
          },
          () => {
            this.getDocumentData();
          }
        );
      })
      .catch((error) => {
        if (error.response) {
          if (error.response.data.non_field_errors) {
            if (
              error.response.data.non_field_errors[0].includes(
                "Kindly pay the bill"
              )
            ) {
              this.setState({
                successMsg: error.response.data.non_field_errors[0],
                snackOpen: false,
                toastColor: "error",
              });
            } else {
              checkOrganizationError(
                error.response.data.non_field_errors[0],
                this.props.history
              );
            }
          }
          this.setState({
            isLoading: false,
            docNotFound: true,
            invalidInvoice: true,
          });
        }
      });
  };

  getDocumentData = async () => {
    // Set loading state

    this.setState({ isLoading: true });
    // Fetch authentication token and document ID from URL params

    let authToken = await fetchAuthToken();
    let { doc_id } = this.props.match.params;
    //-------------------- fetch google vision response api ---------------------------------
    axios({
      method: "get",
      url: `${EXTRICATOR_BASE_URL}/get_passport_data/?id=${doc_id}&organisation_id=${this.ogi}`,
      headers: {
        Authorization: `Bearer ${authToken}`,
      },
    })
      .then((resp) => {
        console.log("===========resp", resp.data.result)
        // const keys = Object.keys(resp.data.result);
        // const values = Object.values(resp.data.result);
        // Initialize temporary array for customRightKeys
        this.setState(
            {
                passportData : resp.data.result,
                passportDataKeys: Object.keys(resp.data.result),
                passportDataValues: Object.values(resp.data.result),
                isLoading: false,
                
            })
            
        console.log("===========resp2", this.state.passportData)
        console.log("===========resp2 keys", this.state.passportDataKeys)
        console.log("===========resp2 values", this.state.passportDataValues)

       
        // console.log("keys",keys);
        // console.log(values);

        // var temp = [];
        for (let key in resp.data.result) {
            if (resp.data.result.hasOwnProperty(key)) {
                // temp.push([]);

              console.log(key + ": " + resp.data.result[key]);
            }
          }
          
        // for (let i in resp.data.result) {
        //   temp.push([]);
        //   console.log("==============i",key,resp.data.result[i])
        // }
        // Set state with response data

        // if (
        //   resp.data.data.left[0].length > 0 &&
        //   resp.data.data.right[0].length > 0
        // ) {
        //   this.setState(
        //     {
        //       isLoading: false,
             
        //     },
           
        //   );
        // } else {
        //   this.setState({ isLoading: false });
        // }
      
}
    )
      .catch((error) => {
        // Handle errors

        // if (error.response) {
        //   if (error.response.data && error.response.data.non_field_errors) {
        //     if (
        //       error.response.data.non_field_errors[0].includes(
        //         "Kindly pay the bill"
        //       )
        //     ) {
        //       this.setState({
        //         successMsg: error.response.data.non_field_errors[0],
        //         snackOpen: false,
        //         toastColor: "error",
        //       });
        //     } else {
        //       checkOrganizationError(
        //         error.response.data.non_field_errors[0],
        //         this.props.history
        //       );
        //       this.setState({
        //         isLoading: false,
        //         errorMsg: error.response.data.non_field_errors[0],
        //       });
        //     }
        //   }
        // }
      });
  };

//   jsPlumbReady = (self) => {
//     jsPlumb.ready(function () {
//       if (self.jsPlumbInstance) {
//         for (let i = 0; i < self.state.leftKeys.length; i++) {
//           for (let j = 0; j < self.state.leftKeys[i].length; j++) {
//             //For Left Node end points
//             if (
//               !self.isLeftKeyDeleted(self.state.leftKeys[i][j].extricator_key)
//             ) {
//               self.jsPlumbInstance.addEndpoint(
//                 self.state.leftKeys[i][j].extricator_key,
//                 {
//                   endpoint: "Dot",
//                   enabled: false,
//                   anchor: ["RightMiddle"],
//                 }
//               );
//             }
//           }
//         }
//         // left custom nodes-------------
//         for (let i = 0; i < self.state.customLeftKeys.length; i++) {
//           if (
//             !self.isLeftKeyDeleted(self.state.customLeftKeys[i].extricator_key)
//           ) {
//             self.jsPlumbInstance.addEndpoint(
//               self.state.customLeftKeys[i].extricator_key,
//               {
//                 endpoint: "Dot",
//                 anchor: ["RightMiddle"],
//               }
//             );
//           }
//         }

//         // right nodes-------------
//         for (let k = 0; k < self.state.rightKeys.length; k++) {
//           for (let l = 0; l < self.state.rightKeys[k].length; l++) {
//             self.jsPlumbInstance.addEndpoint(
//               self.state.rightKeys[k][l].extricator_key,
//               {
//                 endpoint: "Dot",
//                 anchor: ["LeftMiddle"],
//               }
//             );
//           }
//         }

//         for (let m = 0; m < self.state.customRightKeys.length; m++) {
//           for (let n = 0; n < self.state.customRightKeys[m].length; n++) {
//             self.jsPlumbInstance.addEndpoint(
//               self.state.customRightKeys[m][n].ExtricatorRightkey,
//               {
//                 endpoint: "Dot",
//                 anchor: ["LeftMiddle"],
//               }
//             );
//           }
//         }

//         // Events
//         self.jsPlumbInstance.bind("connection", function (jsPlumbConnection) {
//           var addSelFieldBG = function (connection) {
//             $("#" + connection.sourceId).addClass("row-hover-background");
//             $("#" + connection.targetId).addClass("row-hover-background");
//           };

//           var removedSelFieldBG = function (connection) {
//             $("#" + connection.sourceId).removeClass("row-hover-background");
//             $("#" + connection.targetId).removeClass("row-hover-background");
//           };
//           jsPlumbConnection.connection.bind("mouseover", function (connection) {
//             addSelFieldBG(connection);
//           });

//           jsPlumbConnection.connection.bind("mouseout", function (connection) {
//             removedSelFieldBG(connection);
//           });

//           // For clearing all the mapping
//           $("#clear-all").on("click", function () {
//             self.jsPlumbInstance.detachEveryConnection();
//           });

//           $(".source,.target").scroll(function () {
//             self.jsPlumbInstance.repaintEverything();
//           });
//         });

//         // initialize horizontal scroll bar
//         var widthDimensions = {
//           width: function (ele) {
//             return ele.width();
//           },
//           scrollLeft: function (ele) {
//             return ele[0].scrollLeft;
//           },
//           clientWidth: function (ele) {
//             return ele[0].clientWidth;
//           },
//         };
//         initHorizontalScroll();

//         function initHorizontalScroll() {
//           var sourceTableElement = $(".source");
//           var widthOfTable = widthDimensions.width(
//             sourceTableElement.find(".source-table")
//           );
//           var clientWidth = widthDimensions.clientWidth(sourceTableElement);

//           var scrollWidth = widthOfTable - clientWidth;

//           if (isFirefox()) {
//             sourceTableElement.scrollLeft(-scrollWidth); // For Firefox offset comes as -scrollWidth to 0
//           } else if (isIE()) {
//             sourceTableElement.scrollLeft(scrollWidth); // For IE offset comes as scrollWidth to 0
//           } else {
//             sourceTableElement.scrollLeft(0); // For Chrome/Safari offset comes as 0 to scrollWidth
//           }
//         }

//         function isIE() {
//           let userAgent = window.navigator.userAgent.toLowerCase();
//           return (
//             userAgent.indexOf("trident") != -1 ||
//             userAgent.indexOf("msie") != -1
//           );
//         }

//         function isFirefox() {
//           let userAgent = window.navigator.userAgent.toLowerCase();
//           return userAgent.indexOf("firefox") != -1;
//         }

//         var rightKey = null;
//         for (let i = 0; i < self.state.linking.length; i++) {
//           if (
//             Object.keys(self.state.linking[i])[0] ==
//             self.state.leftKeys[0][0].extricator_key
//           ) {
//             // use js filter method
//             rightKey = Object.values(self.state.linking[i])[0];
//           }
//         }

//         self.connection = self.jsPlumbInstance.connect({
//           source: self.state.leftKeys[0][0].extricator_key,
//           target: rightKey,
//           endpoint: "Dot",
//           endpointStyle: nodeStyle,
//         });
//         self.setState({
//           selectedLeftKey: self.state.leftKeys[0][0].extricator_key,
//         });
//       }
//     }); // end jsPlumb ready function
//     this.handleDrawBox(this);
//   };

  confirmDelete = () => {
    // Send delete file request to backend

    this.setState({
      isLoading: true,
    });
    axios({
      method: "post",
      url: `${EXTRICATOR_BASE_URL}/delete_file/`,
      data: {
        file_id: this.props.match.params.doc_id,
        ogi: this.ogi,
      },
      headers: {
        Authorization: `Bearer ${this.state.authenticationToken}`,
      },
    })
      .then((res) => {
        // Close dialog and go back to previous page on success

        this.setState(
          {
            isLoading: false,
            dialogOpen: false,
          },
          () => {
            this.props.history.goBack();
          }
        );
      })
      .catch((error) => {
        // Display error message on failure

        if (error.response) {
          if (error.response.data && error.response.data.non_field_errors) {
            this.setState({
              isLoading: false,
              errorMsg: error.response.data.non_field_errors[0],
            });
          }
        }
      });
  };

  handleModelDialogClose = () => {
    // Open or close new model dialog

    this.setState({
      newModelDialogOpen: !this.state.newModelDialogOpen,
    });
  };
  restoreDuplicateKey = (currentPage) => {
    // Restore the original name of a duplicate key

    var keyName = "";
    for (let i = 0; i < this.state.customName.length; i++) {
      if (
        Object.keys(this.state.customName[i])[0] ==
        this.state.leftKeys[currentPage][this.state.alteredKey].extricator_key
      ) {
        keyName = Object.values(this.state.customName[i])[0];
      }
    }
  };
  /**

Handles the click event on a left key
@param {Object} leftKey - The left key object that was clicked
@param {number} currentPage - The current page number
*/
  handleLeftClick = (leftKey, currentPage) => {
    if (this.state.alteredKey) {
      this.restoreDuplicateKey(currentPage);
    }
    // Sets the selected left key and clears custom input and custom selected key

    this.globalLeftClickedKey = leftKey;
    this.setState({
      selectedLeftKey: leftKey,
      customInputFocused: null,
      selectedCustomLeftKey: null,
    });
    var rightKey = "",
      rightElement = null,
      currentElementLeftStub = 0;
    // Finds the matching right key for the left key in linking array

    for (let i = 0; i < this.state.linking.length; i++) {
      if (Object.keys(this.state.linking[i])[0] == leftKey) {
        // use js filter method
        rightKey = Object.values(this.state.linking[i])[0];
      }
    }
    // Finds the matching right element from the right keys array

    for (let j = 0; j < this.state.rightKeys.length; j++) {
      for (let k = 0; k < this.state.rightKeys[j].length; k++) {
        if (
          this.state.rightKeys[j][k].extricator_key.includes(
            rightKey.substr(0, rightKey.length - 1)
          )
        ) {
          rightElement = this.state.rightKeys[j][k];
        }
      }
    }

    // If right element is not found in right keys array, find it in custom right keys array
    if (rightElement == null) {
      for (let j = 0; j < this.state.customRightKeys.length; j++) {
        /// j represents page number
        for (let k = 0; k < this.state.customRightKeys[j].length; k++) {
          // inner object array loop
          if (this.state.customRightKeys[j][k].ExtricatorRightkey == rightKey) {
            rightElement = this.state.customRightKeys[j][k];
          }
        }
      }
    }
    // Creates a connection between the left and right elements using jsPlumbInstance

    if (this.jsPlumbInstance) {
      if (this.connection && this.connection.source && this.connection.target) {
        this.jsPlumbInstance.deleteConnection(this.connection);
      }

      if (rightKey) {
        // Sets the left stub of the connection based on the position of the right element

        currentElementLeftStub =
          Math.ceil(rightElement.boundingBoxes[0].x1 * 100) * 6;
        this.connection = this.jsPlumbInstance.connect({
          source: leftKey,
          target: rightKey,
          connector: [
            "Straight",
            {
              stub: [currentElementLeftStub, 35],
              gap: [6, 6],
              cornerRadius: 10,
              alwaysRespectStubs: false,
            },
          ],
          endpoint: "Dot",
          endpointStyle: nodeStyle,
        });
        // Scrolls to the position of the right element

        let elmnt = document.getElementById(rightKey);
        if (elmnt) {
          var elementTop = String(elmnt.style.top);
          var percFigure = elementTop.replace("%", "");
          var percVal = ((this.bodyHeight * percFigure) / 100).toFixed(3);
          let calculatedTopOffset =
            Number(percVal) + 1150 * Number(currentPage);
          $("#doc_overflow").scrollTop(calculatedTopOffset);
        }
        if (this.state.valueFocussed) {
          this.setState({ valueFocussed: null });
        }
        this.setState({ focusRightKey: rightKey });
      } else {
        this.connection = this.jsPlumbInstance.connect({
          source: leftKey,
          endpoint: "Dot",
          endpointStyle: nodeStyle,
        });
      }
    }
  };
  // Handles click event for left key

  handleCustomLeftKeyClick = (customLeftKey, currentPage) => {
    this.globalLeftClickedKey = customLeftKey;

    this.setState({
      selectedCustomLeftKey: customLeftKey,
      selectedLeftKey: null,
    });
    var rightKey = "",
      rightElement = null,
      currentElementLeftStub = null;

    for (let i = 0; i < this.state.linking.length; i++) {
      if (Object.keys(this.state.linking[i])[0] == customLeftKey) {
        // use js filter method
        rightKey = Object.values(this.state.linking[i])[0];
      }
    }

    for (let j = 0; j < this.state.customRightKeys.length; j++) {
      for (let k = 0; k < this.state.customRightKeys[j].length; k++) {
        if (
          this.state.customRightKeys[j][k].ExtricatorRightkey.includes(
            rightKey.substr(0, rightKey.length - 1)
          )
        ) {
          rightElement = this.state.customRightKeys[j][k];
        }
      }
    }

    if (this.jsPlumbInstance) {
      if (this.connection && this.connection.source && this.connection.target) {
        this.jsPlumbInstance.deleteConnection(this.connection);
      }
      if (rightKey) {
        if (rightElement) {
          currentElementLeftStub =
            Math.ceil(rightElement.boundingBoxes[0].x1 * 100) * 6;
        } else {
          currentElementLeftStub = 70;
        }

        this.connection = this.jsPlumbInstance.connect({
          source: customLeftKey,
          target: rightKey,
          connector: [
            "Straight",
            {
              stub: [currentElementLeftStub, 35],
              gap: [6, 6],
              cornerRadius: 10,
              alwaysRespectStubs: false,
            },
          ],
          endpoint: "Dot",
          endpointStyle: nodeStyle,
        });
        let elmnt = document.getElementById(rightKey);
        if (elmnt) {
          var elementTop = String(elmnt.style.top);
          var percFigure = elementTop.replace("%", "");
          var percVal = ((this.bodyHeight * percFigure) / 100).toFixed(3);
          let calculatedTopOffset =
            Number(percVal) + 1150 * Number(currentPage);
          $("#doc_overflow").scrollTop(calculatedTopOffset);
        }

        this.setState({ focusRightKey: rightKey });
      }
    }
  };

  handleRightClick = (rightItem) => {
    let isDeleted = false;
    var currentElementLeftStub = null;
    const { extricator_key, boundingBoxes, ExtricatorRightkey } = rightItem;
    currentElementLeftStub = Math.ceil(boundingBoxes[0].x1 * 100) * 6;
    if (this.jsPlumbInstance) {
      if (
        this.connection &&
        this.connection.source &&
        this.connection.target &&
        !this.state.isCustomDeleted
      ) {
        this.jsPlumbInstance.deleteConnection(this.connection);
      } else {
        this.setState({ isCustomDeleted: false });
      }

      //solving bug of selectedLefyKey hold prev value of a deleted key
      let deletedKeys = this.state.deletedLeftKeys;

      for (let i = 0; i < deletedKeys.length; i++) {
        if (deletedKeys[i]["extricator_key"] == this.state.selectedLeftKey) {
          this.setState({ selectedLeftKey: null }, () => {
            //call back fn noting it is just to update the state
          });
          isDeleted = true;
        } else if (
          deletedKeys[i]["extricator_key"] == this.state.selectedCustomLeftKey
        ) {
          this.setState({ selectedCustomLeftKey: null }, () => {
            //call back fn same as prev
          });
          isDeleted = true;
        }
      }

      if (
        (this.state.selectedLeftKey || this.state.selectedCustomLeftKey) &&
        !isDeleted
      ) {
        if (this.state.selectedLeftKey) {
          this.connection = this.jsPlumbInstance.connect({
            source: this.state.selectedLeftKey,
            target: extricator_key || ExtricatorRightkey,
            connector: [
              "Straight",
              {
                stub: [currentElementLeftStub, 35],
                gap: [6, 6],
                cornerRadius: 10,
                alwaysRespectStubs: false,
              },
            ],
            endpoint: "Dot",
            endpointStyle: nodeStyle,
          });

          let linkingTemp = this.state.linking;
          var rightKeyIndex = null;

          //  -------------------remove previous right key linking-------------------
          for (let i = 0; i < this.state.linking.length; i++) {
            rightKeyIndex = this.state.linking.findIndex(
              (obj) =>
                Object.values(obj) == (extricator_key || ExtricatorRightkey)
            );
          }

          if (rightKeyIndex >= 0) {
            linkingTemp[rightKeyIndex][
              Object.keys(linkingTemp[rightKeyIndex])[0]
            ] = "";
            // linkingTemp[rightKeyIndex].isEmpty = true;
          }

          const leftKeyIdex = linkingTemp.findIndex((obj) =>
            Object.keys(obj).includes(this.state.selectedLeftKey)
          );
          //---------------------------update new right key linking------------------------
        //   if (leftKeyIdex >= 0) {
        //     linkingTemp[leftKeyIdex][Object.keys(linkingTemp[leftKeyIdex])[0]] =
        //       extricator_key || ExtricatorRightkey;

        //     var emptyKeys = linkingTemp.filter((item) => item.isEmpty == true);

        //     if (emptyKeys.length >= 3) {
        //       let emptyKeyNames = emptyKeys.map((item) => Object.keys(item)[0]);
        //       for (let j = 0; j < emptyKeyNames.length - 1; j++) {
        //         var tempss = linkingTemp.findIndex(
        //           (obj) => Object.keys(obj)[0] == emptyKeyNames[j]
        //         );
        //         linkingTemp[tempss][emptyKeyNames[j]] =
        //           demoResponse[tempss][emptyKeyNames[j]];
        //       }
        //     }
        //     this.setState({ linking: linkingTemp });
        //   }
        // } else if (this.state.selectedCustomLeftKey) {
        //   this.connection = this.jsPlumbInstance.connect({
        //     source: this.state.selectedCustomLeftKey,
        //     target: extricator_key || ExtricatorRightkey,
        //     connector: [
        //       "Straight",
        //       {
        //         stub: [currentElementLeftStub, 35],
        //         gap: [6, 6],
        //         cornerRadius: 10,
        //         alwaysRespectStubs: false,
        //       },
        //     ],
        //     endpoint: "Dot",
        //     endpointStyle: nodeStyle,
        //   });
          if (leftKeyIdex >= 0) {
              linkingTemp[leftKeyIdex][Object.keys(linkingTemp[leftKeyIdex])[0]] =
              extricator_key || ExtricatorRightkey;
              this.setState({ linking: linkingTemp });
            }
          } else if (this.state.selectedCustomLeftKey) {
            this.connection = this.jsPlumbInstance.connect({
              source: this.state.selectedCustomLeftKey,
              target: extricator_key || ExtricatorRightkey,
              endpoint: "Dot",
              endpointStyle: nodeStyle,
            });
          let linkingTemp1 = this.state.linking;
          var rightKeyIndex1 = null;

          //  -------------------remove previous right key linking-------------------
          for (let i = 0; i < this.state.linking.length; i++) {
            rightKeyIndex1 = this.state.linking.findIndex(
              (obj) =>
                Object.values(obj) == (extricator_key || ExtricatorRightkey)
            );
          }

          if (rightKeyIndex1 >= 0) {
            linkingTemp1[rightKeyIndex1][
              Object.keys(linkingTemp1[rightKeyIndex1])[0]
            ] = "";
          }

          const leftKeyIdexs = linkingTemp1.findIndex((obj) =>
            Object.keys(obj).includes(this.state.selectedCustomLeftKey)
          );
          //---------------------------update new right key linking------------------------
          if (leftKeyIdexs >= 0) {
            linkingTemp1[leftKeyIdexs][
              Object.keys(linkingTemp1[leftKeyIdexs])[0]
            ] = extricator_key || ExtricatorRightkey;
            this.setState({ linking: linkingTemp1 });
          }
        }
      } else {
        var leftSourceKey = "";
        for (let i = 0; i < this.state.linking.length; i++) {
          if (
            Object.values(this.state.linking[i])[0] ==
            (extricator_key || ExtricatorRightkey)
          ) {
            // use js filter method
            leftSourceKey = Object.keys(this.state.linking[i])[0];
          }
        }
        // if (leftSourceKey) {
          this.connection = this.jsPlumbInstance.connect({
            source: leftSourceKey,
            target: extricator_key || ExtricatorRightkey,
            endpoint: "Dot",
            endpointStyle: nodeStyle,
          });
        // } else {
        //   let linkingTemp1 = this.state.linking;

        //   const leftKeyIdexs = linkingTemp1.findIndex((obj) =>
        //     Object.keys(obj).includes(this.state.selectedCustomLeftKey)
        //   );
        //   //---------------------------update new right key linking------------------------
        //   if (leftKeyIdexs >= 0) {
        //     linkingTemp1[leftKeyIdexs][
        //       Object.keys(linkingTemp1[leftKeyIdexs])[0]
        //     ] = extricator_key || ExtricatorRightkey;
        //     this.setState({ linking: linkingTemp1 });
        //   }

        //   this.connection = this.jsPlumbInstance.connect({
        //     source: this.state.selectedCustomLeftKey,
        //     target: extricator_key || ExtricatorRightkey,
        //     endpoint: "Dot",
        //     endpointStyle: nodeStyle,
        //   });
        // }
      }
    }
  };
  // Handles blur event for input fields

  handleBlurInput = (listObj) => (event) => {
    // Clear focus states when input is blurred

    if (listObj.category == "default") {
      this.setState({ leftKeyEditId: null, inputFocused: false });
    } else {
      this.setState({
        customInputFocused: null,
        customLeftKeyText: "",
        inputFocusCustom: false,
      });
    }
  };
  // Handles left key editing

  handleLeftKeyEdit = (evt, index, leftKey) => {
    $("form_content").submit(function () {
      return false;
    });

    if (evt.which == "13") {
      evt.preventDefault();
    }

    var tempCustomName = this.state.customName;
    const leftKeyIndex = this.state.customName.findIndex((obj) =>
      Object.keys(obj).includes(leftKey)
    );

    if (leftKeyIndex !== -1) {
      tempCustomName[leftKeyIndex][
        Object.keys(tempCustomName[leftKeyIndex])[0]
      ] = evt.target.value;

      this.setState({
        leftKeyEditValue: evt.target.value,
        customName: tempCustomName,
        editChangeCount: this.state.editChangeCount + 1,
      });
    }
  };
  // Assigns the left key edit ID

  handleAssignLeftClickId = (leftKey) => {
    const leftKeyIndex = this.state.customName.findIndex((obj) =>
      Object.keys(obj).includes(leftKey)
    );
    var leftKeyEditValue =
      this.state.customName[leftKeyIndex][
        Object.keys(this.state.customName[leftKeyIndex])[0]
      ];

    this.setState({
      leftKeyEditId: leftKey,
      leftKeyEditValue,
    });
  };
  // Assigns the custom left key

  handleAssignCustomLeftKey = (customkey) => {
    var defaultVal = "";
    for (let i = 0; i < this.state.customName.length; i++) {
      if (Object.keys(this.state.customName[i]) == customkey) {
        defaultVal = Object.values(this.state.customName[i])[0];
      }
    }
    this.setState({
      customLeftKeyText: defaultVal,
      inputFocusCustom: true,
      customInputFocused: customkey,
    });
  };
  /**

Handles click event on form
@param {Object} event - click event object
*/
  handleFormClick = (event) => {
    event.stopPropagation();
    if (this.state.leftKeyEditId && !this.state.inputFocused) {
      this.setState({
        leftKeyEditId: null,
      });
    }
    if (this.state.modelListOpen) {
      this.setState({ modelListOpen: false });
    }
    this.showHideLogoutBtn(null);
  };
  /**

Shows or hides logout button based on flag
@param {Boolean} flag - Flag to show/hide logout button
*/
  showHideLogoutBtn = (flag) => {
    if (flag) {
      this.setState({ showLogoutBtn: !this.state.showLogoutBtn });
    } else {
      if (this.state.showLogoutBtn) {
        this.setState({ showLogoutBtn: false });
      }
    }
    this.setState({ modalMenuOpen: false });
  };
  /**

Exports form to excel
*/
  exportFormToExcel = () => {
    var xlsUrl =
      this.activeSection === "PASSPORT_REVIEW"
        ? `${EXTRICATOR_BASE_URL}/form_export/?id=${this.props.match.params.doc_id}&ogi=${this.ogi}`
        : `${EXTRICATOR_BASE_URL}/export_to_excel/?id=${this.props.match.params.doc_id}&ogi=${this.ogi}`;
    window.open(xlsUrl);
  };
  /**

Handles close event of snack bar
@param {Object} event - event object
@param {String} reason - reason for closing snack bar
*/
  handleSnackClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    this.setState({ successMsg: "", snackOpen: false });
  };
  /**

Sets custom key name for left key
@param {String} leftKey - Left key for which custom name is set
@param {String} type - Type of left key (default/custom)
@returns {String} - Custom key name
*/
  setCustomKeyName = (leftKey, type) => {
    var keyName = "";
    for (let i = 0; i < this.state.customName.length; i++) {
      if (Object.keys(this.state.customName[i])[0] == leftKey) {
        // use js filter method
        keyName = Object.values(this.state.customName[i])[0];
        let frags = keyName.split("_");
        for (let i = 0; i < frags.length; i++) {
          frags[i] = frags[i].charAt(0).toUpperCase() + frags[i].slice(1);
        }
        var keyStr = frags.join(" ");
        this.leftKeysArr.push(keyStr);
        this.leftKeysArr = [...new Set(this.leftKeysArr)];
        return keyStr;
      }
    }
  };
  // Adds a new key to the customLeftKeys, linking, and customName arrays

  addNewKey = () => {

    if (this.connection && this.connection.source && this.connection.target) {
      this.jsPlumbInstance.deleteConnection(this.connection);
    }
    // Create a new dynamic key for the new key
    
    const date = new Date();
    var newDynamicKey = `ExtricatorNewLeftkey${date.getTime()}`;
    // Create a new key object
    var listObj = document.getElementById("scrollBeginsHere");

    let newKey = {
      key: "ExtricatorNewLeftkey",
      extricator_key: newDynamicKey,
      page_number: 1,
      table_data: false,
    };
    // Create a new linking key object

    var newLinkingKey = {};
    newLinkingKey[newDynamicKey] = newDynamicKey + "_2";
    // Create a new customName object

    var newCustomName = {};
    newCustomName[newDynamicKey] = "New Key";
    // Add the new key to the customLeftKeys, linking, and customName arrays

    this.setState(
      {
        showLineItems: false,
        editChangeCount: this.state.editChangeCount + 1,
        lineTable: false,
        customLeftKeys: [...this.state.customLeftKeys, newKey],
        linking: [...this.state.linking, newLinkingKey],
        customName: [...this.state.customName, newCustomName],
        // choose the selected key with different color
        selectedLeftKey: null,
        selectedCustomLeftKey: newKey["extricator_key"],
      },
      () => {
        // If jsPlumbInstance exists, add an endpoint to the new key
        listObj.scrollTop = listObj.scrollHeight;
        if (this.jsPlumbInstance) {
          this.jsPlumbInstance.addEndpoint(newDynamicKey, {
            endpoint: "Dot",
            anchor: ["RightMiddle"],
          });
        }
      }
    );
  };
  // Handles keypress event for the input fields, and blurs the field if enter key is pressed

  handleEnterPress = (event, type) => {
    if (event.keyCode === 13) {
      if (type == "custom") {
        this.setState({
          customInputFocused: null,
          customLeftKeyText: "",
          inputFocusCustom: false,
        });
      } else {
        this.setState({ leftKeyEditId: null, inputFocused: false });
      }
    }
  };

  handleCustomLeftAddKeyEdit = (evt, index, leftKey) => {
    var customNameTemp = [...this.state.customName];
    // Update the customName array with the new value

    for (let i = 0; i < this.state.customName.length; i++) {
      if (Object.keys(this.state.customName[i]) == leftKey) {
        customNameTemp[i][leftKey] = evt.target.value;
      }
    }
    // Find the first empty index in customName and set its value to "New Key"

    var emptyIndex = customNameTemp.findIndex((obj) =>
      Object.values(obj).includes("")
    );
    if (emptyIndex >= 0) {
      customNameTemp[emptyIndex][Object.keys(customNameTemp[emptyIndex])[0]] =
        "New Key";
    }
    // Update state with the new customName array

    this.setState({
      customLeftKeyText: evt.target.value,
      customName: customNameTemp,
      editChangeCount: this.state.editChangeCount + 1,
    });
  };

  getLeftKeyValue = (leftKey) => {
    var rightKey = "";
    var emptyIndex;
    // Find the right key associated with the given left key in the linking array

    for (let i = 0; i < this.state.linking.length; i++) {
      if (
        Object.keys(this.state.linking[i])[0] == leftKey &&
        leftKey != undefined
      ) {
        // use js filter method
        rightKey = Object.values(this.state.linking[i])[0];
      }
    }
    // Find the value associated with the right key in the rightKeys array

    for (let j = 0; j < this.state.rightKeys.length; j++) {
      /// j represents page number
      for (let k = 0; k < this.state.rightKeys[j].length; k++) {
        // inner object array loop
        if (
          this.state.rightKeys[j][k].extricator_key == rightKey &&
          rightKey != undefined
        ) {
          // hwa hna by return awl 7aga byla2eha b nfs el key da w ana byb2a 3ndy aktr mn wa7da
          return this.state.rightKeys[j][k].value;
        }
      }
    }
    // Find the value associated with the right key in the customRightKeys array

    // use the same custom right key's key name as what used in the right key JSON.
    for (let j = 0; j < this.state.customRightKeys.length; j++) {
      /// j represents page number
      for (let k = 0; k < this.state.customRightKeys[j].length; k++) {
        // inner object array loop
        if (this.state.customRightKeys[j][k].ExtricatorRightkey == rightKey) {
          return this.state.customRightKeys[j][k].value;
        }
      }
    }
  };
  // Returns the value of the custom right key associated with the given custom left key

  getCustomLeftKeyValue = (leftKey) => {
    var rightKey = "";
    // Loop through linking array to find the corresponding right key for the custom left key

    for (let i = 0; i < this.state.linking.length; i++) {
      if (Object.keys(this.state.linking[i])[0] == leftKey) {
        // use js filter method
        rightKey = Object.values(this.state.linking[i])[0];
      }
    }
    // Loop through customRightKeys array to find the value associated with the found right key

    for (let j = 0; j < this.state.customRightKeys.length; j++) {
      /// j represents page number
      for (let k = 0; k < this.state.customRightKeys[j].length; k++) {
        // inner object array loop
        if (this.state.customRightKeys[j][k].ExtricatorRightkey == rightKey) {
          return this.state.customRightKeys[j][k].value;
        }
      }
    }
  };
  // Handles click event on a value item

  handleValueClick = (event, item) => {
    this.setState({ valueFocussed: item.extricator_key });
  };
  // Handles clicking of the "Go back" button

  handleGoBack = async () => {
    this.props.history.goBack();
  };
  // Handles deleting of a custom bound

  handleDeleteCustomBound = (bound) => {
    var customRightKeysTemp = [...this.state.customRightKeys];
    // Loop through customRightKeysTemp to find the matching bound and remove it

    for (let i = 0; i < customRightKeysTemp.length; i++) {
      for (let j = 0; j < customRightKeysTemp[i].length; j++) {
        if (
          customRightKeysTemp[i][j].ExtricatorRightkey ==
          bound.ExtricatorRightkey
        ) {
          let pageSet = [...customRightKeysTemp[i]];
          pageSet.splice(j, 1);
          customRightKeysTemp[i] = pageSet;
        }
      }
    }
    // Set the state

    this.setState(
      {
        customRightKeys: customRightKeysTemp,
        isCustomDeleted: true,
      },
      () => {
        if (this.connection && this.jsPlumbInstance) {
          if (this.connection.source && this.connection.source) {
            this.jsPlumbInstance.deleteConnection(this.connection);
          }
        }
      }
    );
  };
  // Checks if an array is not empty

  checkArrayNotEmpty = (data) => {
    Object.size = function (obj) {
      var size = 0,
        key;
      for (key in obj) {
        if (obj[key].length != 0) size++;
      }
      return size;
    };

    var size = Object.size(data);

    if (size > 0) return true;
    return false;
  };
  // Handles left key deletion

  handleKeyDelete = (leftKey) => {
    var deletedLeftKeys = [...this.state.deletedLeftKeys];
    deletedLeftKeys.push(leftKey);
    this.setState({ deletedLeftKeys, selectedLeftKey: null }, () => {
      this.connection = undefined;
      if (this.jsPlumbInstance) {
        this.jsPlumbInstance.remove(leftKey.extricator_key);
      }
    });
  };
  // Submits rejected form

  submitRejectedForm = () => {
    this.setState({
      isLoading: true,
    });
    // Fetches organization ID

    let ogi = fetchOrganisationId();
    // Maps custom left keys array to add "ExtricatorLeftkey" property

    var clkTemp = [...this.state.customLeftKeys];
    clkTemp = clkTemp.map((item) => ({
      ...item,
      ExtricatorLeftkey: item.extricator_key,
    }));
    // Constructs request body

    let reqBody = {
      file_id: this.props.match.params.doc_id,
      model_id: this.state.selectedModel.id,
      data: {
        linking: this.state.linking,
        custom_name: this.state.customName,
        customLeftKeys: clkTemp,
        customRightKeys: this.state.customRightKeys,
        deletedLeftKeys: this.state.deletedLeftKeys,
      },
      ogi: ogi,
    };
    // Sends post request to server to save rejected model response

    //To fetch the Invoice image
    axios({
      method: "post",
      url: `${EXTRICATOR_BASE_URL}/save_rejected_model_response/`,
      data: reqBody,
      headers: {
        Authorization: `Bearer ${this.state.authenticationToken}`,
      },
    })
      .then((res) => {
        this.setState({ isLoading: false }, () => {
          this.props.history.goBack();
        });
      })
      .catch((error) => {
        if (error.response) {
          this.setState({
            isLoading: false,
            errorMsg: error.response.data.non_field_errors[0],
          });
        }
      });
  };

  submitInvoice = async () => {
    // Set loading state

    this.setState({
      isLoading: true,
    });
    // Modify custom left keys array to include ExtricatorLeftkey property

    var clkTemp = [...this.state.customLeftKeys];
    clkTemp = clkTemp.map((item) => ({
      ...item,
      ExtricatorLeftkey: item.extricator_key,
    }));
    // Create request body

    let reqBody = {
      file_id: this.props.match.params.doc_id,
      data: {
        linking: this.state.linking,
        custom_name: this.state.customName,
        customLeftKeys: clkTemp,
        customRightKeys: this.state.customRightKeys,
        deletedLeftKeys: this.state.deletedLeftKeys,
      },
      ogi: this.ogi,
    };
    // Send POST request to update keys

    //To fetch the Invoice image
    axios({
      method: "post",
      url: `${EXTRICATOR_BASE_URL}/update_keys/`,
      data: reqBody,
      headers: {
        Authorization: `Bearer ${this.state.authenticationToken}`,
      },
    })
      .then((res) => {
        // Reset loading state and go back to previous page on success

        this.setState(
          {
            isLoading: false,
          },
          () => {
            this.props.history.goBack();
          }
        );
      })
      .catch((error) => {
        // Handle error response

        if (error.response) {
          if (error.response.data && error.response.data.non_field_errors) {
            this.setState({
              isLoading: false,
              errorMsg: error.response.data.non_field_errors,
            });
          }
        }
      });
  };

  componentDidUpdate(prevProps, prevState, snapshot) {
    // Check if there are new custom left keys and add a new endpoint for them

    if (this.state.customLeftKeys.length > 0) {
      var newDynamicKey =
        this.state.customLeftKeys[this.state.customLeftKeys.length - 1]
          .extricator_key;
      let elmnt = document.getElementById(newDynamicKey);
      if (elmnt && this.jsPlumbInstance) {
        this.jsPlumbInstance.addEndpoint(newDynamicKey, {
          endpoint: "Dot",
          anchor: ["RightMiddle"],
        });
      }
    }

    setTimeout(() => {
      // Add endpoints for all custom right keys

      for (let i = 0; i < this.state.customRightKeys.length; i++) {
        for (let j = 0; j < this.state.customRightKeys[i].length; j++) {
          if (this.jsPlumbInstance) {
            this.jsPlumbInstance.addEndpoint(
              this.state.customRightKeys[i][j].ExtricatorRightkey,
              {
                endpoint: "Dot",
                anchor: ["LeftMiddle"],
              }
            );
          }
        }
      }
      // If any left keys have been deleted, remove the endpoints for them

      if (
        this.state.deletedLeftKeys.length > 0 &&
        prevState.deletedLeftKeys !== this.state.deletedLeftKeys
      ) {
        for (let i = 0; i < this.state.leftKeys.length; i++) {
          for (let j = 0; j < this.state.leftKeys[i].length; j++) {
            if (
              !this.isLeftKeyDeleted(this.state.leftKeys[i][j].extricator_key)
            ) {
              if (this.jsPlumbInstance) {
                this.jsPlumbInstance.addEndpoint(
                  this.state.leftKeys[i][j].extricator_key,
                  {
                    endpoint: "Dot",
                    anchor: ["RightMiddle"],
                  }
                );
              }
            }
          }
        }
      }
    }, 2000);
  }
  // Helper function to check if a left key has been deleted

  isLeftKeyDeleted = (leftKey) => {
    var leftKeyOccurance = 0;
    for (let i = 0; i < this.state.deletedLeftKeys.length; i++) {
      if (this.state.deletedLeftKeys[i].extricator_key === leftKey) {
        leftKeyOccurance++;
      }
    }
    if (leftKeyOccurance > 0) {
      return true;
    }
    return false;
  };
  /**
   * Toggles the display of invoice details by updating the state
   * `showInvoiceDetails`. Also deletes the connection if it exists.
   */
  toggleKeyValueListDisplay = () => {
    // Toggle the `showInvoiceDetails` state

    this.setState({ showInvoiceDetails: !this.state.showInvoiceDetails });
    // Delete the connection if it exists

    if (this.connection && this.jsPlumbInstance) {
      if (this.connection.source && this.connection.source) {
        this.jsPlumbInstance.deleteConnection(this.connection);
      }
    }
  };

  render() {
    return (
      <div className="px-3" style={{ position: "relative" }}>
        <SnackbarComponent
          isOpen={this.state.snackOpen}
          message={this.state.successMsg}
          color={this.state.toastColor}
          handleClose={this.handleSnackClose}
        />
        <CheckMobileDevice />
        {this.state.docNotFound ? (
          <DocNotFoundDialog></DocNotFoundDialog>
        ) : (
          <div onClick={this.handleFormClick}>
            <div className="row" id="detailHeader">
              <div
                className="col-3"
                style={{ background: "#011B3E", zIndex: 100 }}
              >
                <div className="form-group d-flex justify-content-center mt-4 banner-head">
                  <div className="col-sm-7">
                    <a onClick={this.handleGoBack} style={{ width: "80%" }}>
                      <img
                        src={Images.app_logo}
                        style={{ width: "100%" }}
                        className="img-responsive"
                        alt=""
                      />
                    </a>
                  </div>
                </div>
              </div>
              <div
                className="col-9"
                style={{ backgroundColor: "#011B3E", zIndex: 100 }}
              >
                <nav
                  id="myBar"
                  className="navbar detail-header-form px-4"
                  style={{
                    maxHeight: "98px",
                    alignItems:
                      this.activeSection === "PASSPORT_REVIEW"
                        ? "flex-start"
                        : "center",
                  }}
                >
                  <div className="col-md-6 form-inline page-count">
                    <div
                      className="form-inline justify-content-start"
                      style={{ width: "100%" }}
                    >
                      <span
                        className="d-flex align-items-center"
                        style={{ fontSize: "14px", cursor: "pointer" }}
                        onClick={() => this.props.history.goBack()}
                      >
                        <span className="material-icons mr-1">arrow_back</span>
                        Back
                      </span>
                      <span
                        className="material-icons ml-2"
                        style={{
                          color: this.state.deleteHovered ? "#fff" : "#678ab9",
                        }}
                        onMouseEnter={() =>
                          this.setState({ deleteHovered: true })
                        }
                        onMouseLeave={() =>
                          this.setState({ deleteHovered: false })
                        }
                        onClick={() =>
                          this.setState({ dialogOpen: !this.state.dialogOpen })
                        }
                      >
                        delete
                      </span>
                      <span
                        className="download-span d-flex ml-2"
                        style={{ marginTop: "2px" }}
                        onClick={() => {
                          window.open(
                            `${EXTRICATOR_BASE_URL}/download_file/?id=${this.props.match.params.doc_id}&ogi=${this.ogi}`
                          );
                        }}
                      >
                        <i className="fa fa-download mr-1"></i>
                        <span>Download</span>
                      </span>
                    </div>
                   
                  </div>
                  <div className="col-md-6 d-flex justify-content-end">
                    <div style={{ position: "relative", marginTop: "-5px" }}>
                      <LogoutBar
                        btnShow={this.state.showLogoutBtn}
                        callback={this.showHideLogoutBtn}
                      ></LogoutBar>
                    </div>
                  </div>
                </nav>
              </div>
            </div>
            <div
              className="row justify-content-between"
              style={{ height: "95vh" }}
              id="drag_canvas"
              onClick={() => this.showHideLogoutBtn(null)}
            >
              {this.state.invalidInvoice && (
                <div
                  className="modal fade show"
                  id="exampleModal"
                  style={{ display: "block", background: "rgba(0,0,0,0.8)" }}
                  tabindex="-1"
                  onClick={() => this.setState({ invalidInvoice: false })}
                  role="dialog"
                  aria-labelledby="exampleModalLabel"
                  aria-hidden="true"
                >
                  <div className="modal-dialog" role="document">
                    <div className="modal-content" style={{ margin: "40% 0" }}>
                      <div className="modal-header">
                        <h5 className="modal-title" id="exampleModalLabel"></h5>
                        <button
                          type="button"
                          className="close"
                          data-dismiss="modal"
                          aria-label="Close"
                        >
                          <span aria-hidden="true">&times;</span>
                        </button>
                      </div>
                      <h5 className="text-center" style={{ color: "#fff" }}>
                        Invalid or corrupt file
                      </h5>
                      <div
                        className="modal-footer"
                        style={{ justifyContent: "center" }}
                      >
                        <button
                          type="button"
                          className="btn"
                          style={{
                            border: "1px solid #35F8F1",
                            color: "#35F8F1",
                            backgroundColor: "transparent",
                          }}
                          onClick={() => this.props.history.goBack()}
                          data-dismiss="modal"
                        >
                          Go Back
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              )}
              <DeleteDialogBox
                open={this.state.dialogOpen}
                handleClose={() =>
                  this.setState({ dialogOpen: !this.state.dialogOpen })
                }
                content="Are you sure you want to delete this document?"
                handleConfirm={() => this.confirmDelete()}
                dialogTitle="Confirmation"
              />
              {this.state.newModelDialogOpen ? (
                <CreateModelDialog
                  action="create"
                  open={this.state.newModelDialogOpen}
                  modelType={"PASSPORT"}
                  handleClose={this.handleModelDialogClose}
                  handleConfirm={(txt) => this.setState({ newModel: txt })}
                />
              ) : null}

              <Backdrop
                style={{ zIndex: 10, color: "#fff" }}
                open={this.state.isLoading}
              >
                <CircularProgress color="inherit" />
              </Backdrop>
              <div
                className="col-sm-3 py-3"
                style={{ backgroundColor: "#04244F" }}
              >
                <div
                  className="board-wrapper"
                  style={{ backgroundColor: "#011B3E", position: "relative" }}
                >
                  <div style={{ flex: 1 }}>
                    <div className="col-12 source" id="scrollBeginsHere">
                      <div className="invoiceTitle text-center py-2 container">
                        {this.state.vendorName}
                      </div>
                      <div className="accordion_head_form">
                        <span
                          className="d-flex align-items-center"
                          onClick={() => this.toggleKeyValueListDisplay()}
                          style={{ width: "78%" }}
                        >
                          <i
                            className={
                              this.state.showInvoiceDetails
                                ? "fa fa-caret-down"
                                : "fa fa-caret-up"
                            }
                            aria-hidden="true"
                          ></i>
                          Keys & Values
                        </span>
                  
                      </div>
                      <div
                        className={
                          this.state.showInvoiceDetails
                            ? "source-table show"
                            : "source-table collapse"
                        }
                      >
                        <ul className="source-list">
                          {Object.keys(this.state.passportData).length > 0 ? (
                            Object.keys(this.state.passportData).map((key, keyIndex) =>{
                                if (key === "valid_passport_number" || key === "valid_date_of_birth"|| key === "valid_expiration_date"|| key === "valid_composite") {
                                    return null; // Skip rendering this key
                                }
                                // if (
                                //   !this.isLeftKeyDeleted(item.extricator_key)
                                // ) {
                                  return (
                                    <li
                                      id={key}
                                      style={{
                                        height: "42px",
                                        direction: "ltr",
                                        padding: "10px 10px 10px 20px",
                                      }}
                                    
                                      className="d-flex justify-content-between align-items-center"
                                      
                                    >
                                      {
                                        <>
                                            <BootstrapTooltip
                                              title={key}
                                            >
                                              <span
                                                style={{
                                                  whiteSpace: "nowrap",
                                                  overflow: "hidden",
                                                  textOverflow: "ellipsis",
                                                  width: "22em",
                                                  fontSize: "12px",
                                                  color: "#fff",
                                                  textTransform: "initial",
                                                  fontWeight: 600,
                                                }}
                                              
                                              >
                                                
                                                {key}
                                              </span>
                                            </BootstrapTooltip>
                                           
                                          <label className="mb-0 mr-2">-</label>
                                          {this.state.valueFocussed ==
                                          key ? (
                                            <BootstrapTooltip
                                              title={this.state.passportData[key]}
                                            >
                                              <span
                                                style={{
                                                  whiteSpace: "nowrap",
                                                  overflow: "hidden",
                                                  textOverflow: "ellipsis",
                                                  width: "22em",
                                                  fontSize: "12px",
                                                  color: "#fff",
                                                  textTransform: "initial",
                                                  fontWeight: 600,
                                                }}
                                               
                                              >
                                                
                                                {this.state.passportData[key]}
                                              </span>
                                            </BootstrapTooltip>
                                          ) : (
                                            <BootstrapTooltip
                                              title={this.state.passportData[key]}
                                            >
                                              <span
                                                style={{
                                                  whiteSpace: "nowrap",
                                                  overflow: "hidden",
                                                  textOverflow: "ellipsis",
                                                  width: "22em",
                                                  fontSize: "12px",
                                                  color: "#fff",
                                                  textTransform: "initial",
                                                  fontWeight: 600,
                                                }}
                                                
                                              >
                                                {this.state.passportData[key]}
                                              </span>
                                            </BootstrapTooltip>
                                          )}
                                 
                                        </>
                                      }
                                    </li>
                                  );
                                // }
                              
                                    })
                          ) : (
                            <div
                              className="d-flex justify-content-center align-items-center"
                              style={{ height: "100%" }}
                            >
                              <p className="m-0">Nothing to show</p>
                            </div>
                          )}

                        </ul>
                      </div>
                      
                    </div>
                  </div>
                </div>
              </div>

              <div
                className="col-sm-9 target pull-right"
                id="doc_overflow"
                style={{
                  marginTop: "unset",
                  height: "85vh",
                  position: "relative",
                }}
              >
                {this.state.previewImg.map((item, index) => {
                  return (
                    <img
                      className="img-shadow"
                      src={item}
                      style={{
                        filter: "brightness(1) contrast(1)",
                        display: "initial",
                        transform: `scale(${this.state.zoomFactor})`,
                        backgroundColor: "white",
                        left: "45px",
                        position: "absolute",
                        top: 1150 * index,
                        width: "870px",
                        height: "1150px",
                      }}
                    />
                  );
                })}
               
              </div>
            </div>
            <div
              className="row w-100"
              style={{ height: "10vh", position: "absolute", bottom: "0px" }}
            >
              <div
                className="col-3"
                style={{ backgroundColor: "#04244F" }}
              ></div>
              <div
                className="col-9"
                style={{ backgroundColor: "#031B40" }}
              ></div>
            </div>
          </div>
        )}
      </div>
    );
  }
}

export default PassportDetails;
