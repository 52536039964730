// Defines an object containing theme colors as properties

const theme_colors = {
  tertiary: "#ef7f03",
  secondary: "#31eff3",
  primary: "#062241",
};
// Exports the theme colors object for use in other parts of the application

export default theme_colors;
