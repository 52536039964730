import React, { Component } from "react";
import $ from "jquery";
import { jsPlumb } from "jsplumb";
import "./POrderDetails.module.scss";
import LogoutBar from "../../components/LogoutBar";
import CustomDropDown from "../../components/CustomDropDown";
import Images from "../../assets/asset_imports";
import {
  checkOrganizationError,
  fetchActiveSection,
  fetchAuthToken,
  fetchOrganisationId,
} from "../../utils/Helpers";
import axios from "axios";
import {
  CircularProgress,
  Backdrop,
  Tooltip,
  makeStyles,
} from "@material-ui/core";
import DeleteDialogBox from "../../components/DeleteDialog/DeleteDialogBox";
import CreateModelDialog from "../../components/CreateModelDialog/CreateModelDialog";
import CheckMobileDevice from "../../components/CheckMobileDevice";
import SnackbarComponent from "../../components/SnackbarComponent";
import { EXTRICATOR_BASE_URL } from "../../utils/constants";
import DocNotFoundDialog from "../../components/NotFoundDialog";
// Define the width and height of an SVG element

var svgWidth = 870;
var svgHeight = 1150;
// Define a useStylesBootstrap function that returns styles for a Bootstrap tooltip

const useStylesBootstrap = makeStyles((theme) => ({
  arrow: {
    color: theme.palette.common.black,
  },
  tooltip: {
    backgroundColor: theme.palette.common.black,
  },
}));
// Define a BootstrapTooltip component that uses the useStylesBootstrap function to apply styles to a Tooltip

function BootstrapTooltip(props) {
  const classes = useStylesBootstrap();
  return <Tooltip classes={classes} placement="top" {...props} arrow />;
}
// Define a nodeStyle object with properties for a node's appearance

const nodeStyle = {
  fill: "transparent",
  radius: 6,
  outlineStroke: "#2F72FF",
  outlineWidth: 20,
  strokeWidth: 5,
};
class POrderDetails extends Component {
  constructor(props) {
    super(props);
    this.state = {
      toastColor: "success",
      successMsg: "",
      snackOpen: false,
      valueFocussed: null,
      editChangeCount: 0,
      isCustomDeleted: false,
      showInvoiceDetails: true,
      showAmounts: false,
      pageValue: 1,
      isLoading: false,
      previewImg: [],
      invoiceImage: "",
      invoiceDetailsMap: "",
      boundsData: "",
      leftSourceId: 1,
      leftSourceIndex: 0,
      authenticationToken: "",
      dialogOpen: false,
      newModelDialogOpen: false,
      showLineItems: false,
      lineTable: false,
      userEmail: "",
      showLogoutBtn: false,
      lineItemslist: [],
      zoomFactor: 1,
      vendorName: "",
      fileName: "",
      deleteHovered: false,
      invalidInvoice: false,
      leftKeys: [],
      customLeftKeys: [],
      linking: [],
      customName: [],
      leftKeyEditId: null,
      inputFocused: false,
      customRightKeys: [],
      deletedLeftKeys: [],
      selectedLeftKey: null,
      selectedCustomLeftKey: null,
      focusRightKey: null,
      customInputFocused: null,
      customLeftKeyText: "",
      totalRows: "0",
      inputFocusCustom: false,
      lineItemTableData: [],
      maxRows: 0,
      activeSection: "",
      newModel: "",
      modalMenuOpen: null,
      selectedModel: null,
      alteredKey: null,
      tableSortedData: [],
      avatar:
        "https://www.gravatar.com/avatar/205e460b479e2e5b48aec07710c08d50",
    };
    this.globalLeftClickedKey = null;
    this.leftKeysArr = [];
    this.connection = null;
    this.ogi = fetchOrganisationId();
    this.activeSection = fetchActiveSection();
    this.docTopOffset = 0;
    this.bodyHeight = $("body").height();
  }
  // Define a componentDidMount function that sets the active section state, initializes a jsPlumbInstance,
  // calls the handleResolutionChange function and fetches the document
  componentDidMount() {
    // Set the overflow of the body to hidden

    $("body").css("overflow", "hidden");
    // Set the active section state based on the props passed to the component

    this.setState({
      activeSection: this.props.location.state
        ? this.props.location.state.split("_")[1]
        : "REVIEW",
    });
    // Initialize a jsPlumbInstance with specific settings

    this.jsPlumbInstance = jsPlumb.getInstance({
      Container: $("#drag_canvas"),
      ConnectionsDetachable: false,
      Anchor: ["RightMiddle", "LeftMiddle"],
      EndpointStyle: { fill: "transparent", radius: 0 },
      Connector: [
        "Straight",
        {
          stub: [35, 35],
          gap: [6, 6],
          cornerRadius: 10,
          alwaysRespectStubs: false,
        },
      ],
      PaintStyle: {
        strokeWidth: 3,
        stroke: "#2F72FF",
        transition: ".2s",
      },
    });
    // Call the handleResolutionChange function and fetch the document

    this.handleResolutionChange(this);
    this.fetchDocument();
  }
  // Define a componentWillUnmount function that deletes every endpoint in the jsPlumbInstance, removes
  // event listeners for mouse events and sets the overflow-x of the body to unset
  componentWillUnmount() {
    // Delete every endpoint in the jsPlumbInstance

    if (this.jsPlumbInstance) {
      this.jsPlumbInstance.deleteEveryEndpoint();
      this.jsPlumbInstance = null;
    }
    // Remove event listeners for mouse events and set the overflow-x of the body to unset

    $(document).off("mousedown");
    $(document).off("mousemove");
    $(document).off("mouseup");
    $("body").css("overflow-x", "unset");
  }
  // Define a handleHeaderFloat function that changes the style of the header based on the user's scroll position

  handleHeaderFloat = () => {
    window.onscroll = function () {
      var header = document.getElementById("detailHeader");
      var sticky = header.offsetTop;
      if (window.pageYOffset > sticky) {
        header.classList.add("sticky");
      } else {
        header.classList.remove("sticky");
      }
    };
  };
  // Define a handleFocusRow function that scrolls the document to the position of a specific row and changes its border style

  handleFocusRow = (row) => {
    let tableSet = this.state.tableSortedData;
    var elemt = document.getElementById(row.table_data_id);
    if (elemt) {
      var elementTop = String(elemt.style.top);
      var percFigure = elementTop.replace("%", "");
      var percVal = ((this.bodyHeight * percFigure) / 100).toFixed(3);
      let calculatedTopOffset = Number(percVal) + 1150 * (Number(row.page) - 1);
      $("#doc_overflow").scrollTop(calculatedTopOffset);
      elemt.style["border"] = "2px solid blue";

      for (let i = 0; i < this.state.rightKeys.length; i++) {
        if (tableSet[i]) {
          for (let j = 0; j < tableSet[i].length; j++) {
            for (let k = 0; k < tableSet[i][j].length; k++) {
              let instantElement = document.getElementById(
                tableSet[i][j][k].table_data_id
              );
              if (row.table_data_id !== tableSet[i][j][k].table_data_id) {
                instantElement.style["border"] = "none";
              }
            }
          }
        }
      }
    }
  };

  handleDrawBox = (self) => {
    $(function () {
      var widget;
      var x;
      var y;
      var finX;
      var finY;
      var ismousedown = false;
      var coords = {};
      var allowDraw = true;
      var incidentPage = 0;

      $(document).on({
        // When the user presses the mouse down, set variables and create a widget element

        mousedown: function (event) {
          // Set the incidentPage variable to the page number of the clicked element

          incidentPage = Number($(event.target).attr("title")) + 1;
          if ($(event.target).attr("class") == "formwrapper") {
            x = event.pageX;
            y = event.pageY;
            coords.x1 = event.offsetX;
            coords.y1 = event.offsetY;
            $("body").append(
              '<div class="widget" style="top:' +
                y +
                "px; left: " +
                x +
                'px;"></div>'
            );
            widget = $(".widget").last();
            ismousedown = true;
          }
        },
        // When the user moves the mouse, resize and position the widget element based on the mouse's movement

        mousemove: function (event) {
          if (ismousedown == true) {
            finX = event.pageX;
            finY = event.pageY;

            widget.width(finX - x > 0 ? finX - x : x - finX);
            widget.height(finY - y > 0 ? finY - y : y - finY);
            finX - x > 0
              ? widget.css({ left: `${x}px`, right: "" })
              : widget.css({
                  right: `${$("#drag_canvas").width() - x}px`,
                  left: "",
                });
            finY - y > 0
              ? widget.css({ top: `${y}px`, bottom: "" })
              : widget.css({
                  bottom: `${$("body").height() - y - 3}px`,
                  top: "",
                });

            widget.css({
              width: (finX - x > 0 ? finX - x : x - finX) + "!important",
              height: (finY - y > 0 ? finY - y : y - finY) + "!important",
              display: "block",
              border: "2px dashed #ccc",
            });
          }
        },
        // When the user releases the mouse, finalize the position and size of the widget element and send its coordinates to a function to be processed

        mouseup: function (event) {
          if (ismousedown == true) {
            ismousedown = false;
            widget.css({
              background: "#222",
              border: "0",
              cursor: "move",
            });

            finX = event.pageX;
            finY = event.pageY;

            coords.width = Math.abs(finX - x) + 4;
            coords.height = Math.abs(finY - y) + 4;
            if (coords.width != 4 && coords.height != 4) {
              coords.x2 = coords.x1 + finX - x;
              coords.y2 = coords.y1 + finY - y;
              //for creating the bounding box calculations it require that x2 > x1
              if (coords.x2 < coords.x1)
                [coords.x1, coords.x2] = [coords.x2, coords.x1];
              //for creating the bounding box calculations it requirs that y2 > y1
              if (coords.y2 < coords.y1)
                [coords.y1, coords.y2] = [coords.y2, coords.y1];
              $(".widget").remove();
              if (allowDraw) {
                self.fetchCoordinatesValue(coords, incidentPage);
                widget = null;
                x = null;
                y = null;
                finX = null;
                finY = null;
                ismousedown = false;
                coords = {};
              }
            }
          }
        },
      });
    });
  };

  setupTableData = () => {
    // Initialize variables

    var multipageTableData = [];
    var maxRows = 0;
    // Loop through each page of the document

    for (let i = 0; i < this.state.rightKeys.length; i++) {
      //Page loop

      var headerSet = [], // Initialize the header set for this page
        rowCount = 0; // Initialize the row count for this page
      // Loop through each field on the page

      for (let j = 0; j < this.state.rightKeys[i].length; j++) {
        // If the field is a line item, add its header to the header set

        if (this.state.rightKeys[i][j].table_data == true) {
          var header = this.state.rightKeys[i][j].key.replace("line_item/", "");
          if (!headerSet.includes(header)) {
            headerSet.push(header);
          }
        }
        // Update the row count for this page

        if (rowCount < this.state.rightKeys[i][j].row) {
          rowCount = this.state.rightKeys[i][j].row;
        }
      }
      var tableData = []; // Initialize the table data for this page
      // Loop through each row in the table

      for (let k = 1; k <= rowCount; k++) {
        var rowData = []; // Initialize the row data for this row
        // Loop through each header in the header set

        for (let l = 0; l < headerSet.length; l++) {
          var cellData = {}; // Initialize the cell data for this cell
          cellData.heading = headerSet[l]; // Set the header for this cell
          // Loop through each field on the page again

          for (let m = 0; m < this.state.rightKeys[i].length; m++) {
            if (this.state.rightKeys[i][m].table_data === true) {
              var header = this.state.rightKeys[i][m].key.replace(
                "line_item/",
                ""
              );
              var row = this.state.rightKeys[i][m].row;
              // If this field corresponds to the current cell, set its value and key

              if (headerSet[l] == header && k == row) {
                cellData.value = this.state.rightKeys[i][m].value;
                cellData.key = this.state.rightKeys[i][m].extricator_key;
              } else {
                // If this field does not correspond to the current cell, set the value to an empty string

                if (!cellData.value) {
                  cellData.value = "";
                }
              }
            }
          }
          rowData.push(cellData);
        }
        tableData.push(rowData);
        maxRows = maxRows + 1;
      }
      multipageTableData.push(tableData);
    }
    this.setState({
      lineItemTableData: multipageTableData,
      maxRows,
    });
  };
  /**

fetchCoordinatesValue - A method that makes an API call to extract data from an image based on the provided coordinates
@param {object} coord - The coordinates of the bounding box that contains the data to be extracted
@param {number} pageNumber - The page number of the image
@returns {void}
*/
  fetchCoordinatesValue = async (coord, pageNumber) => {
    // Set isLoading state to true

    this.setState({
      isLoading: true,
    });
    // Get the document ID from the URL params

    let { doc_id } = this.props.match.params;
    // Get the current date

    var date = new Date();
    // Get the authorization token

    var authToken = await fetchAuthToken();
    // Calculate the normalized coordinates of the bounding box

    const boundingBox = {
      x1: coord.x1 / svgWidth,
      y1: coord.y1 / svgHeight,
      x2: coord.x2 / svgWidth,
      y2: coord.y1 / svgHeight,
      x3: coord.x2 / svgWidth,
      y3: coord.y2 / svgHeight,
      x4: coord.x1 / svgWidth,
      y4: coord.y2 / svgHeight,
    };
    // Create an object that contains the right key data

    const rightKey = {
      ExtricatorRightkey: `ExtricatorNewRightkey${date.getTime()}_${pageNumber}`,
      value: "",
      table_data: false,
      custom_box: true,
      page_number: pageNumber,
      boundingBoxes: [boundingBox],
    };
    // Make a POST request to the API to extract data based on the coordinates

    axios({
      method: "post",
      url: `${EXTRICATOR_BASE_URL}/extract_data_coordniate/`,
      data: { ...rightKey, file_id: doc_id, ogi: this.ogi },
      headers: {
        Authorization: `Bearer ${authToken}`,
      },
    })
      .then((res) => {
        rightKey.value = res.data.value; //"//Set response Value"
        rightKey.key = `CustomBoundBox${date.getTime()}`;
        // Add the right key to the customRightKeys array for the current page

        let rightKeysCustom = [...this.state.customRightKeys];

        for (let i = 0; i < rightKeysCustom.length; i++) {
          if (i === pageNumber - 1) {
            rightKeysCustom[i].push(rightKey);
          }
        }
        // Disable drawing mode and set the customRightKeys state

        this.allowDraw = false;
        this.setState({ customRightKeys: rightKeysCustom, isLoading: false });
      })
      .catch((error) => {
        // Handle errors by setting the isLoading state and displaying an error message

        if (error.response) {
          if (error.response.data && error.response.data.non_field_errors) {
            this.allowDraw = false;
            this.setState({
              isLoading: false,
              errorMsg: error.response.data.non_field_errors[0],
            });
          }
        }
      });
  };
  /**

handleResolutionChange - A method that handles window resize events to repaint the jsPlumbInstance
@param {object} self - Reference to the current component instance
@returns {void}
*/
  handleResolutionChange = (self) => {
    $(window).resize(function () {
      // Check if jsPlumbInstance exists

      if (self.jsPlumbInstance) {
        // Delete connection if it exists

        if (self.connection) {
          if (self.connection.source && self.connection.source) {
            self.jsPlumbInstance.deleteConnection(self.connection);
          }
        }
        // Repaint the jsPlumbInstance

        self.jsPlumbInstance.repaintEverything();
      }
    });
  };
  /**

handleZoom - A method that handles zoom in/out events
@param {number} factor - The zoom factor to apply
@returns {void}
*/
  handleZoom = (factor) => {
    if (factor < 0) {
      // Check if the factor is negative or positive and apply the zoom

      if (this.state.zoomFactor > 1) {
        this.setState({
          zoomFactor: this.state.zoomFactor + factor,
        });
      }
    } else {
      if (this.state.zoomFactor < 2) {
        this.setState({
          zoomFactor: this.state.zoomFactor + factor,
        });
      }
    }
  };
  /**

deleteConnection - A method that deletes the current connection from the jsPlumbInstance
@returns {void}
*/
  deleteConnection = () => {
    // Check if jsPlumbInstance exists and delete the connection

    if (this.jsPlumbInstance) {
      this.jsPlumbInstance.deleteConnection(this.connection);
    }
  };
  /**

fetchDocument - A method that fetches the document and its associated data from the API
@returns {void}
*/
  fetchDocument = async () => {
    // Get the authorization token and set isLoading state to true

    var authToken = await fetchAuthToken();
    this.setState({
      isLoading: true,
      authenticationToken: authToken,
    });
    // Get the document ID from the URL params

    let { doc_id } = this.props.match.params;
    // Create the request body object

    let reqBody = {
      file_id: doc_id,
      ogi: this.ogi,
    };

    //To fetch the Invoice image
    // Make a POST request to the API to fetch the document

    axios({
      method: "post",
      url: `${EXTRICATOR_BASE_URL}/open_doc/`,
      data: reqBody,
      headers: {
        Authorization: `Bearer ${authToken}`,
      },
    })
      .then((res) => {
        // Set the previewImg, vendorName, and fileName states and call getDocumentData method

        this.setState({
          isLoading: false,
        });
        var imageURLs = [];
        imageURLs =
          "https://extricator.ai/api/image_render/?ogi=31&file_id=380&path=2021-07-28 17:34:16.810533_1.jpg".split(
            ","
          );
        this.setState(
          {
            previewImg: res.data.file_link.split(","),
            vendorName: res.data.original_file_name,
            fileName: res.data.original_file_name,
          },
          () => {
            this.getDocumentData();
          }
        );
      })
      .catch((error) => {
        // Handle errors by setting the isLoading, docNotFound, and snackOpen states and displaying an error message

        if (error.response) {
          if (error.response.data.non_field_errors) {
            if (
              error.response.data.non_field_errors[0].includes(
                "Kindly pay the bill"
              )
            ) {
              this.setState({
                successMsg: error.response.data.non_field_errors[0],
                snackOpen: false,
                toastColor: "error",
              });
            } else {
              checkOrganizationError(
                error.response.data.non_field_errors[0],
                this.props.history
              );
            }
          }
          this.setState({
            isLoading: false,
            docNotFound: true,
          });
        }
      });
  };
  /**

submitReceipt - A method that submits the extracted data for a receipt
@returns {void}
*/
  submitReceipt = async () => {
    // Set the isLoading state to true and create the request body object

    this.setState({
      isLoading: true,
    });
    var clkTemp = [...this.state.customLeftKeys];
    clkTemp = clkTemp.map((item) => ({
      ...item,
      ExtricatorLeftkey: item.extricator_key,
    }));
    let reqBody = {
      file_id: this.props.match.params.doc_id,
      data: {
        linking: this.state.linking,
        custom_name: this.state.customName,
        customLeftKeys: clkTemp,
        customRightKeys: this.state.customRightKeys,
        deletedLeftKeys: this.state.deletedLeftKeys,
        table_data: this.state.tableSortedData,
      },
      ogi: this.ogi,
    };
    // Make a POST request to the API to submit the receipt data

    //To fetch the Invoice image
    axios({
      method: "post",
      url: `${EXTRICATOR_BASE_URL}/update_keys/`,
      data: reqBody,
      headers: {
        Authorization: `Bearer ${this.state.authenticationToken}`,
      },
    })
      .then((res) => {
        // Set the isLoading state to false and go back to the previous page in the history

        this.setState(
          {
            isLoading: false,
          },
          () => {
            this.props.history.goBack();
          }
        );
      })
      .catch((error) => {
        // Handle errors by setting the isLoading and errorMsg states and displaying an error message

        if (error.response) {
          if (error.response.data && error.response.data.non_field_errors) {
            this.setState({
              isLoading: false,
              errorMsg: error.response.data.non_field_errors,
            });
          }
        }
      });
  };
  /**

submitRejectedReceipt - A method that submits a rejected receipt for a selected model

@returns {void}
*/
  submitRejectedReceipt = () => {
    // Check if a model is selected and set the isLoading state to true

    if (this.state.selectedModel) {
      this.setState({
        isLoading: true,
      });
      var clkTemp = [...this.state.customLeftKeys];
      clkTemp = clkTemp.map((item) => ({
        ...item,
        ExtricatorLeftkey: item.extricator_key,
      }));
      let reqBody = {
        file_id: this.props.match.params.doc_id,
        model_id: this.state.selectedModel.id,
        data: {
          linking: this.state.linking,
          custom_name: this.state.customName,
          customLeftKeys: clkTemp,
          customRightKeys: this.state.customRightKeys,
          deletedLeftKeys: this.state.deletedLeftKeys,
          table_data: this.state.tableSortedData,
        },
        ogi: this.ogi,
      };
      // Make a POST request to the API to submit the rejected receipt data

      //To fetch the Invoice image
      axios({
        method: "post",
        url: `${EXTRICATOR_BASE_URL}/save_rejected_model_response/`,
        data: reqBody,
        headers: {
          Authorization: `Bearer ${this.state.authenticationToken}`,
        },
      })
        .then((res) => {
          // Set the isLoading state to false and redirect to the dashboard_porders page

          this.setState({ isLoading: false }, () => {
            this.props.history.push("/dashboard_porders");
          });
        })
        .catch((error) => {
          // Handle errors by setting the isLoading and errorMsg states and displaying an error message

          if (error.response) {
            this.setState({
              isLoading: false,
              errorMsg: error.response.data.non_field_errors[0],
            });
          }
        });
    }
  };
  /**

getDocumentData - A method that fetches the extracted data for a document
@returns {void}
*/
  getDocumentData = async () => {
    // Set the isLoading state to true and create the request body object

    this.setState({ isLoading: true });

    let authToken = await fetchAuthToken();
    let { doc_id } = this.props.match.params;
    let reqBody = { file_id: doc_id, ogi: this.ogi };
    //-------------------- fetch google vision response api ---------------------------------
    axios({
      method: "post",
      url: `${EXTRICATOR_BASE_URL}/extract_data/`,
      data: reqBody,
      headers: {
        Authorization: `Bearer ${authToken}`,
      },
    })
      .then((resp) => {
        // Check if the response contains any extracted data, set the state variables and initialize the jsPlumb

        var temp = [];
        for (let i = 0; i < resp.data.data.right.length; i++) {
          temp.push([]);
        }
        if (
          resp.data.data.left[0].length > 0 &&
          resp.data.data.right[0].length > 0
        ) {
          this.setState(
            {
              isLoading: false,
              leftKeys: resp.data.data.left,
              rightKeys: resp.data.data.right,
              customLeftKeys: resp.data.data.customLeftKeys
                ? resp.data.data.customLeftKeys
                : [], // addKey not exists in response
              customName: resp.data.data.custom_name,
              linking: resp.data.data.linking,
              tableSortedData: resp.data.data.table_data,
              customRightKeys:
                resp.data.data.customRightKeys &&
                resp.data.data.customRightKeys.length > 0
                  ? resp.data.data.customRightKeys
                  : temp,
              deletedLeftKeys:
                resp.data.data.deletedLeftKeys &&
                resp.data.data.deletedLeftKeys.length > 0
                  ? resp.data.data.deletedLeftKeys
                  : [],
            },
            () => {
              this.jsPlumbReady(this);
            }
          );
        } else {
          // Set the isLoading and invalidInvoice states to false

          this.setState({ isLoading: false, invalidInvoice: true });
        }
      })
      .catch((error) => {
        // Handle errors by setting the isLoading and errorMsg states and displaying an error message

        if (error.response) {
          if (error.response.data && error.response.data.non_field_errors) {
            if (
              error.response.data.non_field_errors[0].includes(
                "Kindly pay the bill"
              )
            ) {
              this.setState({
                successMsg: error.response.data.non_field_errors[0],
                snackOpen: false,
                toastColor: "error",
              });
            } else {
              checkOrganizationError(
                error.response.data.non_field_errors[0],
                this.props.history
              );
              this.setState({
                isLoading: false,
                errorMsg: error.response.data.non_field_errors[0],
              });
            }
          }
        }
      });
  };
  /**

This function initializes the jsPlumb library and sets up the connections between the nodes.

@param {Object} self - Reference to the current component instance.
*/
  jsPlumbReady = (self) => {
    jsPlumb.ready(function () {
      if (self.jsPlumbInstance) {
        for (let i = 0; i < self.state.leftKeys.length; i++) {
          for (let j = 0; j < self.state.leftKeys[i].length; j++) {
            //For Left Node end points
            if (
              !self.state.leftKeys[i][j].table_data &&
              !self.isLeftKeyDeleted(self.state.leftKeys[i][j].extricator_key)
            ) {
              self.jsPlumbInstance.addEndpoint(
                self.state.leftKeys[i][j].extricator_key,
                {
                  endpoint: "Dot",
                  anchor: ["RightMiddle"],
                }
              );
            }
          }
        }
        // left custom nodes-------------
        for (let i = 0; i < self.state.customLeftKeys.length; i++) {
          if (
            !self.isLeftKeyDeleted(self.state.customLeftKeys[i].extricator_key)
          ) {
            self.jsPlumbInstance.addEndpoint(
              self.state.customLeftKeys[i].extricator_key,
              {
                endpoint: "Dot",
                anchor: ["RightMiddle"],
              }
            );
          }
        }

        // right nodes-------------
        for (let k = 0; k < self.state.rightKeys.length; k++) {
          for (let l = 0; l < self.state.rightKeys[k].length; l++) {
            self.jsPlumbInstance.addEndpoint(
              self.state.rightKeys[k][l].extricator_key,
              {
                endpoint: "Dot",
                anchor: ["LeftMiddle"],
              }
            );
          }
        }

        for (let m = 0; m < self.state.customRightKeys.length; m++) {
          for (let n = 0; n < self.state.customRightKeys[m].length; n++) {
            self.jsPlumbInstance.addEndpoint(
              self.state.customRightKeys[m][n].ExtricatorRightkey,
              {
                endpoint: "Dot",
                anchor: ["LeftMiddle"],
              }
            );
          }
        }

        // Events
        self.jsPlumbInstance.bind("connection", function (jsPlumbConnection) {
          var addSelFieldBG = function (connection) {
            $("#" + connection.sourceId).addClass("row-hover-background");
            $("#" + connection.targetId).addClass("row-hover-background");
          };

          var removedSelFieldBG = function (connection) {
            $("#" + connection.sourceId).removeClass("row-hover-background");
            $("#" + connection.targetId).removeClass("row-hover-background");
          };
          jsPlumbConnection.connection.bind("mouseover", function (connection) {
            addSelFieldBG(connection);
          });

          jsPlumbConnection.connection.bind("mouseout", function (connection) {
            removedSelFieldBG(connection);
          });

          // For clearing all the mapping
          $("#clear-all").on("click", function () {
            self.jsPlumbInstance.detachEveryConnection();
          });
          // Repaint everything when scrolling

          $(".source,.target").scroll(function () {
            if (self.jsPlumbInstance) {
              self.jsPlumbInstance.repaintEverything();
            }
          });
        });

        // initialize horizontal scroll bar
        var widthDimensions = {
          width: function (ele) {
            return ele.width();
          },
          scrollLeft: function (ele) {
            return ele[0].scrollLeft;
          },
          clientWidth: function (ele) {
            return ele[0].clientWidth;
          },
        };
        initHorizontalScroll();

        function initHorizontalScroll() {
          var sourceTableElement = $(".source");
          var widthOfTable = widthDimensions.width(
            sourceTableElement.find(".source-table")
          );
          var clientWidth = widthDimensions.clientWidth(sourceTableElement);

          var scrollWidth = widthOfTable - clientWidth;

          if (isFirefox()) {
            sourceTableElement.scrollLeft(-scrollWidth); // For Firefox offset comes as -scrollWidth to 0
          } else if (isIE()) {
            sourceTableElement.scrollLeft(scrollWidth); // For IE offset comes as scrollWidth to 0
          } else {
            sourceTableElement.scrollLeft(0); // For Chrome/Safari offset comes as 0 to scrollWidth
          }
        }

        function isIE() {
          let userAgent = window.navigator.userAgent.toLowerCase();
          return (
            userAgent.indexOf("trident") != -1 ||
            userAgent.indexOf("msie") != -1
          );
        }

        function isFirefox() {
          let userAgent = window.navigator.userAgent.toLowerCase();
          return userAgent.indexOf("firefox") != -1;
        }

        var rightKey = null;
        for (let i = 0; i < self.state.linking.length; i++) {
          if (
            Object.keys(self.state.linking[i])[0] ==
            self.state.leftKeys[0][0].extricator_key
          ) {
            // use js filter method
            rightKey = Object.values(self.state.linking[i])[0];
          }
        }

        self.connection = self.jsPlumbInstance.connect({
          source: self.state.leftKeys[0][0].extricator_key,
          target: rightKey,
          endpoint: "Dot",
          endpointStyle: nodeStyle,
        });
        self.setState({
          selectedLeftKey: self.state.leftKeys[0][0].extricator_key,
        });
      }
    }); // end jsPlumb ready function
    this.handleDrawBox(this);
  };

  confirmDelete = () => {
    // API call to delete file

    this.setState({
      isLoading: true,
    });
    axios({
      method: "post",
      url: `${EXTRICATOR_BASE_URL}/delete_file/`,
      data: {
        file_id: this.props.match.params.doc_id,
        ogi: this.ogi,
      },
      headers: {
        Authorization: `Bearer ${this.state.authenticationToken}`,
      },
    })
      .then((res) => {
        this.setState(
          {
            isLoading: false,
            dialogOpen: false,
          },
          () => {
            this.handleGoBack();
          }
        );
      })
      .catch((error) => {
        if (error.response) {
          if (error.response.data && error.response.data.non_field_errors) {
            this.setState({
              isLoading: false,
              errorMsg: error.response.data.non_field_errors[0],
            });
          }
        }
      });
  };

  handleShowTable = () => {
    // Toggle state to show/hide table

    this.setState({
      lineTable: !this.state.lineTable,
    });
  };

  restoreDuplicateKey = (currentPage) => {
    // Function to restore duplicate key

    var tempCustomArr = [...this.state.customName],
      uniqueKey = null;
    var keyName = "";
    for (let i = 0; i < this.state.customName.length; i++) {
      if (
        Object.keys(this.state.customName[i])[0] ==
        this.state.leftKeys[currentPage][this.state.alteredKey].extricator_key
      ) {
        keyName = Object.values(this.state.customName[i])[0];
        // Check for unique key
      }
    }
  };

  /**

Handle left node click event.
@param {string} leftKey - The extricator key of the clicked left node.
@param {number} currentPage - The current page number.
*/
  handleLeftClick = (leftKey, currentPage) => {
    // Restore any duplicate custom left keys.

    if (this.state.alteredKey) {
      this.restoreDuplicateKey(currentPage);
    }
    // Set the clicked left key as selected and reset other states.

    this.globalLeftClickedKey = leftKey;
    this.setState({
      selectedLeftKey: leftKey,
      customInputFocused: null,
      selectedCustomLeftKey: null,
    });
    // Find the corresponding right node for the clicked left node.

    var rightKey = "",
      rightElement = null,
      currentElementLeftStub = 0;

    for (let i = 0; i < this.state.linking.length; i++) {
      if (Object.keys(this.state.linking[i])[0] == leftKey) {
        // use js filter method
        rightKey = Object.values(this.state.linking[i])[0];
      }
    }
    for (let j = 0; j < this.state.rightKeys.length; j++) {
      for (let k = 0; k < this.state.rightKeys[j].length; k++) {
        if (
          this.state.rightKeys[j][k].extricator_key.includes(
            rightKey.substr(0, rightKey.length - 1)
          )
        ) {
          rightElement = this.state.rightKeys[j][k];
        }
      }
    }

    //if right Element is not found in rightKeys
    if (rightElement == null) {
      for (let j = 0; j < this.state.customRightKeys.length; j++) {
        /// j represents page number
        for (let k = 0; k < this.state.customRightKeys[j].length; k++) {
          // inner object array loop
          if (this.state.customRightKeys[j][k].ExtricatorRightkey == rightKey) {
            rightElement = this.state.customRightKeys[j][k];
          }
        }
      }
    }
    // Create or update the connection between the clicked left node and its corresponding right node.

    if (this.jsPlumbInstance) {
      if (this.connection && this.connection.source && this.connection.target) {
        this.jsPlumbInstance.deleteConnection(this.connection);
      }
      // If the corresponding right node is found, create a connection between the left and right node.

      if (rightKey) {
        currentElementLeftStub =
          Math.ceil(rightElement.boundingBoxes[0].x1 * 100) * 6;
        this.connection = this.jsPlumbInstance.connect({
          source: leftKey,
          target: rightKey,
          connector: [
            "Straight",
            {
              stub: [currentElementLeftStub, 35],
              gap: [6, 6],
              cornerRadius: 10,
              alwaysRespectStubs: false,
            },
          ],
          endpoint: "Dot",
          endpointStyle: nodeStyle,
        });
        // Scroll to the corresponding right node.

        let elmnt = document.getElementById(rightKey);
        if (elmnt) {
          var elementTop = String(elmnt.style.top);
          var percFigure = elementTop.replace("%", "");
          var percVal = ((this.bodyHeight * percFigure) / 100).toFixed(3);
          let calculatedTopOffset =
            Number(percVal) + 1150 * Number(currentPage);
          $("#doc_overflow").scrollTop(calculatedTopOffset);
        }
        if (this.state.valueFocussed) {
          this.setState({ valueFocussed: null });
        }
        this.setState({ focusRightKey: rightKey });
      } else {
        this.connection = this.jsPlumbInstance.connect({
          source: leftKey,
          endpoint: "Dot",
          endpointStyle: nodeStyle,
        });
      }
    }
  };
  /**

Handles the click event for custom left keys.
@param {string} customLeftKey - The id of the clicked custom left key.
@param {number} currentPage - The current page number.
*/
  handleCustomLeftKeyClick = (customLeftKey, currentPage) => {
    this.globalLeftClickedKey = customLeftKey;
    this.setState({
      selectedCustomLeftKey: customLeftKey,
      selectedLeftKey: null,
    });
    var rightKey = "",
      rightElement = null,
      currentElementLeftStub = null;
    // Finds the right key for the clicked custom left key.

    for (let i = 0; i < this.state.linking.length; i++) {
      if (Object.keys(this.state.linking[i])[0] == customLeftKey) {
        // use js filter method
        rightKey = Object.values(this.state.linking[i])[0];
      }
    }
    // Finds the right element for the right key.

    for (let j = 0; j < this.state.customRightKeys.length; j++) {
      for (let k = 0; k < this.state.customRightKeys[j].length; k++) {
        if (
          this.state.customRightKeys[j][k].ExtricatorRightkey.includes(
            rightKey.substr(0, rightKey.length - 1)
          )
        ) {
          rightElement = this.state.customRightKeys[j][k];
        }
      }
    }
    // Connects the custom left key to the right element.

    if (this.jsPlumbInstance) {
      if (this.connection && this.connection.source && this.connection.target) {
        this.jsPlumbInstance.deleteConnection(this.connection);
      }
      if (rightKey) {
        if (rightElement) {
          currentElementLeftStub =
            Math.ceil(rightElement.boundingBoxes[0].x1 * 100) * 6;
        } else {
          currentElementLeftStub = 70;
        }
        // Creates the connection between the custom left key and the right element.

        this.connection = this.jsPlumbInstance.connect({
          source: customLeftKey,
          target: rightKey,
          connector: [
            "Straight",
            {
              stub: [currentElementLeftStub, 35],
              gap: [6, 6],
              cornerRadius: 10,
              alwaysRespectStubs: false,
            },
          ],
          endpoint: "Dot",
          endpointStyle: nodeStyle,
        });
        // Scrolls the right element into view.

        let elmnt = document.getElementById(rightKey);
        if (elmnt) {
          var elementTop = String(elmnt.style.top);
          var percFigure = elementTop.replace("%", "");
          var percVal = ((this.bodyHeight * percFigure) / 100).toFixed(3);
          let calculatedTopOffset =
            Number(percVal) + 1150 * Number(currentPage);
          $("#doc_overflow").scrollTop(calculatedTopOffset);
        }

        this.setState({ focusRightKey: rightKey });
      }
    }
  };
  /**
   * Function that handles click on a right key element.
   * @param {string} rightKey - The ID of the clicked right key element.
   * @param {object} rightKeyBounds - The bounding box of the clicked right key element.
   */
  handleRightClick = (rightKey, rightKeyBounds) => {
    let isDeleted = false;
    var currentElementLeftStub = Math.ceil(rightKeyBounds[0].x1 * 100) * 6;
    if (this.jsPlumbInstance) {
      if (
        this.connection &&
        this.connection.source &&
        this.connection.target &&
        !this.state.isCustomDeleted
      ) {
        this.jsPlumbInstance.deleteConnection(this.connection);
      } else {
        this.setState({ isCustomDeleted: false });
      }

      //solving bug of selectedLefyKey hold prev value of a deleted key
      let deletedKeys = this.state.deletedLeftKeys;

      for (let i = 0; i < deletedKeys.length; i++) {
        if (deletedKeys[i]["extricator_key"] == this.state.selectedLeftKey) {
          this.setState({ selectedLeftKey: null }, () => {
            //call back fn noting it is just to update the state
          });
          isDeleted = true;
        } else if (
          deletedKeys[i]["extricator_key"] == this.state.selectedCustomLeftKey
        ) {
          this.setState({ selectedCustomLeftKey: null }, () => {
            //call back fn same as prev
          });
          isDeleted = true;
        }
      }
      if (
        (this.state.selectedLeftKey || this.state.selectedCustomLeftKey) &&
        !isDeleted
      ) {
        if (this.state.selectedLeftKey) {
          // Connect the left and right keys.

          this.connection = this.jsPlumbInstance.connect({
            source: this.state.selectedLeftKey,
            target: rightKey,
            connector: [
              "Straight",
              {
                stub: [currentElementLeftStub, 35],
                gap: [6, 6],
                cornerRadius: 10,
                alwaysRespectStubs: false,
              },
            ],
            endpoint: "Dot",
            endpointStyle: nodeStyle,
          });

          let linkingTemp = this.state.linking;
          var rightKeyIndex = null;

          //  -------------------remove previous right key linking-------------------
          for (let i = 0; i < this.state.linking.length; i++) {
            rightKeyIndex = this.state.linking.findIndex(
              (obj) => Object.values(obj) == rightKey
            );
          }

          if (rightKeyIndex >= 0) {
            linkingTemp[rightKeyIndex][
              Object.keys(linkingTemp[rightKeyIndex])[0]
            ] = "";
          }

          const leftKeyIdex = linkingTemp.findIndex((obj) =>
            Object.keys(obj).includes(this.state.selectedLeftKey)
          );
          //---------------------------update new right key linking------------------------
          if (leftKeyIdex >= 0) {
            linkingTemp[leftKeyIdex][Object.keys(linkingTemp[leftKeyIdex])[0]] =
              rightKey;
            this.setState({ linking: linkingTemp });
          }
        } else if (this.state.selectedCustomLeftKey) {
          this.connection = this.jsPlumbInstance.connect({
            source: this.state.selectedCustomLeftKey,
            target: rightKey,
            endpoint: "Dot",
            endpointStyle: nodeStyle,
          });

          let linkingTemp1 = this.state.linking;
          var rightKeyIndex1 = null;

          //  -------------------remove previous right key linking-------------------
          for (let i = 0; i < this.state.linking.length; i++) {
            rightKeyIndex1 = this.state.linking.findIndex(
              (obj) => Object.values(obj) == rightKey
            );
          }

          if (rightKeyIndex1 >= 0) {
            linkingTemp1[rightKeyIndex1][
              Object.keys(linkingTemp1[rightKeyIndex1])[0]
            ] = "";
          }

          const leftKeyIdexs = linkingTemp1.findIndex((obj) =>
            Object.keys(obj).includes(this.state.selectedCustomLeftKey)
          );
          //---------------------------update new right key linking------------------------
          if (leftKeyIdexs >= 0) {
            linkingTemp1[leftKeyIdexs][
              Object.keys(linkingTemp1[leftKeyIdexs])[0]
            ] = rightKey;
            this.setState({ linking: linkingTemp1 });
          }
        }
      } else {
        var leftSourceKey = "";
        for (let i = 0; i < this.state.linking.length; i++) {
          if (Object.values(this.state.linking[i])[0] == rightKey) {
            // use js filter method
            leftSourceKey = Object.keys(this.state.linking[i])[0];
          }
        }
        this.connection = this.jsPlumbInstance.connect({
          source: leftSourceKey,
          target: rightKey,
          endpoint: "Dot",
          endpointStyle: nodeStyle,
        });
      }
    }
  };
  // This function retrieves the value associated with a given left key.
  // It searches for the corresponding right key in the linking state, and
  // then searches the rightKeys and customRightKeys arrays for the corresponding value.
  getLeftKeyValue = (leftKey) => {
    var rightKey = "";

    for (let i = 0; i < this.state.linking.length; i++) {
      if (Object.keys(this.state.linking[i])[0] == leftKey) {
        // use js filter method
        rightKey = Object.values(this.state.linking[i])[0];
      }
    }

    for (let j = 0; j < this.state.rightKeys.length; j++) {
      /// j represents page number
      for (let k = 0; k < this.state.rightKeys[j].length; k++) {
        // inner object array loop
        if (this.state.rightKeys[j][k].extricator_key == rightKey) {
          return this.state.rightKeys[j][k].value;
        }
      }
    }

    //TODO use the same custom right key's key name as what used in the right key JSON.
    for (let j = 0; j < this.state.customRightKeys.length; j++) {
      /// j represents page number
      for (let k = 0; k < this.state.customRightKeys[j].length; k++) {
        // inner object array loop
        if (this.state.customRightKeys[j][k].ExtricatorRightkey == rightKey) {
          return this.state.customRightKeys[j][k].value;
        }
      }
    }
  };
  // This function retrieves the value associated with a given custom left key.
  // It searches for the corresponding right key in the linking state, and then
  // searches the customRightKeys array for the corresponding value.
  getCustomLeftKeyValue = (leftKey) => {
    var rightKey = "";

    for (let i = 0; i < this.state.linking.length; i++) {
      if (Object.keys(this.state.linking[i])[0] == leftKey) {
        // use js filter method
        rightKey = Object.values(this.state.linking[i])[0];
      }
    }

    for (let j = 0; j < this.state.customRightKeys.length; j++) {
      /// j represents page number
      for (let k = 0; k < this.state.customRightKeys[j].length; k++) {
        // inner object array loop
        if (this.state.customRightKeys[j][k].ExtricatorRightkey == rightKey) {
          return this.state.customRightKeys[j][k].value;
        }
      }
    }
  };
  // This function retrieves the custom key name associated with a given left key.
  // It searches the customName state for the corresponding custom key name.
  setCustomKeyName = (leftKey, type) => {
    var keyName = "";
    for (let i = 0; i < this.state.customName.length; i++) {
      if (Object.keys(this.state.customName[i])[0] == leftKey) {
        // use js filter method
        keyName = Object.values(this.state.customName[i])[0];
        let frags = keyName.split("_");
        for (let i = 0; i < frags.length; i++) {
          frags[i] = frags[i].charAt(0).toUpperCase() + frags[i].slice(1);
        }
        return frags.join(" ");
      }
    }
  };
  /**

Adds a new dynamic key to the state and updates the UI.
*/
  addNewKey = () => {
    if (this.connection && this.connection.source && this.connection.target) {
      this.jsPlumbInstance.deleteConnection(this.connection);
    }
    // Get the list element to scroll to the bottom.

    var listObj = document.getElementById("scrollBeginsHereRecipt");
    // Generate a new dynamic key based on the current timestamp.

    const date = new Date();
    var newDynamicKey = `ExtricatorNewLeftkey${date.getTime()}`;
    // Create a new key object with the generated dynamic key.

    let newKey = {
      key: "ExtricatorNewLeftkey",
      extricator_key: newDynamicKey,
      page_number: 1,
      table_data: false,
    };
    // Create a new linking key object with the generated dynamic key.

    var newLinkingKey = {};
    newLinkingKey[newDynamicKey] = newDynamicKey + "_2";
    // Create a new custom name object with the generated dynamic key.

    var newCustomName = {};
    newCustomName[newDynamicKey] = "New Key";

    // Update the state with the new key, linking key, custom name, and selected key.

    this.setState(
      {
        showLineItems: false,
        editChangeCount: this.state.editChangeCount + 1,
        lineTable: false,
        customLeftKeys: [...this.state.customLeftKeys, newKey],
        linking: [...this.state.linking, newLinkingKey],
        customName: [...this.state.customName, newCustomName],
        // choose the selected key with different color
        selectedLeftKey: null,
        selectedCustomLeftKey: newKey["extricator_key"],
      }, // After updating the state, scroll to the bottom of the list and add a new endpoint.

      () => {
        listObj.scrollTop = listObj.scrollHeight;
        if (this.jsPlumbInstance) {
          this.jsPlumbInstance.addEndpoint(newDynamicKey, {
            endpoint: "Dot",
            anchor: ["RightMiddle"],
          });
        }
      }
    );
  };
  /**

Handles the key press event on the input fields for the custom left keys and left key edits.
When the Enter key is pressed, the input field is cleared and the focus is removed.
@param {Object} event - The key press event object.
@param {string} type - The type of input field, either "custom" or "left key edit".
*/
  handleEnterPress = (event, type) => {
    if (event.keyCode === 13) {
      if (type == "custom") {
        // Clear the custom input field and remove focus.

        this.setState({
          customInputFocused: null,
          customLeftKeyText: "",
          inputFocusCustom: false,
        });
      } else {
        // Clear the left key edit input field and remove focus.

        this.setState({ leftKeyEditId: null, inputFocused: false });
      }
    }
  };
  /**

Handles the left key edit event by updating the custom name object with the new value.
@param {Object} evt - The change event object.
@param {number} index - The index of the custom name object in the state array.
@param {string} leftKey - The left key associated with the custom name object.
*/
  handleLeftKeyEdit = (evt, index, leftKey) => {
    if (evt.which == "13") {
      evt.preventDefault();
    }
    // Get a copy of the custom name array from the state.

    var tempCustomName = this.state.customName;
    // Find the index of the custom name object in the array based on the left key.

    const leftKeyIndex = this.state.customName.findIndex((obj) =>
      Object.keys(obj).includes(leftKey)
    );

    if (leftKeyIndex !== -1) {
      tempCustomName[leftKeyIndex][
        Object.keys(tempCustomName[leftKeyIndex])[0]
      ] = evt.target.value;

      this.setState({
        leftKeyEditValue: evt.target.value,
        customName: tempCustomName,
        editChangeCount: this.state.editChangeCount + 1,
      });
    }
  };
  /**

Handles the custom left key add/edit event by updating the custom name object with the new value.
@param {Object} evt - The change event object.
@param {number} index - The index of the custom name object in the state array.
@param {string} leftKey - The left key associated with the custom name object.
*/
  handleCustomLeftAddKeyEdit = (evt, index, leftKey) => {
    // Create a copy of the custom name array from the state.

    var customNameTemp = [...this.state.customName];
    // Loop through the custom name array and update the object with the new value.

    for (let i = 0; i < this.state.customName.length; i++) {
      if (Object.keys(this.state.customName[i]) == leftKey) {
        customNameTemp[i][leftKey] = evt.target.value;
      }
    }
    // Find the index of the first empty custom name object in the array and set its value to "New Key".

    var emptyIndex = customNameTemp.findIndex((obj) =>
      Object.values(obj).includes("")
    );
    if (emptyIndex >= 0) {
      customNameTemp[emptyIndex][Object.keys(customNameTemp[emptyIndex])[0]] =
        "New Key";
    }
    // Update the state with the new custom name array and increment the edit change count.

    this.setState({
      customLeftKeyText: evt.target.value,
      customName: customNameTemp,
      editChangeCount: this.state.editChangeCount + 1,
    });
  };
  /**

Handles the left key assignment event by setting the left key edit ID and value in the state.
@param {string} leftKey - The left key associated with the custom name object.
*/
  handleAssignLeftClickId = (leftKey) => {
    // Find the index of the custom name object in the array based on the left key.

    const leftKeyIndex = this.state.customName.findIndex((obj) =>
      Object.keys(obj).includes(leftKey)
    );
    // Get the left key edit value from the custom name object and update the state.

    var leftKeyEditValue =
      this.state.customName[leftKeyIndex][
        Object.keys(this.state.customName[leftKeyIndex])[0]
      ];

    this.setState({
      leftKeyEditId: leftKey,
      leftKeyEditValue,
    });
  };
  /**

Handles the custom left key assignment event by setting the custom left key text and focus in the state.
@param {string} customkey - The custom key associated with the custom name object.
*/
  handleAssignCustomLeftKey = (customkey) => {
    // Loop through the custom name array and find the default value based on the custom key.

    var defaultVal = "";
    for (let i = 0; i < this.state.customName.length; i++) {
      if (Object.keys(this.state.customName[i]) == customkey) {
        defaultVal = Object.values(this.state.customName[i])[0];
      }
    }
    // Update the state with the default value, input focus, and custom input focus.

    this.setState({
      customLeftKeyText: defaultVal,
      inputFocusCustom: true,
      customInputFocused: customkey,
    });
  };
  /**

Handles the form click event to close the left key edit and model list, and show/hide the logout button.
@param {Object} event - The click event object.
*/
  handleFormClick = (event) => {
    event.stopPropagation();
    if (this.state.leftKeyEditId && !this.state.inputFocused) {
      // If a left key edit is in progress and the input is not focused, clear

      this.setState({
        leftKeyEditId: null,
      });
    }
    if (this.state.modelListOpen) {
      this.setState({ modelListOpen: false });
    }
    this.showHideLogoutBtn(null);
  };
  /**

Handles the componentDidUpdate lifecycle method to update the UI after the component updates.
If a new custom left key has been added, adds an endpoint to the left key element.
If custom right keys exist, adds endpoints to each of them.
If deleted left keys exist, removes endpoints from the corresponding elements.
@param {Object} prevProps - The previous props object.
@param {Object} prevState - The previous state object.
@param {Object} snapshot - The snapshot object.
*/
  componentDidUpdate(prevProps, prevState, snapshot) {
    if (this.state.customLeftKeys.length > 0) {
      // If a new custom left key has been added, get its ID and add an endpoint to the element.

      var newDynamicKey =
        this.state.customLeftKeys[this.state.customLeftKeys.length - 1]
          .extricator_key;
      let elmnt = document.getElementById(newDynamicKey);
      if (elmnt && this.jsPlumbInstance) {
        this.jsPlumbInstance.addEndpoint(newDynamicKey, {
          endpoint: "Dot",
          anchor: ["RightMiddle"],
        });
      }
    }
    // Wait for 2 seconds and then add endpoints to all custom right keys.

    setTimeout(() => {
      for (let i = 0; i < this.state.customRightKeys.length; i++) {
        for (let j = 0; j < this.state.customRightKeys[i].length; j++) {
          if (this.jsPlumbInstance) {
            this.jsPlumbInstance.addEndpoint(
              this.state.customRightKeys[i][j].ExtricatorRightkey,
              {
                endpoint: "Dot",
                anchor: ["LeftMiddle"],
              }
            );
          }
        }
      }
      // If deleted left keys exist, remove endpoints from the corresponding elements.

      if (
        this.state.deletedLeftKeys.length > 0 &&
        prevState.deletedLeftKeys !== this.state.deletedLeftKeys
      ) {
        for (let i = 0; i < this.state.leftKeys.length; i++) {
          for (let j = 0; j < this.state.leftKeys[i].length; j++) {
            if (
              !this.state.leftKeys[i][j].table_data &&
              !this.isLeftKeyDeleted(this.state.leftKeys[i][j].extricator_key)
            ) {
              if (this.jsPlumbInstance) {
                this.jsPlumbInstance.addEndpoint(
                  this.state.leftKeys[i][j].extricator_key,
                  {
                    endpoint: "Dot",
                    anchor: ["RightMiddle"],
                  }
                );
              }
            }
          }
        }
      }
    }, 2000);
  }
  /**

Handles the value click event by setting the value focus in the state.
@param {Object} event - The click event object.
@param {Object} item - The item associated with the clicked value.
*/

  handleValueClick = (event, item) => {
    this.setState({ valueFocussed: item.extricator_key });
  };
  /**

Handles the go back event by navigating to the previous page in the history.
*/
  handleGoBack = async () => {
    this.props.history.goBack();
  };
  /**

Handles the deletion of a custom bound by removing it from the custom right keys list and deleting the corresponding connection if it exists.
@param {Object} bound - The bound to be deleted.
*/
  handleDeleteCustomBound = (bound) => {
    var customRightKeysTemp = [...this.state.customRightKeys];
    for (let i = 0; i < customRightKeysTemp.length; i++) {
      for (let j = 0; j < customRightKeysTemp[i].length; j++) {
        if (
          customRightKeysTemp[i][j].ExtricatorRightkey ==
          bound.ExtricatorRightkey
        ) {
          let pageSet = [...customRightKeysTemp[i]];
          pageSet.splice(j, 1);
          customRightKeysTemp[i] = pageSet;
        }
      }
    }
    this.setState(
      { customRightKeys: customRightKeysTemp, isCustomDeleted: true },
      () => {
        if (this.connection && this.jsPlumbInstance) {
          if (this.connection.source && this.connection.source) {
            this.jsPlumbInstance.deleteConnection(this.connection);
          }
        }
      }
    );
  };
  /**

Checks if an array is not empty.
@param {Array} data - The array to be checked.
@returns {Boolean} - true if the array is not empty, false otherwise.
*/
  checkArrayNotEmpty = (data) => {
    Object.size = function (obj) {
      var size = 0,
        key;
      for (key in obj) {
        if (obj[key].length != 0) size++;
      }
      return size;
    };

    var size = Object.size(data);

    if (size > 0) return true;
    return false;
  };
  /**

Handles the deletion of a left key by adding it to the deleted left keys list and removing the corresponding endpoint if it exists.
@param {Object} leftKey - The left key to be deleted.
*/
  handleKeyDelete = (leftKey) => {
    var deletedLeftKeys = [...this.state.deletedLeftKeys];
    deletedLeftKeys.push(leftKey);
    this.setState({ deletedLeftKeys }, () => {
      this.connection = undefined;
      if (this.jsPlumbInstance) {
        this.jsPlumbInstance.remove(leftKey.extricator_key);
      }
    });
  };
  /**

Checks if a left key has been deleted.
@param {Object} leftKey - The left key to be checked.
@returns {Boolean} - true if the left key has been deleted, false otherwise.
*/
  isLeftKeyDeleted = (leftKey) => {
    var leftKeyOccurance = 0;
    for (let i = 0; i < this.state.deletedLeftKeys.length; i++) {
      if (this.state.deletedLeftKeys[i].extricator_key === leftKey) {
        leftKeyOccurance++;
      }
    }
    if (leftKeyOccurance > 0) {
      return true;
    }
    return false;
  };
  /**

Handles the model dialog close event by toggling the state of the newModelDialogOpen property.
*/
  handleModelDialogClose = () => {
    this.setState({
      newModelDialogOpen: !this.state.newModelDialogOpen,
    });
  };
  /**

Shows or hides the logout button depending on the flag parameter. Also closes the modal menu.
@param {Boolean} flag - true to show the logout button, false to hide it.
*/
  showHideLogoutBtn = (flag) => {
    if (flag) {
      this.setState({ showLogoutBtn: !this.state.showLogoutBtn });
    } else {
      if (this.state.showLogoutBtn) {
        this.setState({ showLogoutBtn: false });
      }
    }
    this.setState({ modalMenuOpen: false });
  };
  /**

Handles the blur event on an input element by resetting the state of the custom input properties.
@param {Object} listObj - The list object.
*/
  handleBlurInput = (listObj) => (event) => {
    var inputValue = event.target.defaultValue;
    var { customName } = this.state;

    this.setState({
      customInputFocused: null,
      customLeftKeyText: "",
      inputFocusCustom: false,
    });
  };
  /**

Exports the form to an Excel file by opening the file URL in a new window.
*/
  exportFormToExcel = () => {
    var xlsUrl = `${EXTRICATOR_BASE_URL}/export_to_excel/?id=${
      this.props.match.params.doc_id
    }&ogi=${fetchOrganisationId()}`;
    window.open(xlsUrl);
  };
  /**

Handles the snack close event by resetting the state of the success message and snack open properties.
@param {Object} event - The event object.
@param {String} reason - The reason for the snack close.
*/
  handleSnackClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    this.setState({ successMsg: "", snackOpen: false });
  };
  /**

Toggles the display of the key-value list for the invoice details.
*/
  toggleKeyValueListDisplay = () => {
    this.setState({ showInvoiceDetails: !this.state.showInvoiceDetails });
    if (this.connection && this.jsPlumbInstance) {
      if (this.connection.source && this.connection.source) {
        this.jsPlumbInstance.deleteConnection(this.connection);
      }
    }
  };
  // ------------------------------------------------------
  handleCellUpdate = (pageIndex, tableIndex, rowIndex, cellIndex, e) => {
    // Get the current tableSortedData state
    const tableSortedData = [...this.state.tableSortedData];

    // Update the cell value in the tableSortedData
    tableSortedData[pageIndex][tableIndex][rowIndex].value[cellIndex] = e.target.textContent;

    // Set the new state for tableSortedData
    this.setState({ tableSortedData });
};

  handleHeaderUpdate = (pageIndex, tableIndex, headingIndex, e) => {
    // Get the current tableSortedData state
    const tableSortedData = [...this.state.tableSortedData];
    // Update the cell value in the tableSortedData
    tableSortedData[pageIndex][tableIndex][0].key[headingIndex] = e.target.textContent;

    // Set the new state for tableSortedData
    this.setState({ tableSortedData });
  };

  render() {
    const { classes } = this.props;
    return (
      <div className="px-3" style={{ position: "relative" }}>
        <SnackbarComponent
          isOpen={this.state.snackOpen}
          message={this.state.successMsg}
          color={this.state.toastColor}
          handleClose={this.handleSnackClose}
        />
        <CheckMobileDevice />
        {this.state.docNotFound ? (
          <DocNotFoundDialog></DocNotFoundDialog>
        ) : (
          <div onClick={this.handleFormClick}>
            <div className="row" id="detailHeader">
              <div
                className="col-3"
                style={{ background: "#011B3E", zIndex: 100 }}
              >
                <div className="form-group d-flex justify-content-center mt-4 banner-head">
                  <div className="col-sm-7">
                    <a onClick={this.handleGoBack} style={{ width: "80%" }}>
                      <img
                        src={Images.app_logo}
                        style={{ width: "100%" }}
                        className="img-responsive"
                        alt=""
                      />
                    </a>
                  </div>
                </div>
              </div>
              <div
                className="col-9"
                style={{ backgroundColor: "#011B3E", zIndex: 100 }}
              >
                <nav
                  id="myBar"
                  className="navbar detail-header-form px-4"
                  style={{
                    maxHeight: "98px",
                    alignItems:
                      this.activeSection === "FORMS_REVIEW"
                        ? "flex-start"
                        : "center",
                  }}
                >
                  <div className="col-md-6 form-inline page-count">
                    <div
                      className="form-inline justify-content-start"
                      style={{ width: "100%" }}
                    >
                      <span
                        className="d-flex align-items-center"
                        style={{ fontSize: "14px", cursor: "pointer" }}
                        onClick={() => this.props.history.goBack()}
                      >
                        <span className="material-icons mr-1">arrow_back</span>
                        Back
                      </span>
                      <span
                        className="material-icons ml-2"
                        style={{
                          color: this.state.deleteHovered ? "#fff" : "#678ab9",
                        }}
                        onMouseEnter={() =>
                          this.setState({ deleteHovered: true })
                        }
                        onMouseLeave={() =>
                          this.setState({ deleteHovered: false })
                        }
                        onClick={() =>
                          this.setState({ dialogOpen: !this.state.dialogOpen })
                        }
                      >
                        delete
                      </span>
                      <span
                        className="download-span d-flex ml-2"
                        onClick={() => {
                          window.open(
                            `${EXTRICATOR_BASE_URL}/download_file/?id=${
                              this.props.match.params.doc_id
                            }&ogi=${fetchOrganisationId()}`
                          );
                        }}
                        style={{ marginTop: "2px" }}
                      >
                        <i className="fa fa-download mr-1"></i>
                        <span>Download</span>
                      </span>
                    </div>
                    {this.activeSection === "PORDER_REJECTED" && (
                      <div className="d-flex model-select mt-3">
                        <label
                          className="parse_label mr-3"
                          onClick={() =>
                            this.setState({
                              newModelDialogOpen:
                                !this.state.newModelDialogOpen,
                            })
                          }
                        >
                          <span className="d-flex justify-content-center align-items-center">
                            <i
                              className="fa fa-plus mr-1"
                              aria-hidden="true"
                            ></i>
                            Add New Model
                          </span>
                        </label>
                        <CustomDropDown
                          newModel={this.state.newModel}
                          screen="docDetails"
                          modelType={"PORDER"}
                          onBarClick={() =>
                            this.setState({ modelListOpen: true })
                          }
                          listIsOpen={this.state.modelListOpen}
                          getSelectedModel={(model) =>
                            this.setState({ selectedModel: model })
                          }
                        />
                      </div>
                    )}
                  </div>
                  <div className="col-md-6 d-flex justify-content-end">
                    <div style={{ position: "relative", marginTop: "-5px" }}>
                      <LogoutBar
                        btnShow={this.state.showLogoutBtn}
                        callback={this.showHideLogoutBtn}
                      ></LogoutBar>
                    </div>
                  </div>
                </nav>
              </div>
            </div>
            <div
              className="row justify-content-between"
              style={{ height: "95vh" }}
              id="drag_canvas"
              onClick={() => this.showHideLogoutBtn(null)}
            >
              {this.state.invalidInvoice && (
                <div
                  className="modal fade show"
                  id="exampleModal"
                  style={{ display: "block", background: "rgba(0,0,0,0.8)" }}
                  tabindex="-1"
                  onClick={() => this.setState({ invalidInvoice: false })}
                  role="dialog"
                  aria-labelledby="exampleModalLabel"
                  aria-hidden="true"
                >
                  <div className="modal-dialog" role="document">
                    <div className="modal-content" style={{ margin: "40% 0" }}>
                      <div className="modal-header">
                        <h5 className="modal-title" id="exampleModalLabel"></h5>
                        <button
                          type="button"
                          className="close"
                          data-dismiss="modal"
                          aria-label="Close"
                        >
                          <span aria-hidden="true">&times;</span>
                        </button>
                      </div>
                      <h5 className="text-center" style={{ color: "#fff" }}>
                        Invalid or corrupt file
                      </h5>
                      <div
                        className="modal-footer"
                        style={{ justifyContent: "center" }}
                      >
                        <button
                          type="button"
                          className="btn"
                          style={{
                            border: "1px solid #35F8F1",
                            color: "#35F8F1",
                            backgroundColor: "transparent",
                          }}
                          onClick={() => this.props.history.goBack()}
                          data-dismiss="modal"
                        >
                          Go Back
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              )}
              <DeleteDialogBox
                open={this.state.dialogOpen}
                handleClose={() =>
                  this.setState({ dialogOpen: !this.state.dialogOpen })
                }
                content="Are you sure you want to delete this document?"
                handleConfirm={() => this.confirmDelete()}
                dialogTitle="Confirmation"
              />
              {this.state.newModelDialogOpen ? (
                <CreateModelDialog
                  action="create"
                  open={this.state.newModelDialogOpen}
                  modelType={"PORDER"}
                  handleClose={this.handleModelDialogClose}
                  handleConfirm={(txt) => this.setState({ newModel: txt })}
                />
              ) : null}

              <Backdrop
                style={{ zIndex: 10, color: "#fff" }}
                open={this.state.isLoading}
              >
                <CircularProgress color="inherit" />
              </Backdrop>
              <div
                className="col-sm-3 pt-3"
                style={{ backgroundColor: "#04244F" }}
              >
                <div
                  className="board-wrapperRecipt"
                  style={{ backgroundColor: "#011B3E", position: "relative" }}
                >
                  <div style={{ flex: 1 }}>
                    <div className="col-12 source" id="scrollBeginsHereRecipt">
                      <div style={{ flex: 1 }}>
                        <div className="invoiceTitle text-center p-2 container">
                          {this.state.vendorName}
                        </div>
                      </div>
                      <div className="accordion_head_recipt">
                        <span
                          className="keyValue-span"
                          onClick={() => this.toggleKeyValueListDisplay()}
                          style={{ width: "79%" }}
                        >
                          <i
                            className={
                              this.state.showInvoiceDetails
                                ? "fa fa-caret-down"
                                : "fa fa-caret-up"
                            }
                          ></i>
                          <span>Keys & Values</span>
                        </span>
                        {this.state.leftKeys.length > 0 && (
                          <span className="addKey" onClick={this.addNewKey}>
                            Add key
                          </span>
                        )}
                      </div>
                      <div
                        className={
                          this.state.showInvoiceDetails
                            ? "source-table show"
                            : "source-table collapse"
                        }
                      >
                        <ul className="source-list">
                          {this.state.leftKeys.length > 0 ? (
                            this.state.leftKeys.map((page, pageIndex) =>
                              page.map((item, index) => {
                                if (
                                  !item.table_data &&
                                  !this.isLeftKeyDeleted(item.extricator_key)
                                ) {
                                  return (
                                    <li
                                      id={item.extricator_key}
                                      style={{
                                        height: "42px",
                                        direction: "ltr",
                                        padding: "10px 10px 10px 20px",
                                      }}
                                      onClick={() =>
                                        this.handleLeftClick(
                                          item.extricator_key,
                                          pageIndex
                                        )
                                      }
                                      className={
                                        this.state.selectedLeftKey ==
                                        item.extricator_key
                                          ? "d-flex justify-content-between align-items-center pair-selected"
                                          : "d-flex justify-content-between align-items-center"
                                      }
                                    >
                                      {
                                        <>
                                          {this.state.leftKeyEditId ==
                                          item.extricator_key ? (
                                            <input
                                              className="details_input"
                                              type="text"
                                              id={item.extricator_key}
                                              name={item.extricator_key}
                                              // onBlur={this.handleBlurInput({index,category:"default"})}
                                              onBlur={() =>
                                                this.setState({
                                                  leftKeyEditId: null,
                                                  inputFocused: false,
                                                })
                                              }
                                              autoFocus={true}
                                              onFocus={() =>
                                                this.setState({
                                                  inputFocused: true,
                                                })
                                              }
                                              value={
                                                this.state.leftKeyEditValue
                                              }
                                              onKeyUp={(evt) =>
                                                this.handleEnterPress(
                                                  evt,
                                                  "base"
                                                )
                                              }
                                              onChange={(evt) =>
                                                this.handleLeftKeyEdit(
                                                  evt,
                                                  index,
                                                  item.extricator_key
                                                )
                                              }
                                            />
                                          ) : (
                                            <BootstrapTooltip
                                              title={this.setCustomKeyName(
                                                item.extricator_key,
                                                "natural"
                                              )}
                                            >
                                              <span
                                                style={{
                                                  whiteSpace: "nowrap",
                                                  overflow: "hidden",
                                                  textOverflow: "ellipsis",
                                                  width: "22em",
                                                  fontSize: "12px",
                                                  color: "#fff",
                                                  textTransform: "initial",
                                                  fontWeight: 600,
                                                }}
                                                onClick={() =>
                                                  this.handleAssignLeftClickId(
                                                    item.extricator_key
                                                  )
                                                }
                                              >
                                                {this.setCustomKeyName(
                                                  item.extricator_key,
                                                  "natural"
                                                )}
                                              </span>
                                            </BootstrapTooltip>
                                          )}
                                          <label className="mb-0 mr-2">-</label>
                                          {this.state.valueFocussed ==
                                          item.extricator_key ? (
                                            <BootstrapTooltip
                                              title={this.getLeftKeyValue(
                                                item.extricator_key
                                              )}
                                            >
                                              <span
                                                style={{
                                                  whiteSpace: "nowrap",
                                                  overflow: "hidden",
                                                  textOverflow: "ellipsis",
                                                  width: "22em",
                                                  fontSize: "12px",
                                                  color: "#fff",
                                                  textTransform: "initial",
                                                  fontWeight: 600,
                                                }}
                                                onClick={(event) =>
                                                  this.handleValueClick(
                                                    event,
                                                    item
                                                  )
                                                }
                                              >
                                                {this.getLeftKeyValue(
                                                  item.extricator_key
                                                )}
                                              </span>
                                            </BootstrapTooltip>
                                          ) : (
                                            <BootstrapTooltip
                                              title={this.getLeftKeyValue(
                                                item.extricator_key
                                              )}
                                            >
                                              <span
                                                style={{
                                                  whiteSpace: "nowrap",
                                                  overflow: "hidden",
                                                  textOverflow: "ellipsis",
                                                  width: "22em",
                                                  fontSize: "12px",
                                                  color: "#fff",
                                                  textTransform: "initial",
                                                  fontWeight: 600,
                                                }}
                                                onClick={(event) =>
                                                  this.handleValueClick(
                                                    event,
                                                    item
                                                  )
                                                }
                                              >
                                                {this.getLeftKeyValue(
                                                  item.extricator_key
                                                )}
                                              </span>
                                            </BootstrapTooltip>
                                          )}
                                          {this.state.selectedLeftKey ==
                                            item.extricator_key && (
                                            <img
                                              onClick={() =>
                                                this.handleKeyDelete(item)
                                              }
                                              src={Images.delete_key}
                                              alt="delete_key"
                                              className="img-thumbnail"
                                            ></img>
                                          )}
                                        </>
                                      }
                                    </li>
                                  );
                                }
                              })
                            )
                          ) : (
                            <div
                              className="d-flex justify-content-center align-items-center"
                              style={{ height: "100%" }}
                            >
                              <p className="m-0">Nothing to show</p>
                            </div>
                          )}

                          {this.state.customLeftKeys &&
                            this.state.customLeftKeys.length > 0 &&
                            this.state.customLeftKeys.map((newKeys, index) => {
                              return (
                                <li
                                  id={newKeys.extricator_key}
                                  style={{
                                    height: "42px",
                                    direction: "ltr",
                                    padding: "10px 10px 10px 20px",
                                  }}
                                  onClick={() =>
                                    this.handleCustomLeftKeyClick(
                                      newKeys.extricator_key,
                                      index
                                    )
                                  }
                                  className={
                                    this.state.selectedCustomLeftKey ==
                                    newKeys.extricator_key
                                      ? "d-flex justify-content-between align-items-center pair-selected"
                                      : "d-flex justify-content-between align-items-center"
                                  }
                                >
                                  {!this.isLeftKeyDeleted(
                                    newKeys.extricator_key
                                  ) && (
                                    <>
                                      {this.state.inputFocusCustom &&
                                      this.state.customInputFocused ==
                                        newKeys.extricator_key ? (
                                        <input
                                          type="text"
                                          className="details_input mr-1"
                                          id={newKeys.extricator_key}
                                          name={newKeys.extricator_key}
                                          value={this.state.customLeftKeyText}
                                          style={{ color: "#fff" }}
                                          onFocus={() =>
                                            this.setState({
                                              inputFocusCustom: true,
                                            })
                                          }
                                          onKeyUp={(evt) =>
                                            this.handleEnterPress(evt, "custom")
                                          }
                                          onChange={(evt) =>
                                            this.handleCustomLeftAddKeyEdit(
                                              evt,
                                              index,
                                              newKeys.extricator_key
                                            )
                                          }
                                          onBlur={this.handleBlurInput({
                                            index,
                                            category: "custom",
                                          })}
                                        />
                                      ) : (
                                        <BootstrapTooltip
                                          title={this.setCustomKeyName(
                                            newKeys.extricator_key,
                                            "custom"
                                          )}
                                        >
                                          <span
                                            style={{
                                              whiteSpace: "nowrap",
                                              overflow: "hidden",
                                              textOverflow: "ellipsis",
                                              width: "22em",
                                              fontSize: "12px",
                                              color: "#fff",
                                              textTransform: "initial",
                                              fontWeight: 600,
                                            }}
                                            onClick={() =>
                                              this.handleAssignCustomLeftKey(
                                                newKeys.extricator_key
                                              )
                                            }
                                          >
                                            {this.setCustomKeyName(
                                              newKeys.extricator_key,
                                              "custom"
                                            )}
                                          </span>
                                        </BootstrapTooltip>
                                      )}
                                      <label className="mb-0 mr-2">-</label>
                                      <BootstrapTooltip
                                        title={this.getLeftKeyValue(
                                          newKeys.extricator_key
                                        )}
                                      >
                                        <span
                                          style={{
                                            whiteSpace: "nowrap",
                                            overflow: "hidden",
                                            textOverflow: "ellipsis",
                                            width: "22em",
                                            fontSize: "12px",
                                            color: "#fff",
                                            textTransform: "initial",
                                            fontWeight: 600,
                                          }}
                                        >
                                          {this.getLeftKeyValue(
                                            newKeys.extricator_key
                                          )}
                                        </span>
                                      </BootstrapTooltip>

                                      {this.state.selectedCustomLeftKey ==
                                        newKeys.extricator_key && (
                                        <img
                                          onClick={() =>
                                            this.handleKeyDelete(newKeys)
                                          }
                                          src={Images.delete_key}
                                          alt="delete_key"
                                          className="img-thumbnail"
                                        ></img>
                                      )}
                                    </>
                                  )}
                                </li>
                              );
                            })}
                        </ul>
                      </div>
                      <div className="accordion_head_invoice">
                        <span
                          className="keyValue-span"
                          onClick={() =>
                            this.setState({
                              showLineItems: !this.state.showLineItems,
                            })
                          }
                          style={{ width: "79%" }}
                        >
                          <i
                            className={
                              this.state.showLineItems
                                ? "fa fa-caret-down"
                                : "fa fa-caret-up"
                            }
                          ></i>
                          <span>Line Items</span>
                        </span>
                      </div>
                      <div
                        className={
                          this.state.showLineItems
                            ? "collapse show"
                            : "collapse"
                        }
                        id="LineItemcollapse"
                      >
                        <div className="d-flex justify-content-start flowchart-demo">
                          <ul
                            className="list-group"
                            style={{
                              cursor: "pointer",
                              background: "#03142d",
                              width: "100%",
                            }}
                          >
                            <li
                              className="list-group-item d-flex justify-content-between align-items-center bg-transparent px-3"
                              style={{ cursor: "pointer", width: "100%" }}
                              onClick={() => this.handleShowTable()}
                            >
                              <span>Line Items</span>
                              {}
                            </li>
                          </ul>
                        </div>
                      </div>
                      <div
                        // style={{
                        //   flex: 1,
                        //   position: "absolute",
                        //   bottom: "0px",
                        //   width: "100%",
                        // }}
                      >
                        {this.activeSection === "PORDER_REJECTED" ? (
                          <div className="btn_group d-flex justify-content-center mt-2">
                            <button
                              type="button"
                              className={
                                !this.state.selectedModel
                                  ? "btn confirm_btn_inactive"
                                  : "btn confirm_btn"
                              }
                              style={{
                                backgroundColor: !this.state.selectedModel
                                  ? "gray"
                                  : "#35F8F1",
                                cursor: "pointer",
                              }}
                              disabled={!this.state.selectedModel}
                              onClick={() =>
                                this.activeSection == "PORDER_REJECTED"
                                  ? this.submitRejectedReceipt()
                                  : this.submitReceipt()
                              }
                            >
                              Confirm
                            </button>
                          </div>
                        ) : (
                          <div className="btn_group d-flex justify-content-center mt-2">
                            <button
                              type="button"
                              className="confirm_btn btn"
                              onClick={() =>
                                this.activeSection == "PORDER_REJECTED"
                                  ? this.submitRejectedReceipt()
                                  : this.submitReceipt()
                              }
                            >
                              Confirm
                            </button>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div
                className="col-sm-9 target pull-right"
                id="doc_overflow"
                style={{
                  marginTop: "unset",
                  height: "85vh",
                  position: "relative",
                  backgroundColor: "#031B40",
                }}
              >
                {this.state.previewImg.map((item, index) => (
                  <img
                    className="img-shadow"
                    src={item}
                    style={{
                      filter: "brightness(1) contrast(1)",
                      display: "initial",
                      transform: `scale(${this.state.zoomFactor})`,
                      backgroundColor: "white",
                      left: "45px",
                      position: "absolute",
                      top: 1150 * index,
                      width: "870px",
                      height: "1150px",
                    }}
                  />
                ))}
                {this.state.rightKeys &&
                  this.state.rightKeys.length > 0 &&
                  this.state.rightKeys.map((pageArr, mainIndex) => {
                    return (
                      <div
                        id="invoiceImg"
                        title={mainIndex}
                        className="formwrapper"
                        style={{
                          top: 1150 * mainIndex,
                          left: "45px",
                          transform: `scale(${this.state.zoomFactor})`,
                        }}
                      >
                        {pageArr.map((item, index) => {
                          return (
                            <div
                              id={item.extricator_key}
                              onClick={() =>
                                this.handleRightClick(
                                  item.extricator_key,
                                  item.boundingBoxes
                                )
                              }
                              style={{
                                background: "rgba(26, 115, 232,0.2)",
                                position: "absolute",
                                cursor: "pointer",
                                top: item.boundingBoxes[0].y1 * 100 + "%",
                                left: item.boundingBoxes[0].x1 * 100 + "%",
                                width:
                                  item.boundingBoxes[0].x2 * 100 -
                                  item.boundingBoxes[0].x1 * 100 +
                                  "%",
                                height:
                                  item.boundingBoxes[0].y4 * 100 -
                                  item.boundingBoxes[0].y1 * 100 +
                                  "%",
                              }}
                            ></div>
                          );
                        })}
                        {this.checkArrayNotEmpty(this.state.customRightKeys) &&
                          this.state.customRightKeys[mainIndex].map(
                            (item, index) => {
                              return (
                                <div
                                  id={item.ExtricatorRightkey}
                                  onClick={() =>
                                    this.handleRightClick(
                                      item.ExtricatorRightkey,
                                      item.boundingBoxes
                                    )
                                  }
                                  style={{
                                    background: "rgba(26, 115, 232,0.2)",
                                    position: "absolute",
                                    cursor: "pointer",
                                    top: item.boundingBoxes[0].y1 * 100 + "%",
                                    left: item.boundingBoxes[0].x1 * 100 + "%",
                                    width:
                                      item.boundingBoxes[0].x2 * 100 -
                                      item.boundingBoxes[0].x1 * 100 +
                                      "%",
                                    height:
                                      item.boundingBoxes[0].y4 * 100 -
                                      item.boundingBoxes[0].y1 * 100 +
                                      "%",
                                  }}
                                ></div>
                              );
                            }
                          )}

                        {this.state.tableSortedData.length > 0 &&
                          this.state.tableSortedData[mainIndex].map((page) => {
                            return page.map((item) => {
                              return (
                                <div
                                  id={item.table_data_id}
                                  style={{
                                    background: "rgba(26, 115, 232,0.2)",
                                    position: "absolute",
                                    cursor: "pointer",
                                    top: item.bounding_box[0].y1 * 100 + "%",
                                    left: item.bounding_box[0].x1 * 100 + "%",
                                    width:
                                      item.bounding_box[0].x2 * 100 -
                                      item.bounding_box[0].x1 * 100 +
                                      "%",
                                    height:
                                      item.bounding_box[0].y4 * 100 -
                                      item.bounding_box[0].y1 * 100 +
                                      "%",
                                  }}
                                ></div>
                              );
                            });
                          })}
                      </div>
                    );
                  })}
              </div>
            </div>

            {this.state.lineTable ? (
              <div className="invoice-table">
                <div className="col-12 close_btn_inv mt-3">
                  <i
                    className="fa fa-times"
                    onClick={() => {
                      this.setState({
                        lineTable: false,
                      });
                    }}
                  ></i>
                </div>
                {this.state.tableSortedData.length > 0 &&
                  this.state.tableSortedData.map((page, pageIndex) => {
                    return (
                      <div className="form-group">
                        {page.map((table, tableIndex) => {
                          return (
                            <table className="table line-table">
                              <thead>
                                <tr>
                                  {table[0].key.map((heading, headingIndex) => (
                                     <th 
                                     contentEditable="true"
                                     suppressContentEditableWarning={true}
                                     scope="col"
                                     onBlur={(e) => this.handleHeaderUpdate(pageIndex, tableIndex, headingIndex, e)} 
                                     >{heading}</th>
                                  ))}
                                </tr>
                              </thead>
                              <tbody>
                                {table.map((item, rowIndex) => {
                                  return (
                                    <tr
                                      onClick={() => this.handleFocusRow(item)}
                                    >
                                    {item.value.map((cell, cellIndex) => {
                                        return(
                                          <td
                                          contentEditable="true"
                                          suppressContentEditableWarning={true}
                                          onBlur={(e) => this.handleCellUpdate(pageIndex, tableIndex, rowIndex, cellIndex, e)}
                                          >
                                            {cell}</td>
                                          );                                     
                                      })}
                                    </tr>
                                  );
                                })}
                              </tbody>
                            </table>
                          );
                        })}
                      </div>
                    );
                  })}
              </div>
            ) : null}

            <div
              className="row w-100"
              style={{ height: "10vh", position: "absolute", bottom: "0px" }}
            >
              <div
                className="col-3"
                style={{ backgroundColor: "#04244F" }}
              ></div>
              <div
                className="col-9"
                style={{ backgroundColor: "#031B40" }}
              ></div>
            </div>
          </div>
        )}
      </div>
    );
  }
}

export default POrderDetails;
