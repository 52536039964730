import React, { useState, useEffect } from "react";
import Images from "../../../assets/asset_imports";
import { Link } from "react-router-dom";
import { setActiveSection } from "../../../utils/Helpers";

const TopNav = (props) => {
  const [activeBtn, setActiveBtn] = useState(1);
  /**

useEffect hook that updates the active button when the defaultActiveTab prop changes.
@param {string} props.defaultActiveTab - The default active tab of the component.
*/
  useEffect(() => {
    if (props.defaultActiveTab && activeBtn != props.defaultActiveTab) {
      setActiveBtn(props.defaultActiveTab);
    }
  }, [props.defaultActiveTab]);
  /**

Function that handles clicks on the navigation buttons and updates the active section.
@param {object} event - The event object.
@param {number} val - The value associated with the clicked button.
*/
  const handleClick = async (event, val) => {
    event.preventDefault();
    setActiveBtn(val);
    if (val == 1) {
      await setActiveSection("FORMS_REVIEW");
      props.callback({ val: "FORM", selectedRoute: "/dashboard_forms" });
    } else if (val == 2) {
      await setActiveSection("INVOICE_REVIEW");
      props.callback({ val: "INVOICE", selectedRoute: "/dashboard_invoices" });
    } else if (val == 3) {
      await setActiveSection("RECEIPT_REVIEW");
      props.callback({ val: "RECEIPT", selectedRoute: "/dashboard_receipts" });
    } else if (val == 4) {
      await setActiveSection("UTILITY_REVIEW");
      props.callback({ val: "UTILITY", selectedRoute: "/dashboard_utilities" });
    } else if (val == 5) {
      await setActiveSection("PORDER_REVIEW");
      props.callback({ val: "PORDER", selectedRoute: "/dashboard_porders" });
    } else if (val == 6) {
      await setActiveSection("PASSPORT_REVIEW");
      props.callback({ val: "PASSPORT", selectedRoute: "/dashboard_passports" });
    }
  };

  return (
    <>
    <div className="col-12 p-0">
      <div
        id="doc-group"
        className="d-flex justify-content-start  mt-2"
        style={{ color: "white" }}
      >
        <Link onClick={(event) => handleClick(event, 1)}>
          <div
            id="btn-form"
            name="1"
            className="btn btn-outline-primary section_btn"
            style={{
              backgroundColor: activeBtn == 1 ? "#007bff" : "transparent",
            }}
          >
            <img
              name="1"
              src={Images.form_icon_white}
              style={{ width: 20, height: 20, marginRight: "0px" }}
            />
            <span>Form</span>
          </div>
        </Link>
        <Link
          style={{ marginLeft: "5px" }}
          onClick={(event) => handleClick(event, 2)}
        >
          <div
            type="button"
            id="btn-invoice"
            name="2"
            className="btn btn-outline-primary section_btn"
            style={{
              backgroundColor: activeBtn == 2 ? "#007bff" : "transparent",
            }}
          >
            <img
              name="2"
              src={Images.invoice_icon_white}
              style={{ width: 20, height: 20, marginRight: "0px" }}
            />
            <span>Invoice</span>
          </div>
        </Link>
        <Link
          style={{ marginLeft: "5px" }}
          onClick={(event) => handleClick(event, 3)}
        >
          <div
            type="button"
            id="btn-receipt"
            name="3"
            className="btn btn-outline-primary section_btn"
            style={{
              backgroundColor: activeBtn == 3 ? "#007bff" : "transparent",
            }}
          >
            <img
              name="3"
              src={Images.receipt_icon_white}
              style={{ width: 20, height: 20, marginRight: "0px" }}
            />
            <span>Receipt</span>
          </div>
          
        </Link>
        <Link
          style={{ marginLeft: "5px" }}
          onClick={(event) => handleClick(event, 4)}
        >
          <div
            type="button"
            id="btn-utility"
            name="4"
            className="btn btn-outline-primary section_btn"
            style={{
              backgroundColor: activeBtn == 4 ? "#007bff" : "transparent",
            }}
          >
            <img
              name="4"
              src={Images.receipt_icon_white}
              style={{ width: 20, height: 20, marginRight: "0px" }}
            />
            <span>Utility</span>
          </div>
          
        </Link>
        <Link
          style={{ marginLeft: "5px" }}
          onClick={(event) => handleClick(event, 6)}
        >
          <div
            type="button"
            id="btn-passport"
            name="6"
            className="btn btn-outline-primary section_btn"
            style={{
              backgroundColor: activeBtn == 6 ? "#007bff" : "transparent",
            }}
          >
            <img
              name="6"
              src={Images.receipt_icon_white}
              style={{ width: 20, height: 20, marginRight: "0px" }}
            />
            <span>Passport</span>
          </div>
          
        </Link>
        </div>
        </div>
         <div className="col-12 p-0">
         <div
           id="doc-group"
           className="d-flex justify-content-start  mt-2"
           style={{ color: "white" }}
         >
        <Link
          onClick={(event) => handleClick(event, 5)}
        >
          <div
            type="button"
            id="btn-porder"
            name="5"
            className="btn btn-outline-primary section_btn "
            style={{
              backgroundColor: activeBtn == 5 ? "#007bff" : "transparent",
              width: "100%",
            }}
          >
            <img
              name="5"
              src={Images.receipt_icon_white}
              style={{ width: 20, height: 20, marginRight: "0px" }}
            />
            <span>Purchase Order</span>
          </div>
          
        </Link>          
      </div>
    </div>
    </>
  );
};

export default TopNav;
