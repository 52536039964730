import React from "react";
import "./terms.module.scss";
import Images from "../../assets/asset_imports";
import FooterComponent from "../../components/footer-component";

export default function PrivacyPolicy(props) {
  // Returns the JSX to render the component

  return (
    <div className="col-12 mains_container p-0">
      <nav className="navbar px-5 py-3" style={{ backgroundColor: "#031B40" }}>
        <a className="navbar-brand" href="/">
          <img
            src={Images.app_logo}
            className="img-responsive"
            style={{ width: "75%", height: "40px" }}
            alt=""
          />
        </a>
      </nav>
      <div className="col-12 tnc_container px-5" style={{ height: "unset" }}>
        <br />
        <p>
          <strong>PRIVACY POLICY</strong>
          <br />
          <br /> This policy together with our&nbsp;Terms of Use of our website
          and any other documents we mention in this policy is meant to inform
          you on which personal data about you we collect, use, disclose, share
          or otherwise process when you are using this website, our products and
          our services. Please read this policy carefully to understand our
          views and practices on how we protect your personal data. <br />
          <br />
          <strong>1. About Us:</strong>
          <br />
          <br /> Extricator and our existing or future affiliates (collectively
          “Extricator” or “we”) are the controllers with respect to your data.
          This means that we determine the purpose and way your personal data is
          processed, as specified in this Policy.
          <br />
          <br />
          &nbsp; Extricator and Technologies c.c. “Extricator”, a legal entity
          organized and existing under the laws of South Africa with registered
          office at 90 Rivonia Rd Sandton Johannesburg South Africa is the
          contact entity for any questions regarding how your personal data is
          being processed.
          <br />
          <br />
          <strong>2. What do we understand by Personal Data?</strong>
          <br />
          <br /> Personal data is the information related to a natural person
          and which can be used to identify that person such as name, email
          address, telephone number, social media usernames, location data or
          business titles.
          <br />
          <br /> This means that when you use our products, services and
          website, we will collect certain information about you (“personal
          data”) which can identify you directly (name, email) or indirectly (IP
          address or other online identifier). We also collect anonymous
          information and aggregated data regarding user behavior, navigational
          information, device information, which helps us tailor our services
          and understand usage trends. <br /> Personal data is data about you
          which you provide to us when interacting with Extricator through our
          website and by using our products and services and which helps us know
          who you are.
          <br />
          <br /> &nbsp;{" "}
          <strong>3. What Personal Data is processed and why?</strong>
          <br />
          <br /> In Short: We collect personal information that you provide to
          us such as name, address, contact information, passwords and security
          data, payment information, and social media login data. We collect
          personal information that you voluntarily provide to us when
          [registering at the Sites or Apps,] expressing an interest in
          obtaining information about us or our products and services, when
          participating in activities on the Sites [(such as posting messages in
          our online forums or entering competitions, contests or giveaways)] or
          otherwise contacting us. <br />
          <br /> The personal information that we collect depends on the context
          of your interactions with us and the Sites, the choices you make and
          the products and features you use. The personal information we collect
          can include the following: <br />
          <br /> Name and Contact Data. We collect your first and last name,
          email address, postal address, phone number, and other similar contact
          data.
          <br />
          <br /> Credentials. We collect passwords, password hints, and similar
          security information used for authentication and account access.
          <br />
          <br /> Payment Data. We collect data necessary to process your payment
          if you make purchases, such as your payment instrument number (such as
          a credit card number), and the security code associated with your
          payment instrument. All payment data is stored by our payment
          processor and you should review its privacy policies and contact the
          payment processor directly to respond to your questions. Information
          automatically collected
          <br />
          <br /> In Short: Some information – such as IP address and/or browser
          and device characteristics – is collected automatically when you visit
          our websites. <br />
          <br /> We automatically collect certain information when you visit,
          use or navigate the Sites. This information does not reveal your
          specific identity (like your name or contact information) but may
          include device and usage information, such as your IP address, browser
          and device characteristics, operating system, language preferences,
          referring URLs, device name, country, location, information about how
          and when you use our Site and other technical information. &nbsp;This
          information is primarily needed to maintain the security and operation
          of our Sites, and for our internal analytics and reporting purposes.{" "}
          <br />
          <br /> Like many businesses, we also collect information through
          cookies and similar technologies. <br />
          <br /> Information collected from other Sources
          <br />
          <br /> In Short: We may collect limited data from public databases,
          marketing partners, social media platforms, and other outside sources.{" "}
          <br />
          <br /> We may obtain information about you from other sources, such as
          public databases, joint marketing partners, social media platforms
          (such as Facebook), as well as from other third parties. Examples of
          the information we receive from other sources include: social media
          profile information (your name, gender, birthday, email, current city,
          state and country, user identification numbers for your contacts,
          profile picture URL and any other information that you choose to make
          public); marketing leads and search results and links, including paid
          listings (such as sponsored links). <br />
          <br />
          <strong> 4. What Analytics tools do we use?</strong>
          <br />
          <br /> In order to understand the navigational trends on our website
          we use third-party analytics tools which collect information which
          your browser sends when you visit our web page. Here are tools which
          we use and information about their privacy policies:
          <br />
          <br /> • Hubspot&nbsp;uses the information collected according to
          its&nbsp;Privacy Policy, which also contains indications on how to
          unsubscribe.
          <br /> • Twitter Advertising&nbsp;uses the information collected
          according to its&nbsp;Privacy Policy, which also includes information
          on how to unsubscribe.
          <br /> • DoubleClick Ad Exchange&nbsp;is a Google product. Its policy
          and contact information can be found&nbsp;here. • Google Tag
          Manager&nbsp;is a Google analytics solution. The way Google uses the
          information collected can be found&nbsp;here.
          <br /> • Google Analytics tool&nbsp;is also reflected in
          the&nbsp;Google Privacy Policy, where you can also find information
          about your ads settings.
          <br /> • LinkedIn Analytics&nbsp;use of the information collected can
          be found in its&nbsp;Privacy Policy. • Hotjar&nbsp;uses information
          collected according to its&nbsp;Privacy Policy, where you can also
          find opt-out information.
          <br /> • Demandbase&nbsp;processes and uses information collected s
          specified in its&nbsp;Privacy Policy.
          <br /> • Drift&nbsp;uses information collected in accordance with its
          Privacy Policy available&nbsp;here.
          <br />
          <br /> When you visit our website and if you accept our cookies, we
          collect certain data about your navigation on our website, which helps
          us improve our services and our online presence.
          <br />
          <br /> • Cookies When you are visiting our website, we are using
          cookies, a small software file stored temporarily or placed on the
          hard drive of your device to allow a web server to identify your
          device and the web browser you use, in order to recognize you when you
          are visiting the site again. Cookies may also store preferences or
          other information about you. <br />
          <br />
          <strong>
            5. How do we use personal Data for Marketing purposes?
          </strong>
          <br />
          <br /> For sending you marketing communications
          <br />
          <br /> We may use the personal data you provide to us, as well as the
          personal data we collect about you from your interactions with our
          products and services, and from third party sources, for marketing
          purposes, i.e., to keep you informed about events, new product
          releases and service developments, alerts, updates, terms, special
          offers and associated campaigns and promotions or prices. Before we do
          so, however, we will, in accordance with the applicable privacy laws
          in your country, offer you the opportunity to choose whether or not to
          have your personal data used in this way. We may also contact you
          regarding products or services similar to those you have already
          used/purchased or are in the process of using/purchasing. Our sales
          representatives may also use your phone number or email address to
          contact you directly by phone, in connection with our products and
          services, upcoming events or other promotions, in accordance with the
          laws applicable in your country.
          <br />
          <br /> In all cases, and irrespective of your country, you may at any
          time choose not to receive marketing communications from us by
          clicking on the unsubscribe link included in each e-mail you may
          receive, by indicating so when we call you, by unsubscribing or by
          contacting us directly at&nbsp;
          <a href="mailto: support@neuralwave.ai">
            <span className="c9 c12">support@neuralwave.ai</span>
          </a>
          . We do our best to tailor your website visit, marketing experience
          and our communications to your expressed interests and we conduct our
          marketing as permitted by applicable law. Please note that if you opt
          out from marketing communications, we may still contact you regarding
          your use of our products and services and to respond to your questions
          or requests.
          <br />
          <br /> Personal data used for direct marketing and sales activities
          will be retained for as long as we have an active relationship with
          you. We treat you as an inactive contact if (i) you have made a
          deletion request; and (ii) you have not interacted with us or updated
          your preferences and contact information in the past 12 months.
          <br />
          <br />
          <strong>6. Do we use Automated Decision making and Profiling?</strong>
          <br />
          <br /> As a result of our obligations regarding export control
          regulations, we will deny access to our services or products to
          designated persons or companies
          <br />
          <br /> For our business purposes we build individual profiles in order
          to send you relevant offerings. You may be denied access to our
          services in order to comply with our export control regulations. if
          you or your company are among the ones listed on the relevant
          websites.
          <br />
          <br /> In order to send you information about our products, events,
          news and activities, tailored to your interests we build individual
          profiles from the personal data you provided to us (such as name,
          company, job title, social media), from navigational information,
          information regarding the use of our services and information provided
          by our partners about you. Based on our legitimate interest to promote
          our products and services, we may also use third party platforms such
          as LinkedIn, in accordance with their own terms and conditions of use.
          <br />
          <br /> We do this to maximize your experience with our products and to
          make available to you personalized offers of your liking.
          <br />
          <br /> We also use personal data, such as online behavior data, for
          statistical purposes, to help us improve the way we promote our
          products.
          <br />
          <br /> You can oppose to this anytime by writing at&nbsp;&nbsp;
          <a href="mailto: support@neuralwave.ai">
            <span className="c9 c12">support@neuralwave.ai</span>
          </a>
          <br />
          <br /> We do not use profiling or automated decision making on
          children’s personal data.
          <br />
          <br />
          <strong>7. To Whom we disclose your Personal Data?</strong>
          <br />
          <br /> For the purposes described in this policy we use the services
          of various contractors, mainly digital service providers. Some of them
          were mentioned in the section 3 above. Other sub-processors we use for
          our day to day business operations are:&nbsp;Atlassian,&nbsp;Microsoft
          365,&nbsp;Marketo,&nbsp;Slack. These contractors process specific
          categories of personal data on our behalf to help us organize events,
          manage business information and contracts, store data, analyse and
          organize data. We also share your personal data with Extricator
          affiliates. <br />
          <br /> For you to benefit from quality services and products we engage
          contractors to process data on our behalf. These contractors are bound
          by confidentiality and other contractual requirements to keep your
          data safe and secure. When the law requires, data is also shared with
          public authorities.
          <br />
          <br /> &nbsp; With each of these contractors we have contractual
          agreements which ensure that your personal data is protected and that
          the processing of personal data is protected and used only as
          necessary to perform the contracted services. Some of our contractors
          are also controllers on their own, carrying out processing activities
          while using their services and products, which include online
          advertising technologies, search engine, cloud computing.
          <br />
          <br /> Some of the contractors are third parties who are not intended
          to process the personal data but may have access to it upon fulfilling
          their tasks or interacting with us, such as technical maintenance
          companies, financial entities, or legal auditors.
          <br />
          <br /> We may also provide personal data to third parties in the
          following situations:
          <br />
          <br /> • to public authorities, auditors, or institutions competent to
          exercise inspections on Extricator, which may ask us to provide
          information, based on their legal obligations. Such public authorities
          or institutions may be relevant data protection authorities or
          authorities for consumer protection.
          <br /> • to comply with a legal requirement or to protect the rights
          and assets of Extricator or other entities or people, such as courts
          of law.
          <br /> • to third parties’ acquirers, if Extricator's business would
          be subject to a change of control or other similar procedure or
          acquisition and your personal data would be part of the transaction.
          <br />
          <br />
          <strong>8. Do we transfer your Personal Data Abroad?</strong>
          <br />
          <br /> Extricator is a global company so your data is shared with
          Extricator's affiliates or with Extricator's processors around the
          world. We make sure that your data is kept and transferred safely, by
          respecting European legal requirements for data transfers.
          <br />
          <br /> &nbsp; In the context of completing the purposes presented to
          you in this policy, your personal data may be transferred abroad to
          states in the European Union&nbsp;(“EU”), European Economic
          Area&nbsp;(the “EEA”)&nbsp;or to third countries.
          <br />
          <br /> Any transfer performed by Extricator will respect the legal
          requirements laid down by the European data protection legislation.
          <br />
          <br /> In what concerns data transfers to third countries, we make
          sure that appropriate safeguards are in place to keep your data
          secure.
          <br />
          <br />
          <strong>
            9. Participation in the EU – U.S. Swiss – U.S. Privacy Shield
            Framework
          </strong>
          <br />
          <br /> Extricator is subject to the enforcement and investigatory
          powers of the U.S. Federal Trade Commission (FTC).
          <br />
          <br /> &nbsp; Extricator adheres to the principles stated by the
          Privacy Shield Framework, for personal data processed from EEA, UK and
          Switzerland. Extricator also uses other mechanisms to ensure the
          adequate protection of personal data such as the Standard Contractual
          Clauses approved by the European Commission. (*)
          <br />
          <br /> Extricator may be required to disclose personal data because of
          lawful requests made by public authorities, including to meet national
          security and law enforcement requests. Independent dispute resolution
          body designated to address complaints.
          <br />
          <br /> If Extricator has not managed to handle your complaint
          addressed at{" "}
          <a href="mailto: support@neuralwave.ai">
            <span className="c9 c12">support@neuralwave.ai</span>
          </a>{" "}
          in a satisfactory manner, you can raise a concern to the attention of
          your data protection authorities (“DPAs”) or the Swiss Federal Data
          Protection and Information Commissioner, which will establish a panel
          in order to investigate and resolve complaints raised under the
          Privacy Shield. Extricator will comply with the recommendations
          provided by this panel, by the DPAs or by the Commissioner regarding
          data transferred from the EEA, the UK and Switzerland, as appropriate.
          <br />
          <br /> Binding arbitration
          <br />
          <br /> If you have exhausted all other means to resolve your complaint
          regarding a potential breach of Extricator's obligations under the
          Privacy Shield Framework and if you are located in the EEA, the UK or
          Switzerland, you may invoke binding arbitration, as
          specified&nbsp;here.
          <br />
          <br />
          <strong>Liability</strong>
          <br />
          <br /> Extricator is liable for third parties processing personal data
          on its behalf. However, Extricator reserves the right to prove that
          the non-compliance was not a result of Extricator's actions or was not
          in Extricator's control.
          <br />
          <br /> For any inquiries about this section please reach out to&nbsp;
          <a href="mailto: support@neuralwave.ai">
            <span className="c9 c12">support@neuralwave.ai</span>
          </a>
          .<br />
          <br /> (*)&nbsp;When GDPR became effective, Extricator has signed
          Standard Contractual Clauses with its affiliates and has imposed GDPR
          policies and standards, globally, among its entities. Privacy Shield
          was used by Extricator as an additional transfer mechanism and not as
          the primary transfer mechanism, which is why the recent ruling of the
          European Court of Justice does not affect the protection or transfer
          of personal data of European customers. With its sub-processors,
          Extricator has concluded data processing agreements in accordance with
          GDPR, ensuring that any transfer mechanism used will be in compliance
          with the European legislation.
          <br />
          <br />
          <strong>10. For how long do we store your personal data?</strong>
          <br />
          <br /> Extricator will store your data only for the period of time
          necessary or required by law. If you have entered into a contract with
          Extricator we will store your data for up to 5 years or as long as it
          is required by law, after the termination of the agreement with us.
          <br />
          <br /> &nbsp; We will store your personal data only for the period of
          time necessary in order to achieve the purposes described to you in
          this policy and in accordance with our legal obligations, our
          contractual obligations or the industry practices.
          <br />
          <br /> We will make sure not to keep excessive or irrelevant data,
          such as data which is out of date. We will also constantly review the
          periods of time for which the different categories of data we process
          are retained.
          <br />
          <br /> After the duration of the processing expires and Extricator no
          longer has legal or legitimate reasons to process your personal data,
          the data will be deleted in accordance with its procedures, which may
          involve archiving, anonymizing or destroying it. For using Extricator
          products and services we store your data up to 5 years from the
          termination of our agreement with you, except for the cases when legal
          requirements state otherwise.
          <br />
          <br />
          <strong>11. Do we process personal data from Minor? </strong>
          <br />
          <br /> We sometimes process personal data from Minor in order to
          spread the RPA knowledge and develop educational partnerships. Under
          certain circumstances we process personal data from children attending
          our workshops or educational programs, respecting all applicable
          legislation and taking the relevant consents.
          <br />
          <br />
          <strong>12. How do we keep your Data secure?</strong>
          <br />
          <br /> Extricator cares about the security of your personal data.
          Therefore, Extricator implements an information security framework in
          accordance to industry standards.
          <br />
          <br /> &nbsp; Extricator constantly evaluates and upgrades the
          security measures implemented as to ensure a secure and safe personal
          data processing. Among the measures implemented by Extricator for
          personal data protection are: physical security measures, access
          control, organizational security measures and incident management
          procedures.
          <br />
          <br /> What happens if you do not agree to your personal data being
          processed?
          <br />
          <br /> If you refuse to have your personal data processed, depending
          on your relationship with us, we might not be able to provide you with
          our products or services, send you newsletters, information about our
          events or other offerings, we will not be able to enter into a
          contract with you, or otherwise interact with you as described in this
          policy.
          <br />
          <br />
          <strong>13. What are your rights?</strong>
          <br />
          <br /> When we process your personal data, it is very important to
          know that you have the following rights:
          <br />
          <br /> • The right of access&nbsp;to the personal data we process
          about you: you have the right to obtain a confirmation whether or not
          we process your personal data, and, if so, to have access to the type
          of personal data and to the conditions of processing, by sending us a
          request in this regard.
          <br /> • The right to request the rectification or erasure of personal
          data: you have the right to request, the rectification of inaccurate
          or incomplete personal data which we have about you, or the erasure of
          your personal data in case (i) the data are no longer necessary for
          their original purpose (and no new lawful purpose exists), (ii) we
          initially process your data based on your consent and you withdraw
          your consent and therefore no awful ground exists anymore, (iii) you
          object and we have no overriding grounds for continuing the
          processing, (iv) the data have been processed unlawfully, (v) erasure
          is necessary for compliance with EU law, or (vi) the data were
          collected from children
          <br />
          <br /> You can always contact Extricator at&nbsp;
          <a href="mailto: support@neuralwave.ai">
            <span className="c9 c12">support@neuralwave.ai</span>
          </a>
          &nbsp;to ask for your rights regarding the processing of your personal
          data by Extricator, such as the right to access your personal data, to
          receive a copy, to oppose to marketing communications, to have your
          data rectified or deleted. <br />
          <br /> &nbsp; • The right to request the restriction of processing:
          you have the right to ask for the restriction of processing in cases
          where: (i) you consider that the personal data processed is
          inaccurate, for a period enabling us to verify the accuracy of the
          personal data; (ii) the processing is unlawful, however you don’t want
          us to erase your personal data, but to restrict the use of data; (iii)
          in case we no longer need your personal data for the purposes we
          described in this policy, but you are requiring the data for
          establishing, exercising or defending a legal claim or (iv) you have
          objected to processing pending the verification whether our legitimate
          grounds prevail;
          <br /> • The right to withdraw your consent for processing, when the
          processing is based on your consent, without affecting the lawfulness
          of processing undertaken until that moment.
          <br /> • The right to object to the data processing&nbsp;on grounds
          related to your particular situation, when the processing is based on
          legitimate interest and to object at any moment to the data processing
          for direct marketing purposes, including profiling.
          <br /> • The right not to be subject to a decision based solely on
          automated processing, including profiling, which produces legal
          effects for your similarly affect you in a significant manner.
          <br /> • The right to data portability, meaning the right to receive
          your personal data, which you provided us in a structured, commonly
          used and machine-readable format and the right to transfer those data
          to another controller, if the processing is based on your consent or
          the performance of a contract and is undertaken by using automatic
          means.
          <br /> • The right to file a complaint&nbsp;with the Data Protection
          Authority&nbsp;(ANSPDCP)&nbsp;and&nbsp;the right to address to the
          competent courts of law.
          <br /> • The right to opt-out of sale of personal information, if you
          are a resident of the state of California. However, Extricator does
          not sell your personal data.
          <br />
          <br /> You may exercise your rights at any time.
          <br />
          <br /> For any questions regarding your rights please write to us
          at&nbsp;
          <a href="mailto: support@neuralwave.ai">
            <span className="c9 c12">support@neuralwave.ai</span>
          </a>
          . Also, in case you wish to withdraw your consent given for direct
          marketing purposes you have the possibility to use the “unsubscribe”
          option provided to you in every marketing communication. <br />
          <br />
          <strong> 14. Specific Rights for California Residents.</strong>
          <br />
          <br /> Any California resident has the right to request a company
          doing business in California not to sell any personal information
          about them. This right is referred to as the right to opt-out. Who is
          a “California resident”?
          <br />
          <br /> “The term “resident,” as defined in the law, includes (1) every
          individual who is in the State for other than a temporary or
          transitory purpose, and (2) every individual who is domiciled in the
          State who is outside the State for a temporary or transitory purpose.
          All other individuals are non-residents.” 17014 of Title 18 of the
          California Code of Regulations
          <br />
          <br /> What does the right to opt out of the sale of your personal
          information mean?
          <br />
          <br /> If you are a California resident whose personal information we
          process, you have the right to request us not to sell your personal
          information. However, Extricator does not sell your personal data and
          only shares your personal data as directed by you, based on your
          consent or with its processors which only process personal data on
          behalf of Extricator.
          <br />
          <br /> For more information about your rights please write to us
          at&nbsp;
          <a href="mailto: support@neuralwave.ai">
            <span className="c9 c12">support@neuralwave.ai</span>
          </a>
          , <br />
          <br />
          <strong>15. Changes to the Privacy Policy</strong>
          <br />
          <br /> This Privacy Policy may be changed and updated by us from time
          to time as it could become necessary. We will post the updates, so we
          encourage you to check our website.
          <br />
          <br /> We will notify you of any substantial changes to this Privacy
          Policy and will ensure that the notification is made in a way which
          ensures that you acknowledge them, for example by use of the email
          address that you have provided to us, or any other appropriate means
          that ensure effective communication. If you do not agree with the
          changes, please contact us or deactivate your account.
          <br />
          <br /> You may address any question regarding this document to who may
          be contacted using the following contact details:&nbsp;
          <a href="mailto: support@neuralwave.ai">
            <span className="c9 c12">support@neuralwave.ai</span>
          </a>
        </p>
        <br />
        <br />
      </div>
      <FooterComponent />
    </div>
  );
}
