import React from "react";
import { makeStyles, createMuiTheme } from "@material-ui/core/styles";
import Slide from "@material-ui/core/Slide";
import theme_colors from "../../utils/theme";
import "./terms.module.scss";
import Images from "../../assets/asset_imports";
import FooterComponent from "../../components/footer-component";
// Defines a makeStyles hook to style the TermsConditions component

const useStyles = makeStyles((theme) => ({
  appBar: {
    position: "relative",
    backgroundColor: theme_colors.primary,
  },
  dialog: {
    backgroundColor: "#052a5c 0% 0% no-repeat padding-box !important",
  },
  title: {
    marginLeft: theme.spacing(2),
    flex: 1,
  },
}));
// Defines a custom Material-UI theme for the dialog component

const dialogTheme = createMuiTheme({
  overrides: {
    MuiDialog: {
      paper: {
        overflowY: "hidden",
      },
      container: {
        height: "unset !important",
      },
    },
  },
});
// Defines a custom Slide transition component to use in the dialog component

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function ShippingPolicy(props) {
  // Uses the useStyles hook to get access to the styling classes

  const classes = useStyles();
  // Returns the JSX to render the component

  return (
    <div className="col-12 mains_container p-0">
      <nav className="navbar px-5 py-3" style={{ backgroundColor: "#031B40" }}>
        <a className="navbar-brand" href="/">
          <img
            src={Images.app_logo}
            className="img-responsive"
            style={{ width: "75%", height: "40px" }}
            alt=""
          />
        </a>
      </nav>
      <div className="col-12 tnc_container px-5">
        <br />
        <h1 className="c5" id="h.kw7et8gjzzzq">
          <span className="c7 c0">Shipping/Delivery Policy</span>
        </h1>
        <p className="c5">
          <span className="c0">
            We provide service in the form of SaaS models across the globe as it
            involves no physical products, goods, or entities. The services
            offered are software-based solutions hence there is no element of
            shipping or delivery involved.
          </span>
        </p>
        <p className="c4">
          <span className="c0"></span>
        </p>
        <p className="c5">
          <span className="c0">
            Please feel free to contact us for AI-based SaaS solutions before
            placing your orders to discuss your needs. All the services and
            products are delivered as per the user requirement. We try to ensure
            100% customer satisfaction whilst providing services, if anything
            remains left as per your requirement you can write back to us at
          </span>
          &nbsp;
          <a href="mailto: support@neuralwave.ai">
            <span className="c9">support@neuralwave.ai</span>
          </a>
          <span className="c0">&nbsp;within</span>
          <span className="c0">&nbsp;</span>
          <span className="c3 c0">7</span>
          <span className="c0">&nbsp;working days</span>
          <span className="c0">
            &nbsp;from the date of getting the subscription.
          </span>
        </p>
        <p className="c4">
          <span className="c0"></span>
        </p>
        <p className="c5">
          <span className="c0">
            The cost of availing services will depend on the individual user
            requirements and consumption.
          </span>
          <span className="c0">
            The expense breakup will be mentioned in the invoice for your
            reference (Extricator)./Users can recharge by adding credits to
            their wallet as per the requirement. (AI for you)
          </span>
          <span className="c0">
            &nbsp;If your order is urgent it is strongly recommended that you
            discuss your needs with us before placing an order to avoid
            inconvenience.
          </span>
        </p>
        <p className="c4">
          <span className="c0"></span>
        </p>
        <p className="c5">
          <span className="c0">
            We strive to provide the best in class service and timely delivery
            of our services offered in the form of AI-based software. The
            software download email will contain all details and instructions on
            how to run and use the tool.
          </span>
        </p>
        <p className="c4">
          <span className="c0"></span>
        </p>
      </div>
      <FooterComponent />
    </div>
  );
}
