import React, { useEffect, useState } from "react";
import "./adminLanding.module.scss";
import Images from "../../assets/asset_imports";
import {
  checkOrganizationError,
  fetchAuthToken,
  handleLogout,
} from "../../utils/Helpers";
import axios from "axios";
import {
  CircularProgress,
  Backdrop,
  makeStyles,
  Menu,
  MenuItem,
} from "@material-ui/core";
import LogoutBar from "../../components/LogoutBar";
import RenameOrgDialog from "../../components/RenameOrgDialog";
import SnackbarComponent from "../../components/SnackbarComponent";
import { useHistory } from "react-router-dom";
import { EXTRICATOR_BASE_URL } from "../../utils/constants";

// Define styles for the accordion component using the makeStyles function

const accordionStyles = makeStyles((theme) => ({
  // Style for the backdrop element

  backdrop: {
    zIndex: 10,
    color: "#fff",
  },
}));
// Component for the Admin Landing page

const AdminLanding = (props) => {
  const history = useHistory();
  // Initializing state variables

  const classes = accordionStyles();
  const authToken = fetchAuthToken();
  const [organizations, setOrganizations] = useState([]);
  const [logOutBtn, showLogoutBtn] = useState(false);
  const [loading, setLoading] = React.useState(false);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [renameOrg, setRenameOrg] = React.useState(null);
  const [successMsg, setSuccessMsg] = React.useState("");
  const [snackOpen, setSnackOpen] = React.useState(false);
  const [toastColor, setToastColor] = React.useState("");
  const [dialogShow, setDialogShow] = React.useState(false);

  useEffect(() => {
    // Redirects back to the admin landing page if back button is pressed

    return () => {
      if (history.action === "POP") {
        props.history.push("/admin_landing");
      }
    };
  }, [history]);
  // Fetches the user's organizations on component mount

  useEffect(() => {
    getUserOrganizations();

    if (props.location.state) {
      // Displays error message in a snack bar if present

      setToastColor("error");
      setSuccessMsg(props.location.state.error_code);
      setSnackOpen(true);
    }
  }, []);
  // Shows or hides the logout button

  const showHideLogoutBtn = (flag) => {
    if (flag) {
      showLogoutBtn(!logOutBtn);
    } else {
      if (logOutBtn) {
        showLogoutBtn(false);
      }
    }
  };
  // Handles clicking on an organization in the dropdown

  const handleClick = (org) => (event) => {
    setAnchorEl(event.currentTarget);
    setRenameOrg(org.org_id);
  };
  // Handles closing the dropdown menu

  const handleClose = () => {
    setAnchorEl(null);
  };

  const getUserOrganizations = () => {
    setLoading(true);
    axios({
      method: "GET",
      url: `${EXTRICATOR_BASE_URL}/get_user_organisation/`,
      headers: {
        Authorization: `Bearer ${authToken}`,
      },
    })
      .then(function (response) {
        setLoading(false);
        setOrganizations(response.data.Organizations);
      })
      .catch(function (error) {
        if (error.response) {
          if (error.response.status === 401) {
            handleLogout(props.history);
          }
        }
        setLoading(false);
      });
  };
  // Handles selecting an organization

  // removing the organization feature from UI only 
  const handleSelectOrganization = (org) => {

    setLoading(true);
    let time = null;
    clearTimeout(time);
    var val = localStorage.getItem("auth_token");
    if (val) {
      localStorage.setItem("user_company", org.org_name);
      localStorage.setItem("ogi", org.org_id);
      localStorage.setItem("org_role", org.role);
      localStorage.setItem("org_access", org.display);
    } else {
      sessionStorage.setItem("user_company", org.org_name);
      sessionStorage.setItem("ogi", org.org_id);
      sessionStorage.setItem("org_role", org.role);
      sessionStorage.setItem("org_access", org.display);
    }

    axios({
      method: "GET",
      url: `${EXTRICATOR_BASE_URL}/last_login/?ogi=${org.org_id}`,
      headers: {
        Authorization: `Bearer ${authToken}`,
      },
    })
      .then(function (response) {
        time = setTimeout(() => {
          setLoading(false);
          props.history.push("/dashboard_forms");
        }, 2000);
      })
      .catch(function (error) {
        setLoading(false);
        if (error.response) {
          if (error.response.status === 401) {
            handleLogout(props.history);
          } else if (
            error.response.data.non_field_errors[0].response.data
              .non_field_errors
          ) {
            if (
              error.response.data.non_field_errors[0].includes(
                "Kindly pay the bill"
              )
            ) {
              setToastColor("error");
              setSuccessMsg(error.response.data.non_field_errors[0]);
              setSnackOpen(true);
            } else {
              checkOrganizationError(
                error.response.data.non_field_errors[0],
                props.history
              );
            }
          }
          setToastColor("error");
          setSuccessMsg(error.response.data.non_field_errors[0]);
          setSnackOpen(true);
        }
      });
  };

  const handleRename = (org_id) => {
    // Shows the rename organization dialog and closes the current organization menu

    setDialogShow(true);
    handleClose();
  };

  const handleGetResult = (obj) => {
    // Resets the state for the rename organization dialog, shows the success or error message, and reloads the user organizations

    setRenameOrg(null);
    setDialogShow(false);
    if (obj) {
      setToastColor(obj.color);
      setSuccessMsg(obj.msg);
      setSnackOpen(true);
      getUserOrganizations();
    }
  };

  const handleSnackClose = (event, reason) => {
    // Closes the snackbar and clears the success message

    if (reason === "clickaway") {
      return;
    }
    setSuccessMsg("");
    setSnackOpen(false);
  };
  // Renders the component

  return (
    <div className="adminLanding-div">
      <SnackbarComponent
        isOpen={snackOpen}
        message={successMsg}
        color={toastColor}
        handleClose={handleSnackClose}
      />
      {dialogShow && (
        <RenameOrgDialog
          action="create"
          orgId={renameOrg}
          open={dialogShow}
          handleClose={handleGetResult}
        />
      )}
      <div className="col-12">
        <div className="d-flex justify-content-end">
          <LogoutBar
            isAccounts={true}
            btnShow={logOutBtn}
            callback={showHideLogoutBtn}
          ></LogoutBar>
        </div>
      </div>

      <Backdrop className={classes.backdrop} open={loading}>
        <CircularProgress color="inherit" />
      </Backdrop>

      <div className="center-txt col-sm-11" style={{ margin: "0 auto" }}>
        <div className="header-div">
          <h5 className="padd-mar-0 mb-1">WELCOME TO Neural-Xtractor</h5>
          <div className="d-flex justify-content-center">
            <div className="col-11 text-center">
              At Neural-Xtractor, we provide high-quality and intelligent data
              extraction services to find, capture, extract, and process data
              automatically from unstructured documents. If you want to avail
              automated AI services for your business, let’s get started.
            </div>
          </div>
        </div>

        <div className="container mt-4" style={{ width: "100%" }}>
          <img
            src={Images.app_logo}
            width="30%"
            className="img-responsive"
            alt="extricator_logo"
          />
        </div>

        {organizations.length > 0 ? (
          <div className="col-12 role-selector mt-5">
            <div className="row">
              {organizations.map((org) => {
                return (
                  // fix renaming organization issue with ternary operator
                  <div className="card col-lg-3 mr-3 py-3">
                    {org.display == "Owner" ? (
                      <>
                        {" "}
                        <div className="form-inline justify-content-end">
                          <img
                            src={Images.menu_dots}
                            className="img-responsive"
                            alt="orgn_action"
                            style={{ width: "15px" }}
                            aria-controls="simple-menu"
                            aria-haspopup="true"
                            onClick={handleClick(org)}
                          />
                        </div>
                        <Menu
                          id="simple-menu"
                          anchorEl={anchorEl}
                          keepMounted
                          open={Boolean(anchorEl)}
                          Rename
                          onClose={handleClose}
                        >
                          <MenuItem onClick={() => handleRename(org)}>
                            Rename
                          </MenuItem>
                          {}
                        </Menu>
                      </>
                    ) : (
                      <div style={{ paddingTop: "12px" }}></div>
                    )}
                    <div
                      className="card-body"
                      onClick={() => handleSelectOrganization(org)}
                    >
                      <span className="card-text form-group">
                        {org.org_name}
                      </span>
                      <span className="card-text">{org.display}</span>
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        ) : (
          <div className="no-items">No organizations at the moment</div>
        )}
      </div>
    </div>
  );
};

export default AdminLanding;
