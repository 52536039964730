import React, { useEffect, useState } from "react";
import "./navbar.module.scss";
import "../../assets/css/style.scss";
import Images from "../../assets/asset_imports";
import { Chip, Avatar, makeStyles } from "@material-ui/core";
import theme_colors from "../../utils/theme";
import { withRouter } from "react-router-dom";
import { handleLogout } from "../../utils/Helpers";

const navbarStyles = makeStyles((theme) => ({
  // CSS class for email chips
  emailChip: {
    background: "rgba(255,255,255,0.1)",
    cursor: "pointer",
    "&:hover": {
      backgroundColor: "rgba(255,255,255,0.2)",
      color: "#FFF",
    },
  },
  // CSS class for email logos
  emailLogo: {
    background: theme_colors.secondary,
    color: `${theme_colors.primary} !important`,
  },
}));
// Define the Navbar component
const Navbar = (props) => {
  // Initialize state variables
  const [companyId, setCompanyId] = useState("");
  const [profileMenuShow, setProfileMenuShow] = React.useState(false);
  // Define classes using styles
  const classes = navbarStyles();
  // Function to get the company ID from local or session storage
  const getCompanyId = async () => {
    var company = await localStorage.getItem("company_id");
    if (!company) {
      company = await sessionStorage.getItem("company_id");
    }
    setCompanyId(company);
  };
  // Call getCompanyId on component mount
  useEffect(() => {
    getCompanyId();
  }, []);
  // Function to toggle profile menu visibility

  const profileAction = () => {
    setProfileMenuShow(!profileMenuShow);
  };
  // Render the navbar

  return (
    <>
      <nav className="navbar justify-content-between right-header">
        {props.children}

        <form className="form-inline control-section">
          <div className="icon-badge mx-2">
            <img
              src={Images.matrix_icon}
              style={{ width: "100%" }}
              className="img-responsive"
              alt=""
            />
          </div>
          <Chip
            onClick={() => profileAction()}
            classes={{ root: classes.emailChip }}
            avatar={<Avatar classes={{ root: classes.emailLogo }}>U</Avatar>}
            label={companyId}
          />
        </form>
      </nav>
      {profileMenuShow && (
        <div className="profile-menu show">
          <a className="dropdown-item" href="#">
            My Account
          </a>
          <a
            className="dropdown-item"
            href="#"
            onClick={() => handleLogout(props.history)}
          >
            Sign Out
          </a>
        </div>
      )}
    </>
  );
};

export default withRouter(Navbar);
