// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("../../assets/images/pagination_bg.png");
exports = ___CSS_LOADER_API_IMPORT___(false);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
exports.push([module.id, ".right-vendor-div{display:-webkit-inline-box;align-items:center;justify-content:flex-end;font-family:\"Open Sans\", sans-serif !important;font-size:15px}.right-vendor-div h6{margin:0 0 0 10px}.pagination .wrapper{border:1px solid #faf7f7 !important;border-radius:4px !important;height:25px}.diamond-container{cursor:pointer}.selected-page{background-image:url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");background-repeat:round;width:24px;height:22px;margin-right:2px}.selected-page span{margin:0px 5px;font-size:10px}.page-item{cursor:pointer}.go-btn{font-size:14px;padding:2px 10px;font-weight:700;margin-left:10px}*{margin:0;padding:0}#wrapper{height:35px;font-family:helvetica;overflow:hidden;position:relative;z-index:99;display:flex;align-items:center}.arrow{display:block;width:60px;height:60px;text-align:center;font-weight:bold;cursor:pointer !important;position:absolute;top:0;z-index:101}.arrow:first-of-type{left:0}.arrow:nth-of-type(2){right:0}#nav{overflow:hidden;margin-bottom:unset !important}#nav li{display:inline;line-height:39px;font-size:12px;padding:3px 0px;background-image:url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAUCAYAAACNiR0NAAAABHNCSVQICAgIfAhkiAAAAOJJREFUOE+t1M0NgkAQBeA3hgKwEyKVWIFYgfGCsQU8aAdSgo2glGAJelfGLMmSBfaPRa7MfIHMmyF4PEnBCb541Ud6usrJVZAWfCHCTtQ1jO09p9LWYwXTE5cEbFTAhRpBHSZhG6oFbZgLHYE+mA3tgVMwE9qBIZgObUE1Gq4Ymd7LQZEIbUR4hEJq3yfC8m8gM95VTnH7y6uCswXhOucrucG6OtCtG8ocVA16LzYh6HBrRsGegupWULt6Pqhpn43HwYZOPg5y2jo0+HwNUZEzMDIRjeADKxuTM8f1nl4+Of0BiyGG43GmjtkAAAAASUVORK5CYII=);background-repeat:no-repeat}\n", ""]);
// Exports
module.exports = exports;
