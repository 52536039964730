import React, { useEffect } from "react";
import "./customInput.module.scss";

const CustomNumericInput = (props) => {
  // Destructure props
  let { maxOffset, minOffset, handleFocus, handleBlur, inputId, handleChange } =
    props;
  // Add an event listener to the input field to allow only numeric input
  useEffect(() => {
    document
      .querySelector(".number_input")
      .addEventListener("keypress", function (evt) {
        if (
          (evt.which != 8 && evt.which != 0 && evt.which < 48) ||
          evt.which > 57
        ) {
          evt.preventDefault();
        }
      });
  }, []);

  return (
    <div className="form-inline wrapper pl-2">
      <div className="col-sm-11 text-wrapper pl-2">
        <input
          style={{ height: "auto", color: "#fff" }}
          id={inputId}
          autoComplete="off"
          type="number"
          onFocus={handleFocus}
          onBlur={handleBlur}
          onChange={handleChange}
          min={minOffset}
          max={maxOffset}
          className="number_input"
        />
      </div>
    </div>
  );
};

export default CustomNumericInput;
