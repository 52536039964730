import React, { useEffect, useState } from "react";
import "./settings.module.scss";
import "../../assets/css/style.scss";
import axios from "axios";
import { CircularProgress } from "@material-ui/core";
import { EXTRICATOR_BASE_URL } from "../../utils/constants";
import { handleLogout } from "../../utils/Helpers";
/**

Functional component that displays the payment failure status.
@param {Object} props - The component's props.
*/
const PaymentFailure = (props) => {
  let { token, user } = props.match.params;
  const [loading, setLoading] = useState(true);
  const [payLoad, setPayload] = useState(null);
  /**

Sends a request to the server to retrieve the payment status using the token and user.
Sets the payLoad and loading states based on the server response.
*/
  const getPaymentStatus = () => {
    setLoading(true);
    axios
      .post(
        `${EXTRICATOR_BASE_URL}/payment_status/?txnToken=${token}&user=${user}`
      )
      .then((res) => {
        setPayload(res.data.message[0]);
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
      });
  };

  useEffect(() => {
    getPaymentStatus();
  }, []);
  /**

Returns the border color of the status box based on the payment status.
@returns {String} - The border color of the status box.
*/
  const getIndicationColor = () => {
    if (payLoad) {
      if (payLoad.payment_status == "APPROVED") {
        return "4px solid green";
      } else {
        return "4px solid red";
      }
    } else {
      return "4px solid #fff";
    }
  };

  return (
    <div id="login">
      <div className="container">
        <div
          id="login-row"
          className="row justify-content-center align-items-center"
        >
          <div id="login-column" style={{ border: getIndicationColor() }}>
            {!loading && payLoad ? (
              <div
                id="login-box"
                className="col-md-12"
                style={{
                  flexDirection: "column",
                  justifyContent: "flex-start",
                  padding: "0px",
                }}
              >
                <div
                  className="d-flex"
                  style={{ width: "100%", justifyContent: "center" }}
                >
                  <h4>
                    Payment {payLoad.payment_status}
                    <i
                      className={
                        payLoad.payment_status == "APPROVED"
                          ? "fa fa-check i_success"
                          : "fa fa-exclamation-triangle i_fail"
                      }
                      aria-hidden="true"
                    ></i>
                  </h4>
                </div>
                <div
                  className="d-flex"
                  style={{
                    height: "fit-content",
                    fontSize: "13px",
                    width: "100%",
                  }}
                >
                  <div className="col-5 d-flex justify-content-end">
                    Transaction Token
                  </div>
                  <div className="col-6 txn-id">{payLoad.txnToken}</div>
                </div>
                <div className="col-12 d-flex amount-label">
                  <div className="col-5 d-flex justify-content-end">Amount</div>
                  <div className="col-7 txn-id">{payLoad.amount}</div>
                </div>
                <div className="col-12 back_btn mt-5">
                  {}
                  <button
                    onClick={() => handleLogout(props.history)}
                    type="button"
                    className="btn btn-dark"
                  >
                    Go back to Billing details
                  </button>
                  {}
                </div>
              </div>
            ) : (
              <div
                id="login-box"
                className="col-md-12"
                style={{
                  flexDirection: "column",
                  justifyContent: "center",
                  padding: "0px",
                }}
              >
                <CircularProgress color="inherit" />
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};
export default PaymentFailure;
