// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("../../assets/fonts/NewsideFPItalic.ttf");
var ___CSS_LOADER_URL_IMPORT_1___ = require("../../assets/fonts/AlternateGothicRegular.ttf");
exports = ___CSS_LOADER_API_IMPORT___(false);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_1___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_1___);
// Module
exports.push([module.id, "@font-face{font-family:\"NewSide\";font-style:normal;font-weight:400;src:local(\"NewSide\"),local(\"NewSide\"),url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ") format(\"truetype\")}@font-face{font-family:\"GothicReg\";font-style:normal;font-weight:400;src:local(\"GothicReg\"),local(\"GothicReg\"),url(" + ___CSS_LOADER_URL_REPLACEMENT_1___ + ") format(\"truetype\")}.access-selector{position:absolute;height:120px;right:0px}.access-selector .Dropdown-root{cursor:pointer}#orgnForm{background:rgba(0,0,0,0.8)}.container{padding:2rem 0rem}h4{margin:2rem 0rem}.panel{border-radius:4px;padding:1rem;margin-top:0.2rem;background-color:#f5f5f5;color:#323b40}.panel.panel-blue{background-color:#e0f5ff;color:#00a5fa}.panel.panel-big-height{min-height:150px}.modal-content{background-color:#011b3e}.form-check-label{font-size:12px;margin-top:3px}.form-check input{width:20% !important}.item{border-radius:4px;padding:0.5rem;margin:0.2rem}.item.item-blue{background-color:#b9e5fe;color:#00a5fa}.item.item-green{background-color:#b7e0dc;color:#019888}.item.item-lime{background-color:#c7e8c8;color:#42b045}.item.item-yellow{background-color:#ffeeba;color:#ff9901}.item.item-pink{background-color:#fabad0;color:#ef075f}.item.item-red{background-color:#fec9c6;color:#fd3d08}.item.item-big-width{min-width:380px}.check-combo input{cursor:pointer}.cancel_btn{background:transparent;border:1px solid #35F8F1;padding:3px 33px;color:#35F8F1 !important;text-decoration:none}.cancel_btn :hover{color:#35F8F1 !important}\n", ""]);
// Exports
module.exports = exports;
