import React, { useState } from "react";
import { CircularProgress } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import theme_colors from "../../utils/theme";
import axios from "axios";
import { fetchAuthToken, fetchOrganisationId } from "../../utils/Helpers";
import "./renameOrg.module.scss";
import { EXTRICATOR_BASE_URL } from "../../utils/constants";

// Define styles using makeStyles from Material-UI
const modelStyles = makeStyles((theme) => ({
  label1: {
    color: "#35F8F1 !important", // Set the color of the label
    fontWeight: "600", // Set the font weight of the label
  },
  btnRoot2: {
    border: "1px solid #35F8F1", // Set the border of the button
  },
  label2: {
    color: "#011B3E !important", // Set the color of the label
  },
  circleColor: {
    stroke: "#35F8F1", // Set the stroke color of a circle
  },
  actionRoot: {
    padding: "8px 20px !important", // Set the padding of the root element of an action component
  },
}));

const RenameOrgDialog = (props) => {
  // Destructure handleClose function from props
  let { handleClose } = props;
  // Use makeStyles hook to define CSS styles
  const classes = modelStyles();
  // Get authentication token and organization ID from local storage
  var authToken = fetchAuthToken();
  // Define state variables for new organization name, loading status, and error message
  const [newName, setNewName] = useState("");
  const [loading, setLoading] = useState(false);
  const [errMsg, setErrMsg] = useState("");

  let ogi = fetchOrganisationId();
  // Handle changes to the new organization name input field

  const handleModelInput = (event) => {
    setNewName(event.target.value);
    if (errMsg) {
      setErrMsg("");
    }
  };
  // Handle form submission to rename organization name

  const sendInvite = (event) => {
    event.preventDefault();
    setLoading(true);
    axios({
      method: "post",
      url: `${EXTRICATOR_BASE_URL}/rename_organisation_name/`,
      data: {
        ogi: props.orgId,
        org_name: newName,
      },
      headers: {
        Authorization: `Bearer ${authToken}`,
      },
    })
      .then((response) => {
        setLoading(false);
        handleClose({ msg: response.data.message, color: "success" });
      })
      .catch((error) => {
        setLoading(false);
        if (error.response) {
          handleClose({
            msg: error.response.data.non_field_errors[0],
            color: "error",
          });
        }
      });
  };
  // Render the dialog form for renaming organization name

  return (
    <div
      className="modal fade show"
      id="orgnForm"
      tabindex="-1"
      role="dialog"
      aria-labelledby="exampleModalLabel"
      style={{ display: "block" }}
      onClick={() => handleClose(null)}
    >
      <div className="modal-dialog modal-dialog-centered" role="document">
        <div
          className="modal-content"
          onClick={(event) => event.stopPropagation()}
        >
          <div className="modal-header border-bottom-0 d-flex justify-content-end pb-0">
            <i
              className="fa fa-times"
              aria-hidden="true"
              onClick={() => handleClose(null)}
              style={{ fontSize: "16px", cursor: "pointer" }}
            ></i>
          </div>
          <form onSubmit={sendInvite}>
            <div className="modal-body">
              <div className="form-group">
                <input
                  type="text"
                  className="form-control"
                  id="name1"
                  aria-describedby="nameHelp"
                  style={{ height: "38px", color: "#000" }}
                  autoComplete="off"
                  onChange={handleModelInput}
                  placeholder="New Organization Name"
                />
              </div>
            </div>
            <div className="modal-footer border-top-0 d-flex justify-content-center">
              {loading ? (
                <div className="col-sm-2">
                  <CircularProgress
                    classes={{ circle: classes.circleColor }}
                    size={30}
                    color="secondary"
                  />
                </div>
              ) : (
                <>
                  
                  <a
                    href="javascript:"
                    onClick={() => handleClose(null)}
                    className="btn cancel_btn"
                  >
                    {" "}
                    Cancel{" "}
                  </a>
                  <button
                    type="submit"
                    className="btn"
                    disabled={!newName}
                    style={{
                      backgroundColor: newName ? "#35F8F1" : "gray",
                      color: theme_colors.primary,
                      padding: "3px 33px",
                      fontWeight: 600,
                    }}
                  >
                    Done
                  </button>
                </>
              )}
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default RenameOrgDialog;
