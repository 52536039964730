import React from "react";
import "./terms.module.scss";
import Images from "../../assets/asset_imports";
import FooterComponent from "../../components/footer-component";

export default function RefundPolicy(props) {
  // Returns the JSX to render the component

  return (
    <div className="col-12 mains_container p-0">
      <nav className="navbar px-5 py-3" style={{ backgroundColor: "#031B40" }}>
        <a className="navbar-brand" href="/">
          <img
            src={Images.app_logo}
            className="img-responsive"
            style={{ width: "75%", height: "40px" }}
            alt=""
          />
        </a>
      </nav>
      <div className="col-12 tnc_container px-5">
        <br />
        <p className="c4">
          <span className="c0"></span>
        </p>
        <h1 className="c5" id="h.lcpsb6flyjoc">
          <span className="c7 c2">Refund Policy</span>
        </h1>
        <p className="c5">
          <span className="c0">
            Our services are based on a business model where products are served
            on a network platform and the customer is charged based on their
            subscription or consumption. Given the nature of our services, we do
            not offer a refund or credit on subscription.
          </span>
        </p>
        <p className="c4">
          <span className="c0"></span>
        </p>
        <p className="c5">
          <span className="c0">
            We will assess your refund request based on factors keeping in mind
            the digital nature of our services and products. There is no
            obligation to provide a return or credit under the following
            situations:
          </span>
        </p>
        <p className="c4">
          <span className="c0"></span>
        </p>
        <ul className="c10 lst-kix_f5vw4a6fhxer-0 start">
          <li className="c5 c6 li-bullet-0">
            <span className="c0">
              You have changed your mind about a particular service or product
            </span>
          </li>
          <li className="c5 c6 li-bullet-0">
            <span className="c0">Service was subscribed by mistake</span>
          </li>
          <li className="c5 c6 li-bullet-0">
            <span className="c0">
              Lack of expertise to make the best use of service or product
            </span>
          </li>
        </ul>
        <p className="c4">
          <span className="c0"></span>
        </p>
        <p className="c5">
          <span className="c0">
            In order to file a refund request, you can contact us at
          </span>
          &nbsp;
          <a href="mailto: support@neuralwave.ai">
            <span className="c9">support@neuralwave.ai</span>
          </a>
        </p>
        <p className="c5">
          <span className="c0">
            within 7 days from the date of subscribing to get your refund
            processed. Based on the usage of services the refund amount will be
            processed and disbursed within
          </span>
          <span className="c0 c3">7</span>
          <span className="c0">
            &nbsp;working days. The mode and currency of the refund will be the
            same as the original mode of payment.
          </span>
        </p>
        <p className="c4">
          <span className="c12 c13"></span>
        </p>
      </div>
      <FooterComponent />
    </div>
  );
}
