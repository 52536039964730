import React from "react";
import Dropdown from "react-dropdown";
// Define the SearchOnSelect functional component

const SearchOnSelect = (props) => {
  // Define a state variable for the default selected option

  const [defaultOption, setDefaultOption] = React.useState("File");
  // Define an array of options to display in the dropdown

  const displayList = ["File", "Vendor"];
  // Define an onSelect function that calls the getValue function passed through props

  const _onSelect = (option) => {
    props.getValue(option.value.toLowerCase());
  };
  // Render the component

  return (
    <div className="d-flex search_criteria"  >
      <span className="h6 pt-2 mr-2" >Search on :</span>
      <Dropdown
        options={displayList}
        onChange={_onSelect}
        value={defaultOption}
        placeholder="Select"
        
      />
    </div>
  );
};

export default SearchOnSelect;
