import React, {useEffect} from 'react';
import { checkOrganizationError, fetchAuthToken, fetchOrganisationId } from '../../utils/Helpers';
import axios from 'axios';
import './customdrop.module.scss';
import Dropdown from 'react-dropdown';
import 'react-dropdown/style.css';
import { EXTRICATOR_BASE_URL } from '../../utils/constants';

const CustomDropDown = (props) => {
  // Destructure the props object to get specific properties needed for the component.
  let {
    newModel,
    getSelectedModel,
    modelType,
    screen,
    onBarClick = null,
    listIsOpen = null,
  } = props;
  // useEffect hook that gets called every time the 'newModel' property changes.
  // This hook calls the getModelOptions function.
  useEffect(() => {
    if (newModel !== "") {
      getModelOptions();
    }
  }, [newModel]);

  // useEffect hook that gets called when the component is first mounted.
  // This hook also calls the getModelOptions function.
  useEffect(() => {
    getModelOptions();
  }, []);

  // Declare some state variables using the useState hook to keep track of the default and sort options,
  // the list of models, and whether or not the list is open or visible.
  var anchorElValue = null;
  const [defaultOption, setDefaultOption] = React.useState("Select Model");
  const [modelsList, setModelsList] = React.useState([]);
  const [displayList, setDisplayList] = React.useState([]);
  const [modelListVisible, setModelListVisible] = React.useState(false);
  // Function that makes an API call to get the list of rejected models.
  const getModelOptions = (event) => {
    let authToken = fetchAuthToken();
    let ogi = fetchOrganisationId();
    axios({
      method: "GET",
      url: `${EXTRICATOR_BASE_URL}/get_rejected_model/?model_type=${modelType}&ogi=${ogi}`,
      headers: { Authorization: `Bearer ${authToken}` },
    })
      .then((response) => {
        setModelsList(response.data);
        setDisplayList(response.data.map((item) => item.supplier_name));
        anchorElValue = event.currentTarget;
      })
      .catch(function (error) {
        if (error.response) {
          if (error.response.data.non_field_errors) {
            checkOrganizationError(
              error.response.data.non_field_errors[0],
              props.history
            );
          }
        }
      });
  };
  // useEffect hook that gets called when the modelsList changes.
  // This hook sets the modelListVisible state variable to true.
  useEffect(() => {
    if (modelsList.length > 0) {
      setModelListVisible(true);
    }
  }, [modelsList]);

  // Function that handles the selection of an option in the dropdown list.
  const _onSelect = (option) => {
    var optionIndex = modelsList.findIndex(
      (opt) => opt.supplier_name == option.value
    );
    getSelectedModel(modelsList[optionIndex]);
  };
  // Function that handles the selection of a model option in the dropdown list.

  const handleOptionSelect = (item) => {
    setDefaultOption(item.supplier_name);
    getSelectedModel(item);
  };

  return (
    <>
      {screen === "docDetails" ? (
        <div className="dropdown" style={{ position: "relative" }}>
          <button
            className="btn btn-secondary dropdown-toggle"
            onClick={onBarClick}
            type="button"
            id="dropdownMenuButton"
            data-toggle="dropdown"
            aria-haspopup="true"
            aria-expanded="false"
          >
            {defaultOption}
          </button>
          {listIsOpen && (
            <div
              className="dropdown-menu details-list show"
              aria-labelledby="dropdownMenuButton"
            >
              {modelsList.map((item) => (
                <a
                  className="dropdown-item"
                  href="#"
                  onClick={() => handleOptionSelect(item)}
                >
                  {item.supplier_name}
                </a>
              ))}
            </div>
          )}
        </div>
      ) : (
        <Dropdown
          options={displayList}
          onChange={_onSelect}
          value={defaultOption}
          placeholder="Select Model"
        />
      )}
    </>
  );
};

export default CustomDropDown;
