import React, { useEffect } from "react";
import "./showcase.module.scss";
import axios from "axios";
import { fetchAuthToken, fetchOrganisationId } from "../../utils/Helpers";
import { EXTRICATOR_BASE_URL } from "../../utils/constants";

// This component displays an image preview of a file using its ID
// fileId: The ID of the file to preview
// handleClose: Function to close the dialog that displays the preview
// fileName: The name of the file to display in the dialog title
const ImageShowcase = ({ fileId, handleClose, fileName }) => {
  // Fetch the organisation ID and authentication token from storage

  const ogi = fetchOrganisationId();
  var authToken = fetchAuthToken();
  // State for loading spinner and image preview

  const [isLoading, setLoading] = React.useState(false);
  const [preview, setPreview] = React.useState("");
  // Fetches the image preview from the server using the file ID and organisation ID

  const fetchFileImage = () => {
    setLoading(true);
    const reqData = {
      file_id: fileId,
      ogi: ogi,
    };
    axios({
      method: "post",
      url: `${EXTRICATOR_BASE_URL}/doc_preview/`,
      data: reqData,
      headers: { Authorization: `Bearer ${authToken}` },
    })
      .then((res) => {
        // Extract the base64 encoded image string from the response

        setLoading(false);
        const img = res.data.encoded_string;
        var tempArr = img.split(",");
        let previewImg = tempArr[0];
        // Set the preview image state to the extracted string

        setPreview(previewImg);
      })
      .catch((error) => {
        setLoading(false);
      });
  };
  // Fetch the image preview when the authentication token changes

  useEffect(() => {
    fetchFileImage();
  }, [authToken]);
  // Return JSX for displaying the image preview and file name in a dialog

  return (
    <div id="myNav" className="overlay">
      {isLoading ? (
        <div className="invoice-spinner container">
          <div className="overlay-content">
            <i className="fa fa-spinner fa-pulse fa-3x fa-fw"></i>
          </div>
        </div>
      ) : (
        <>
          <div
            className="text-center"
            style={{
              wordBreak: "break-word",
              padding: "0 75px",
              fontSize: "12px",
              marginTop: "20px",
            }}
          >
            {fileName}
          </div>
          <div
            className="d-flex justify-content-center"
            style={{ marginTop: "10px" }}
          >
            <img
              src={preview}
              className="img-fluid invoice-img"
              alt="Responsive image"
            ></img>
          </div>
        </>
      )}
    </div>
  );
};

export default ImageShowcase;
