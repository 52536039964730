const Images = {
  data_center: require("./images/data_center.jpg"),
  data_flow: require("./images/cognitive-data.svg"),
  sample_invoice: require("../assets/images/newInvo.png"),
  app_logo: require("../assets/images/Neural-Xtractor_White.png"),
  app_logo_inv: require("../assets/images/extricator_logo_inv.png"),
  matrix_icon: require("../assets/images/dots-menu.png"),
  test_invoice: require("../assets/images/invoice.gif"),

  checked_active: require("../assets/images/checked_active.png"),
  rejected: require("../assets/images/Subtraction_32.png"),
  checked: require("../assets/images/checked.png"),
  glasses_active: require("../assets/images/glasses_active.png"),
  glasses: require("../assets/images/glasses.png"),
  export_active: require("../assets/images/export_active.png"),
  export: require("../assets/images/export.png"),
  time_active: require("../assets/images/time_active.png"),
  time: require("../assets/images/time.png"),
  trash_active: require("../assets/images/delete_active.png"),
  trash: require("../assets/images/delete.png"),
  search_icon: require("../assets/images/loupe.png"),
  calendar: require("../assets/images/Icon awesome-calendar-alt.png"),
  folder_icon: require("../assets/images/folder.png"),
  arrow_back: require("../assets/images/back.png"),
  users_icon: require("../assets/images/users.png"),
  focus_icon: require("../assets/images/focus.png"),
  file_icon: require("../assets/images/file.png"),
  rejected_active: require("../assets/images/exclusion_selected.svg"),
  input_glass: require("../assets/images/glass_input.png"),
  input_email: require("../assets/images/email_input.png"),
  input_phone: require("../assets/images/phone-call.png"),
  input_company: require("../assets/images/trade_input.png"),
  input_user: require("../assets/images/user_input.png"),
  input_lock: require("../assets/images/lock_input.png"),
  gear_icon: require("../assets/images/gear.png"),
  chart_icon: require("../assets/images/flowchart.png"),
  info_icon: require("../assets/images/info.png"),
  menu_icon: require("../assets/images/menu_icon.png"),
  tree_icon: require("../assets/images/tree.png"),
  brain_bg: require("../assets/images/Group_330.jpg"),
  api_icon: require("../assets/images/api.png"),
  erp_icon: require("../assets/images/erp.png"),
  email_icon: require("../assets/images/email.png"),
  robot_icon: require("../assets/images/robotics.png"),
  upload_icon: require("../assets/images/upload.png"),
  dms_icon: require("../assets/images/dms.png"),
  app_icon: require("../assets/images/app.png"),
  scan_icon: require("../assets/images/qr-code-scan.png"),
  photo_icon: require("../assets/images/photos.png"),
  data_source_icon: require("../assets/images/data-storage-stack-variant.png"),

  person: require("../assets/images/user-icon.png"),
  userLogo: require("../assets/images/userLogo.svg"),
  userLogo_active: require("../assets/images/userLogo_active.svg"),

  bill: require("../assets/images/bill.svg"),
  bill_active: require("../assets/images/bill_active.svg"),
  file_download: require("../assets/images/file_download.png"),
  curveLine: require("../assets/images/curveLine.svg"),
  curveLine_active: require("../assets/images/curveLine_active.svg"),

  about: require("../assets/images/about.svg"),
  about_active: require("../assets/images/about_active.svg"),
  gear: require("../assets/images/gear.png"),
  dashboard_delete: require("../assets/images/Icon material-delete.png"),
  dashboard_to_review: require("../assets/images/Group 216.png"),
  dashboard_to_review_active: require("../assets/images/Group 2167.png"),
  dashboard_checked: require("../assets/images/Icon awesome-check.png"),
  dashboard_delete_active: require("../assets/images/delete_active_dashboard.png"),
  delete_key: require("../assets/images/delete_key.png"),
  dashboard_parsed_checked: require("../assets/images/whiteChecked.png"),
  dashboard_download_file: require("../assets/images/Icon awesome-file-download.png"),
  dashboard_download_button: require("../assets/images/Icon metro-download.png"),
  dashboard_upload: require("../assets/images/uploadbtn.png"),
  dashboard_search: require("../assets/images/glass_input.png"),
  back: require("../assets/images/Icon ionic-ios-arrow-roundBack.png"),
  exported: require("../assets/images/Icon import.png"),
  exported_active: require("../assets/images/Icon import_active.png"),
  left_chevron: require("../assets/images/left-arrow.png"),
  right_chevron: require("../assets/images/chevron_right.png"),
  upload: require("../assets/images/Icon metro-upload.png"),
  accuracy_icon: require("../assets/images/accuracy.png"),
  efficiency_icon: require("../assets/images/efficiency.png"),
  quick_process_icon: require("../assets/images/quick_process.png"),
  automation_icon: require("../assets/images/automation.png"),
  implementation_icon: require("../assets/images/implement.png"),
  secure_icon: require("../assets/images/secure.png"),
  settings: require("../assets/images/Icon ionic-ios-settings.png"),
  aisol_icon: require("../assets/images/ai_soln.png"),
  structure_icon: require("../assets/images/structure.png"),
  extract_process_icon: require("../assets/images/extract_process.png"),
  close: require("../assets/images/Icon ionic-ios-close.png"),
  diamond_bg: require("../assets/images/pagination_bg.png"),
  page_not_found: require("../assets/images/page-not-found.png"),
  form_icon: require("../assets/images/contact-form.png"),
  invoice_icon: require("../assets/images/invoice.png"),
  receipt_icon: require("../assets/images/receipt.png"),
  form_icon_white: require("../assets/images/form_white.svg"),
  invoice_icon_white: require("../assets/images/invoice_white.svg"),
  receipt_icon_white: require("../assets/images/receipt_white.svg"),
  interface_icon: require("../assets/images/interface.png"),
  youtube_thumb: require("../assets/images/yt_thumb.png"),
  processing_active: require("../assets/images/exchange.png"),
  processing_inactive: require("../assets/images/exchange_inactive.png"),
  organization: require("../assets/images/orgn.png"),
  menu_dots: require("../assets/images/menu_dot.png"),
  caution_logo: require("../assets/images/caution-sign.png"),
};

export default Images;
