import React, { useState, useEffect } from "react";
import "./adminReport.module.scss";
import "../../assets/css/style.scss";
import {
  Box,
  Paper,
  makeStyles,
  withStyles,
  TableHead,
  Collapse,
  Switch,
  Table,
  TableBody,
  TableCell,
  TableRow,
  TableContainer,
} from "@material-ui/core";
import axios from "axios";
import { fetchAuthToken } from "../../utils/Helpers";
import PropTypes from "prop-types";
import { adminMonthsSet, EXTRICATOR_BASE_URL } from "../../utils/constants";

// function to format date

const formatDate = (data) => {
  return new Date(data).toString().split(" GMT")[0];
};
// custom switch component

const IOSSwitch = withStyles((theme) => ({
  root: {
    width: 42,
    height: 26,
    padding: 0,
    margin: theme.spacing(1),
  },
  switchBase: {
    padding: 1,
    "&$checked": {
      transform: "translateX(16px)",
      color: theme.palette.common.white,
      "& + $track": {
        backgroundColor: "#52d869",
        opacity: 1,
        border: "none",
      },
    },
    "&$focusVisible $thumb": {
      color: "#52d869",
      border: "6px solid #fff",
    },
  },
  thumb: {
    width: 24,
    height: 24,
  },
  track: {
    borderRadius: 26 / 2,
    border: `1px solid ${theme.palette.grey[400]}`,
    backgroundColor: theme.palette.grey[50],
    opacity: 1,
    transition: theme.transitions.create(["background-color", "border"]),
  },
  checked: {},
  focusVisible: {},
}))(({ classes, ...props }) => {
  // return Switch component with customized styling

  return (
    <Switch
      focusVisibleClassName={classes.focusVisible}
      disableRipple
      classes={{
        root: classes.root,
        switchBase: classes.switchBase,
        thumb: classes.thumb,
        track: classes.track,
        checked: classes.checked,
      }}
      {...props}
    />
  );
});
// custom styles for table rows and cells

const useRowStyles = makeStyles({
  root: {
    "& > *": {
      borderBottom: "unset",
    },
    backgroundColor: "#04234F",
    border: "1px solid #103261",
  },
  cellRoot: {
    color: "#fff",
    fontWeight: "800",
    padding: "0 16px 0px 20px",
  },
  cellRootLink: {
    color: "#fff",
    fontWeight: "800",
    width: "100px",
  },
  cellRootSwitch: {
    color: "#fff",
    fontWeight: "100",
    fontSize: "12px",
    padding: "0px",
  },
  labelRoot: {
    maxWidth: "unset !important",
    width: "unset !important",
    margin: "0px",
  },
  rowChild: {
    backgroundColor: "rgb(19, 86, 177)",
  },
  table: {
    minWidth: 650,
  },
  childTableHeadRoot: {
    backgroundColor: "#0E4B9C",
  },
  tableBody: {
    backgroundColor: "#0E3C7A",
  },
  fileTableRoot: {
    color: "#fff",
  },
  fileTableRootHead: {
    color: "#fff",
    fontWeight: "800",
    fontSize: "12px",
  },
});
function Row(props) {
  const { row, daySet, yearSet, handleProgress, tokenAuth } = props;
  const [open, setOpen] = React.useState(false);
  const [defaultOption, setDefaultOption] = React.useState("DD");
  const [days, setDays] = React.useState([1]);
  const [years, setYears] = React.useState([2020]);
  const [state, setState] = React.useState({
    checkedA: true,
    checkedB: true,
    checkedC: true,
  });
  const [toMonths, setToMonths] = React.useState(adminMonthsSet);
  const [fromMonths, setFromMonths] = React.useState(adminMonthsSet);
  const [from, setFrom] = React.useState({
    day: null,
    month: null,
    year: null,
  });

  const [to, setTo] = React.useState({
    day: null,
    month: null,
    year: null,
  });

  const classes = useRowStyles();
  // initialize state on component mount

  useEffect(() => {
    // create date set on mount

    createDateSet();
  }, []);
  // function to create date set

  const createDateSet = () => {
    // initialize days array with all days of the month

    let days = [];
    const date = new Date();

    for (let i = 1; i < 32; i++) {
      if (i < 10) days.push(`0${i}`);
      else days.push(`${i}`);
    }
    // set days state to array of days

    setDays(days);
    // call setYearset function

    setYearset();
  };
  // function to set year set

  const setYearset = async () => {
    var date = new Date();
    // get register year from local storage

    var registerYear = await localStorage.getItem("register_year");
    if (!registerYear) {
      // if register year not in local storage, get from session storage

      registerYear = await sessionStorage.getItem("register_year");
    }
    var yearSet = [];
    // initialize year set with range from register year to current year

    for (let i = registerYear - 1; i < date.getFullYear(); i++) {
      yearSet.push(`${i + 1}`);
    }
    // set years state to array of years

    setYears(yearSet);
  };
  // initialize state with empty array

  const [rows, rowsData] = useState([]);
  // function to get role billing details

  const getRoleBillingDetails = async (user_id, from = null, to = null) => {
    // show progress indicator

    handleProgress(true);
    axios({
      method: "GET",
      url: `${EXTRICATOR_BASE_URL}/role_user_billing_details/?user_id=${user_id}&from=${from}&to=${to}`,
      headers: {
        Authorization: `Bearer ${tokenAuth}`,
      },
    })
      .then(function (response) {
        // hide progress indicator on success

        handleProgress(false);
        setOpen(!open);
        rowsData(response.data.billing_data);
      })
      .catch(function (error) {
        // hide progress indicator on error

        handleProgress(false);
      });
  };
  // function to handle download

  const handleDownload = (id) => {
    handleProgress(true);
    axios({
      method: "GET",
      url: `${EXTRICATOR_BASE_URL}/user_bill_download/?id=${id}`,
      headers: {
        Authorization: `Bearer ${tokenAuth}`,
      },
    })
      .then(function (response) {
        handleProgress(false);
        setOpen(false);
        window.open(response.data.link);
        props.handleProgress(false);
      })
      .catch(function (error) {
        handleProgress(false);
        setOpen(false);
      });
  };

  return (
    <React.Fragment>
      <TableRow className={classes.root}>
        <TableCell
          className={classes.cellRoot}
          component="th"
          scope="row"
          onClick={() => getRoleBillingDetails(row.user_id)}
          style={{ cursor: "pointer" }}
        >
          <i
            className={open ? "fa fa-caret-up mr-2" : "fa fa-caret-down mr-2"}
            aria-hidden="true"
          ></i>
          {row.user_name}
        </TableCell>
        <TableCell className={classes.cellRoot} align="left">
          {row.form}
        </TableCell>
        <TableCell className={classes.cellRoot} align="left">
          {row.invoice}
        </TableCell>
        <TableCell className={classes.cellRoot} align="left">
          {row.receipt}
        </TableCell>
        <TableCell className={classes.cellRoot} colSpan={4}>
          {row.total}
        </TableCell>
      </TableRow>
      <TableRow style={{ height: "0px", backgroundColor: "#1356B1" }}>
        <TableCell style={{ border: "0px", padding: "0px" }} colSpan={8}>
          <Collapse
            in={open}
            classes={{ wrapperInner: classes.rowChild }}
            timeout="auto"
            unmountOnExit
          >
            <Box margin={1} style={{ margin: "0px" }}>
              <div
                id="row_child"
                className="d-flex justify-content-end px-3"
                style={{ backgroundColor: "#1356B1" }}
              >
                {}
                <span>Total amount spent : {row.total}</span>
              </div>
              <div style={{ backgroundColor: "#0E4B9C" }}>
                <TableContainer component={Paper}>
                  <Table
                    className={classes.table}
                    size="small"
                    aria-label="a dense table"
                  >
                    <TableHead className={classes.childTableHeadRoot}>
                      <TableRow>
                        <TableCell className={classes.fileTableRootHead}>
                          Vendor Details{" "}
                        </TableCell>
                        <TableCell
                          className={classes.fileTableRootHead}
                          align="left"
                        >
                          Billing Date
                        </TableCell>
                        <TableCell
                          className={classes.fileTableRootHead}
                          align="left"
                        >
                          Billing Amount
                        </TableCell>
                        <TableCell
                          className={classes.fileTableRootHead}
                          align="left"
                        >
                          Number Of Documents
                        </TableCell>
                        <TableCell
                          className={classes.fileTableRootHead}
                          align="left"
                        >
                          Billing Status
                        </TableCell>
                        <TableCell
                          className={classes.fileTableRootHead}
                          align="left"
                        >
                          View Bill
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody className={classes.tableBody}>
                      {rows.map((row) => (
                        <TableRow key={row.name}>
                          <TableCell
                            className={classes.fileTableRoot}
                            component="th"
                            scope="row"
                          >
                            {row.description}
                          </TableCell>
                          <TableCell
                            className={classes.fileTableRoot}
                            align="left"
                          >
                            {formatDate(row.from)}
                          </TableCell>
                          <TableCell
                            className={classes.fileTableRoot}
                            align="left"
                          >
                            {row.amount}
                          </TableCell>
                          <TableCell
                            className={classes.fileTableRoot}
                            align="left"
                          >
                            {row.no_of_invoice}
                          </TableCell>
                          <TableCell
                            className={classes.fileTableRoot}
                            align="left"
                          >
                            {row.bill_status}
                          </TableCell>
                          <TableCell
                            className={classes.fileTableRoot}
                            align="left"
                            onClick={() => handleDownload(row.id)}
                            style={{ cursor: "pointer" }}
                          >
                            <i className="fa fa-eye" aria-hidden="true"></i>
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              </div>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </React.Fragment>
  );
}
/**

Row component propTypes.
@param {Object} row - The row data object.
@param {number} row.invoice - The invoice number.
@param {number} row.form - The form number.
@param {number} row.receipt - The receipt number.
@param {Array<Object>} row.history - The history array containing objects with amount, customerId, and date properties.
@param {string} row.name - The name of the row.
@param {number} row.price - The price of the row.
@param {number} row.billing - The billing amount of the row.
*/
Row.propTypes = {
  row: PropTypes.shape({
    invoice: PropTypes.number.isRequired,
    form: PropTypes.number.isRequired,
    receipt: PropTypes.number.isRequired,
    history: PropTypes.arrayOf(
      PropTypes.shape({
        amount: PropTypes.number.isRequired,
        customerId: PropTypes.string.isRequired,
        date: PropTypes.string.isRequired,
      })
    ).isRequired,
    name: PropTypes.string.isRequired,
    price: PropTypes.number.isRequired,
    billing: PropTypes.number.isRequired,
  }).isRequired,
};
/**

Styles for the table.
*/
const tableStyles = makeStyles((theme) => ({
  tcRoot: {
    borderRadius: "0px",
    overflow: "hidden",
  },
  thRoot: {
    backgroundColor: "#01132B",
  },
  tcHead: {
    color: "#fff",
    fontSize: "14px",
    fontWeight: "800",
    width: "180px",
    paddingLeft: "0px",
  },
  tCellHead: {
    padding: "0 16px 0px 20px",
  },
  tcHeadMin: {
    color: "#fff",
    fontSize: "14px",
    fontWeight: "800",
    width: "70px",
  },
  tcHeadAction: {
    color: "#fff",
    fontSize: "14px",
    fontWeight: "800",
    width: "70px",
  },
  circleColor: {
    stroke: "#35F8F1",
  },
  actionRoot: {
    padding: "8px 20px !important",
  },
}));

const RoleManagement = (props) => {
  const { loaderCallback } = props;
  const date = new Date();
  const classes = tableStyles();
  const [rows, setrowsData] = useState([]);
  let authToken = fetchAuthToken();
  // useEffect hook to fetch data on component mount

  useEffect(() => {
    getroleManagementData();
  }, []);
  // function to fetch role management data

  const getroleManagementData = async (from = null, to = null) => {
    // show loader

    loaderCallback(true);
    // make GET request to fetch role management data

    axios({
      method: "GET",
      url: `${EXTRICATOR_BASE_URL}/role_management/?from=${from}&to=${to}`,
      headers: {
        Authorization: `Bearer ${authToken}`,
      },
    })
      .then(function (response) {
        // set role management data to state

        loaderCallback(false);
        setrowsData(response.data.role_details);
      })
      .catch(function (error) {
        // handle error
        // hide loader

        loaderCallback(false);
      });
  };

  return (
    <div id="role_management">
      <TableContainer component={Paper} classes={{ root: classes.tcRoot }}>
        <Table aria-label="collapsible table">
          <TableHead classes={{ root: classes.thRoot }}>
            <TableRow>
              <TableCell
                classes={{ head: classes.tcHead, root: classes.tCellHead }}
              >
                Org Name
              </TableCell>
              <TableCell
                classes={{ head: classes.tcHeadMin, root: classes.tCellHead }}
              >
                Form
              </TableCell>
              <TableCell
                classes={{ head: classes.tcHeadMin, root: classes.tCellHead }}
              >
                Invoice
              </TableCell>
              <TableCell
                classes={{ head: classes.tcHeadMin, root: classes.tCellHead }}
              >
                Receipt
              </TableCell>
              <TableCell
                classes={{
                  head: classes.tcHeadAction,
                  root: classes.tCellHead,
                }}
              >
                Billing
              </TableCell>
              <TableCell
                classes={{
                  head: classes.tcHeadAction,
                  root: classes.tCellHead,
                }}
              ></TableCell>
              <TableCell
                classes={{
                  head: classes.tcHeadAction,
                  root: classes.tCellHead,
                }}
              ></TableCell>
              <TableCell
                classes={{
                  head: classes.tcHeadAction,
                  root: classes.tCellHead,
                }}
              ></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {rows.length > 0
              ? rows.map((row) => (
                  <Row
                    key={row.name}
                    row={row}
                    handleProgress={loaderCallback}
                    tokenAuth={authToken}
                  />
                ))
              : null}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
};
export default RoleManagement;
