import React, { useEffect, useState } from "react";
import {
  fetchAuthToken,
  handleLogout,
  fetchOrganisationId,
  fetchOrgRole,
} from "../utils/Helpers";
import { Link } from "react-router-dom";
import { withRouter } from "react-router";
import axios from "axios";
import { EXTRICATOR_BASE_URL } from "../utils/constants";

const PayContainer = (props) => {
  // Fetch the role of the organization.
  const orgRole = fetchOrgRole();
  // Define the 'amount' constant and the 'setAmount' hook.
  const [amount, setAmount] = useState("");
  const [total, setTotal] = useState("");
  const today = new Date().toISOString().slice(0, 10);
  const [date, setDate] = useState(today);
  const [counter, setCounter] = useState(null);
  const [loading, setLoading] = useState(false);

  const handleDateChange = (event) => {
      setDate(event.target.value);
  };

  const handleSubmit = async () => {
      setLoading(true);
      // Make API call to get counter based on date
      // For instance: const response = await fetch(`/api/counter?date=${date}`);
      // setCounter(response.data.counter);
      let authToken = fetchAuthToken();
      let ogi = fetchOrganisationId();
      axios({
        method: "GET",
        url: `${EXTRICATOR_BASE_URL}/get-plan-documents-counter_per_day/?file_type=${props.file_type}&date=${date}`,
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: `Bearer ${authToken}`,
        },
      })
        .then(function (response) {
          console.log("responseeeeeeeeeeeeee", response)
          setCounter(response.data.count);
          // setTotal(response.data.total)
  
        })
        .catch(function (error) {
          if (error.response) {
            if (error.response.status === 401) {
              handleLogout(props.history);
            }
          }
        });
      setLoading(false);
  };

  // Call 'getAmount' when the component is mounted.
  useEffect(() => {
    getAmount();
  }, []);
  // Retrieve the total amount and set it as the value of the 'amount' constant.
  const getAmount = () => {
    let authToken = fetchAuthToken();
    let ogi = fetchOrganisationId();
    axios({
      method: "GET",
      url: `${EXTRICATOR_BASE_URL}/get-plan-documents-counter/?company_id=${ogi}&plan_name=${props.planName}&file_type=${props.file_type}`,
      headers: {
        "Content-Type": "multipart/form-data",
        Authorization: `Bearer ${authToken}`,
      },
    })
      .then(function (response) {
        console.log("responseeeeeeeeeeeeee", response)
        setAmount(response.data.documents_counter);
        setTotal(response.data.total)

      })
      .catch(function (error) {
        if (error.response) {
          if (error.response.status === 401) {
            handleLogout(props.history);
          }
        }
      });
  };

  return (
    <>
    <div className="date-counter-container" style={{textAlign:"center"}}>
      <h6>Date-based Documents Counter</h6>
      <input type="date"  value={date} style={{color: "black"}} onChange={handleDateChange} />
      <button class="btn btn-dark" style={{backgroundColor:"#01132b"}}onClick={handleSubmit}>Get Counter</button>
      {loading ? <p>Loading...</p> : <p>Counter: {counter}</p>}
    </div>   
    <div className="mb-5">
      <p
        style={{
          marginBottom: "2%",
          textAlign: "center",
          color: "#fff",
          opacity: 1,
          letterSpacing: 0,
          font: "normal normal bold 10px/22px Open Sans",
        }}
      >
      {total?(
        props.planName == "forms"?
       (<h6> Forms Uploaded </h6>): 
       props.planName == "passports"?
       (<h6> Passports uploaded</h6>): (<h6> Documents uploaded </h6>)
      ): null}

      </p>

      <p style={{ marginBottom: "5%", textAlign: "center", fontSize: 20 }}>
      {total?(
          <>
          {amount} / {total}  
          </>
        ): (""
        )}
      </p>
      <p
        style={{
          marginBottom: "5%",
          textAlign: "center",
          color: "#fff",
          opacity: 1,
          letterSpacing: 0,
          font: "normal normal bold 10px/22px Open Sans",
        }}
      ></p>
     {/* {orgRole == "Admin" ? (
        <Link to={{ pathname: `/admin_report`, section: 1 }}>
          <button type="button" className="payBtn">
            Pay Now
          </button>
        </Link>
      ) : null} */}
    </div>
    </>
  );
};

export default withRouter(PayContainer);
