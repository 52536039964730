import React from "react";
import Images from "../../assets/asset_imports";
import "./mobileDevice.module.scss";

// Component that checks if the device's screen width is less than or equal to 768 pixels
// and renders a modal with a warning message if it is.
export default function CheckMobileDevice() {
  // Get the device's screen width

  let screenWidth = window.screen.width;
  return (
    <>
      {screenWidth <= 768 ? (
        <div
          className="modal fade show d-flex justify-content-center align-items-center"
          id="resolutionModal"
          style={{ background: "rgba(0,0,0,0.8)", zIndex: 1320 }}
          tabindex="-1"
          role="dialog"
          aria-labelledby="resolutionModalLabel"
          aria-hidden="true"
        >
          <div className="modal-dialog" role="document">
            <div className="modal-content" style={{ height: "80%" }}>
              <div className="modal-header"></div>
              <div className="col-12 warning-text">
                <div className="d-flex justify-content-center">
                  <img
                    src={Images.caution_logo}
                    className="img-responsive mb-3"
                    style={{ width: "140px", color: "#fff" }}
                    alt=""
                  />
                </div>
                This site is compatible with desktop only
              </div>
              <div
                className="modal-footer"
                style={{ justifyContent: "center" }}
              ></div>
            </div>
          </div>
        </div>
      ) : null}
    </>
  );
}
