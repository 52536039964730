import React from "react";
import Images from "../../assets/asset_imports";
// This is the DocNotFoundDialog component, which is a reusable component for displaying a "Document not found" message with an image

const DocNotFoundDialog = (props) => (
  <div className="main-notfound" style={{ overflow: "hidden" }}>
    <div className="col-12 text-center" style={{ marginTop: "100px" }}>
      <img
        src={Images.page_not_found}
        width="100px"
        height="100px"
        className="img-fluid"
        alt="Responsive image"
      />
      <h2 className="mt-3">Sorry. Document not found !</h2>
    </div>
  </div>
);

export default DocNotFoundDialog;
