import React from "react";
import { makeStyles, createMuiTheme } from "@material-ui/core/styles";
import Slide from "@material-ui/core/Slide";
import theme_colors from "../../utils/theme";
import "./terms.module.scss";
import Images from "../../assets/asset_imports";
import FooterComponent from "../../components/footer-component";
// Defines a makeStyles hook to style the TermsConditions component

const useStyles = makeStyles((theme) => ({
  appBar: {
    position: "relative",
    backgroundColor: theme_colors.primary,
  },
  dialog: {
    backgroundColor: "#052a5c 0% 0% no-repeat padding-box !important",
  },
  title: {
    marginLeft: theme.spacing(2),
    flex: 1,
  },
}));
// Defines a custom Material-UI theme for the dialog component

const dialogTheme = createMuiTheme({
  overrides: {
    MuiDialog: {
      paper: {
        overflowY: "hidden",
      },
      container: {
        height: "unset !important",
      },
    },
  },
});
// Defines a custom Slide transition component to use in the dialog component

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});
// Defines the TermsConditions component, which renders a dialog with terms and conditions

export default function TermsConditions(props) {
  // Uses the useStyles hook to get access to the styling classes

  const classes = useStyles();
  // Returns the JSX to render the component

  return (
    <div className="col-12 mains_container p-0">
      <nav className="navbar px-5 py-3" style={{ backgroundColor: "#031B40" }}>
        <a className="navbar-brand" href="/">
          <img
            src={Images.app_logo}
            className="img-responsive"
            style={{ width: "75%", height: "40px" }}
            alt=""
          />
        </a>
      </nav>
      <div className="col-12 tnc_container px-5" style={{ height: "unset" }}>
        <br />
        <strong>Terms &amp; Conditions</strong>
        <br />
        <p className="font-weight-bold">Updated at 2021-03-24</p>
        <br />

        <strong> General Terms</strong>
        <br />

        <p className="terms-content">
          By accessing and placing an order with Extricator, you confirm that
          you are in agreement with and bound by the terms of service contained
          in the Terms &amp; Conditions outlined below. These terms apply to the
          entire website and any email or other type of communication between
          you and Extricator.
        </p>

        <p>
          Under no circumstances shall Extricator team be liable for any direct,
          indirect, special, incidental, or consequential damages, including,
          but not limited to, loss of data or profit, arising out of the use, or
          the inability to use, the materials on this site, even if Extricator
          team or an authorized representative has been advised of the
          possibility of such damages. If your use of materials from this site
          results in the need for servicing, repair or correction of equipment
          or data, you assume any costs thereof.
        </p>

        <p>
          Extricator will not be responsible for any outcome that may occur
          during the course of usage of our resources. We reserve the rights to
          change prices and revise the resources usage policy in any moment.
        </p>

        <br />
        <strong> License</strong>
        <br />

        <p className="terms-content">
          Extricator grants you a revocable, non-exclusive, non-transferable,
          limited license to download, install and use the website strictly in
          accordance with the terms of this Agreement.
        </p>

        <p>
          These Terms &amp; Conditions are a contract between you and Extricator
          (referred to in these Terms &amp; Conditions as
          &quot;Extricator&quot;, &quot;us&quot;, &quot;we&quot; or
          &quot;our&quot;), the provider of the Extricator website and the
          services accessible from the Extricator website (which are
          collectively referred to in these Terms &amp; Conditions as the
          &quot;Extricator Service&quot;).
        </p>

        <p>
          You are agreeing to be bound by these Terms &amp; Conditions. If you
          do not agree to these Terms &amp; Conditions, please do not use the
          Extricator Service. In these Terms &amp; Conditions, &quot;you&quot;
          refers both to you as an individual and to the entity you represent.
          If you violate any of these Terms &amp; Conditions, we reserve the
          right to cancel your account or block access to your account without
          notice.
        </p>

        <br />

        <strong> Meanings</strong>

        <br />

        <p className="font-weight-bold terms-content">
          For these Terms &amp; Conditions:
        </p>

        <p>
          {" "}
          -Cookie: A small amount of data generated by a website and saved by
          your web browser. It is used to identify your browser, provide
          analytics, remember information about you such as your language
          preference or login information.
        </p>

        <p>
          {" "}
          -Company: when this policy mentions Company, it refers to Innovsol
          systems and Technologies, (90 RIVONIA ROAD, NORTH WING SECOND FLOOR,
          THE BUSINESS EXCHANGE, SANDTON, JOHANNESBURG, GAUTENG) that is
          responsible for your information under this Terms &amp; Conditions.
        </p>

        <p>
          {" "}
          -Country: where Extricator or the owners/founders of Extricator are
          based, in this case is South Africa
        </p>

        <p>
          {" "}
          -Device: Any internet connected device such as a phone, tablet,
          computer, or any other device that can be used to visit Extricator and
          use the services.
        </p>

        <p>
          {" "}
          -Service: refers to the service provided by Extricator as described in
          the relative terms (if available) and on this platform.
        </p>

        <p>
          {" "}
          -Third-party service: Refers to advertisers, contest sponsors,
          promotional and marketing partners, and others who provide our content
          or whose products or services we think may interest you.
        </p>

        <p>
          {" "}
          -Website: Extricator website, which can be accessed via this URL:
          https://extricator.ai
        </p>

        <p>
          {" "}
          -You: a person or entity that is registered with Extricator to use the
          Services.
        </p>

        <br />

        <p className="font-weight-bold"> Restrictions</p>

        <p className="terms-content">
          You agree not to, and you will not permit others to:
        </p>

        <p>
          {" "}
          -License, sell, rent, lease, assign, distribute, transmit, host,
          outsource, disclose, or otherwise commercially exploit the website or
          make the platform available to any third party.
        </p>

        <p>
          {" "}
          -Modify, make derivative works of, disassemble, decrypt, reverse
          compile, or reverse engineer any part of the website.
        </p>

        <p>
          {" "}
          -Remove, alter, or obscure any proprietary notice (including any
          notice of copyright or trademark) of Extricator or its affiliates,
          partners, suppliers or the licensors of the website.{" "}
        </p>

        <p>Return and Refund Policy</p>

        <p>
          Thanks for shopping at Extricator. We appreciate the fact that you
          like to buy the stuff we build. We also want to make sure you have a
          rewarding experience while you are exploring, evaluating, and
          purchasing our products.
        </p>

        <p>
          As with any shopping experience, there are terms and conditions that
          apply to transactions at Extricator. We will be as brief as our
          attorneys will allow. The main thing to remember is that by placing an
          order or making a purchase at Extricator, you agree to the terms along
          with Extricator&rsquo;s Privacy Policy.
        </p>

        <p>
          If, for any reason, you are not completely satisfied with any good or
          service that we provide, do not hesitate to contact us and we will
          discuss any of the issues you are going through with our product.
        </p>

        <br />

        <strong> Your Suggestions</strong>
        <br />

        <p className="terms-content">
          Any feedback, comments, ideas, improvements, or suggestions
          (collectively, &quot;Suggestions&quot;) provided by you to Extricator
          with respect to the website shall remain the sole and exclusive
          property of Extricator.
        </p>

        <p>
          Extricator shall be free to use, copy, modify, publish, or
          redistribute the Suggestions for any purpose and in any way without
          any credit or any compensation to you.
        </p>

        <br />

        <strong> Your Consent</strong>
        <br />

        <p className="terms-content">
          We have updated our Terms &amp; Conditions to provide you with
          complete transparency into what is being set when you visit our site
          and how it's being used. By using our website, registering an account,
          or making a purchase, you hereby consent to our Terms &amp;
          Conditions.
        </p>

        <br />

        <strong> Links to Other Websites</strong>
        <br />

        <p className="terms-content">
          This Terms &amp; Conditions applies only to the Extricator Services.
          The Services may contain links to other websites not operated or
          controlled by Extricator. We are not responsible for the content,
          accuracy or opinions expressed in such websites, and such websites are
          not investigated, monitored, or checked for accuracy or completeness
          by us. Please remember that when you use a link to go from the
          Services to another website, our Terms &amp; Conditions are no longer
          in effect. Your browsing and interaction on any other website,
          including those that have a link on our platform, is subject to that
          website&rsquo;s own rules and policies. Such third parties may use
          their own cookies or other methods to collect information about you.
        </p>

        <br />

        <strong> Cookies</strong>
        <br />

        <p className="terms-content">
          Extricator uses &quot;Cookies&quot; to identify the areas of our
          website that you have visited. A Cookie is a small piece of data
          stored on your computer or mobile device by your web browser. We use
          Cookies to enhance the performance and functionality of our website
          but are non-essential to their use. However, without these cookies,
          certain functionality like videos may become unavailable or you would
          be required to enter your login details every time you visit the
          website as we would not be able to remember that you had logged in
          previously. Most web browsers can be set to disable the use of
          Cookies. However, if you disable Cookies, you may not be able to
          access functionality on our website correctly or at all. We never
          place Personally Identifiable Information in Cookies.
        </p>

        <br />

        <strong>Changes to Our Terms &amp; Conditions</strong>
        <br />

        <p className="terms-content">
          You acknowledge and agree that Extricator may stop (permanently or
          temporarily) providing the Service (or any features within the
          Service) to you or to users generally at Extricator&rsquo;s sole
          discretion, without prior notice to you. You may stop using the
          Service at any time. You do not need to specifically inform Extricator
          when you stop using the Service. You acknowledge and agree that if
          Extricator disables access to your account, you may be prevented from
          accessing the Service, your account details or any files or other
          materials which is contained in your account.
        </p>

        <p>
          If we decide to change our Terms &amp; Conditions, we will post those
          changes on this page, and/or update the Terms &amp; Conditions
          modification date below.
        </p>

        <br />

        <strong> Modifications to Our website</strong>
        <br />

        <p className="terms-content">
          Extricator reserves the right to modify, suspend or discontinue,
          temporarily or permanently, the website or any service to which it
          connects, with or without notice and without liability to you.
        </p>

        <br />

        <strong> Updates to Our website</strong>
        <br />

        <p className="terms-content">
          Extricator may from time to time provide enhancements or improvements
          to the features/ functionality of the website, which may include
          patches, bug fixes, updates, upgrades, and other modifications
          (&quot;Updates&quot;).
        </p>

        <p>
          Updates may modify or delete certain features and/or functionalities
          of the website. You agree that Extricator has no obligation to (i)
          provide any Updates, or (ii) continue to provide or enable any
          features and/or functionalities of the website to you.
        </p>

        <p>
          You further agree that all Updates will be (i) deemed to constitute an
          integral part of the website, and (ii) subject to the terms and
          conditions of this Agreement.
        </p>

        <br />

        <strong> Third-Party Services</strong>
        <br />

        <p className="terms-content">
          We may display, include, or make available third-party content
          (including data, information, applications, and other products
          services) or provide links to third-party websites or services
          (&quot;Third- Party Services&quot;).
        </p>

        <p>
          You acknowledge and agree that Extricator shall not be responsible for
          any Third-Party Services, including their accuracy, completeness,
          timeliness, validity, copyright compliance, legality, decency,
          quality, or any other aspect thereof. Extricator does not assume and
          shall not have any liability or responsibility to you or any other
          person or entity for any Third-Party Services.
        </p>

        <p>
          Third-Party Services and links thereto are provided solely as a
          convenience to you, and you access and use them entirely at your own
          risk and subject to such third parties' terms and conditions.
        </p>

        <br />

        <strong> Term and Termination</strong>
        <br />

        <p className="terms-content">
          This Agreement shall remain in effect until terminated by you or
          Extricator.
        </p>

        <p>
          Extricator may, in its sole discretion, at any time and for any or no
          reason, suspend or terminate this Agreement with or without prior
          notice.
        </p>

        <p>
          This Agreement will terminate immediately, without prior notice from
          Extricator, if you fail to comply with any provision of this
          Agreement. You may also terminate this Agreement by deleting the
          website and all copies thereof from your computer.
        </p>

        <p>
          Upon termination of this Agreement, you shall cease all use of the
          website and delete all copies of the website from your computer.
          Termination of this Agreement will not limit any of Extricator's
          rights or remedies at law or in equity in case of breach by you
          (during the term of this Agreement) of any of your obligations under
          the present Agreement.
        </p>

        <br />

        <strong> Copyright Infringement Notice</strong>
        <br />

        <p className="terms-content">
          If you are a copyright owner or such owner&rsquo;s agent and believe
          any material on our website constitutes an infringement on your
          copyright, please contact us setting forth the following information:
          (a) a physical or electronic signature of the copyright owner or a
          person authorized to act on his behalf; (b) identification of the
          material that is claimed to be infringing; (c) your contact
          information, including your address, telephone number, and an email;
          (d) a statement by you that you have a good faith belief that use of
          the material is not authorized by the copyright owners; and (e) the a
          statement that the information in the notification is accurate, and,
          under penalty of perjury you are authorized to act on behalf of the
          owner.
        </p>

        <br />

        <strong> Indemnification</strong>
        <br />

        <p className="terms-content">
          You agree to indemnify and hold Extricator and its parents,
          subsidiaries, affiliates, officers, employees, agents, partners, and
          licensors (if any) harmless from any claim or demand, including
          reasonable attorneys' fees, due to or arising out of your: (a) use of
          the website; (b) violation of this Agreement or any law or regulation;
          or (c) violation of any right of a third party.
        </p>

        <br />

        <strong> No Warranties</strong>
        <br />

        <p className="terms-content">
          The website is provided to you &quot;AS IS&quot; and &quot;AS
          AVAILABLE&quot; and with all faults and defects without warranty of
          any kind. To the maximum extent permitted under applicable law,
          Extricator, on its own behalf and on behalf of its affiliates and its
          and their respective licensors and service providers, expressly
          disclaims all warranties, whether express, implied, statutory or
          otherwise, with respect to the website, including all implied
          warranties of merchantability, fitness for a particular purpose, title
          and non-infringement, and warranties that may arise out of course of
          dealing, course of performance, usage or trade practice.
        </p>

        <p>
          Without limitation to the foregoing, Extricator provides no warranty
          or undertaking, and makes no representation of any kind that the
          website will meet your requirements, achieve any intended results, be
          compatible or work with any other software systems or services,
          operate without interruption, meet any performance or reliability
          standards or be error free or that any errors or defects can or will
          be corrected.
        </p>

        <p>
          Without limiting the foregoing, neither Extricator nor any
          Extricator's provider makes any representation or warranty of any
          kind, express or implied: (i) as to the operation or availability of
          the website, or the information, content, and materials or products
          included thereon; (ii) that the website will be uninterrupted or
          error-free; (iii) as to the accuracy, reliability, or currency of any
          information or content provided through the website; or (iv) that the
          website, its servers, the content, or e-mails sent from or on behalf
          of Extricator are free of viruses, scripts, trojan horses, worms,
          malware, timebombs or other harmful components.
        </p>

        <p>
          Some jurisdictions do not allow the exclusion of or limitations on
          implied warranties or the limitations on the applicable statutory
          rights of a consumer, so some or all the above exclusions and
          limitations may not apply to you.
        </p>

        <br />

        <strong> Limitation of Liability</strong>
        <br />

        <p className="terms-content">
          Notwithstanding any damages that you might incur, the entire liability
          of Extricator and any of its suppliers under any provision of this
          Agreement and your exclusive remedy for all the foregoing shall be
          limited to the amount actually paid by you for the website.
        </p>

        <p>
          To the maximum extent permitted by applicable law, in no event shall
          Extricator or its suppliers be liable for any special, incidental,
          indirect, or consequential damages whatsoever (including, but not
          limited to, damages for loss of profits, for loss of data or other
          information, for business interruption, for personal injury, for loss
          of privacy arising out of or in any way related to the use of or
          inability to use the website, third-party software and/or third-party
          hardware used with the website, or otherwise in connection with any
          provision of this Agreement), even if Extricator or any supplier has
          been advised of the possibility of such damages and even if the remedy
          fails of its essential purpose.
        </p>

        <p>
          Some states/jurisdictions do not allow the exclusion or limitation of
          incidental or consequential damages, so the above limitation or
          exclusion may not apply to you.
        </p>

        <br />

        <strong> Severability</strong>
        <br />

        <p className="terms-content">
          If any provision of this Agreement is held to be unenforceable or
          invalid, such provision will be changed and interpreted to accomplish
          the objectives of such provision to the greatest extent possible under
          applicable law and the remaining provisions will continue in full
          force and effect.
        </p>

        <p>
          This Agreement, together with the Privacy Policy and any other legal
          notices published by Extricator on the Services, shall constitute the
          entire agreement between you and Extricator concerning the Services.
          If any provision of this Agreement is deemed invalid by a court of
          competent jurisdiction, the invalidity of such provision shall not
          affect the validity of the remaining provisions of this Agreement,
          which shall remain in full force and effect. No waiver of any term of
          this Agreement shall be deemed a further or continuing waiver of such
          term or any other term, and Extricator&rsquo;s failure to assert any
          right or provision under this Agreement shall not constitute a waiver
          of such right or provision. YOU AND Extricator AGREE THAT ANY CAUSE OF
          ACTION ARISING OUT OF OR RELATED TO THE SERVICES MUST COMMENCE WITHIN
          ONE (1) YEAR AFTER THE CAUSE OF ACTION ACCRUES. OTHERWISE, SUCH CAUSE
          OF ACTION IS PERMANENTLY BARRED.
        </p>

        <br />

        <strong> Waiver</strong>
        <br />

        <p className="terms-content">
          Except as provided herein, the failure to exercise a right or to
          require performance of an obligation under this Agreement shall not
          affect a party's ability to exercise such right or require such
          performance at any time thereafter nor shall be the waiver of a breach
          constitute waiver of any subsequent breach.
        </p>

        <p>
          No failure to exercise, and no delay in exercising, on the part of
          either party, any right or any power under this Agreement shall
          operate as a waiver of that right or power. Nor shall any single or
          partial exercise of any right or power under this Agreement preclude
          further exercise of that or any other right granted herein. In the
          event of a conflict between this Agreement and any applicable purchase
          or other terms, the terms of this Agreement shall govern.
        </p>

        <br />

        <strong> Amendments to this Agreement</strong>
        <br />

        <p className="terms-content">
          Extricator reserves the right, at its sole discretion, to modify or
          replace this Agreement at any time. If a revision is material, we will
          provide at least 30 days' notice prior to any new terms taking effect.
          What constitutes a material change will be determined at our sole
          discretion. By continuing to access or use our website after any
          revisions become effective, you agree to be bound by the revised
          terms. If you do not agree to the new terms, you are no longer
          authorized to use Extricator.
        </p>

        <br />

        <strong> Entire Agreement</strong>
        <br />

        <p className="terms-content">
          The Agreement constitutes the entire agreement between you and
          Extricator regarding your use of the website and supersedes all prior
          and contemporaneous written or oral agreements between you and
          Extricator. You may be subject to additional terms and conditions that
          apply when you use or purchase other Extricator services, which
          Extricator will provide to you at the time of such use or purchase.
        </p>

        <br />

        <strong> Updates to Our Terms</strong>
        <br />

        <p className="terms-content">
          We may change our Service and policies, and we may need to make
          changes to these Terms so that they accurately reflect our Service and
          policies. Unless otherwise required by law, we will notify you (for
          example, through our Service) before we make changes to these Terms
          and give you an opportunity to review them before they go into effect.
          Then, if you continue to use the Service, you will be bound by the
          updated Terms. If you do not want to agree to these or any updated
          Terms, you can delete your account.
        </p>

        <br />

        <strong> Intellectual Property</strong>
        <br />

        <p className="terms-content">
          The website and its entire contents, features, and functionality
          (including but not limited to all information, software, text,
          displays, images, video and audio, and the design, selection and
          arrangement thereof), are owned by Extricator, its licensors or other
          providers of such material and are protected by South Africa and
          international copyright, trademark, patent, trade secret and other
          intellectual property or proprietary rights laws. The material may not
          be copied, modified, reproduced, downloaded, or distributed in any
          way, in whole or in part, without the express prior written permission
          of Extricator, unless and except as is expressly provided in these
          Terms &amp; Conditions. Any unauthorized use of the material is
          prohibited.
        </p>

        <br />

        <strong> Agreement to Arbitrate</strong>
        <br />

        <p className="terms-content">
          This section applies to any dispute EXCEPT IT DOESN&rsquo;T INCLUDE A
          DISPUTE RELATING TO CLAIMS FOR INJUNCTIVE OR EQUITABLE RELIEF
          REGARDING THE ENFORCEMENT OR VALIDITY OF YOUR OR Extricator&rsquo;s
          INTELLECTUAL PROPERTY RIGHTS. The term dispute means any dispute,
          action, or other controversy between you and Extricator concerning the
          Services or this agreement, whether in contract, warranty, tort,
          statute, regulation, ordinance, or any other legal or equitable basis.
          Dispute will be given the broadest possible meaning allowable under
          law.
        </p>

        <br />

        <strong> Notice of Dispute</strong>
        <br />

        <p className="terms-content">
          In the event of a dispute, you or Extricator must give the other a
          Notice of Dispute, which is a written statement that sets forth the
          name, address, and contact information of the party giving it, the
          facts giving rise to the dispute, and the relief requested. You must
          send any Notice of Dispute via email to:{" "}
          <a href="mailto: support@neuralwave.ai">
            <span className="c9">support@neuralwave.ai</span>
          </a>
          . Extricator will send any Notice of Dispute to you by mail to your
          address if we have it, or otherwise to your email address. You and
          Extricator will attempt to resolve any dispute through informal
          negotiation within sixty (60) days from the date the Notice of Dispute
          is sent. After sixty (60) days, you or Extricator may commence
          arbitration.
        </p>

        <br />

        <strong> Binding Arbitration</strong>
        <br />

        <p className="terms-content">
          If you and Extricator don&rsquo;t resolve any dispute by informal
          negotiation, any other effort to resolve the dispute will be conducted
          exclusively by binding arbitration as described in this section. You
          are giving up the right to litigate (or participate in as a party or
          class member) all disputes in court before a judge or jury. The
          dispute shall be settled by binding arbitration in accordance with the
          commercial arbitration rules of the American Arbitration Association.
          Either party may seek any interim or preliminary injunctive relief
          from any court of competent jurisdiction, as necessary to protect the
          party&rsquo;s rights or property pending the completion of
          arbitration. All legal, accounting, and other costs, fees, and
          expenses incurred by the prevailing party shall be borne by the
          non-prevailing party.
        </p>

        <br />

        <strong> Submissions and Privacy</strong>
        <br />

        <p className="terms-content">
          In the event that you submit or post any ideas, creative suggestions,
          designs, photographs, information, advertisements, data or proposals,
          including ideas for new or improved products, services, features,
          technologies or promotions, you expressly agree that such submissions
          will automatically be treated as non-confidential and non-proprietary
          and will become the sole property of Extricator without any
          compensation or credit to you whatsoever. Extricator and its
          affiliates shall have no obligations with respect to such submissions
          or posts and may use the ideas contained in such submissions or posts
          for any purposes in any medium in perpetuity, including, but not
          limited to, developing, manufacturing, and marketing products and
          services using such ideas.
        </p>

        <br />

        <strong>Promotions</strong>
        <br />

        <p className="terms-content">
          Extricator may, from time to time, include contests, promotions,
          sweepstakes, or other activities (Promotions) that require you to
          submit material or information concerning yourself. Please note that
          all Promotions may be governed by separate rules that may contain
          certain eligibility requirements, such as restrictions as to age and
          geographic location. You are responsible to read all Promotions rules
          to determine w your eligibility to participate. If you enter any
          Promotion, you agree to abide by and to comply with all Promotions
          Rules.
        </p>

        <p>
          Additional terms and conditions may apply to purchases of goods or
          services on or through the Services, which terms and conditions are
          made a part of this Agreement by this reference.
        </p>

        <br />

        <strong> Typographical Errors</strong>
        <br />

        <p className="terms-content">
          In the event a product and/or service is listed at an incorrect price
          or with incorrect information due to typographical error, we shall
          have the right to refuse or cancel any orders placed for the product
          and/or service listed at the incorrect price. We shall have the right
          to refuse or cancel any such order whether or not the order has been
          confirmed and your credit card charged. If your credit card has
          already been charged for the purchase and your order is cancelled, we
          shall immediately issue a credit to your credit card account or other
          payment account in the amount of the charge.
        </p>

        <br />

        <strong> Miscellaneous</strong>
        <br />

        <p className="terms-content">
          If for any reason a court of competent jurisdiction finds any
          provision or portion of these Terms &amp; Conditions to be
          unenforceable, the remainder of these Terms &amp; Conditions will
          continue in full force and effect. Any waiver of any provision of
          these Terms &amp; Conditions will be effective only if in writing and
          signed by an authorized representative of Extricator. Extricator will
          be entitled to injunctive or other equitable relief (without the
          obligations of posting any bond or surety) in the event of any breach
          or anticipatory breach by you. Extricator operates and controls the
          Extricator Service from its offices in South Africa. The Service is
          not intended for distribution to or use by any person or entity in any
          jurisdiction or country where such distribution or use would be
          contrary to law or regulation. Accordingly, those persons who choose
          to access the Extricator Service from other locations do so on their
          own initiative and are solely responsible for compliance with local
          laws, if and to the extent local laws are applicable. These Terms
          &amp; Conditions (which include and incorporate the Extricator Privacy
          Policy) contains the entire understanding, and supersedes all prior
          understandings, between you and Extricator concerning its subject
          matter, and cannot be changed or modified by you. The section headings
          used in this Agreement are for convenience only and will not be given
          any legal import.
        </p>

        <br />

        <strong> Disclaimer</strong>
        <br />

        <p className="terms-content">
          Extricator is not responsible for any content, code, or any other
          imprecision.
        </p>

        <p>Extricator does not provide warranties or guarantees.</p>

        <p>
          In no event shall Extricator be liable for any special, direct,
          indirect, consequential, or incidental damages or any damages
          whatsoever, whether in an action of contract, negligence, or other
          tort, arising out of or in connection with the use of the Service or
          the contents of the Service. Extricator reserves the right to make
          additions, deletions, or modifications to the contents on the Service
          at any time without prior notice.
        </p>

        <p>
          The Extricator Service and its contents are provided &quot;as is&quot;
          and &quot;as available&quot; without any warranty or representations
          of any kind, whether express or implied. Extricator is a distributor
          and not a publisher of the content supplied by third parties; as such,
          Extricator exercises no editorial control over such content and makes
          no warranty or representation as to the accuracy, reliability or
          currency of any information, content, service, or merchandise provided
          through or accessible via the Extricator Service. Without limiting the
          foregoing, Extricator specifically disclaims all warranties and
          representations in any content transmitted on or in connection with
          the Extricator Service or on sites that may appear as links on the
          Extricator Service, or in the products provided as a part of, or
          otherwise in connection with, the Extricator Service, including
          without limitation any warranties of merchantability, fitness for a
          particular purpose or non-infringement of third-party rights. No oral
          advice or written information given by Extricator or any of its
          affiliates, employees, officers, directors, agents, or the like will
          create a warranty. Price and availability information is subject to
          change without notice. Without limiting the foregoing, Extricator does
          not warrant that the Extricator Service will be uninterrupted,
          uncorrupted, timely, or error-free.
        </p>

        <br />

        <strong> Contact Us</strong>
        <br />

        <p className="terms-content">
          Don't hesitate to contact us if you have any questions.
        </p>

        <br />

        <strong>
          {" "}
          -Via Email:{" "}
          <a href="https://hypercap.ai/">
            <span className="c9">support@neuralwave.ai</span>
          </a>
        </strong>
        <br />
        <br />
        <br />
      </div>
      <FooterComponent />
    </div>
  );
}
