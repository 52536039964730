import React, { useEffect, useState } from "react";
import { CircularProgress } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import theme_colors from "../../utils/theme";
import axios from "axios";
import { fetchAuthToken, fetchOrganisationId } from "../../utils/Helpers";
import "./adminAddUser.module.scss";
import { emailRegex, EXTRICATOR_BASE_URL } from "../../utils/constants";

const modelStyles = makeStyles((theme) => ({
  label1: {
    color: "#35F8F1 !important",
    fontWeight: "600",
  },
  btnRoot2: {
    border: "1px solid #35F8F1",
  },
  label2: {
    color: "#011B3E !important",
  },
  circleColor: {
    stroke: "#35F8F1",
  },
  actionRoot: {
    padding: "8px 20px !important",
  },
}));

// This component is used to add a new user by an admin
const AdminAddUser = (props) => {
  // Destructure handleClose and userRoles from the props object
  let { handleClose, userRoles } = props;
  // Get styles from the modelStyles function
  const classes = modelStyles();
  /// Get the auth token using the fetchAuthToken function
  var authToken = fetchAuthToken();

  // Initialize state variables using the useState hook
  const [email, setEmail] = useState("");
  const [loading, setLoading] = useState(false);
  const [errMsg, setErrMsg] = useState("");
  const [selectedAccess, setSelectedAccess] = useState(null);
  const [accessList, setAccessList] = useState(userRoles);

  // Get the organisation ID using the fetchOrganisationId function
  let ogi = fetchOrganisationId();
  // Use the useEffect hook to check if an access level has been selected
  useEffect(() => {
    var checkedItem = accessList.findIndex((item) => item.isChecked == true);
    if (checkedItem > -1 && email.trim()) {
      setSelectedAccess(true);
    } else {
      setSelectedAccess(false);
    }
  }, [accessList, email]);
  // Handle input changes in the email input field
  const handleModelInput = (event) => {
    setEmail(event.target.value);
    if (errMsg) {
      setErrMsg("");
    }
  };

  // This function is used to send an invitation link to a user
  const sendInvite = (event) => {
    // Prevent the default form submission behavior
    event.preventDefault();
    // Find the index of the selected access level
    var accessRoped = accessList.findIndex((item) => item.isChecked == true);
    // Check if the email field is not empty
    if (email) {
      // Check if the entered email is in a valid format
      if (emailRegex.test(email)) {
        // Set the loading state to true while the request is being processed
        setLoading(true);
        // Send a POST request to the server with the user's email, organisation ID, and selected access level
        axios({
          method: "post",
          url: `${EXTRICATOR_BASE_URL}/send_invitation_link/`,
          data: {
            email: email,
            ogi: ogi,
            role_id: accessList[accessRoped].id,
          },
          headers: {
            Authorization: `Bearer ${authToken}`,
          },
        })
          .then((response) => {
            // Set the loading state to false and close the dialog with a success message
            setLoading(false);
            handleClose({ msg: response.data.message, color: "success" });
          })
          .catch((error) => {
            setLoading(false);
            if (error.response) {
              handleClose({
                msg: error.response.data.non_field_errors[0],
                color: "error",
              });
            }
          });
      } else {
        // Show an error message if the entered email is not in a valid format
        setErrMsg("*Please enter a valid email");
      }
    } else {
      // Show an error message if the email field is empty
      setErrMsg("*Please enter an email");
    }
  };

  // This function is used to handle changes in the selected access level
  const handleChangeAccess = (access) => {
    // Create a copy of the accessList array
    var listTemp = [...accessList];

    // Loop through the list and set the isChecked property of the selected access level to true
    // and the isChecked property of all other access levels to false
    for (let i = 0; i < listTemp.length; i++) {
      if (listTemp[i].id === access.id) {
        listTemp[i].isChecked = !listTemp[i].isChecked;
      } else {
        listTemp[i].isChecked = false;
      }
    }

    // Update the state with the modified accessList array
    setAccessList(listTemp);
  };

  return (
    <div
      className="modal fade show"
      id="emailForm"
      tabindex="-1"
      role="dialog"
      aria-labelledby="exampleModalLabel"
      style={{ display: "block" }}
      onClick={() => handleClose(null)}
    >
      <div className="modal-dialog modal-dialog-centered" role="document">
        <div
          className="modal-content"
          onClick={(event) => event.stopPropagation()}
        >
          <div className="modal-header border-bottom-0 d-flex justify-content-end pb-0">
            <i
              className="fa fa-times"
              aria-hidden="true"
              onClick={() => handleClose(null)}
              style={{ fontSize: "16px", cursor: "pointer" }}
            ></i>
          </div>
          <form onSubmit={sendInvite}>
            <div className="modal-body">
              <div className="form-group">
                <input
                  type="email"
                  className="form-control"
                  id="email1"
                  aria-describedby="emailHelp"
                  style={{ height: "38px", color: "#000" }}
                  autoComplete="off"
                  onChange={handleModelInput}
                  placeholder="Email"
                />
              </div>
              <div className="col-12">
                <div className="row">
                  {accessList.map((access) => {
                    return (
                      <div className="form-check check-combo mr-2">
                        <input
                          className="form-check-input"
                          type="checkbox"
                          value=""
                          id={`flexCheckDefault${access.id}`}
                          onChange={() => handleChangeAccess(access)}
                          checked={access.isChecked}
                        />

                        <label
                          className="label"
                          for={`flexCheckDefault${access.id}`}
                        >
                          {access.label}
                        </label>
                      </div>
                    );
                  })}
                </div>
              </div>
            </div>
            <div className="modal-footer border-top-0 d-flex justify-content-center">
              {loading ? (
                <div className="col-sm-2">
                  <CircularProgress
                    classes={{ circle: classes.circleColor }}
                    size={30}
                    color="secondary"
                  />
                </div>
              ) : (
                <>
                  <button
                    type="submit"
                    className="btn"
                    disabled={!selectedAccess}
                    style={{
                      backgroundColor: selectedAccess ? "#35F8F1" : "gray",
                      color: theme_colors.primary,
                      padding: "3px 33px",
                      fontWeight: 600,
                    }}
                  >
                    Invite
                  </button>
                </>
              )}
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default AdminAddUser;
