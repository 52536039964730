import React from "react";
import "./terms.module.scss";
import Images from "../../assets/asset_imports";
import FooterComponent from "../../components/footer-component";

export default function ReturnPolicy(props) {
  // Returns the JSX to render the component

  return (
    <div className="col-12 mains_container p-0">
      <nav className="navbar px-5 py-3" style={{ backgroundColor: "#031B40" }}>
        <a className="navbar-brand" href="/">
          <img
            src={Images.app_logo}
            className="img-responsive"
            style={{ width: "75%", height: "40px" }}
            alt=""
          />
        </a>
      </nav>
      <div className="col-12 tnc_container px-5">
        <br />
        <h1 className="c5" id="h.2lseooyyov6k">
          <span className="c7 c2">Return Policy</span>
        </h1>
        <p className="c5">
          <span className="c0">
            We offer services and solutions via software delivery that allow
            data to be accessed from any device with an active internet
            connection.
          </span>
          <span className="c3 c0">&nbsp;Extricator&nbsp;</span>
          <span className="c0">
            offers a detailed demonstration of the services offered to provide
            clarity to customers. Therefore, it is assumed that the buyer has
            understood and reviewed the product thoroughly before purchasing.
          </span>
        </p>
        <p className="c4">
          <span className="c0"></span>
        </p>
        <p className="c5">
          <span className="c0">
            The nature of our services is completely digital and does not
            involve any physical products, goods, or entities. Considering the
            nature and area of operation of our services there is no element of
            return involved. Nevertheless, we will review and process
            return/refund requests on defective software if any.
          </span>
        </p>
        <p className="c4">
          <span className="c0"></span>
        </p>
        <p className="c5">
          <span className="c0">
            In case of any queries, you can contact us at &nbsp;
          </span>
          <a href="mailto: support@neuralwave.ai">
            <span className="c9">support@neuralwave.ai</span>
          </a>
          <span className="c1">&nbsp;</span>
        </p>
        <p className="c4">
          <span className="c0"></span>
        </p>
      </div>
      <FooterComponent />
    </div>
  );
}
