import React, { useEffect } from "react";
import "./billing.module.scss";
import { CircularProgress, Button, Backdrop } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import axios from "axios";
import "./account.module.scss";
import "../../assets/css/style.scss";
import theme_colors from "../../utils/theme";
import {
  fetchAuthToken,
  getTimeRecord,
  fetchOrganisationId,
} from "../../utils/Helpers";
import { EXTRICATOR_BASE_URL } from "../../utils/constants";
import SnackbarComponent from "../../components/SnackbarComponent";

const accordionStyles = makeStyles((theme) => ({
  // Define styles for the form control

  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  // Define styles for a select box with no options

  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  // Define root styles for the accordion

  root: {
    color: "#fff",
    width: "100%",
    background: "transparent",
    boxShadow: "none",
  },
  // Define styles for the heading of the accordion

  heading: {
    fontSize: theme.typography.pxToRem(15),
    fontWeight: theme.typography.fontWeightRegular,
    color: "#fff",
  },
  // Define styles for the content of the accordion

  content: {
    color: "#062241",
    background: "rgba(255,255,255,0.1)",
    fontWeight: "600",
    padding: "6px",
    alignItems: "center",
    cursor: "pointer",
    justifyContent: "space-between",
    paddingLeft: "40px",
  },
  // Define styles for the content list of the accordion

  contentList: {
    backgroundColor: "transparent",
    fontSize: "12px",
  },
  // Define styles for the arrow icon of the accordion

  arrowIcon: {
    padding: "0",
  },
  // Define styles for the badge used in the app

  badgeRoot: {
    background: theme_colors.secondary,
    width: "33px",
    height: "17px",
    color: theme_colors.primary,
  },
  // Define styles for the label of the badge used in the app

  badgeLabel: {
    color: theme_colors.primary,
  },
  // Define styles for the "Powered by" section of the app

  poweredBy: {
    borderRight: "1px solid #c7c7c7",
  },
  // Define styles for the email chip used in the app

  emailChip: {
    background: "rgba(255,255,255,0.1)",
    cursor: "pointer",
    "&:hover": {
      backgroundColor: "rgba(255,255,255,0.2)",
      color: "#FFF",
    },
  },
  // Define styles for the email logo used in the app

  emailLogo: {
    background: "#86b5e0",
    color: `${theme_colors.primary} !important`,
  },
  // Define styles for the app bar

  appbarRoot: {
    padding: "0 15px",
    background: "transparent",
    boxShadow: "none",
  },
  // Define styles for the tab indicator

  indicator: {
    backgroundColor: theme_colors.secondary,
  },
  // Define styles for the tab root

  tabRoot: {
    width: "180px",
  },
  // Define styles for the import button

  importBtn: {
    backgroundColor: "#35F8F1",
    borderRadius: "6px",
    "&:hover": {
      backgroundColor: theme_colors.primary,
      color: "#FFF",
    },
  },
  // Define styles for the remove button

  removeBtn: {
    backgroundColor: "#031B40",
    color: "#35F8F1",
    border: "1px solid #35F8F1",
    borderRadius: "6px",
    "&:hover": {
      backgroundColor: theme_colors.primary,
      color: "#FFF",
    },
  },
  // Define styles for the logout button

  logOutBtn: {
    backgroundColor: theme_colors.primary,
    color: "#FFF",
    borderRadius: "2px",
    "&:hover": {
      backgroundColor: theme_colors.primary,
      color: "#FFF",
    },
  },

  reviewBtn: {
    backgroundColor: theme_colors.primary,
    background: "rgb(2,27,65)",
    background:
      "linear-gradient(0deg, rgba(2,27,65,1) 49%, rgba(35,67,115,1) 100%)",
    borderRadius: "10px",
    padding: "10px 20px",
    "&:hover": {
      backgroundColor: theme_colors.primary,
      color: "#FFF",
    },
  },
  backdrop: {
    zIndex: 10,
    color: "#fff",
  },
}));

export default function Billing(props) {
  // Destructure props

  const { loaderCallback, handleErrorCallback } = props;
  // State variables

  const [authToken, setAuthToken] = React.useState("");
  const [data, setData] = React.useState([]);
  const classes = accordionStyles();
  const [open, setOpen] = React.useState(false);
  const [snackOpen, setSnackOpen] = React.useState(false);
  const [successMsg, setSuccessMsg] = React.useState("");
  const [toastColor, setToastColor] = React.useState("success");
  // Function to fetch billing details

  const fetchBilling = () => {
    var auth = fetchAuthToken();
    let ogi = fetchOrganisationId();
    setAuthToken(auth);
    loaderCallback(true);
    axios({
      method: "GET",
      url: `${EXTRICATOR_BASE_URL}/billing_details/?ogi=${ogi}`,
      headers: {
        "Content-Type": "multipart/form-data",
        Authorization: `Bearer ${auth}`,
      },
    })
      .then(function (response) {
        // Handle successful response

        loaderCallback(false);
        setData(response.data);
      })
      .catch(function (error) {
        // Handle errors

        if (error.response) {
          if (error.response.data.non_field_errors) {
            handleErrorCallback(error.response.data.non_field_errors[0]);
          }
        }
        loaderCallback(false);
      });
  };

  useEffect(() => {
    fetchBilling();
  }, []);
  // Function to handle download

  const handleDownload = (id) => {
    setOpen(true);
    let ogi = fetchOrganisationId();
    axios({
      method: "POST",
      url: `${EXTRICATOR_BASE_URL}/template/`,
      headers: {
        Authorization: `Bearer ${authToken}`,
      },
      data: {
        id,
        ogi: ogi,
      },
    })
      .then(function (response) {
        // Handle successful response

        setOpen(false);
        window.open(response.data.link);
      })
      .catch(function (error) {
        // Handle errors

        if (error.response) {
          if (error.response.data.non_field_errors) {
            handleErrorCallback(error.response.data.non_field_errors[0]);
          } else {
            console.error("::NO_NON_FIELD_ERR_FOUND::");
          }
        }
        setOpen(false);
      });
  };
  // Function to handle closing of Snackbar

  const handleSnackClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setSnackOpen(false);
    setSuccessMsg("");
  };
  // Function to initiate payment

  const handleInitPayment = (id) => {
    let ogi = fetchOrganisationId();
    setOpen(true);
    axios({
      method: "POST",
      url: `${EXTRICATOR_BASE_URL}/initial_transaction/`,
      headers: {
        Authorization: `Bearer ${authToken}`,
      },
      data: {
        id,
        ogi: ogi,
      },
    })
      .then(function (response) {
        //handle success
        var { data } = response;
        if (data.hasOwnProperty("url")) {
          setOpen(false);
          window.open(data.url);
        } else if (data.hasOwnProperty("message")) {
          setOpen(false);
          setSnackOpen(true);
          setSuccessMsg(data.message);
          setToastColor("success");
        }
      })
      .catch(function (error) {
        // Handle errors

        if (error.response) {
          if (error.response.data.non_field_errors) {
            handleErrorCallback(error.response.data.non_field_errors[0]);
          } else {
            console.error("::NO_NON_FIELD_ERR_FOUND::");
          }
        }
        setOpen(false);
      });
  };

  return (
    <>
      <Backdrop
        className={classes.backdrop}
        open={open}
        onClick={() => setOpen(false)}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <SnackbarComponent
        isOpen={snackOpen}
        message={successMsg}
        color={toastColor}
        handleClose={handleSnackClose}
      />
      <div id="billing_page" className="col-12">
        <table className="table">
          <thead>
            <tr className="head-row">
              <th scope="col" className="text-left start-cell">
                From
              </th>
              <th scope="col" className="text-left pl-0">
                To
              </th>
              <th scope="col" className="text-left pl-0">
                Description
              </th>
              <th scope="col" className="text-left pl-0">
                Organization Bill Amount
              </th>
              <th scope="col"></th>
              <th scope="col"></th>
            </tr>
          </thead>
          <tbody>
            {data.map((item) => (
              <tr key={item.id}>
                <td
                  className="text-left time_cell start-cell"
                  style={{ width: "20%" }}
                >
                  {getTimeRecord(item.From)}
                </td>
                <td className="text-left time_cell">
                  {getTimeRecord(item.To)}
                </td>
                <td className="text-left time_cell" style={{ width: "220px" }}>
                  {item.Description}
                  {}
                </td>
                <td className="text-left time_cell">{item.Amount}</td>
                <td
                  style={{
                    padding: "4px 4px 2px 0px",
                    width: "36px",
                  }}
                >
                  {item.payment_status == false ? (
                    <Button
                      component="span"
                      variant="contained"
                      classes={{ root: classes.removeBtn }}
                      style={{
                        marginTop: "4px",
                        borderRadius: 2,
                        width: "125px",
                      }}
                      onClick={() => handleInitPayment(item.id)}
                    >
                      <span
                        style={{
                          color: "#35F8F1",
                          paddingLeft: 10,
                          paddingRight: 10,
                        }}
                      >
                        Pay
                      </span>
                    </Button>
                  ) : null}
                </td>

                <td
                  align="center"
                  style={{
                    padding: "4px 4px 2px 0px",
                    width: "36px",
                  }}
                >
                  <Button
                    component="span"
                    variant="contained"
                    classes={{ root: classes.removeBtn }}
                    style={{
                      marginTop: "4px",
                      borderRadius: 2,
                      width: "125px",
                    }}
                    onClick={() => handleDownload(item.id)}
                  >
                    <span
                      style={{
                        color: "#35F8F1",
                        paddingLeft: 10,
                        paddingRight: 10,
                      }}
                    >
                      {" "}
                      Download{" "}
                    </span>
                  </Button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </>
  );
}
