import React, { useEffect } from "react";
import "./adminReport.module.scss";
import "../../assets/css/style.scss";
import { Box, makeStyles, Typography } from "@material-ui/core";
import theme_colors from "../../utils/theme";
import PropTypes from "prop-types";
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  Label,
} from "recharts";
import axios from "axios";
import { checkOrganizationError, fetchAuthToken } from "../../utils/Helpers";
import { adminMonthsSet, EXTRICATOR_BASE_URL } from "../../utils/constants";
import Dropdown from "react-dropdown";
/**

TabPanel component displays the content of a tab based on the selected value.
@param {object} props - The properties passed to the component.
@param {node} props.children - The child component(s) of the component.
@param {any} props.index - The index value of the tab.
@param {any} props.value - The value of the selected tab.
@returns {JSX.Element} - The JSX element to be rendered.
*/
function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`scrollable-auto-tabpanel-${index}`}
      aria-labelledby={`scrollable-auto-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};
/**

accordionStyles is a hook that provides styling for the accordion components.
*/
const accordionStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  root: {
    color: "#fff",
    width: "100%",
    background: "transparent",
    boxShadow: "none",
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    fontWeight: theme.typography.fontWeightRegular,
    color: "#fff",
  },
  content: {
    color: "#062241",
    background: "rgba(255,255,255,0.1)",
    fontWeight: "600",
    padding: "6px",
    alignItems: "center",
    cursor: "pointer",
    justifyContent: "space-between",
    paddingLeft: "40px",
  },
  contentList: {
    backgroundColor: "transparent",
    fontSize: "12px",
  },
  arrowIcon: {
    padding: "0",
  },
  badgeRoot: {
    background: theme_colors.secondary,
    width: "33px",
    height: "17px",
    color: theme_colors.primary,
  },
  backdrop: {
    zIndex: 10,
    color: "#fff",
  },
  badgeLabel: {
    color: theme_colors.primary,
  },
  poweredBy: {
    borderRight: "1px solid #c7c7c7",
  },
  emailChip: {
    backgroundColor: "#0B346B",
    cursor: "pointer",
    borderRadius: 3,
  },
  emailLogo: {
    background: "#86b5e0",
    color: `${theme_colors.primary} !important`,
  },
  appbarRoot: {
    padding: "0 5px",
    background: "transparent",
    boxShadow: "none",
  },
  indicator: {
    backgroundColor: "transparent !important",
    height: "0px !important",
  },
  tabRoot: {
    width: "180px",
  },
  importBtn: {
    backgroundColor: "#35F8F1",
    borderRadius: "6px",
    "&:hover": {
      backgroundColor: theme_colors.primary,
      color: "#FFF",
    },
  },
  saveBtn: {
    background: "#35F8F1 0% 0% no-repeat padding-box",
    borderRadius: "3px",
    opacity: 1,
    height: "38px",
    width: "132px",
  },
  disabledBtn: {
    border: " 1px solid #35F8F1",
  },
  removeBtn: {
    backgroundColor: "transparent",
    color: "#35F8F1",
    border: "1px solid #35F8F1",
    width: "132px",
    height: "38px",
    borderRadius: "3px",
    "&:hover": {
      backgroundColor: theme_colors.primary,
      color: "#FFF",
    },
  },

  logOutBtn: {
    backgroundColor: theme_colors.primary,
    color: "#FFF",
    borderRadius: "2px",
    "&:hover": {
      backgroundColor: theme_colors.primary,
      color: "#FFF",
    },
  },

  reviewBtn: {
    backgroundColor: theme_colors.primary,
    background: "rgb(2,27,65)",
    background:
      "linear-gradient(0deg, rgba(2,27,65,1) 49%, rgba(35,67,115,1) 100%)",
    borderRadius: "10px",
    padding: "10px 20px",
    "&:hover": {
      backgroundColor: theme_colors.primary,
      color: "#FFF",
    },
  },
  backdrop: {
    zIndex: 10,
    color: "#fff",
  },
  accountTab: {
    height: "26px",
    minHeight: "unset",
  },
  tabGroup: {
    height: "30px",
  },
  tabGroupRoot: {
    minHeight: "unset !important",
  },
  panelRoot: {
    padding: "0px",
  },
}));

const GlobalReport = (props) => {
  let { loaderCallback, dateSet, handleErrorCallback } = props;
  const date = new Date();
  const authToken = fetchAuthToken();
  const [document, setDocument] = React.useState("FORM");
  const [report, setReport] = React.useState([]);
  const [usageReport, setUsageReport] = React.useState([]);
  const [billingReport, setBillingReport] = React.useState([]);
  const [billingYear, setBillingYear] = React.useState(null);
  const [years, setYears] = React.useState([2020]);
  const [months, setMonths] = React.useState([]);
  const [usageYear, setUsageYear] = React.useState(
    date.getFullYear().toString()
  );
  const [usageMonth, setUsageMonth] = React.useState(null);
  const [usageMonthStr, setUsageMonthStr] = React.useState("");

  useEffect(() => {
    // Set coveredMonths based on current month

    var coveredMonthsTemp = [...adminMonthsSet];
    var coveredMonths = coveredMonthsTemp.splice(0, date.getMonth() + 1);
    // Set usageMonthStr and months state based on coveredMonths

    setUsageMonthStr(coveredMonths[coveredMonths.length - 1]);
    setMonths(coveredMonths);
  }, []);

  useEffect(() => {
    // Get report data when dateSet or document changes

    getReportData(dateSet.from, dateSet.to);
  }, [document, props.dateSet]);

  useEffect(() => {
    // Get usage report when usageYear or usageMonth changes

    getUsageReport();
  }, [usageYear, usageMonth]);
  // Set yearSet based on register year and current year

  const setYearSet = async () => {
    var registerYear = await localStorage.getItem("register_year");
    if (!registerYear) {
      registerYear = await sessionStorage.getItem("register_year");
    }
    var yearSet = [];

    for (let i = registerYear - 1; i < date.getFullYear(); i++) {
      yearSet.push(`${i + 1}`);
    }
    // Set yearSet and billingYear state

    setYears(yearSet);
    setBillingYear(registerYear);
  };
  // Get usage report data using API

  const getUsageReport = () => {
    loaderCallback(true);
    axios({
      method: "GET",
      url: `${EXTRICATOR_BASE_URL}/global_usage_chart/?year=${usageYear}&month=${usageMonth}`,
      headers: {
        Authorization: `Bearer ${authToken}`,
      },
    })
      .then(function (response) {
        loaderCallback(false);
        // Set usageReport state and call setYearSet function

        setUsageReport(response.data.global_usage_chart_data);
        setYearSet();
      })
      .catch(function (error) {
        if (error.response) {
          handleErrorCallback(error.response);
          loaderCallback(false);
        }
      });
  };
  // Get global report data using API

  const getReportData = async (from = null, to = null) => {
    loaderCallback(true);
    axios({
      method: "GET",
      url: `${EXTRICATOR_BASE_URL}/global_report/?from=${from}&to=${to}`,
      headers: {
        Authorization: `Bearer ${authToken}`,
      },
    })
      .then(function (response) {
        loaderCallback(false);
        // Set report state

        setReport(response.data.global_report_data);
      })
      .catch(function (error) {
        if (error.response) {
          if (error.response.status == 401) {
            handleErrorCallback(error.response);
          } else if (error.response.data.non_field_errors) {
            checkOrganizationError(
              error.response.data.non_field_errors[0],
              props.history
            );
          }
          loaderCallback(false);
        }
      });
  };
  // Update usageMonth and usageMonthStr state based on selected option

  const _onSelectUsageMonth = (option) => {
    let monthObj = months.filter((item) => item.value == option.value);
    setUsageMonth(
      monthObj[0].index < 10 ? `0${monthObj[0].index}` : `${monthObj[0].index}`
    );
    setUsageMonthStr(monthObj[0].label);
  };

  const _onSelectUsageYear = (option) => setUsageYear(option.value.toString());

  return (
    <div id="global_report">
      <div className="col-12 mt-4">
        <div className="row">
          <div className="col-12 px-0">
            <div
              className="d-flex"
              style={{ justifyContent: "space-between", padding: "0px 20px" }}
            >
              <div className="stat-card" style={{ marginLeft: "0px" }}>
                <span>Total Earn from Invoice</span>
                <h4
                  style={{
                    justifyContent: "space-between",
                    padding: "0px 20px",
                    position: "relative",
                    top: "10px",
                  }}
                >
                  {report.length > 0 ? report[0].invoice_earn : "0$"}
                </h4>
              </div>
              <div className="stat-card">
                <span>Total Earn from Forms</span>
                <h4
                  style={{
                    justifyContent: "space-between",
                    padding: "0px 20px",
                    position: "relative",
                    top: "10px",
                  }}
                >
                  {report.length > 0 ? report[0].form_earn : "0$"}
                </h4>
              </div>
              <div className="stat-card">
                <span>Total Earn from Receipt</span>
                <h4
                  style={{
                    justifyContent: "space-between",
                    padding: "0px 20px",
                    position: "relative",
                    top: "10px",
                  }}
                >
                  {report.length > 0 ? report[0].receipt_earn : "0$"}
                </h4>
              </div>
              <div className="stat-card">
                <span>Active User</span>
                <h4
                  style={{
                    justifyContent: "space-between",
                    padding: "0px 20px",
                    position: "relative",
                    top: "10px",
                  }}
                >
                  {report.length > 0 ? report[0].active_user : "0"}
                </h4>
              </div>
              <div className="stat-card">
                <span>Blocked User</span>
                <h4
                  style={{
                    justifyContent: "space-between",
                    padding: "0px 20px",
                    position: "relative",
                    top: "10px",
                  }}
                >
                  {report.length > 0 ? report[0].blocked_user : "0"}
                </h4>
              </div>
              <div className="stat-card">
                <span>New User</span>
                <h4
                  style={{
                    justifyContent: "space-between",
                    padding: "0px 20px",
                    position: "relative",
                    top: "10px",
                  }}
                >
                  {report.length > 0 ? report[0].new_user : "0"}
                </h4>
              </div>
            </div>
          </div>
        </div>
        <br />

        <div className="col-12">
          <div className="row justify-content-between">
            <span
              style={{ fontSize: "18px", marginLeft: "3px", marginTop: "5px" }}
            >
              Usage Chart
            </span>

            <div className="d-flex drop-wrapper">
              <Dropdown
                options={years}
                onChange={_onSelectUsageYear}
                value={usageYear}
                style={{ marginLeft: "10px" }}
                placeholder="Year"
              />

              <Dropdown
                options={months}
                onChange={_onSelectUsageMonth}
                value={usageMonthStr}
                style={{ marginLeft: "10px" }}
                placeholder="Month"
              />
            </div>
          </div>
        </div>
        <div className="container mt-2">
          <BarChart
            width={1000}
            height={300}
            data={usageReport}
            margin={{
              top: 5,
              right: 30,
              left: 20,
              bottom: 5,
            }}
          >
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis dataKey="week">
              <Label
                stroke="#fff"
                value="Weeks"
                offset={0}
                position="insideBottom"
              />
            </XAxis>
            <YAxis
              label={{
                value: "Total Count",
                angle: -90,
                position: "insideLeft",
                textAnchor: "middle",
                stroke: "#fff",
              }}
            />
            <Tooltip
              cursor={{ fill: "transparent" }}
              contentStyle={{ backgroundColor: "#426aa1" }}
            />
            <Legend />
            <Bar dataKey="invoice" barSize={20} fill="#426aa1" />
            <Bar dataKey="form" barSize={20} fill="#3664a3" />
            <Bar dataKey="receipt" barSize={20} fill="#4d89db" />
          </BarChart>
        </div>
      </div>
    </div>
  );
};
export default GlobalReport;
