import React, { useState, useEffect, useRef } from "react";
import "./dashboard.module.scss";
import "../../assets/css/style.scss";
import { Link } from "react-router-dom";
import Images from "../../assets/asset_imports";
import {
  Box,
  Tabs,
  Tab,
  Button,
  Paper,
  Accordion,
  CircularProgress,
  AccordionSummary,
  Backdrop,
  Tooltip,
  Modal,
  AccordionDetails,
  createMuiTheme,
  MenuItem,
  ThemeProvider,
  Checkbox,
  Menu,
  Select,
  Typography,
} from "@material-ui/core";
import MuiAlert from "@material-ui/lab/Alert";
import { makeStyles } from "@material-ui/core/styles";
import theme_colors from "../../utils/theme";
import PropTypes from "prop-types";
import $ from "jquery";
import axios from "axios";
import {
  fetchAuthToken,
  fetchLoggedInUserEmail,
  handleLogout,
  paginateTable,
  getTimeRecord,
  setActiveSection,
  fetchOrganisationId,
  checkOrganizationError,
  fetchActiveSection,
  fetchUserID,
} from "../../utils/Helpers";
import SnackbarComponent from "../../components/SnackbarComponent";
import ImageShowcase from "../../components/ImageShowcase";
import TopNav from "./components/TopNav";
import LogoutBar from "../../components/LogoutBar";
import DeleteDialogBox from "../../components/DeleteDialog/DeleteDialogBox";
import FileCategoryComponent from "./components/FileCategoryComponent";
import PaginationComponent from "../../components/PaginatorComponent";
import PayContainer from "../../components/PayContainer";
import CustomDropDown from "../../components/CustomDropDown";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import SearchOnSelect from "./components/SearchCriteriaMenu";
import { EXTRICATOR_BASE_URL, monthsSet } from "../../utils/constants";
import CheckMobileDevice from "../../components/CheckMobileDevice";
// An array of sort options

const sortOptions = [
  {
    name: "By Name",
    value: "by_name",
  },
  {
    name: "By Upload",
    value: "by_upload",
  },
];
// A functional component for a tab panel
function TabPanel(props) {
  // Destructuring props to get the value, index, and other props

  const { children, value, index, ...other } = props;
  // Render a div that displays the children when value is equal to index

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`scrollable-auto-tabpanel-${index}`}
      aria-labelledby={`scrollable-auto-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}
// Prop types for TabPanel component

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};
// Define a styles object using the makeStyles hook

const accordionStyles = makeStyles((theme) => ({
  root: {
    color: "#fff",
    width: "100%",
    background: "transparent",
    boxShadow: "none",
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    fontWeight: theme.typography.fontWeightRegular,
    color: "#fff",
  },
  content: {
    color: "#062241",
    background: "rgba(255,255,255,0.1)",
    fontWeight: "600",
    padding: "6px",
    alignItems: "center",
    cursor: "pointer",
    justifyContent: "space-between",
    paddingLeft: "40px",
  },
  contentList: {
    backgroundColor: "transparent",
    fontSize: "12px",
  },
  arrowIcon: {
    padding: "0",
  },
  badgeRoot: {
    width: "33px",
    height: "17px",
    color: theme_colors.primary,
    background: "#2280FF",
  },
  badgeLabel: {
    color: theme_colors.primary,
  },
  poweredBy: {
    borderRight: "1px solid #c7c7c7",
  },
  emailChip: {
    backgroundColor: "#0B346B",
    cursor: "pointer",
    borderRadius: 3,
    fontWeight: "600",
    fontFamily: "'Open Sans', sans-serif !important",
    width: "249px",
    height: "35px",
    marginRight: "10px",
  },
  emailLogo: {
    background: theme_colors.secondary,
    color: `${theme_colors.primary} !important`,
  },
  appbarRoot: {
    padding: "0 15px",
    background: "transparent",
    boxShadow: "none",
    marginTop: "15px",
  },
  indicator: {
    backgroundColor: "transparent !important",
    height: "0px !important",
  },
  tabRoot: {
    width: "180px",
    fontWeight: "600",
  },
  importBtn: {
    backgroundColor: "#35F8F1",
    borderRadius: "6px",
  },

  summary_root: {
    minHeight: "32px !important",
    paddingRight: "0px !important",
  },
  summary_rootSelected: {
    backgroundColor: "#2280FF",
    minHeight: "22px !important",
    paddingRight: "0px !important",
  },
  summaryStyle: {
    alignItems: "center",
    display: "flex",
  },
  accordionRoot: {
    backgroundColor: "red",
  },
  summaryDisabled: {
    opacity: "1 !important",
  },
  goBtn: {
    backgroundColor: "#031B40",
    borderRadius: "6px",
    "&:hover": {
      backgroundColor: theme_colors.primary,
      color: "#FFF",
    },
  },
  calendarList: {
    background: "green !important",
  },
  logOutBtn: {
    backgroundColor: theme_colors.primary,
    color: "#FFF",
    borderRadius: "3px",
    "&:hover": {
      backgroundColor: theme_colors.primary,
      color: "#FFF",
    },
  },

  reviewBtn: {
    backgroundColor: theme_colors.primary,
    background: "rgb(2,27,65)",
    background:
      "linear-gradient(0deg, rgba(2,27,65,1) 49%, rgba(35,67,115,1) 100%)",
    borderRadius: "10px",
    padding: "10px 20px",
    "&:hover": {
      backgroundColor: theme_colors.primary,
      color: "#FFF",
    },
  },
  backdrop: {
    zIndex: 10,
    color: "#fff",
  },
  myMenu: {
    height: "50px",
    width: "30px",
    color: "#fff !important",
  },
  menuPaper: {
    left: "875px !important",
    top: "52px !important",
    width: "114px !important",
  },
  spinner: {
    color: "#011B3E",
  },
  alertIcon: {
    display: "none",
  },
  alertMsg: {
    padding: "0px 0px",
  },
}));

const accoSummaryStyles = makeStyles((theme) => ({
  root: {
    minHeight: "26px",
    flexDirection: "row-reverse",
    justifyContent: "flex-start",
    alignItems: "center",
    padding: 0,
    boxShadow: "none",
  },
  content: {
    margin: "0",
    justifyContent: "space-between",
    alignItems: "center",
  },
}));
// Define a theme using the createMuiTheme function

const myTheme = createMuiTheme({
  // Override styles for the MuiMenu component

  overrides: {
    MuiMenu: {
      paper: {
        padding: 0,
        borderRadius: "5px",
      },
      list: {
        paddingBottom: "0px !important",
      },
    },
    // Override styles for the MuiPopover component

    MuiPopover: {
      paper: {
        "& ul": {
          background: "#12458A 0% 0% no-repeat padding-box !important",
        },
      },
    },
    // Override styles for the MuiInput component

    MuiInput: {
      root: {
        padding: "0px",
      },
      underline: {
        cursor: "pointer",
      },
    },
    // Override styles for the MuiSelect component

    MuiSelect: {
      select: {
        paddingRight: "0px !important",
      },
      // Override styles for the MuiListItem component

      icon: {
        height: "100%",
        marginTop: "0px !important",
        top: "0 !important",
      },
      selectMenu: {
        paddingLeft: "5px",
      },
    },
    MuiListItem: {
      gutters: {
        paddingRight: "22px !important",
        cursor: "default",
      },
    },
    MuiSvgIcon: {
      root: {
        color: "#fff",
      },
    },
  },
});

const myThemeSort = createMuiTheme({
  overrides: {
    MuiMenu: {
      paper: {
        padding: 0,
        height: 56,
        borderRadius: 6,
        width: "100px",
      },
    },
    MuiPopover: {
      paper: {
        "& ul": {
          background: "#12458A 0% 0% no-repeat padding-box !important",
          textAlign: "center",
          height: "100%",
          overflow: "hidden",
        },
        "& li": {
          paddingLeft: "22px !important",
          width: "100% !important",
          height: "28px !important",
        },
        "& li:hover": {
          backgroundColor: "#5F88BF",
        },
      },
    },
    MuiList: {
      padding: {
        padding: "0px !important",
      },
    },
    MuiListItem: {
      root: {
        width: "114px !important",
        height: "22px !important",
        fontSize: "12px !important",
      },
    },
  },
});
// A functional component for a custom tab

const CustomTab = ({ title, icon, showChip, classes, thickness }) => (
  <div className="customs-tab review-div" style={{ fontWeight: thickness }}>
    <img src={icon} />
    {title}
  </div>
);
// A functional component for an alert message

function Alert(props) {
  // Use the accordionStyles hook to get classes

  const classes = accordionStyles();
  // Render a MuiAlert component with custom classes and props

  return (
    <MuiAlert
      classes={{
        root: classes.toastRoot,
        icon: classes.toastIcon,
        message: classes.toastMessage,
      }}
      elevation={6}
      variant="filled"
      {...props}
    />
  );
}

var timerVal;
var previewTime;
var tripper = 0;

const DashboardInvoice = (props) => {
  const authToken = fetchAuthToken();
  const ogi = fetchOrganisationId();
  const user_id = fetchUserID();
  const activeSection = fetchActiveSection();
  const [logOutBtn, showLogoutBtn] = useState(false);
  const [searchSubject, setSearchSubject] = useState("file");
  const [fileType, setFileType] = React.useState("FORM");
  const [searchAttempt, setSearchAttempt] = useState(false);
  const [testCounter, setTestCounter] = useState(0);
  const [userId, setUserId] = useState(null);
  const [fullProcessingFiles, setFullProcessingFiles] = useState([]);
  const [navActiveTab, setNavActiveTab] = useState(1);
  const [exportBuffer, setExportBuffer] = useState([]);
  const [deletedInvoiceTab, setDeletedInvoiceTab] = useState(0);
  const [fileUploading, setFileUploading] = useState(false);
  const [deletableFileId, setDeletableFileId] = useState(null);
  const [deleteFileProgress, setDeleteFileProgress] = useState(false);
  const [activeStep, setActiveStep] = useState(0);
  const [fileMessage, setFileMessage] = useState(0);
  const [dateErrMessage, setDateErrMessage] = useState("");
  const [searchFocused, setSearchFocus] = useState(false);
  const [dateRange, setDateRange] = useState({ from: null, to: null });
  const [bulkVendorsList, setBulkVendorsList] = useState([]);
  const [value, setValue] = React.useState(0);
  const [valueParsed, setParsedValue] = React.useState(0);
  const [paginMenu, setPaginMenu] = React.useState(false);
  const [itemsPerPage, setItemsPerPage] = React.useState(10);
  const [profileMenuShow, setProfileMenuShow] = React.useState(false);
  const [successMsg, setSuccessMsg] = React.useState("");
  const [snackOpen, setSnackOpen] = React.useState(false);
  const [toastColor, setToastColor] = React.useState("success");
  const [open, setOpen] = React.useState(false);
  const [updateTable, setUpdateTable] = React.useState(false);
  const [sortOption, setSortOption] = React.useState("Sort by");
  const [calander, openCalander] = React.useState(false);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [anchorElSort, setAnchorElSort] = React.useState(null);
  const [anchorElLogout, setAnchorElLogout] = React.useState(null);
  const [vendorHTML, setVendorHTML] = React.useState([]);
  const [rejectedHTML, setRejectedHTML] = React.useState([]);
  const [processingFiles, setProcessingFiles] = React.useState([]);
  const [vendorDetailsHTML, setVendorDetails] = React.useState([]);
  const [vendorsLength, setVendorsLength] = React.useState([]);

  const [amount, setAmount] = React.useState(0);
  const [expanded, setExpanded] = React.useState(false);
  const [userEmail, setEmail] = React.useState("");
  const [counterValue, setCounter] = React.useState(0);
  const [arrayPushed, setArray] = React.useState([]);
  const [status, setStatus] = React.useState("PARSED");
  const [formStatus, setFormStatus] = React.useState("");
  const [invoiceStatus, setInvoiceStatus] = React.useState(activeSection);
  const [receiptStatus, setReceiptStatus] = React.useState("");
  const [days, setDays] = React.useState([1]);
  const [fileId, setFileId] = React.useState(null);
  const [selectedModel, setSelectedModel] = React.useState(null);
  const [mainCheck, setMaincheck] = React.useState(false);
  const [toMonths, setToMonths] = React.useState(monthsSet);
  const [fromMonths, setFromMonths] = React.useState(monthsSet);
  const [years, setYears] = React.useState([2020]);
  const [id, setId] = React.useState();
  // React state hooks for sorting and filtering data

  const [sortBy, setSortBy] = React.useState({
    name: "Sort By", // name of the sorting option
    value: null, // value of the sorting option
  });

  const [from, setFrom] = React.useState({
    day: null, // day of the 'from' date
    month: null, // month of the 'from' date
    year: null, // year of the 'from' date
  });

  const [to, setTo] = React.useState({
    day: null, // day of the 'to' date
    month: null, // month of the 'to' date
    year: null, // year of the 'to' date
  });
  const [activeTab, setActiveTab] = useState(activeSection);
  const [selectedVendorFiles, setSelectedVendorFiles] = React.useState("");
  const [selectedVendor, setSelectedVendor] = React.useState("");
  const [tooltip, setTooltip] = React.useState(false);
  const [tooltipDate, setTooltipDate] = React.useState("");
  const [vendorCounter, setVendorCounter] = React.useState(0);
  const [gif, setGif] = React.useState(require("./analysis.gif"));
  const [logout, showLogout] = React.useState(false);
  const [search, setSearch] = React.useState("");
  const [fileSearch, setFileSearch] = React.useState("");
  const [vendorSearch, setVendorSearch] = React.useState("");
  const [searchCounter, setSearchCounter] = React.useState(0);
  const [page, setPage] = React.useState(1);
  const [formPage, setFormPage] = React.useState(1);
  const [processingFilePage, setProcessingFilePage] = React.useState(1);
  const [formPageExported, setFormPageExported] = React.useState(1);
  const [formPageDeleted, setFormPageDeleted] = React.useState(1);
  const [receiptPage, setReceiptPage] = React.useState(1);
  const [rows, setRows] = React.useState(10);
  const [pagedData, setPagedData] = React.useState([]);
  const [totalPages, setTotalPages] = React.useState([]);
  const [totalFormPages, setTotalFormPages] = React.useState([]);
  const [totalExportedPages, setTotalExportedPages] = React.useState([]);
  const [totalDeletedPages, setTotalDeletedPages] = React.useState([]);
  const [totalReceiptPages, setTotalReceiptPages] = React.useState([]);
  const [totalVendorHtmlPages, setTotalVendorHtmlPages] = React.useState([]);
  const [totalRejectedHtmlPages, setTotalRejectedHtmlPages] = React.useState(
    []
  );
  const [totalProcessingPages, setTotalProcessingPages] = React.useState([]);
  const [uploadModal, setUploadModal] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const [fileName, setFilename] = React.useState("");
  const [fileLength, setFileLength] = React.useState(0);
  const [vendorChecked, setChecked] = React.useState(false);
  const [invoices, setInvoices] = React.useState(false);
  const [uploaded, setUploaded] = React.useState(false);
  const [docType, setDocType] = React.useState("INVOICE");
  const [formList, setFormList] = React.useState([]);
  const [parsedForms, setParsedForms] = React.useState([]);
  const [fullParsedForms, setFullParsedForms] = React.useState([]);
  const [exportedForms, setExportedForms] = React.useState([]);
  const [fullExportedForms, setFullExportedForms] = React.useState([]);
  const [deletedForms, setDeletedForms] = React.useState([]);
  const [fullDeletedForms, setFullDeletedForms] = React.useState([]);
  const [showExcelBtn, setExcelBtn] = React.useState(false);
  const [showExcelBtnR, setExcelBtnR] = React.useState(false);
  const [formId, setFormId] = React.useState(0);
  const [selectedForms, setSelectedForms] = React.useState(0);
  const [selectedFormIds, setSelectedFormIds] = React.useState([]);
  const [receiptList, setReceiptList] = React.useState([]);
  const [receiptVendorDetails, setReceiptVendorDetails] = React.useState([]);
  const [fullReceiptVendorDetails, setFullReceiptVendorDetails] =
    React.useState([]);
  const [selectedReceipts, setSelectedReceipts] = React.useState(0);
  const [selectedReceiptIds, setSelectedReceiptIds] = React.useState([]);
  const [receiptId, setReceiptId] = React.useState(0);
  const [selectedFiles, setSelectedFiles] = React.useState([]);
  const [vendorTicked, setVendorTicked] = React.useState(false);
  const [activeVendor, setActiveVendor] = React.useState(null);
  const [focusedVendorId, setFocusedVendorId] = React.useState(null);
  const [expandedVendorId, setexpandedVendorId] = React.useState(null);
  const [goInputFocused, setGoInputFocused] = React.useState(false);
  const [pageInputValue, setPageInputValue] = React.useState(1);
  const [formPageValue, setFormPageValue] = React.useState(1);
  const [fullVendorDetails, setFullVendorDetails] = React.useState([]);
  const [fullVendorHtml, setFullVendorHtml] = React.useState([]);
  const [fullRejectedHtml, setFullRejectedHtml] = React.useState([]);
  const [filesDefaultSelectedId, setFilesDefaultSelectedId] = React.useState(
    []
  );
  const [showTable, setShowTable] = React.useState(false);
  const [selectedVendorIds, setSelectedVendorIds] = React.useState([]);
  const [myName, setMyName] = React.useState("akshay");
  const [deletedTabValue, setDeletedTabValue] = React.useState(0);
  const [parsedInvoiceTab, setParsedInvoiceTab] = React.useState(0);
  const [parsedReceiptTab, setParsedReceiptTab] = React.useState(0);
  const [rejectedDeleteFiles, setRejectedDeleteFiles] = React.useState([]);
  const [processingPage, setProcessingPage] = React.useState(1);
  //check if exceeded the max no. of pages or not
  const [checkMaxNoPages, setCheckMaxNoPages] = React.useState(true);
  const planName = "invoices";


  const myNameRef = React.useRef(myName);
  // React state hooks and ref for managing component state
  const setMyState = (data) => {
    myNameRef.current = data; // set the current value of myNameRef to data
    setMyName(data); // update the state of myName with data
  };
  const prevStatusRef = useRef(); // create a ref for the previous status
  useEffect(() => {
    prevStatusRef.current = status; // update the ref with the current status value
  });
  const prevStatus = prevStatusRef.current; // get the previous status from the ref
  const searchInput = React.useRef(null); // create a ref for the search input
  // function to handle accordion change event

  const handleAccordianChange = (vendor) => (event, isExpanded) => {
    var vendorHTMLTemp = [...vendorHTML];
    var receiptListTemp = [...receiptList];
    if (!goInputFocused) {
      for (let i = 0; i < vendorHTMLTemp.length; i++) {
        if (vendor.id == vendorHTMLTemp[i].id) {
          vendorHTMLTemp[i].isAccordianOpen =
            !vendorHTMLTemp[i].isAccordianOpen;
        } else {
          vendorHTMLTemp[i].isAccordianOpen = false;
        }
      }
      setVendorHTML(vendorHTMLTemp);
      if (!vendor.isAccordianOpen) {
        return;
      }
      getVendorDetails(vendor);
    }
  };

  const classes = accordionStyles(); // create a style object for accordion

  const summaryClass = accoSummaryStyles(); // create a style object for accordion summary

  // function to format invoice table data for display

  const formatInvoiceTable = (vendorDetails) => {
    var paginatedData = paginateTable(vendorDetails, page, rows); // paginate the data
    if (paginatedData.paginated_data.length > 0) {
      setVendorDetails(paginatedData.paginated_data); // update the vendor details state
    }
    setTotalPages(paginatedData.totalPages); // update the total pages state
  };
  // function to format vendor HTML data for display

  const formatVendorHtml = (vendorDetails) => {
    var paginatedData = paginateTable(vendorDetails, page, rows); // paginate the data
    setVendorHTML(paginatedData.paginated_data); // update the vendor HTML state
    setTotalVendorHtmlPages(paginatedData.totalPages); // update the total pages state
  };
  // function to format rejected documents for display

  const formatRejectedHtml = (rejectedDoc) => {
    var paginatedData = paginateTable(rejectedDoc, page, rows); // paginate the data
    setRejectedHTML(paginatedData.paginated_data); // update the rejected HTML state
    setTotalRejectedHtmlPages(paginatedData.totalPages); // update the total pages state
  };
  // function to format processing files for display

  const formatProcessingFiles = (files) => {
    var paginatedData = paginateTable(files, processingFilePage, rows); // paginate the data
    setProcessingFiles(paginatedData.paginated_data); // update the processing files state
    setTotalProcessingPages(paginatedData.totalPages); // update the total pages state
  };
  // function to format forms data for display

  const formatFormsTable = (forms) => {
    var paginatedData = paginateTable(forms, formPage, rows); // paginate the data
    setParsedForms(paginatedData.paginated_data); // update the parsed forms state
    setTotalFormPages(paginatedData.totalPages); // update the total pages state
  };
  // function to format exported forms data for display

  const formatExportedForms = (exportedForms) => {
    var paginatedData = paginateTable(exportedForms, formPageExported, rows); // paginate the data
    setExportedForms(paginatedData.paginated_data); // update the exported forms state
    setTotalExportedPages(paginatedData.totalPages); // update the total pages state
  };
  // function to format deleted forms data for display

  const formatDeletedForms = (deletedForms) => {
    var paginatedData = paginateTable(deletedForms, formPageDeleted, rows); // paginate the data
    setDeletedForms(paginatedData.paginated_data); // update the deleted forms state
    setTotalDeletedPages(paginatedData.totalPages); // update the total pages state
  };

  // function to format receipt data for display

  const formatReceiptData = (receiptData) => {
    var paginatedData = paginateTable(receiptData, receiptPage, rows); // paginate the data
    setReceiptVendorDetails(paginatedData.paginated_data); // update the receipt vendor details state
    setTotalReceiptPages(paginatedData.totalPages); // update the total pages state
  };
  // function to handle sort option selection

  const handleSortOptionSelect = (option) => {
    var activeVendorIndex = vendorHTML.findIndex(
      (vendor) => vendor.isAccordianOpen == true
    );

    handleCloseSort(); // close the sort modal
    setActiveVendor(activeVendorIndex); // set the active vendor index
    setSortBy(option); // update the sort by state
    if (docType === "INVOICE") {
      if (invoiceStatus === "INVOICE_DELETE_REJECTED") {
        getRejectedDeleteFiles(null, null, option.value, ""); // fetch rejected files with the selected sort option
      }
    }
  };
  // function to handle sliding to the left

  const handleSlideLeft = (event) => {
    event.stopPropagation();
    if (docType === "INVOICE") {
      var navwidth = $("#invo_nav"); // get the invoice navigation element
    }

    navwidth.scrollLeft(navwidth.scrollLeft() - 10); // scroll left by 10 pixels
  };
  // function to handle sliding to the right

  const handleSlideRight = (event) => {
    event.stopPropagation();
    if (docType === "INVOICE") {
      var navwidth = $("#invo_nav"); // get the invoice navigation element
    }
    navwidth.scrollLeft(navwidth.scrollLeft() + 10); // scroll right by 10 pixels
  };
  // function to handle page change

  const handlePageChange = async (event, value) => {
    event.stopPropagation();
    if (invoiceStatus == "INVOICE_PROCESSING") {
      setProcessingFilePage(value); // update the processing file page state
    } else {
      setPage(value); // update the page state
    }
  };
  // function to show file image

  const showFileImage = (data) => {
    setFilename(data.original_file_name); // update the filename state
    setFileId(data.id); // update the file id state
  };
  // function to handle receipt checkbox

  const handleReceiptCheck = (event) => {
    let tempArr = [...selectedReceiptIds]; // create a temporary array for selected receipt ids
    if (event.target.checked) {
      setExcelBtnR(event.target.checked); // enable the excel button
      setSelectedReceipts(selectedReceipts + 1); // increment the selected receipts count
      tempArr.push(parseInt(event.target.id)); // add the selected receipt id to the temporary array
      setSelectedReceiptIds(tempArr); // update the selected receipt ids state
      setReceiptId(parseInt(event.target.id)); // update the receipt id state
    } else {
      for (var i = 0; i < selectedReceiptIds.length; i++) {
        if (selectedReceiptIds[i] === parseInt(event.target.id)) {
          selectedReceiptIds.splice(i, 1); // remove the selected receipt id from the temporary array
        }
      }
      if (selectedReceiptIds.length == 0) {
        setExcelBtnR(false); // disable the excel button if no receipt is selected
      }
    }
  };
  // function to perform necessary actions on page load

  const myFunction = async () => {
    if (!authToken) {
      // if user is not authenticated
      props.history.replace({ pathname: "/Login" }); // redirect to login page
    }

    var loggedInUserEmail = fetchLoggedInUserEmail(); // fetch logged in user email
    setEmail(loggedInUserEmail); // update the email state
    if (docType == "INVOICE") {
      // perform actions specific to invoice type
    }
  };
  // function to set the year set for the year dropdown

  const setYearset = async () => {
    var date = new Date();
    var registerYear = await localStorage.getItem("register_year"); // get register year from local storage
    if (!registerYear) {
      registerYear = await sessionStorage.getItem("register_year"); // get register year from session storage if not found in local storage
    }
    var yearSet = [];
    for (let i = registerYear - 1; i < date.getFullYear(); i++) {
      // loop through years from register year - 1 to current year
      yearSet.push(i + 1); // add year to year set
    }
    setYears(yearSet); // update the years state
  };
  // effect hook to call myFunction on status change

  useEffect(() => {
    myFunction();
  }, [status]);
  // effect hook to fetch processed files every 7 seconds if processing files exist

  useEffect(() => {
    if (processingFiles.length > 0) {
      const interval = setInterval(() => {
        getProcessedFiles();
      }, 7000);
      return () => clearInterval(interval);
    }
  }, [processingFiles]);
  // effect hook to clear date error message after 3 seconds

  useEffect(() => {
    if (dateErrMessage) {
      setTimeout(() => {
        setDateErrMessage("");
      }, 3000);
    }
  }, [dateErrMessage]);
  // effect hook to perform actions based on invoice status

  useEffect(() => {
    if (invoiceStatus) {
      if (invoiceStatus === "INVOICE_DELETE_REJECTED") {
        getRejectedDeleteFiles(null, null, null, ""); // fetch rejected delete files
      } else {
        if (invoiceStatus === "INVOICE_PROCESSING") {
          getProcessingFiles(); // fetch processing files
        } else {
          if (invoiceStatus === "INVOICE_REJECTED") {
            getRejectedInvoices(null, null); // fetch rejected invoices
          } else {
            getVendor(null, null); // fetch vendors
          }
        }
      }
    }
  }, [invoiceStatus]);
  // effect hook to handle search input blur

  useEffect(() => {
    if (searchFocused && searchCounter > 0) {
      // if search is focused and counter is greater than 0
      searchInput.current.blur(); // blur search input
      if (searchAttempt) {
        // if search attempt is made
        if (invoiceStatus === "INVOICE_DELETE_REJECTED") {
          getRejectedDeleteFiles(null, null, null, ""); // fetch rejected delete files
        } else {
          getVendor(null, null); // fetch vendors
        }
        setSearchAttempt(false); // reset search attempt flag
      }
    }
  }, [searchFocused, search, fileSearch, vendorSearch]);
  // effect hook to handle sort and active vendor change

  useEffect(() => {
    if (sortBy.value !== null && activeVendor !== null) {
      // if sort value and active vendor exist
      if (activeVendor > -1) {
        getVendorDetails(vendorHTML[activeVendor]); // fetch vendor details for active vendor
      } else {
        getVendor(null, null, sortBy.value, null); // fetch vendors with sort value
      }
    } else if (invoiceStatus === "INVOICE_REJECTED") {
      // if invoice is rejected
      getVendor(null, null, sortBy.value, null); // fetch vendors with sort value
    }
  }, [sortBy, activeVendor]);
  // effect hook to format invoice table or rejected html based on page change

  useEffect(() => {
    if (invoiceStatus == "INVOICE_REJECTED") {
      formatRejectedHtml(fullRejectedHtml); // format rejected html
    } else {
      formatInvoiceTable(fullVendorDetails); // format invoice table
    }
  }, [page]);
  // effect hook to format parsed forms table on form page change

  useEffect(() => {
    formatFormsTable(fullParsedForms);
  }, [formPage]);
  // effect hook to format exported forms table on form page exported change

  useEffect(() => {
    formatExportedForms(fullExportedForms);
  }, [formPageExported]);
  // effect hook to format deleted forms table on form page deleted change

  useEffect(() => {
    formatDeletedForms(fullDeletedForms);
  }, [formPageDeleted]);
  // effect hook to format receipt data table on receipt page change

  useEffect(() => {
    formatReceiptData(fullReceiptVendorDetails);
  }, [receiptPage]);
  // effect hook to format processing files table on processing file page change

  useEffect(() => {
    formatProcessingFiles(fullProcessingFiles);
  }, [processingFilePage]);
  // effect hook to handle date range change

  useEffect(() => {
    var openListIndex = vendorHTML.findIndex(
      (vendor) => vendor.isAccordianOpen === true
    ); // get index of open vendor list
    if (openListIndex > -1) {
      getVendorDetails(vendorHTML[openListIndex]); // fetch vendor details for open vendor list
    } else {
      if (dateRange.from !== null && dateRange.to !== null) {
      }
    }
  }, [dateRange]);
  // effect hook to set days, years, and user id on component mount

  useEffect(() => {
    let days = [];
    const date = new Date();

    for (let i = 1; i < 32; i++) {
      // loop through days 1-31
      if (i < 10) days.push(`0${i}`); // add leading zero for days less than 10
      else days.push(i);
    }

    setDays(days); // update days
    setYearset();
    var user = localStorage.getItem("user_id");
    if (!user) {
      var user = sessionStorage.getItem("user_id");
    }
    setUserId(user);
  }, []);
  // Function to get the value of section_active from localStorage or sessionStorage

  const asyncLocalStorage = () => {
    return Promise.resolve().then(function () {
      var sectActive = localStorage.getItem("section_active");
      if (!sectActive) {
        sectActive = sessionStorage.getItem("section_active");
      }
      return sectActive;
    });
  };
  // Function to handle tab change

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  // Function to download a single xls file

  const handleSingleXlsDownload = (fileId) => {
    var xlsUrl = `${EXTRICATOR_BASE_URL}/export_to_excel/?id=${fileId}&ogi=${ogi}`;
    window.open(xlsUrl);
    getVendor(null, null);
  };
  // Function to close the profile menu

  const closeProfileMenu = () => {
    if (profileMenuShow || $(".dropdown-menu").hasClass("show")) {
      setProfileMenuShow(false);
      $(".dropdown-menu").removeClass("show");
    }
  };
  // Function to open the navigation menu

  function openNav() {
    document.getElementById("myNav").style.height = "100%";
  }
  // Function to close the navigation menu

  function closeNav() {
    document.getElementById("myNav").style.height = "0%";
  }
  // Function to add an invoice

  const addInvoice = (event) => {
    let fileData = null;
    if (event.target.files.length) {
      fileData = event.target.files;
      const formData = new FormData();
      const checkNoPagesData = new FormData();

      for (let i = 0; i < fileData.length; i++) {
        formData.append("file", fileData[i]);
        checkNoPagesData.append("file", fileData[i]);

      }
      checkNoPagesData.append("user", sessionStorage.getItem('user_id'));
      checkNoPagesData.append("company_name", ogi);
      checkNoPagesData.append("plan_name", planName);

       axios({
        method: "post",
        url: `${EXTRICATOR_BASE_URL}/check-max-number-of-pages/`,
        data: checkNoPagesData,
        headers: {
          Authorization: `Bearer ${authToken}`,
        },
      })
        .then(function (response) {
          if (response.data.status) {
            setToastColor("success");
            setSuccessMsg(response.data.message);
            setSnackOpen(true);
            formData.append("file_type", docType);
            formData.append("ogi", ogi);
            formData.append("plan_name", planName);
            setInvoices(formData);
            setCheckMaxNoPages(false)
            setFileLength(fileData.length);
            setFileMessage("");
          }
          else{   
            setFileMessage(response.data.message);
            setSnackOpen(true);
            setInvoices(false);
            setCheckMaxNoPages(true)     
            setFileLength(0);
            setUploaded(false);
            setFileType("");
          }
        });
      
    }
  };
  // A function that handles showing a file preview

  const handleShowPreview = (fileDetail) => {
    previewTime = setTimeout(() => {
      showFileImage(fileDetail);
    }, [2000]);
  };
  // A function that handles hiding a file preview

  const handleHidePreview = () => {
    setFilename("");
    setFileId("");
    clearTimeout(previewTime);
  };
  // A function that gets processed files from the server

  const getProcessedFiles = () => {
    var processingFilesTemp = [...processingFiles];
    let statusFiles = processingFilesTemp.map((item) => item.id);
    axios({
      method: "GET",
      url: `${EXTRICATOR_BASE_URL}/processed_files/?ogi=${ogi}&file_id=${statusFiles.join(
        ","
      )}`,
      headers: {
        "Content-Type": "multipart/form-data",
        Authorization: `Bearer ${authToken}`,
      },
    })
      .then(function (response) {
        formatProcessingFiles(response.data.file_data);
        setFullProcessingFiles(response.data.file_data);
      })
      .catch(function (error) {
        if (error.response) {
          if (error.response.status === 401) {
            handleLogout(props.history);
          }
          if (error.response.data.non_field_errors) {
            if (
              error.response.data.non_field_errors[0].includes(
                "Kindly pay the bill"
              )
            ) {
              setToastColor("error");
              setSuccessMsg(error.response.data.non_field_errors[0]);
              setSnackOpen(true);
            } else {
              checkOrganizationError(
                error.response.data.non_field_errors[0],
                props.history
              );
            }
          }
        }
      });
  };
  // Fetches processing files from the server

  const getProcessingFiles = () => {
    setLoading(true);
    // Send GET request to server

    axios({
      method: "GET",
      url: `${EXTRICATOR_BASE_URL}/processing_files/?file_type=invoice&ogi=${ogi}`,
      headers: {
        "Content-Type": "multipart/form-data",
        Authorization: `Bearer ${authToken}`,
      },
    })
      .then(function (response) {
        // Format and update processing files state

        formatProcessingFiles(response.data.file_data);
        setFullProcessingFiles(response.data.file_data);
        setLoading(false);
      })
      .catch(function (error) {
        if (error.response) {
          if (error.response.status === 401) {
            // If unauthorized, redirect to login page

            handleLogout(props.history);
          }
          if (error.response.data.non_field_errors) {
            if (
              error.response.data.non_field_errors[0].includes(
                "Kindly pay the bill"
              )
            ) {
              // Display error message to user

              setToastColor("error");
              setSuccessMsg(error.response.data.non_field_errors[0]);
              setSnackOpen(true);
            } else {
              // Check for organization error and redirect accordingly

              checkOrganizationError(
                error.response.data.non_field_errors[0],
                props.history
              );
            }
          }
        }
        setLoading(false);
      });
  };

  const checkUserSubscription = () => {
  const reqData = {
    user: user_id,
    company_name: ogi,
    plan_name: planName 
  };
  axios({
    method: "post",
    url: `${EXTRICATOR_BASE_URL}/check-subscription/`,
    data: reqData,
    headers: {
      Authorization: `Bearer ${authToken}`,
    },
  })
    .then(function (response) {
      if (response.data.status) {
        setToastColor("success");
        setSuccessMsg(response.data.message);
        setSnackOpen(true);

        setUploadModal(true)
      }
      else{
        setToastColor("error");
        setSuccessMsg(response.data.message);

        setSnackOpen(true);
        setUploadModal(false)
      }
    });
}
  // Uploads invoice files to the server
  const uploadInvoice = () => {
    if (invoices) {
      setCheckMaxNoPages(false)
      setFileUploading(true);
      axios({
        method: "post",
        url: `${EXTRICATOR_BASE_URL}/file_upload/`,
        data: invoices,
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: `Bearer ${authToken}`,
        },
      })
        .then(function (response) {
          // Display success message

          setToastColor("success");
          setSuccessMsg(response.data.message);
          setSnackOpen(true);
          setExpanded(false);
          setUpdateTable(!updateTable);
          setUploaded(true);
          setFileUploading(false);
          setUploadModal(false);
          setInvoices(false);
          setInvoiceStatus("INVOICE_PROCESSING");
          setActiveTab("INVOICE_PROCESSING");
          setFileMessage("");
          setFileLength(0);
        })
        .catch(function (error) {
          if (error.response) {
            if (error.response.data && error.response.data.non_field_errors) {
              // Display error message

              setToastColor("error");
              setSuccessMsg(error.response.data.non_field_errors[0]);
              setSnackOpen(true);
              setFileUploading(false);
              setUploadModal(false);
              setInvoices(false);
              setFileMessage("");
              setFileLength(0);
            }
          }
        });
    } else {
      setCheckMaxNoPages(true)
      setFileMessage("Please select a file to proceed.");
    }
  };

  const confirmDelete = (fileIds) => {
    // Prepare request body based on the type of `fileIds`

    var idTemp = null,
      reqBody = {};
    if (typeof fileIds !== "number") {
      idTemp = fileIds.map((item) => item.id);
      reqBody = { file_id: idTemp.join(","), ogi: ogi };
    } else {
      idTemp = fileIds;
      reqBody = { file_id: idTemp, ogi: ogi };
    }
    // Get the list of vendors and check if any vendor is selected

    let vendorsTemp = [...vendorHTML];
    let checkSelectedVendor = vendorsTemp.map((vendor) => vendor.isSelected);

    axios({
      method: "post",
      url: `${EXTRICATOR_BASE_URL}/delete_file/?ogi=${ogi}`,
      data: reqBody,
      headers: { Authorization: `Bearer ${authToken}` },
    })
      .then((res) => {
        // Handle UI updates based on the current invoice status

        if (invoiceStatus === "INVOICE_REJECTED") {
          // If invoice is rejected, remove the deleted file from rejected HTML

          var rejectedHTMLTemp = [...fullRejectedHtml];
          rejectedHTMLTemp = rejectedHTMLTemp.filter(
            (item) => item.id !== fileIds
          );
          formatRejectedHtml(rejectedHTMLTemp);
          setFullRejectedHtml(rejectedHTMLTemp);
          getRejectedInvoices(null, null);
        } else {
          // If invoice is not rejected, remove the deleted file from vendor or invoice details

          getVendor(null, null);
          if (checkSelectedVendor.includes(true)) {
            vendorsTemp = vendorsTemp.filter(
              (vendor) => vendor.isSelected == false
            );
            setVendorHTML(vendorsTemp);
          } else {
            var vendorDetailsHTMLTemp = [...vendorDetailsHTML];
            for (let i = 0; i < idTemp.length; i++) {
              vendorDetailsHTMLTemp = vendorDetailsHTMLTemp.filter(
                (file) => file.id !== idTemp[i]
              );
            }
            formatInvoiceTable(vendorDetailsHTMLTemp);
          }
        }
        // Show success message

        setDeleteFileProgress(false);
        setDeletableFileId(null);
        setToastColor("success");
        setSuccessMsg(res.data.message);
        setSnackOpen(true);
      })
      .catch((error) => {
        // Handle errors and show error message

        if (error.response) {
          if (error.response.data && error.response.data.non_field_errors) {
            setToastColor("error");
            setSuccessMsg(error.response.data.non_field_errors[0]);
            setSnackOpen(true);
            checkOrganizationError(
              error.response.data.non_field_errors[0],
              props.history
            );
          }
        }
        setDeletableFileId(null);
        setDeleteFileProgress(false);
      });
  };
  // Handle calendar click event

  const onCalandarClick = (event) => {
    // Set anchor element to current target

    setAnchorEl(event.currentTarget);
    // Toggle calendar state

    openCalander(!calander);
    // Set tooltip state to calendar state

    setTooltip(calander);
  };
  // Handle calendar close event

  const handleCalandarClose = (flag) => {
    // Set anchor element to null

    setAnchorEl(null);
    if (flag == "reset" || dateErrMessage) {
      // Reset 'from' and 'to' dates and clear tooltip date and date range

      setFrom({
        ...from,
        day: null,
        month: null,
        year: null,
      });

      setTo({
        ...to,
        day: null,
        month: null,
        year: null,
      });
      setTooltipDate("");
      setDateRange({ from: null, to: null });
      setDateErrMessage("");
      // If document type is 'INVOICE', get rejected delete files or vendor data

      if (docType === "INVOICE") {
        if (invoiceStatus == "INVOICE_DELETE_REJECTED") {
          getRejectedDeleteFiles(null, null, null, "");
        } else {
          getVendor(null, null);
        }
      }
    }
    if (tooltipDate) {
      // Set tooltip state to true if tooltip date is available

      setTooltip(true);
    }
  };
  /**
   * Fetches vendor details from the server.
   * @param {string} from - Starting date of the date range.
   * @param {string} to - Ending date of the date range.
   * @param {string} sortBy - Sorting criteria for the vendor details.
   * @param {string} searchKeywords - Search keywords for the vendor details.
   */
  const getVendor = async (
    from = null,
    to = null,
    sortBy = null,
    searchKeywords = null
  ) => {
    setLoading(true);
    setReceiptPage(1);
    setPage(1);
    axios({
      method: "GET",
      url: `${EXTRICATOR_BASE_URL}/vendor_details/?file_type=${docType}&status=${
        invoiceStatus.split("_")[1]
      }&search=${searchKeywords}&from=${from}&to=${to}&search_on=${searchSubject}&type=${sortBy}&ogi=${ogi}`,
      headers: {
        "Content-Type": "multipart/form-data",
        Authorization: `Bearer ${authToken}`,
      },
    })
      .then(function (response) {
        var vendors = response.data;
        vendors = vendors.map((item) => ({
          ...item,
          isSelected: false,
          isAccordianOpen: false,
        }));
        setVendorHTML(vendors);
        setVendorsLength(vendors.length);
        setLoading(false);
      })
      .catch(function (error) {
        setLoading(false);
        if (error.response) {
          if (error.response.status === 401) {
            handleLogout(props.history);
          }
          if (error.response.data.non_field_errors) {
            if (
              error.response.data.non_field_errors[0].includes(
                "Kindly pay the bill"
              )
            ) {
              setToastColor("error");
              setSuccessMsg(error.response.data.non_field_errors[0]);
              setSnackOpen(true);
            } else {
              checkOrganizationError(
                error.response.data.non_field_errors[0],
                props.history
              );
            }
          }
        }
      });
  };
  // Get rejected invoices based on search filters

  const getRejectedInvoices = async (
    from = null,
    to = null,
    sortBy = null,
    searchKeywords = null
  ) => {
    setLoading(true);
    let authToken = await fetchAuthToken();
    // Send API request to get rejected invoices

    axios({
      method: "POST",
      url: `${EXTRICATOR_BASE_URL}/rejected/?file_type=${docType}&from=${from}&to=${to}&type=${sortBy}&search=${searchKeywords}&ogi=${ogi}`,
      headers: {
        "Content-Type": "multipart/form-data",
        Authorization: `Bearer ${authToken}`,
      },
    })
      .then(function (response) {
        // Format rejected files data to include additional properties

        var rejectedFiles = response.data;
        rejectedFiles = rejectedFiles.map((item) => ({
          ...item,
          isSelected: false,
        }));

        formatRejectedHtml(rejectedFiles);
        setFullRejectedHtml(rejectedFiles);
        setLoading(false);
      })
      .catch(function (error) {
        setLoading(false);
        if (error.response.data.non_field_errors) {
          if (
            error.response.data.non_field_errors[0].includes(
              "Kindly pay the bill"
            )
          ) {
            setToastColor("error");
            setSuccessMsg(error.response.data.non_field_errors[0]);
            setSnackOpen(true);
          } else {
            checkOrganizationError(
              error.response.data.non_field_errors[0],
              props.history
            );
          }
        }
      });
  };
  // Check if any vendor filter is currently active

  const getFiltersDisabled = () => {
    var sortEnabled = vendorHTML.findIndex(
      (vendor) => vendor.isAccordianOpen == true
    );
    if (sortEnabled > -1) {
      return false;
    }
    return true;
  };

  const getVendorDetails = (vendor) => {
    setVendorDetails([]);
    var exportBufferTemp = [...exportBuffer];
    // Define variables for date range and sort type
    var from = dateRange.from,
      to = dateRange.to,
      type = sortBy.value;
    setLoading(true);
    // Fetch vendor details from API

    axios({
      method: "GET",
      url: `${EXTRICATOR_BASE_URL}/vendor_file_details/?id=${
        vendor.id
      }&from=${from}&to=${to}&type=${type}&status=${
        invoiceStatus.split("_")[1]
      }&search=${fileSearch.length ? fileSearch : null}&ogi=${ogi}`, //search.length?search:null
      headers: {
        "Content-Type": "multipart/form-data",
        Authorization: `Bearer ${authToken}`,
      },
    })
      .then(function (response) {
        var vendorDetails = response.data;
        // Set 'isSelected' property for each vendor detail item based on parent vendor's state

        if (vendor.isSelected && vendor.isAccordianOpen) {
          vendorDetails = vendorDetails.map((item) => ({
            ...item,
            isSelected: true,
          }));
        } else {
          vendorDetails = vendorDetails.map((item) => ({
            ...item,
            isSelected: false,
          }));
        }
        // Format vendor details for display in invoice table
        formatInvoiceTable(vendorDetails);
        // Set full vendor details and reset pagination

        setFullVendorDetails(vendorDetails);
        setPage(1);
        setLoading(false);
      })
      .catch((error) => {
        // Handle API error response

        setLoading(false);
        if (error.response) {
          if (error.response.data.non_field_errors) {
            if (
              error.response.data.non_field_errors[0].includes(
                "Kindly pay the bill"
              )
            ) {
              setToastColor("error");
              setSuccessMsg(error.response.data.non_field_errors[0]);
              setSnackOpen(true);
            } else {
              checkOrganizationError(
                error.response.data.non_field_errors[0],
                props.history
              );
            }
          }
        }
      });
  };
  /**
   * Fetches rejected Delete invoice files from the server, optionally filtered by date range, sort type, and search keywords.
   * @param {string|null} from - The start date of the date range to filter by in YYYY-MM-DD format, or null to not filter by date range.
   * @param {string|null} to - The end date of the date range to filter by in YYYY-MM-DD format, or null to not filter by date range.
   * @param {string|null} sortBy - The sort type to use, or null to not sort. Accepted values: "name", "date".
   * @param {string|null} searchKeywords - The keywords to search for in the file names, or null to not search.
   * @throws {Error} If there is an error in the HTTP request or response, or if the user is not authorized.
   * @returns {Promise} A promise that resolves with an array of rejected invoice files.
   */
  const getRejectedDeleteFiles = (
    from = null,
    to = null,
    sortBy = null,
    search = ""
  ) => {
    axios({
      method: "GET",
      url: `${EXTRICATOR_BASE_URL}/rejected_delete_files/?&from=${from}&to=${to}&file_type=${docType}&type=${sortBy}&search=${
        search.length ? search : null
      }&ogi=${ogi}`,
      headers: {
        Authorization: `Bearer ${authToken}`,
      },
    })
      .then(function (response) {
        //handle success
        setRejectedDeleteFiles(response.data);
      })
      .catch(function (error) {
        //handle error
        setLoading(false);
        if (error.response.data.non_field_errors) {
          if (
            error.response.data.non_field_errors[0].includes(
              "Kindly pay the bill"
            )
          ) {
            setToastColor("error");
            setSuccessMsg(error.response.data.non_field_errors[0]);
            setSnackOpen(true);
          } else {
            checkOrganizationError(
              error.response.data.non_field_errors[0],
              props.history
            );
          }
        }
      });
  };

  const handleDeletedInvoiceTabChange = (event, newValue) => {
    // Reset export buffer and set new invoice status

    setExportBuffer([]);
    if (newValue === 0) {
      setInvoiceStatus("INVOICE_DELETE");
    } else {
      setInvoiceStatus("INVOICE_DELETE_REJECTED");
    }
    setDeletedInvoiceTab(newValue);
  };

  const handleParsedInvoiceTabChange = (event, newValue) => {
    // Reset state values when switching tabs

    setVendorDetails([]);
    setFullVendorDetails([]);
    setExportBuffer([]);
    // Set invoice status based on selected tab

    if (newValue === 0) {
      setInvoiceStatus("INVOICE_PARSED");
    } else {
      setInvoiceStatus("INVOICE_EXPORTED");
    }
    setParsedInvoiceTab(newValue);
  };

  const handleFormCheck = (event) => {
    let tempArr = [...selectedFormIds];
    if (event.target.checked) {
      // Set Excel button to be enabled

      setExcelBtn(event.target.checked);
      setSelectedForms(selectedForms + 1);
      tempArr.push(parseInt(event.target.id));
      setSelectedFormIds(tempArr);
      setFormId(parseInt(event.target.id));
    } else {
      setSelectedForms(selectedForms - 1);
      // Remove unchecked form from selected form IDs

      for (var i = 0; i < selectedFormIds.length; i++) {
        if (selectedFormIds[i] === parseInt(event.target.id)) {
          selectedFormIds.splice(i, 1);
        }
      }
      // Disable Excel button if no forms are selected

      if (selectedFormIds.length == 0) {
        setExcelBtn(false);
      }
    }
  };

  const handlePaginationCallback = (focused) => setGoInputFocused(focused);

  const formatDate = (data) => {
    // Format date to be in the format "YYYY-MM-DD"

    let data1 = data.slice(0, 10);
    return data1;
  };

  const setCounterInitial = () => {
    // Reset counter to initial value

    setCounter(0);
    return counterValue;
  };
  // Function to handle change in "from" date inputs

  const handleFormChange = (event, value) => {
    // Update "from" state with new value for current input

    setFrom({
      ...from,
      [event.target.name]: event.target.value,
    });
    // If the current input is "day" and the value is greater than 28...

    if (event.target.name == "day") {
      if (event.target.value > 28) {
        // If the value is 31, set "fromMonths" to only months with 31 days

        if (event.target.value == 31) {
          let monthsTemp = monthsSet.filter((item) => item.total_days == 31);
          setFromMonths(monthsTemp);
        } else {
          // Otherwise, set "fromMonths" to all months except February

          let monthsTemp = monthsSet.filter((item) => item.month !== "Feb");
          setFromMonths(monthsTemp);
        }
      } else {
        // Otherwise, set "fromMonths" to all months

        setFromMonths(monthsSet);
      }
    }
  };
  // Function to handle change in "to" date inputs

  const handleToChange = (event, value) => {
    // Update "to" state with new value for current input

    setTo({
      ...to,
      [event.target.name]: event.target.value,
    });
    if (event.target.name == "day") {
      // If the current input is "day" and the value is greater than 28...

      if (event.target.value > 28) {
        let monthsTemp = monthsSet.filter((item) => item.month !== "Feb");
        setToMonths(monthsTemp);
        if (event.target.value == 31) {
          // If the value is 31, set "toMonths" to only months with 31 days

          let monthsTemp = monthsSet.filter((item) => item.total_days == 31);
          setToMonths(monthsTemp);
        } else {
          // Otherwise, set "toMonths" to all months except February

          let monthsTemp = monthsSet.filter((item) => item.month !== "Feb");
          setToMonths(monthsTemp);
        }
      } else {
        // Otherwise, set "toMonths" to all months

        setToMonths(monthsSet);
      }
    }
  };

  const handleSearch = (e) => {
    e.preventDefault();
    handleCalandarClose(null);
    setSearchAttempt(true);
    let fromDate;
    let toDate;
    // Format the selected from and to dates

    if (from.month && from.day && from.year && to.month && to.day && to.year) {
      if (from.month < 10) {
        fromDate = `0${from.month} ${from.day} ${from.year}`;
      } else {
        fromDate = `${from.month} ${from.day} ${from.year}`;
      }
      if (to.month < 10) {
        toDate = `0${to.month} ${to.day} ${to.year}`;
      } else {
        toDate = `${to.month} ${to.day} ${to.year}`;
      }
      // Make API call to fetch vendor or vendor details based on whether a vendor is selected or not

      if (formatDate && toDate) {
        if (!vendorChecked) {
          getVendor(fromDate, toDate, null, null, null);
        } else {
          getVendorDetails(id, fromDate, toDate, null);
        }
      }
      // Set tooltip to display the selected date range

      setTooltipDate(
        `${from.day}/${from.month}/${from.year} to ${to.day}/${to.month}/${to.year}`
      );
      setTooltip(true);
    } else {
      // If dates are not selected, make API call based on the selected tab and search keyword

      if (invoiceStatus === "INVOICE_DELETE_REJECTED") {
        getRejectedDeleteFiles(null, null, null, search);
      } else {
        if (!vendorChecked) {
          if (searchSubject == "file") {
            getVendor(null, null, null, fileSearch);
          } else {
            getVendor(null, null, null, vendorSearch);
          }
        } else {
          getVendorDetails(id);
        }
      }
    }
  };

  const handleDateSearch = async (date, from) => {
    setDateRange(date);
    // Set tooltip to display the selected date range

    setTooltipDate(
      `${from.day}/${from.month}/${from.year} to ${to.day}/${to.month}/${to.year}`
    );
    setTooltip(true);
    handleCalandarClose(null);
    // Make API call to fetch vendor or vendor details based on whether a vendor is selected or not

    if (docType === "INVOICE") {
      if (invoiceStatus === "INVOICE_DELETE_REJECTED") {
        getRejectedDeleteFiles(date.from, date.to, null, "");
      } else {
        getVendor(date.from, date.to);
      }
    }
  };

  const filterSearch = (e) => {
    e.preventDefault();
    setSearch("");
    setVendorSearch("");
    setFileSearch("");
    let todayDate = new Date();
    let fromDate;
    let toDate;
    // Check if both from and to dates are selected

    if (from.month && from.day && from.year && to.month && to.day && to.year) {
      // Format the from and to dates

      if (from.month < 10) {
        fromDate = `0${from.month} ${from.day} ${from.year}`;
      } else {
        fromDate = `${from.month} ${from.day} ${from.year}`;
      }
      if (to.month < 10) {
        toDate = `0${to.month} ${to.day} ${to.year}`;
      } else {
        toDate = `${to.month} ${to.day} ${to.year}`;
      }

      var date = {
        from: fromDate,
        to: toDate,
      };
      var fromDateString = date.from.split(" ").join("/");
      var toDateString = date.to.split(" ").join("/");

      var fromDateObject = new Date(fromDateString);
      var toDateObject = new Date(toDateString);
      // Check if from date is before or equal to to date

      if (fromDateObject <= toDateObject) {
        if (to.year == todayDate.getFullYear()) {
          if (to.month <= 1 + todayDate.getMonth()) {
            if (to.month == 1 + todayDate.getMonth()) {
              if (to.day <= todayDate.getDate()) {
                handleDateSearch(date, from);
              } else {
                setDateErrMessage("You can not select a future date");
              }
            } else {
              handleDateSearch(date, from);
            }
          } else {
            setDateErrMessage("You can not select a future date");
          }
        } else {
          handleDateSearch(date, from);
        }
      } else {
        setDateErrMessage(
          "Start date should be less than or equal to End date"
        );
      }
    }
  };

  const getFileCounter = () => {
    // Count selected files based on invoice status
    if (invoiceStatus == "INVOICE_REJECTED") {
      var temp = fullRejectedHtml.map((item) => item.isSelected);
      temp = temp.filter((item) => item == true);
      return {
        isVisible: temp.length > 0 ? true : false,
        fileCounter: temp.length,
      };
    } else {
      var temp = fullVendorDetails.map((item) => item.isSelected);
      temp = temp.filter((item) => item == true);
      return {
        isVisible: temp.length > 0 ? true : false,
        fileCounter: temp.length,
      };
    }
  };
  /**
   * Returns an object with the file counter and its visibility for rejected invoices
   * @returns {Object} Object containing file counter and visibility
   */
  const getRejectedInvoiceCounter = () => {
    var vendorHTMLTemp = [...rejectedHTML];
    var temp = vendorHTMLTemp.map((item) => item.isSelected);
    temp = temp.filter((item) => item == true);
    return {
      isVisible: temp.length > 0 ? true : false,
      fileCounter: temp.length,
    };
  };
  /**
   * Handles mapping multiple rejected files to a selected model
   */
  const handleMapMultipleRejectedFiles = () => {
    var selectedObjSet = rejectedHTML.filter((item) => item.isSelected == true);
    var selectedFiles = selectedObjSet.map((item) => item.id);
    setLoading(true);
    axios({
      method: "post",
      url: `${EXTRICATOR_BASE_URL}/mapping_multiple_files/`,
      data: {
        file_id: selectedFiles.join(","),
        model_id: String(selectedModel.id),
        ogi: ogi,
      },
      headers: { Authorization: `Bearer ${authToken}` },
    })
      .then(function (response) {
        setToastColor("success");
        setSuccessMsg(response.data.message);
        setSnackOpen(true);
        setLoading(false);
        getRejectedInvoices(null, null);
      })
      .catch(function (error) {
        if (error.response) {
          if (error.response.data && error.response.data.non_field_errors) {
            setToastColor("error");
            setSuccessMsg(error.response.data.non_field_errors[0]);
            setSnackOpen(true);
            setLoading(false);
          }
        }
      });
  };
  /**
   * Handles mapping multiple explicit rejected files to rejected
   */
  const handleMapMultipleExplicitRejectedFiles = () => {
    var selectedFiles = exportBuffer.map((item) => item.id);

    setLoading(true);
    axios({
      method: "get",
      url: `${EXTRICATOR_BASE_URL}/reject_document/?ogi=${ogi}&file_id=${selectedFiles.join(
        ","
      )}`,
      headers: { Authorization: `Bearer ${authToken}` },
    })
      .then(function (response) {
        setToastColor("success");
        setSuccessMsg(response.data.message);
        setSnackOpen(true);
        setLoading(false);
        getRejectedInvoices(null, null);
      })
      .catch(function (error) {
        if (error.response) {
          if (error.response.data && error.response.data.non_field_errors) {
            setToastColor("error");
            setSuccessMsg(error.response.data.non_field_errors[0]);
            setSnackOpen(true);
            setLoading(false);
          }
        }
      });
  };
  const getVendorCounter = () => {
    // Get list of selected vendors

    var temp = vendorHTML.map((item) => item.isSelected);
    temp = temp.filter((item) => item == true);
    return {
      isVisible: temp.length > 0 ? true : false,
      vendorCounter: temp.length,
    };
  };

  const handleDownload = (type) => {
    // Get list of selected vendors or receipt files

    var selectedVendorsSet = vendorHTML.map((item) => {
      if (item.isSelected) {
        return item.id;
      }
      return null;
    });

    selectedVendorsSet = selectedVendorsSet.filter((item) => item !== null);

    if (invoiceStatus === "INVOICE_REJECTED") {
      var selectedFilesSet = vendorHTML.map((item) => {
        if (item.isSelected) {
          return item.id;
        }
        return null;
      });
      selectedFilesSet = selectedFilesSet.filter((item) => item !== null);
      // Open URL to export selected files to Excel and remove selected files from vendorHTML

      var xlsUrl = `${EXTRICATOR_BASE_URL}/export_to_excel/?id=${selectedFilesSet.join(
        ","
      )}&ogi=${ogi}`;
      window.open(xlsUrl);
      var vendorHTMLTemp = [];
      for (let i = 0; i < vendorHTML.length; i++) {
        if (!selectedFilesSet.includes(vendorHTML[i].id)) {
          vendorHTMLTemp.push(vendorHTML[i]);
        }
      }
      setVendorHTML(vendorHTMLTemp);
    } else {
      // Get list of selected receipt files

      var time = null;
      clearTimeout(time);
      var selectedReceiptFilesSet = exportBuffer.map((item) => {
        if (item.isSelected) {
          return item.id;
        }
        return null;
      });

      selectedReceiptFilesSet = selectedReceiptFilesSet.filter(
        (item) => item !== null
      );
      // Open URL to export selected files to Excel and clear exportBuffer after 3 seconds

      var xlsUrl = `${EXTRICATOR_BASE_URL}/export_to_excel/?id=${selectedReceiptFilesSet.join(
        ","
      )}&ogi=${ogi}`;
      window.open(xlsUrl);
      getVendor(null, null);
      time = setTimeout(() => {
        setExportBuffer([]);
      }, 3000);
    }
  };
  // Function to handle batch delete

  const handleBatchDelete = () => {
    // Clear timeout if set

    var time = null;
    clearTimeout(time);
    // Confirm delete
    confirmDelete(exportBuffer);
    // Set timeout to clear export buffer after 2 seconds

    time = setTimeout(() => {
      setExportBuffer([]);
    }, 2000);
  };
  // Function to handle rejected batch delete

  const handleRejectedBatchDelete = () => {
    // Get selected objects and IDs

    var selectedObjSet = fullVendorHtml.filter(
      (item) => item.isSelected == true
    );
    var idSet = selectedObjSet.map((item) => item.id);
    // Confirm delete

    confirmDelete(idSet);
  };
  // Effect hook to update on change in selectedVendor

  useEffect(() => {
    let ssr = selectedVendor.split(",");
  }, [selectedVendor]);
  // Function to handle checkbox click for vendors

  const handleVendorCheckbox = async (event, vendor) => {
    // Prevent event bubbling
    event.stopPropagation();
    // Create copies of state variables

    var vendorHTMLTemp = [...vendorHTML];
    var exportBufferTemp = [...exportBuffer];
    var fullVendorDetailsTemp = [...fullVendorDetails];
    // Find vendor and toggle isSelected property
    var check = 0;
    for (let i = 0; i < vendorHTMLTemp.length; i++) {
      if (vendorHTMLTemp[i].id == vendor.id) {
        check = i;
        vendorHTMLTemp[i].isSelected = !vendorHTMLTemp[i].isSelected;
      } else {
        vendorHTMLTemp[i].isAccordianOpen = false;
        
      }
    }
  
    // Set updated state variables
    await setVendorHTML(vendorHTMLTemp);
     // Find the file in fullVendorDetailsTemp and toggle its isSelected property

    // Set the updated exportBuffer
    // Open accordion and fetch vendor details if isSelected is true
    if (vendorHTMLTemp[check].isAccordianOpen) {
      getVendorDetails(vendor);
      for (let i = 0; i < fullVendorDetailsTemp.length; i++) { 
        exportBufferTemp.push(fullVendorDetailsTemp[i]);   
        }
      }
      const uniqueElements = [];
      const uniqueIdsSet = new Set();
      
      for (const element of exportBufferTemp) {
        if (!uniqueIdsSet.has(element.id)) {
          uniqueElements.push(element);
          uniqueIdsSet.add(element.id);
        }
      }
      
    setExportBuffer(uniqueElements);

    // Fetch export files
    fetchExportFiles(vendor, invoiceStatus);
  };
  // Fetches the list of files to be exported for the selected vendor and updates the export buffer

  const fetchExportFiles = (vendor, docStatus) => {
    // Create a temporary copy of the export buffer to add to

    var exportBufferTemp = [...exportBuffer];
    // Create a count to track how many files have the same vendor

    var sameVendorCount = 0;
    // Define global variables for the date range and sort type
    var from = dateRange.from,
      to = dateRange.to,
      type = sortBy.value;
    // Set the loading state to true

    setLoading(true);
    axios({
      method: "GET",
      url: `${EXTRICATOR_BASE_URL}/vendor_file_details/?id=${
        vendor.id
      }&from=${from}&to=${to}&type=${type}&status=${
        docStatus.split("_")[1]
      }&search=${fileSearch.length ? fileSearch : null}&ogi=${ogi}`,
      headers: {
        "Content-Type": "multipart/form-data",
        Authorization: `Bearer ${authToken}`,
      },
    })
      .then(function (response) {
        // Retrieve the list of export files for the vendor

        var exportFiles = response.data;
        if (vendor.isSelected) {
          // Mark all files as selected if the vendor is selected

          exportFiles = exportFiles.map((item) => ({
            ...item,
            isSelected: true,
          }));
          // Check if any files with the same vendor already exist in the export buffer

          for (let i = 0; i < exportBufferTemp.length; i++) {
            if (exportBufferTemp[i].vendor_id == vendor.id) {
              sameVendorCount++;
            }
          }
          // If no files with the same vendor exist in the export buffer, add the files to the buffer

          if (sameVendorCount < 1) {
            exportBufferTemp = [...exportBufferTemp, ...exportFiles];
            setExportBuffer(exportBufferTemp);
          }
        } else {
          // Mark all files as unselected if the vendor is not selected
          exportFiles = exportFiles.map((item) => ({
            ...item,
            isSelected: false,
          }));
          // Remove all files with the same vendor from the export buffer if the vendor is not selected

          exportBufferTemp = exportBufferTemp.filter(
            (item) => item.vendor_id != vendor.id
          );
          setExportBuffer(exportBufferTemp);
        }
        // Set the loading state to false

        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
        // Handle any errors that occur during the API call

        if (error.response.data.non_field_errors) {
          if (
            error.response.data.non_field_errors[0].includes(
              "Kindly pay the bill"
            )
          ) {
            setToastColor("error");
            setSuccessMsg(error.response.data.non_field_errors[0]);
            setSnackOpen(true);
          } else {
            checkOrganizationError(
              error.response.data.non_field_errors[0],
              props.history
            );
          }
        }
      });
  };
  // Function to close the sort dropdown menu

  const handleCloseSort = () => {
    setAnchorElSort(null);
  };
  // Function to open the sort dropdown menu

  const handleClickSort = (event) => {
    setAnchorElSort(event.currentTarget);
  };
  // Function to close the logout dropdown menu

  const handleCloseLogout = () => {
    setAnchorElLogout(null);
  };
  // Function to open the logout dropdown menu

  const handleClickLogout = (event) => {
    setAnchorElLogout(event.currentTarget);
  };
  // Function to clear the download state variables

  const clearDowload = () => {
    setVendorCounter(0);
    setCounter(0);
    setSelectedVendor("");
    setSelectedVendor("");
  };
  // Function to get the color of a vendor item based on whether it's selected or not

  const getVendorColor = (classes, val) => {
    let temp = selectedVendor.split(",");
    let newArr = temp.map((item) => Number(item));

    if (newArr.includes(val)) {
      return classes.summary_rootSelected;
    } else {
      return classes.summary_root;
    }
  };
  // Function to check whether a file is selected or not

  const getCheckedFiles = (val) => {
    let temp = selectedVendorFiles.split(",");
    let newArr = temp.map((item) => Number(item));

    if (newArr.includes(val)) {
      return true;
    }
    return false;
  };
  // Function to handle the re-authentication flow

  const handleReAuth = (isReAuth) => {
    if (isReAuth) {
      handleLogout(props.history);
    }
  };
  // Handle section click based on new status

  const handleSectionClick = async (newStatus) => {
    if (docType === "INVOICE") {
      if (invoiceStatus !== newStatus && newStatus.includes("INVOICE")) {
        // Set new status

        setInvoiceStatus(newStatus);
        // Clear existing parameters

        handleClearParameters(newStatus);
        // Clear vendor details and HTML

        setVendorDetails([]);
        setVendorHTML([]);
        setFullVendorDetails([]);
        setFullVendorHtml([]);
        setExportBuffer([]);
        setActiveSection(newStatus);
        setProcessingFiles([]);
        // Clear timer for INVOICE_PROCESSING section

        if (newStatus !== "INVOICE_PROCESSING") {
          clearInterval(timerVal);
        }
        // Set search subject based on new status

        if (
          [
            "INVOICE_REJECTED",
            "INVOICE_DELETE_REJECTED",
            "INVOICE_PROCESSING",
          ].includes(newStatus)
        ) {
          setSearchSubject("file");
        }
      }
    }
  };
  // Clear existing parameters

  const handleClearParameters = (newStatus) => {
    // Clear tooltip date, search, vendor search, and file search

    setTooltipDate("");
    setSearch("");
    setVendorSearch("");
    setFileSearch("");
    // Clear active vendor and sort by

    setActiveVendor(null);
    setSortBy({ name: "Sort By", value: null });
    // Reset parsed value if new status is PARSED

    if (newStatus === "PARSED") {
      setParsedValue(0);
    }
    // Clear date range and tab values

    setFrom({
      ...from,
      day: null,
      month: null,
      year: null,
    });

    setTo({
      ...from,
      day: null,
      month: null,
      year: null,
    });
    setDeletedInvoiceTab(0);
    setParsedInvoiceTab(0);
    setDeletedTabValue(0);
    // Set new status and clear vendor HTML and download

    setStatus(newStatus);
    setVendorHTML([]);
    clearDowload();
    setDateRange({ from: null, to: null });
  };
  // Toggle selection of a file in the table

  const toggleFileSelect = (event, fileDetail, vendor) => {
    var vendorHTMLTemp = [...vendorHTML];
    var fullVendorDetailsTemp = [...fullVendorDetails];
    var exportBufferTemp = [...exportBuffer];
    // Find the file in fullVendorDetailsTemp and toggle its isSelected property
    for (let i = 0; i < fullVendorDetailsTemp.length; i++) {
      if (fullVendorDetailsTemp[i].id === fileDetail.id) {
        fullVendorDetailsTemp[i].isSelected =
          !fullVendorDetailsTemp[i].isSelected;
        // Update the exportBufferTemp based on the isSelected value
        if (fullVendorDetailsTemp[i].isSelected) {
          exportBufferTemp.push(fullVendorDetailsTemp[i]);
        } else {
          exportBufferTemp = exportBufferTemp.filter(
            (item) => item.id != fullVendorDetailsTemp[i].id
          );
        }
      }
    }
    // Set the updated exportBuffer
    setExportBuffer(exportBufferTemp);
    // Check if all files are selected for this vendor, and update vendorHTMLTemp accordingly
    var uncheckedIndex = fullVendorDetailsTemp.findIndex(
      (file) => file.isSelected == false
    );
    if (uncheckedIndex < 0) {
      handleVendorCheckbox(event, vendor);
    } else {
      for (let i = 0; i < vendorHTMLTemp.length; i++) {
        vendorHTMLTemp[i].isSelected = false;
      }
      setVendorHTML(vendorHTMLTemp);
    }
    // Format the invoice table based on the updated fullVendorDetailsTemp
    formatInvoiceTable(fullVendorDetailsTemp);
  };
  // Handle input change in the search bar

  const handleSearchInput = (event) => {
    event.preventDefault();
    // If search input has a value, set search state based on the search subject

    if (event.target.value) {
      if (searchSubject == "file") {
        setFileSearch(event.target.value);
      } else {
        setVendorSearch(event.target.value);
      }
    } else {
      // Otherwise, clear the search state and reset the search counter
      setFileSearch("");
      setVendorSearch("");
      setSearchCounter(searchCounter + 1);
    }
  };
  // Function to handle search input blur event

  const handleSearchBlur = () => {
    setSearchFocus(false);
    // If search counter is greater than 0, reset it

    if (searchCounter > 0) {
      setSearchCounter(0);
    }
  };
  // Function to get the height of the invoice canvas based on the invoice status

  const getInvoiceCanvasHeight = () => {
    switch (invoiceStatus) {
      case "INVOICE_REVIEW":
        return "calc(100% - 175px)";
        break;
      case "INVOICE_REJECTED":
        return "calc(100% - 215px)";
        break;
      default:
        return "calc(100% - 236px)";
    }
  };
  // Function to handle document type change

  const handleDocType = ({ val, selectedRoute }) => {
    // If document type is different from current value, reset necessary state

    if (docType !== val) {
      handleResetInterval();
      setActiveVendor(null);
      setExportBuffer([]);
      setSortBy({ name: "Sort By", value: null });
      setFrom({
        ...from,
        day: null,
        month: null,
        year: null,
      });

      setTo({
        ...to,
        day: null,
        month: null,
        year: null,
      });
      setTooltipDate("");
      setDateRange({ from: null, to: null });
      setDateErrMessage("");
      setFormStatus("");
      setInvoiceStatus("");
      setReceiptStatus("");
      // If new type is INVOICE, set invoice status to INVOICE_REVIEW

      if (val === "INVOICE") {
        setInvoiceStatus("INVOICE_REVIEW");
      }
      setStatus("REVIEW");
      setFileType(val);
      // If file search or vendor search is not empty, clear it

      if (fileSearch) {
        setFileSearch("");
      }
      if (vendorSearch) {
        setVendorSearch("");
      }
      // Push new route to history

      props.history.push(selectedRoute);
    }
  };
  // handles closing the document type modal

  const handleDocTypeModalClose = () => {
    setUploadModal(false);
    setFileLength(0);
    setUploaded(false);
    setFileType("");
    setInvoices(false);
    setFileMessage("");
    setCheckMaxNoPages(true)
  };
  // handles closing the snack bar

  const handleSnackClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setSuccessMsg("");
    setSnackOpen(false);
  };
  // shows or hides the logout button based on flag

  const showHideLogoutBtn = (flag) => {
    if (flag) {
      showLogoutBtn(!logOutBtn);
    } else {
      if (logOutBtn) {
        showLogoutBtn(false);
      }
    }
  };
  // handles checkbox selection for rejected files

  const handleCheckbox = (file) => {
    var vendorHTMLTemp = [...rejectedHTML];
    var rejectedFileIndex = vendorHTMLTemp.findIndex(
      (item) => item.id === file.id
    );
    vendorHTMLTemp[rejectedFileIndex].isSelected = !file.isSelected;
    setFullRejectedHtml(vendorHTMLTemp);

    var exportBufferTemp = [...exportBuffer];

    for (let i = 0; i < vendorHTMLTemp.length; i++) {
      if (vendorHTMLTemp[i].id === file.id) {
        if (vendorHTMLTemp[i].isSelected) {
          exportBufferTemp.push(vendorHTMLTemp[i]);
        } else {
          exportBufferTemp = exportBufferTemp.filter(
            (item) => item.id != vendorHTMLTemp[i].id
          );
        }
      }
    }

    setExportBuffer(exportBufferTemp);
  };
  // Resets the processing timer and clears processing file arrays

  const handleResetInterval = () => {
    tripper = 0;
    setTestCounter(0);
    setProcessingFiles([]);
    setFullProcessingFiles([]);
    clearInterval(timerVal);
  };
  // Handles dropping files onto the page for upload

  const dropHandler = (ev) => {
    ev.preventDefault();
    let filesSet = [];
    // Check if files are being dropped or dragged

    if (ev.dataTransfer.items) {
      for (var i = 0; i < ev.dataTransfer.items.length; i++) {
        if (ev.dataTransfer.items[i].kind === "file") {
          var file = ev.dataTransfer.items[i].getAsFile();
          filesSet.push(file);
        }
      }
    } else {
      for (var i = 0; i < ev.dataTransfer.files.length; i++) {
        filesSet.push(ev.dataTransfer.files[i]);
      }
    }
    // Create a FormData object and append the dropped files to it

    const formData = new FormData();
    const checkNoPagesData = new FormData();

    for (let i = 0; i < filesSet.length; i++) {
      formData.append("file", filesSet[i]);
      checkNoPagesData.append("file", filesSet[i]);
    }
    // Append other necessary data to the FormData object
    checkNoPagesData.append("user", sessionStorage.getItem('user_id'));
    checkNoPagesData.append("company_name", ogi);
    checkNoPagesData.append("plan_name", planName);
    axios({
      method: "post",
      url: `${EXTRICATOR_BASE_URL}/check-max-number-of-pages/`,
      data: checkNoPagesData,
      headers: {
        Authorization: `Bearer ${authToken}`,
      },
    })
      .then(function (response) {
        if (response.data.status) {
          setToastColor("success");
          setSuccessMsg(response.data.message);
          setSnackOpen(true);
          formData.append("file_type", docType);
          formData.append("ogi", ogi);
          formData.append("plan_name", planName);
          setInvoices(formData);
          setCheckMaxNoPages(false)
          setFileLength(filesSet.length);
          setFileMessage("");
        }
        else{
          setFileMessage(response.data.message);
          setSnackOpen(true);
          setInvoices(false);
          setCheckMaxNoPages(true)
          setFileLength(0);
          setUploaded(false);
          setFileType("");
          
        }
      });
  
  };
  // Handles dragging files over the page

  function dragOverHandler(ev) {
    ev.preventDefault();
  }

  return (
    <>
      <CheckMobileDevice />
      <DeleteDialogBox
        open={deletableFileId}
        handleClose={() => setDeletableFileId(null)}
        content="Are you sure you want to delete this document?"
        inProgress={deleteFileProgress}
        handleConfirm={() => confirmDelete(deletableFileId)}
        dialogTitle="Confirmation"
      />

      <Backdrop className={classes.backdrop} open={loading}>
        <CircularProgress color="inherit" />
      </Backdrop>
      <Backdrop className={classes.backdrop} open={open}>
        <Modal
          open={open}
          style={{ top: 100, left: 479, width: 408, height: 496 }}
          aria-labelledby="simple-modal-title"
          aria-describedby="simple-modal-description"
        >
          <div>
            <img style={{ opacity: 1, borderRadius: 11 }} src={gif} />
          </div>
        </Modal>

        <Modal
          open={uploadModal}
          onClose={() => (!fileUploading ? handleDocTypeModalClose() : null)}
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
          aria-labelledby="simple-modal-title"
          aria-describedby="simple-modal-description"
        >
          <div
            style={{
              backgroundColor: "#FFFFFF",
              width: 520,
              height: 246,
              borderRadius: 6,
            }}
            align="center"
          >
            <>
              {!fileUploading ? (
                <>
                  <img
                    src={Images.close}
                    onClick={() => handleDocTypeModalClose()}
                    style={{
                      cursor: "pointer",
                      width: 19,
                      height: 19,
                      marginTop: 12,
                      marginLeft: "94%",
                      marginTop: 6,
                    }}
                  />
                  <img
                    src={Images.upload}
                    style={{ width: 35, height: 33, marginTop: 12 }}
                  />

                  <div
                    align="center"
                    id="drop_zone"
                    onDrop={dropHandler}
                    onDragOver={dragOverHandler}
                  >
                    <div
                      style={{
                        color: "#1C5784",
                        font: "normal normal bold 15px/26px Open Sans",
                        letterSpacing: "0px",
                        opacity: 1,
                      }}
                    >
                      Drop files here
                    </div>

                    <div
                      style={{
                        color: "#1C5784",
                        font: "normal normal bold 10px/26px Open Sans",
                        opacity: 1,
                        letterSpacing: 0,
                      }}
                    >
                      or click here
                      <span
                        style={{
                          background: "#1177FD 0% 0% no-repeat padding-box",
                          borderRadius: 5,
                          marginLeft: 4,
                          padding: 2,
                        }}
                      >
                        <label
                          htmlFor="contained-button-file"
                          style={{ cursor: "pointer" }}
                        >
                          <span
                            style={{
                              font: "normal normal 600 10px/26px Open Sans",
                              textAlign: "center",
                              letterSpacing: 0,
                              opacity: 1,
                              color: "#fff",
                            }}
                          >
                            Choose files
                          </span>
                        </label>
                      </span>
                    </div>
                    <div>
                      {fileLength && (
                        <>
                          <span
                            style={{
                              color: "#1C5784",
                              font: "normal normal bold 10px/26px Open Sans",
                              letterSpacing: 0,
                              opacity: 1,
                            }}
                          >
                            {fileLength} Files selected
                          </span>
                          <i
                            className="fa fa-check-circle ml-1"
                            style={{ fontSize: "12px", color: "#40FF7F" }}
                          ></i>
                        </>
                      )
                       }
                        {fileMessage && (
                        <>
                          <span
                            style={{
                              color: "red",
                              font: "normal normal bold 10px/26px Open Sans",
                              letterSpacing: 0,
                              opacity: 1,
                            }}
                          >
                            {fileMessage}
                          </span>
                        
                        </>
                      )
                       }
                    </div>

                    <button
                      onClick={uploadInvoice}
                      disabled={checkMaxNoPages}
                      className="btn btn-lg sign-btn get-btn mt-4"
                      style={{
                        display: "flex",
                        alignContent: "center",
                        justifyContent: "center",
                        background: !checkMaxNoPages? "#35F8F1 0% 0% no-repeat padding-box" : "gray 0% 0% no-repeat padding-box",
                        boxShadow: "0px 3px 6px #14141429",
                        borderRadius: 3,
                        opacity: 1,
                        width: 142,
                      }}
                    >
                      Upload
                    </button>
                    
                  </div>
                </>
              ) : (
                <div className="col-12">
                  <div
                    className="d-flex justify-content-center align-items-center"
                    style={{ height: "200px" }}
                  >
                    <svg
                      className="spinner"
                      width="65px"
                      height="65px"
                      viewBox="0 0 66 66"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <circle
                        className="path"
                        fill="none"
                        stroke-width="6"
                        stroke-linecap="round"
                        cx="33"
                        cy="33"
                        r="30"
                      ></circle>
                    </svg>
                  </div>
                  <h6 style={{ color: "#011B3E", fontSize: "14px" }}>
                    Please wait while your files upload.
                  </h6>
                </div>
              )}
            </>
          </div>
        </Modal>
      </Backdrop>
      <div
        className="dashboard_container"
        onClick={() => showHideLogoutBtn(null)}
      >
        {fileId && (
          <ImageShowcase
            fileId={fileId}
            fileName={fileName}
            handleClose={() => setFileId(null)}
          />
        )}
        <SnackbarComponent
          isOpen={snackOpen}
          message={successMsg}
          color={toastColor}
          handleClose={handleSnackClose}
        />
        <div
          className="menu-side col-2"
          style={{ backgroundImage: Images.data_center }}
        >
          <div>
            <div className="image-full">
              <div className="form-group d-flex justify-content-center mt-4 banner-head">
                <Link to="/">
                  <img
                    src={Images.app_logo}
                    className="img-responsive img-fluid"
                    alt=""
                  />
                </Link>
              </div>
            </div>
            <FileCategoryComponent
              reAuthenticate={handleReAuth}
              handleCategoryClick={handleSectionClick}
              docType={"INVOICE"}
              docStatus={invoiceStatus}
              defaultActiveTab={activeTab}
            />
          </div>
          {/* <PayContainer /> */}
          <PayContainer planName={planName} file_type={docType}/>

        </div>

        <div
          className="invoice-side pt-2"
          style={{ position: "relative" }}
          onClick={closeProfileMenu}
        >
          <div className="col-12">
            <div className="row">
              <div className="col-6" style={{ paddingLeft: "12px" }}>
                <TopNav callback={handleDocType} defaultActiveTab={2} />
                <div className="col-md-12 basic-search mt-3">
                  <div className="input-field">
                    <form
                      autoComplete="off"
                      onSubmit={handleSearch}
                      className="col-6 d-flex"
                      style={{
                        borderRadius: 6,
                        opacity: 1,
                        background: "#0B346B 0% 0% no-repeat padding-box",
                        padding: "0px",
                      }}
                    >
                      <div className="d-flex search_container col-11 ml-2">
                        {![
                          "INVOICE_REJECTED",
                          "INVOICE_DELETE_REJECTED",
                          "INVOICE_PROCESSING",
                        ].includes(invoiceStatus) && (
                          <SearchOnSelect
                            getValue={(option) => setSearchSubject(option)}
                          />
                        )}
                        <div className="d-flex search_container">
                          <img
                            src={Images.dashboard_search}
                            width={14}
                            height={14}
                            style={{ margin: "10px 5px" }}
                          />
                          <input
                            id="search"
                            type="text"
                            placeholder="Search"
                            style={{ width: "245px" }}
                            value={
                              searchSubject == "file"
                                ? fileSearch
                                : vendorSearch
                            }
                            onFocus={() => setSearchFocus(true)}
                            onBlur={() => handleSearchBlur()}
                            ref={searchInput}
                            onChange={handleSearchInput}
                            onEnter
                          />
                          <button className="btn search_btn">
                            <i
                              style={{ color: "#01132B" }}
                              className="fa fa-search"
                            ></i>
                          </button>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
              <div className="col-6 pr-4">
                <div className="d-flex justify-content-end align-items-center">
                  <Tooltip
                    open={tooltip}
                    title={tooltipDate}
                    placement="bottom"
                    arrow
                  >
                    <img
                      src={Images.calendar}
                      className="calendar"
                      onClick={onCalandarClick}
                      style={{
                        width: 25,
                        height: 29,
                        color: "#FFFFFF 0% 0% no-repeat padding-box",
                        marginLeft: "26.45px",
                        cursor: "pointer",
                        zIndex: 1310,
                      }}
                    />
                  </Tooltip>
                  <div className="dashboard_calendar mx-3">
                    <ThemeProvider theme={myTheme}>
                      {calander && (
                        <>
                          <Menu
                            id="simple-menu"
                            anchorEl={anchorEl}
                            keepMounted
                            open={Boolean(anchorEl)}
                            // open={calander}
                            className="mt-5"
                            onClose={() => handleCalandarClose(null)}
                            style={{ left: "0px", overflowY: "hidden" }}
                          >
                            <MenuItem
                              style={{
                                background:
                                  "#12458A 0% 0% no-repeat padding-box",
                                borderRadius: 5,
                                opacity: 1,
                              }}
                            >
                              <div>
                                <div className="date-band">
                                  <label>From</label>
                                  <Select
                                    labelId="demo-simple-select-outlined-label"
                                    id="demo-simple-select-outlined"
                                    onChange={handleFormChange}
                                    className="to-day"
                                    value={from.day}
                                    name="day"
                                    style={{
                                      display: "flex",
                                      width: "42px",
                                      height: "19px",
                                      color: "#FFF",
                                      fontSize: "12px",
                                      marginLeft: "16px",
                                    }}
                                  >
                                    {days.map((item) => (
                                      <MenuItem
                                        style={{
                                          color: "#FFF",
                                          fontSize: "12px",
                                        }}
                                        value={item}
                                        id={item}
                                      >
                                        {item}
                                      </MenuItem>
                                    ))}
                                  </Select>
                                  <Select
                                    labelId="demo-simple-select-outlined-label"
                                    id="demo-simple-select-outlined"
                                    className="to-day"
                                    value={from.month}
                                    onChange={handleFormChange}
                                    name="month"
                                    style={{
                                      display: "flex",
                                      width: "51px",
                                      height: "19px",
                                      fontSize: "12px",
                                      color: "#FFF",
                                      marginLeft: 25,
                                    }}
                                  >
                                    {fromMonths.map((item) => (
                                      <MenuItem
                                        style={{
                                          color: "#FFF",
                                          fontSize: "12px",
                                        }}
                                        value={item.index}
                                        id={item.index}
                                      >
                                        {item.month}
                                      </MenuItem>
                                    ))}
                                  </Select>
                                  <Select
                                    labelId="demo-simple-select-outlined-label"
                                    id="demo-simple-select-outlined"
                                    className="to-day"
                                    onChange={handleFormChange}
                                    value={from.year}
                                    name="year"
                                    style={{
                                      display: "flex",
                                      width: "58px",
                                      height: "19px",
                                      color: "#FFF",
                                      fontSize: "12px",
                                      marginLeft: 22,
                                    }}
                                  >
                                    {years.map((item) => (
                                      <MenuItem
                                        style={{
                                          color: "#FFF",
                                          fontSize: "12px",
                                        }}
                                        value={item}
                                        key={item}
                                      >
                                        {item}
                                      </MenuItem>
                                    ))}
                                  </Select>
                                </div>

                                <div
                                  className="date-band"
                                  style={{ marginTop: "19px" }}
                                >
                                  <label>To</label>
                                  <Select
                                    labelId="demo-simple-select-outlined-label"
                                    id="demo-simple-select-outlined"
                                    className="to-day"
                                    onChange={handleToChange}
                                    name="day"
                                    value={to.day}
                                    style={{
                                      display: "flex",
                                      width: "42px",
                                      height: "19px",
                                      color: "#FFF",
                                      fontSize: "12px",
                                      marginLeft: "16px",
                                    }}
                                  >
                                    {days.map((item) => (
                                      <MenuItem
                                        style={{
                                          color: "#FFF",
                                          fontSize: "12px",
                                        }}
                                        value={item}
                                        id={item}
                                      >
                                        {item}
                                      </MenuItem>
                                    ))}
                                  </Select>
                                  <Select
                                    labelId="demo-simple-select-outlined-label"
                                    id="demo-simple-select-outlined"
                                    className="to-day"
                                    value={to.month}
                                    onChange={handleToChange}
                                    name="month"
                                    style={{
                                      display: "flex",
                                      width: "51px",
                                      height: "19px",
                                      color: "#FFF",
                                      fontSize: "12px",
                                      marginLeft: 25,
                                    }}
                                  >
                                    {toMonths.map((item) => (
                                      <MenuItem
                                        style={{
                                          color: "#FFF",
                                          fontSize: "12px",
                                        }}
                                        value={item.index}
                                        id={item.index}
                                      >
                                        {item.month}
                                      </MenuItem>
                                    ))}
                                  </Select>
                                  <Select
                                    labelId="demo-simple-select-outlined-label"
                                    id="demo-simple-select-outlined"
                                    className="to-day"
                                    onChange={handleToChange}
                                    name="year"
                                    value={to.year}
                                    style={{
                                      display: "flex",
                                      width: "58px",
                                      height: "19px",
                                      color: "#FFF",
                                      fontSize: "12px",
                                      marginLeft: 22,
                                    }}
                                  >
                                    {years.map((item) => (
                                      <MenuItem
                                        style={{
                                          color: "#FFF",
                                          fontSize: "12px",
                                        }}
                                        value={item}
                                      >
                                        {item}
                                      </MenuItem>
                                    ))}
                                  </Select>
                                </div>
                              </div>
                            </MenuItem>
                            <MenuItem>
                              <div className="col-12">
                                <div className="row justify-content-between">
                                  <span
                                    onClick={() => handleCalandarClose("reset")}
                                    style={{
                                      font: "normal normal 600 10px/42px Open Sans",
                                      marginLeft: "10px",
                                      opacity: 1,
                                      cursor: "pointer",
                                      textDecoration: "underline",
                                    }}
                                  >
                                    Clear Search
                                  </span>
                                  <Button
                                    component="span"
                                    variant="contained"
                                    classes={{ root: classes.logOutBtn }}
                                    style={{
                                      height: 28,
                                      borderRadius: 2,
                                      marginLeft: "53px",
                                      background:
                                        "#35F8F1 0% 0% no-repeat padding-box",
                                      width: "110px",
                                      marginTop: 5,
                                    }}
                                    onClick={filterSearch}
                                  >
                                    <span
                                      style={{
                                        paddingLeft: 10,
                                        paddingRight: 10,
                                        font: "normal normal bold 10px/25px Open Sans",
                                        color: " #011B3E",
                                        opacity: 1,
                                        letterSpacing: 0,
                                      }}
                                    >
                                      Search
                                    </span>
                                  </Button>
                                </div>
                              </div>
                            </MenuItem>
                            {dateErrMessage && (
                              <Alert
                                classes={{
                                  icon: classes.alertIcon,
                                  message: classes.alertMsg,
                                }}
                                style={{ fontSize: "12px", padding: "6px" }}
                                severity="error"
                              >
                                {dateErrMessage}
                              </Alert>
                            )}
                          </Menu>
                        </>
                      )}
                      <div
                        className="d-flex align-items-center"
                        style={{ marginLeft: "10px" }}
                      >
                        <h6 className="mb-0" style={{ fontSize: "15px" }}>
                          Sort by :
                        </h6>
                        <div className="dropdown ml-2">
                          <button
                            className="btn dropdown-toggle sort-btn"
                            type="button"
                            id="dropdownMenuButton"
                            data-toggle="dropdown"
                            aria-haspopup="true"
                            aria-expanded="false"
                          >
                            {sortBy.name}
                          </button>
                          <div
                            className="dropdown-menu"
                            aria-labelledby="dropdownMenuButton"
                          >
                            {sortOptions.map((option) => (
                              <a
                                className="dropdown-item"
                                onClick={() => handleSortOptionSelect(option)}
                                key={option.value}
                                href="#"
                              >
                                {option.name}
                              </a>
                            ))}
                          </div>
                        </div>
                      </div>
                    </ThemeProvider>
                  </div>
                  <div style={{ position: "relative" }}>
                    <LogoutBar
                      btnShow={logOutBtn}
                      callback={showHideLogoutBtn}
                    ></LogoutBar>
                  </div>
                </div>
                {/* exportBuffer refers to selected files */}

                <div className="d-flex align-items-center justify-content-end mt-2">
                  {invoiceStatus !== "INVOICE_PROCESSING" &&
                    invoiceStatus !== "INVOICE_REJECTED" &&
                    invoiceStatus !== "INVOICE_DELETE" && (
                      <div
                        className="d-flex model_input"
                        style={{ marginLeft: "16px" }}
                      >
                        <label
                          onClick={() =>
                            exportBuffer.length > 0
                              ? handleMapMultipleExplicitRejectedFiles()
                              : null
                          }
                          style={{
                            backgroundColor:
                              exportBuffer.length > 0 ? "#35F8F1" : "gray",
                            paddingBottom: "10px",
                          }}
                          className="btn parse_label ml-3"
                        >
                          <span
                            style={{ alignItems: "center", paddingTop: "6px" }}
                          >
                            Explicit Reject
                          </span>
                        </label>
                      </div>
                    )}

                  {invoiceStatus == "INVOICE_REJECTED" && (
                    <div
                      className="d-flex model_input"
                      style={{ marginLeft: "16px" }}
                    >
                      <CustomDropDown
                        screen="dashboard"
                        modelType={"INVOICE"}
                        getSelectedModel={(model) => setSelectedModel(model)}
                        listOffset={{ top: "144px", left: "255px" }}
                      />
                      <label
                        onClick={() =>
                          getRejectedInvoiceCounter().isVisible && selectedModel
                            ? handleMapMultipleRejectedFiles()
                            : null
                        }
                        style={{
                          backgroundColor:
                            getRejectedInvoiceCounter().isVisible &&
                            selectedModel
                              ? "#35F8F1"
                              : "gray",
                        }}
                        className="parse_label ml-3"
                      >
                        <span>Parse</span>
                      </label>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>

          {docType === "INVOICE" &&
          (invoiceStatus === "INVOICE_DELETE" ||
            invoiceStatus === "INVOICE_DELETE_REJECTED") ? (
            <Paper
              square
              style={{ backgroundColor: "#04244f", marginLeft: 12 }}
            >
              <Tabs
                value={deletedInvoiceTab}
                onChange={(e, newValue) =>
                  handleDeletedInvoiceTabChange(e, newValue)
                }
                variant="fullWidth"
                indicatorColor="primary"
                textColor="primary"
                aria-label="icon tabs example"
                style={{ marginBottom: 10, backgroundColor: "#04244f" }}
              >
                <Tab
                  aria-label="phone"
                  style={{ maxWidth: 92 }}
                  label={
                    <CustomTab
                      icon={
                        valueParsed === 0
                          ? Images.dashboard_parsed_checked
                          : Images.dashboard_checked
                      }
                      title="Deleted"
                    />
                  }
                />
                <Tab
                  style={{ maxWidth: 92 }}
                  label={
                    <CustomTab
                      icon={
                        valueParsed === 1
                          ? Images.exported_active
                          : Images.exported
                      }
                      title="Rejected"
                    />
                  }
                />
              </Tabs>
            </Paper>
          ) : null}

          {docType === "INVOICE" &&
          (invoiceStatus == "INVOICE_PARSED" ||
            invoiceStatus == "INVOICE_EXPORTED") ? (
            <Paper
              square
              style={{ backgroundColor: "#04244f", marginLeft: 12 }}
            >
              <Tabs
                value={parsedInvoiceTab}
                onChange={(e, newValue) =>
                  handleParsedInvoiceTabChange(e, newValue)
                }
                variant="fullWidth"
                indicatorColor="primary"
                textColor="primary"
                aria-label="icon tabs example"
                style={{ marginBottom: 10, backgroundColor: "#04244f" }}
              >
                <Tab
                  style={{ maxWidth: 92 }}
                  label={
                    <CustomTab
                      icon={
                        valueParsed === 0
                          ? Images.dashboard_parsed_checked
                          : Images.dashboard_checked
                      }
                      title="Parsed"
                    />
                  }
                />
                <Tab
                  style={{ maxWidth: 92 }}
                  label={
                    <CustomTab
                      icon={
                        valueParsed === 1
                          ? Images.exported_active
                          : Images.exported
                      }
                      title="Exported"
                    />
                  }
                />
              </Tabs>
            </Paper>
          ) : null}

          {/* Rejected Invoices */}
          {docType === "INVOICE" &&
            invoiceStatus == "INVOICE_DELETE_REJECTED" && (
              <div
                className="col-12 p-0"
                style={{
                  height: "380px",
                  overflowX: "hidden",
                  overflowY: "auto",
                }}
              >
                <table className="table forms-table">
                  <thead>
                    <tr style={{ backgroundColor: "#01132B" }}>
                      <th
                        scope="col"
                        className="text-left"
                        style={{ paddingLeft: "19px", fontSize: '1rem' }}
                      >
                        Files
                      </th>
                      <th scope="col" className="text-left px-0" style={{ fontSize: '1rem'}}>
                        Created at
                      </th>
                      <th scope="col" className="text-left px-0" style={{ fontSize: '1rem'}}>
                        Modified at
                      </th>
                      <th scope="col" className="text-left px-0" style={{ fontSize: '1rem'}}>
                        Download
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {rejectedDeleteFiles.length > 0 ? (
                      rejectedDeleteFiles.map((item) => (
                        <tr key={item.id} style={{ height: "40px" }}>
                          <td>
                            <div className="form-check d-flex align-items-center">
                              <i className="fa fa-file-o mr-1"></i>
                              {}
                              <span
                                onMouseEnter={() => handleShowPreview(item)}
                                onMouseLeave={() => handleHidePreview()}
                                className="ml-2 review-div"
                              >
                                {item.original_file_name}
                              </span>
                              {}
                            </div>
                          </td>
                          <td className="text-left">
                            {getTimeRecord(item.created_at)}
                          </td>
                          <td className="text-left">
                            {getTimeRecord(item.modified_at)}
                          </td>
                          <td className="text-left">
                            <span
                              className="fileaction-span"
                              onClick={() => {
                                window.open(
                                  `${EXTRICATOR_BASE_URL}/download_file/?id=${item.id}`
                                );
                              }}
                            >
                              <i className="fa fa-download mr-1"></i>
                              <span>Download</span>
                            </span>
                          </td>
                        </tr>
                      ))
                    ) : (
                      <>
                        <tr></tr>
                        <tr></tr>
                        <tr></tr>
                        <tr align="center">
                          <td></td>
                          <td></td>
                          <td align="center">Nothing to show</td>
                          <td></td>
                          <td></td>
                        </tr>
                        <tr></tr>
                        <tr></tr>
                        <tr></tr>
                      </>
                    )}
                  </tbody>
                </table>
              </div>
            )}

          {docType === "INVOICE" && (
            <>
              <div
                className="startAcc_invoice"
                style={{ height: getInvoiceCanvasHeight() }}
              >
                {invoiceStatus == "INVOICE_PROCESSING" && (
                  <>
                    <div className="col-12 mt-2 p-0">
                      <table className="table forms-table">
                        <thead>
                          <tr style={{ backgroundColor: "#01132B" }}>
                            {}
                            <th
                              scope="col"
                              style={{ textAlign: "left", width: "12%", fontSize: '1rem', }}
                            >
                              Files
                            </th>
                            <th
                              scope="col"
                              style={{ textAlign: "left", width: "17%",fontSize: '1rem' }}
                            >
                              Received at
                            </th>
                            <th
                              scope="col"
                              style={{ textAlign: "left", width: "17%", fontSize: '1rem' }}
                            >
                              Modified at
                            </th>
                            <th
                              scope="col"
                              style={{ textAlign: "left", width: "10%", fontSize: '1rem' }}
                            >
                              Status
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          {processingFiles.length > 0 ? (
                            processingFiles.map((item) => (
                              <tr key={item.id}>
                                <td style={{ width: "12%" }}>
                                  <div
                                    className="form-check d-flex align-items-center"
                                    style={{ paddingLeft: "12px" }}
                                  >
                                    <i className="fa fa-file-o"></i>
                                    <span className="ml-2 processing-div">
                                      {item.original_file_name}
                                    </span>
                                  </div>
                                </td>
                                <td
                                  className="text-left"
                                  style={{ padding: "0px 13px", width: "17%" }}
                                >
                                  {getTimeRecord(item.created_at)}
                                </td>
                                <td
                                  className="text-left"
                                  style={{ padding: "0px 13px", width: "17%" }}
                                >
                                  {getTimeRecord(item.modified_at)}
                                </td>
                                <td
                                  className="text-left"
                                  style={{ padding: "0px 13px", width: "10%" }}
                                >
                                  {item.file_status}
                                </td>
                              </tr>
                            ))
                          ) : (
                            <>
                              <tr></tr>
                              <tr></tr>
                              <tr></tr>
                              <tr align="center">
                                <td align="center" colSpan={6}>
                                  Nothing to show
                                </td>
                              </tr>
                              <tr></tr>
                              <tr></tr>
                              <tr></tr>
                            </>
                          )}
                        </tbody>
                      </table>
                    </div>
                    {fullProcessingFiles.length > 0 && (
                      <div
                        className="d-flex col-12"
                        style={{
                          position: "absolute",
                          bottom:
                            invoiceStatus == "INVOICE_PROCESSING"
                              ? "80px"
                              : "0px",
                        }}
                      >
                        <div className="col-7"></div>
                        <div className="col-5">
                          <PaginationComponent
                            onPageChange={(event, item) =>
                              handlePageChange(event, item)
                            }
                            data={totalProcessingPages}
                            callback={handlePaginationCallback}
                          />
                        </div>
                      </div>
                    )}
                  </>
                )}

                {![
                  "INVOICE_REJECTED",
                  "INVOICE_DELETE_REJECTED",
                  "INVOICE_PROCESSING",
                ].includes(invoiceStatus) && (
                  <Accordion className=" text-white" style={{ height: 37 }}>
                    <div
                      className={
                        invoiceStatus == "INVOICE_REVIEW"
                          ? "col-12 mt-2"
                          : "col-12"
                      }
                    >
                      <ul>
                        <li>
                          <span
                            style={{
                              font: "normal normal 600 14px/35px Open Sans",
                              color: "#fff",
                              fontSize: '0.9rem' 
                            }}
                          >
                            {" "}
                            Vendor name{" "}
                          </span>
                        </li>
                      </ul>
                    </div>
                  </Accordion>
                )}

                {![
                  "INVOICE_REJECTED",
                  "INVOICE_DELETE_REJECTED",
                  "INVOICE_PROCESSING",
                ].includes(invoiceStatus) ? (
                  vendorHTML.length > 0 ? (
                    vendorHTML
                      .filter((e) => e.total_file !== 0)
                      .map((vendor, index) => (
                        <Accordion
                          expanded={vendor.isAccordianOpen}
                          onChange={handleAccordianChange(vendor)}
                          style={{ marginBottom: 5, zIndex: 0 }}
                        >
                          <AccordionSummary
                            classes={{
                              root: getVendorColor(classes, vendor.id),
                            }}
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="panel2a-content"
                            id="panel2a-header"
                            className="vendorNameDiv"
                          >
                            <div className="left-vendor-div d-flex col-7">
                              {invoiceStatus !== "INVOICE_DELETE" ? (
                                <ThemeProvider theme={myTheme}>
                                  <Checkbox
                                    value={`vendor${vendor.id}`}
                                    name={`vendor_check${vendor.id}`}
                                    id={`vendor_id${vendor.id}`}
                                    checked={vendor.isSelected}
                                    onClick={(event) =>
                                      handleVendorCheckbox(event, vendor)
                                    }
                                  />
                                </ThemeProvider>
                              ) : (
                                <span>&nbsp;&nbsp;&nbsp;&nbsp;</span>
                              )}
                              <span
                                className="d-flex align-items-center"
                                onClick={setCounterInitial}
                              >
                                <span style={{ fontWeight: "600px", fontSize: '1rem'}}>
                                  {vendor.supplier_name}
                                </span>
                                {expanded === `panel${index}` ? (
                                  <i
                                    className="fa fa-caret-up ml-1"
                                    style={{ fontSize: "18px" }}
                                    color="#fff"
                                  ></i>
                                ) : (
                                  <i
                                    className="fa fa-caret-down ml-1"
                                    style={{ fontSize: "18px" }}
                                    color="#fff"
                                  ></i>
                                )}

                                {counterValue > 0 &&
                                expanded === `panel${index}` ? (
                                  <span className="selectedCount ml-1">
                                    ({counterValue} Selected)
                                  </span>
                                ) : (
                                  ""
                                )}
                              </span>
                            </div>

                            {vendor.isAccordianOpen &&
                              vendorDetailsHTML.length > 0 && (
                                <PaginationComponent
                                  customclassName={
                                    invoiceStatus == "INVOICE_PARSED"
                                      ? "pr-3"
                                      : "pr-4"
                                  }
                                  place={"vendor"}
                                  onPageChange={(event, item) =>
                                    handlePageChange(event, item)
                                  }
                                  data={totalPages}
                                  callback={(flag) => setGoInputFocused(flag)}
                                />
                              )}
                          </AccordionSummary>

                          <AccordionDetails
                            style={{ paddingBottom: 0 }}
                            className="details-accordian width-100"
                          >
                            <>
                              <ul
                                className="nav upNav pl-3"
                                style={{ backgroundColor: "#011B3E" }}
                              >
                                <li
                                  className="nav-item sub-nav left head-file"
                                  title={"Files"}
                                >
                                  <span className="review-div">Files</span>
                                </li>
                                <li className="nav-item sub-nav left time-heading">
                                  Received at
                                </li>
                                <li className="nav-item sub-nav left time-heading">
                                  Modified at
                                </li>

                                {invoiceStatus == "INVOICE_DELETE" ? (
                                  <li
                                    className="nav-item sub-nav pl-2"
                                    style={{ width: "10%" }}
                                  >
                                    <span className="fileaction-span disableHover">
                                      <span>Download</span>
                                    </span>
                                  </li>
                                ) : (
                                  <li className="nav-item eyeIcon pl-2 text-left">
                                    <span className="fileaction-span disableHover">
                                      <span>Delete</span>
                                    </span>
                                  </li>
                                )}
                                {invoiceStatus !== "INVOICE_DELETE" && (
                                  <>
                                    <li
                                      className="nav-item sub-nav pl-2"
                                      style={{ width: "10%" }}
                                    >
                                      <span className="fileaction-span disableHover">
                                        <span>Download</span>
                                      </span>
                                    </li>
                                    <li
                                      className="nav-item sub-nav"
                                      style={{
                                        width: "10%",
                                        padding: "10px 0px 10px 7px",
                                      }}
                                    >
                                      <span className="fileaction-span disableHover">
                                        <span>Download.xls</span>
                                      </span>
                                    </li>
                                  </>
                                )}
                              </ul>
                              {vendorDetailsHTML.length > 0 ? (
                                vendorDetailsHTML.map((fileDetail) => (
                                  <ul
                                    className="nav upNav pl-3"
                                    style={{
                                      backgroundColor: fileDetail.isSelected
                                        ? "#017BFF"
                                        : "transparent",
                                    }}
                                  >
                                    <li
                                      className={
                                        invoiceStatus !== "INVOICE_DELETE"
                                          ? "nav-item sub-nav-vendor"
                                          : "nav-item sub-nav"
                                      }
                                      style={{
                                        whiteSpace: "nowrap",
                                        overflow: "hidden",
                                        textOverflow: "ellipsis",
                                        width: "250px",
                                        height: "100%",
                                      }}
                                      title={fileDetail.original_file_name}
                                    >
                                      {invoiceStatus !== "INVOICE_DELETE" ? (
                                        <ThemeProvider theme={myTheme}>
                                          <Checkbox
                                            checked={fileDetail.isSelected}
                                            onClick={(event) =>
                                              toggleFileSelect(
                                                event,
                                                fileDetail,
                                                vendor
                                              )
                                            }
                                            value="checkedA"
                                            inputProps={{
                                              "aria-label": "Checkbox A",
                                            }}
                                          />
                                        </ThemeProvider>
                                      ) : (
                                        <span></span>
                                      )}

                                      <i className="fa fa-file-o"></i>
                                      {invoiceStatus == "INVOICE_DELETE" ? (
                                        <span
                                          className="ml-2 review-div"
                                          onMouseEnter={() =>
                                            handleShowPreview(fileDetail)
                                          }
                                          onMouseLeave={() =>
                                            handleHidePreview()
                                          }
                                        >
                                          {fileDetail.original_file_name}
                                        </span>
                                      ) : (
                                        <Link
                                          to={{
                                            pathname: `/document_details/${fileDetail.id}`,
                                            state: invoiceStatus,
                                          }}
                                          style={{ textDecoration: "none" }}
                                        >
                                          {value !== 2 ? (
                                            <span
                                              className="ml-2 review-div"
                                              onMouseEnter={() =>
                                                handleShowPreview(fileDetail)
                                              }
                                              onMouseLeave={() =>
                                                handleHidePreview()
                                              }
                                            >
                                              {fileDetail.original_file_name}
                                            </span>
                                          ) : (
                                            <span className="ml-2 review-div">
                                              {fileDetail.original_file_name}
                                            </span>
                                          )}
                                        </Link>
                                      )}
                                    </li>
                                    <li className="nav-item sub-nav left">
                                      {getTimeRecord(fileDetail.created_at)}
                                    </li>
                                    <li className="nav-item sub-nav left">
                                      {getTimeRecord(fileDetail.modified_at)}
                                    </li>
                                    {invoiceStatus !== "INVOICE_DELETE" && (
                                      <li className="nav-item eyeIcon pl-2 text-left">
                                        <span
                                          className="fileaction-span"
                                          onClick={() =>
                                            setDeletableFileId(fileDetail.id)
                                          }
                                        >
                                          <i className="fa fa-trash"></i>&nbsp;
                                          <span>Delete</span>
                                        </span>
                                      </li>
                                    )}

                                    <>
                                      <li
                                        className="nav-item sub-nav pl-2"
                                        style={{ width: "10%" }}
                                      >
                                        <span
                                          className="fileaction-span"
                                          onClick={() => {
                                            window.open(
                                              `${EXTRICATOR_BASE_URL}/download_file/?id=${fileDetail.id}&ogi=${ogi}`
                                            );
                                          }}
                                        >
                                          <i className="fa fa-download mr-1"></i>
                                          <span>Download</span>
                                        </span>
                                      </li>
                                      {invoiceStatus !== "INVOICE_DELETE" && (
                                        <li
                                          className="nav-item sub-nav xls-btn-row pl-2"
                                          style={{ width: "14%" }}
                                          onClick={() =>
                                            handleSingleXlsDownload(
                                              fileDetail.id
                                            )
                                          }
                                        >
                                          <i className="fa fa-download mr-1"></i>
                                          Download.xls
                                        </li>
                                      )}
                                    </>
                                  </ul>
                                ))
                              ) : (
                                <div className="col-12 no-files">
                                  Nothing to show
                                </div>
                              )}
                            </>
                          </AccordionDetails>
                        </Accordion>
                      ))
                  ) : (
                    <div className="col-12 no-vendors p-0">Nothing to show</div>
                  )
                ) : null}

                {invoiceStatus === "INVOICE_REJECTED" && (
                  <>
                    <ul
                      id="rejected-header"
                      className="nav upNav justify-content-between mt-2 py-2" 
                    >
                      <li
                        className="nav-item sub-nav pl-3"
                        style={{
                          whiteSpace: "nowrap",
                          overflow: "hidden",
                          textOverflow: "ellipsis",
                          width: "300px",
                          height: "100%",
                        }}
                      >
                        <span className="fileaction-span noHover">
                          <span>Files</span>
                        </span>
                      </li>
                      <li className="nav-item sub-nav left">
                        <span className="fileaction-span noHover">
                          <span>Received at</span>
                        </span>
                      </li>
                      <li className="nav-item sub-nav left">
                        <span className="fileaction-span noHover">
                          <span>Modified at</span>
                        </span>
                      </li>
                      <li className="nav-item eyeIcon pl-2 text-left">
                        <span className="fileaction-span noHover">
                          <span>Delete</span>
                        </span>
                      </li>
                      <li className="nav-item sub-nav pl-2 text-left">
                        <span
                          className="fileaction-span d-flex justify-content-start noHover"
                          style={{ marginRight: "21px" }}
                        >
                          <span>Download</span>
                        </span>
                      </li>
                    </ul>
                    {rejectedHTML.length > 0 ? (
                      rejectedHTML.map((item) => (
                        <>
                          <ul
                            className="nav upNav justify-content-between"
                            style={{
                              backgroundColor: item.isSelected
                                ? "#017BFF"
                                : "transparent",
                            }}
                          >
                            <li
                              className="nav-item sub-nav-vendor pl-2"
                              style={{
                                whiteSpace: "nowrap",
                                overflow: "hidden",
                                textOverflow: "ellipsis",
                                width: "300px",
                                height: "100%",
                              }}
                              title={item.original_file_name}
                            >
                              <ThemeProvider theme={myTheme}>
                                <Checkbox
                                  checked={item.isSelected}
                                  onClick={(event) => handleCheckbox(item)}
                                  value="checkedA"
                                  inputProps={{ "aria-label": "Checkbox A" }}
                                />
                              </ThemeProvider>
                              <i className="fa fa-file-o"></i>
                              <Link
                                to={{
                                  pathname: `/document_details/${item.id}`,
                                  state: invoiceStatus,
                                }}
                                style={{ textDecoration: "none" }}
                              >
                                {value !== 2 ? (
                                  <span
                                    className="ml-2 review-div"
                                    onMouseEnter={() => handleShowPreview(item)}
                                    onMouseLeave={() => handleHidePreview()}
                                  >
                                    {item.original_file_name}
                                  </span>
                                ) : (
                                  <span
                                    className="ml-2 review-div"
                                    onMouseEnter={() => handleShowPreview(item)}
                                    onMouseLeave={() => handleHidePreview()}
                                  >
                                    {item.original_file_name}
                                  </span>
                                )}
                              </Link>
                            </li>
                            <li className="nav-item sub-nav left">
                              {getTimeRecord(item.created_at)}
                            </li>
                            <li className="nav-item sub-nav left">
                              {getTimeRecord(item.modified_at)}
                            </li>
                            {value !== 2 && (
                              <li className="nav-item eyeIcon pl-2 text-left">
                                <span
                                  className="fileaction-span"
                                  onClick={() => setDeletableFileId(item.id)}
                                >
                                  <i className="fa fa-trash"></i>&nbsp;
                                  <span>Delete</span>
                                </span>
                              </li>
                            )}
                            <li className="nav-item sub-nav pl-2">
                              <span
                                className="fileaction-span"
                                onClick={() => {
                                  window.open(
                                    `${EXTRICATOR_BASE_URL}/download_file/?id=${
                                      item.id
                                    }&ogi=${fetchOrganisationId()}`
                                  );
                                }}
                              >
                                <i className="fa fa-download mr-1"></i>
                                <span>Download</span>
                              </span>
                            </li>
                          </ul>
                        </>
                      ))
                    ) : (
                      <div className="col-12 no-vendors">Nothing to show</div>
                    )}
                  </>
                )}
              </div>
            </>
          )}
          {invoiceStatus !== "INVOICE_DELETE" && (
            <div
              className="col-12 mt-2"
              style={{
                position: "absolute",
                bottom: "8px",
                right: ["INVOICE_REJECTED"].includes(invoiceStatus)
                  ? "8px"
                  : "0px",
              }}
            >
              {invoiceStatus === "INVOICE_REJECTED" &&
                fullRejectedHtml.length > 0 && (
                  <div className="d-flex col-12 mb-2">
                    <div className="col-7"></div>
                    <div className="col-5 pr-0" style={{ marginLeft: "15px" }}>
                      <PaginationComponent
                        onPageChange={(event, item) =>
                          handlePageChange(event, item)
                        }
                        data={totalRejectedHtmlPages}
                        callback={(flag) => setGoInputFocused(flag)}
                      />
                    </div>
                  </div>
                )}
              <div className="d-flex justify-content-end">
                {docType == "INVOICE" &&
                (getFileCounter().isVisible || getVendorCounter().isVisible) &&
                exportBuffer.length > 0 ? (
                  <div
                    className="d-flex action-group mr-2"
                    style={{
                      width:
                        invoiceStatus == "INVOICE_DELETE" ? "unset" : "390px",
                      justifyContent:
                        invoiceStatus == "INVOICE_REJECTED"
                          ? "flex-end"
                          : "space-between",
                    }}
                  >
                    <label
                      className="xls_label"
                      onClick={() => handleBatchDelete()}
                    >
                      <i
                        className="fa fa-trash"
                        style={{
                          fontSize: "17px",
                          color: theme_colors.primary,
                        }}
                      ></i>
                      <span
                        style={{
                          marginLeft: 15,
                          font: "normal normal bold 14px/35px Open Sans",
                          color: "#011B3E",
                        }}
                      >
                        {}
                        ({exportBuffer.length})
                        &nbsp;
                        Delete
                      </span>
                    </label>
                    {!["INVOICE_DELETE", "INVOICE_REJECTED"].includes(
                      invoiceStatus
                    ) && (
                      <label
                        className="xls_label ml-2"
                        onClick={() => handleDownload("vendorFiles")}
                      >
                        <img
                          src={Images.dashboard_download_button}
                          style={{ marginLeft: -3 }}
                        />
                        <span
                          style={{
                            marginLeft: 15,
                            font: "normal normal bold 14px/35px Open Sans",
                            color: "#011B3E",
                          }}
                        >
                          ({exportBuffer.length})
                          &nbsp;
                          Download.xls
                        </span>
                      </label>
                    )}
                  </div>
                ) : null}

                {}

                {invoiceStatus == "INVOICE_REVIEW" ? (
                  <label
                    className="upload_btn"
                    style={{ marginRight: "8px" }}
                    onClick={() => checkUserSubscription()}
                  >
                    <img
                      src={Images.dashboard_upload}
                      style={{
                        marginRight: "7px",
                        width: "15px",
                        height: "15px",
                      }}
                    />
                    <span
                      style={{
                        font: "normal normal bold 14px/35px Open Sans",
                        color: "#011B3E",
                      }}
                    >
                      Upload
                    </span>
                  </label>
                ) : null}
                {uploadModal && (
                  <input
                    multiple
                    className="uploadBtn"
                    id="contained-button-file"
                    style={{ display: "none" }}
                    type={"file"}
                    accept="image/*,application/pdf"
                    onChange={addInvoice}
                  />
                )}
              </div>
            </div>
          )}
        </div>
      </div>
    </>
  );
};
export default DashboardInvoice;
