import React from "react";
import { Switch, Route } from "react-router-dom";
import Login from "../Login/Loadable";
import ForgotPassword from "../ForgotPassword/Loadable";
import ResetPassword from "../ResetPassword/Loadable";
import Landing from "../Landing/Loadable";
import Account from "../Account/Loadable";
import Report from "../Account/Loadable";
import TabPanel from "../Account/Loadable";
import UserDetails from "../admin/index";

import SignUp from "../Signup/Loadable";
import NotFoundPage from "../NotFoundPage/Loadable";
import DocumentDetails from "../DocDetails";
import FormDetails from "../FormDetails";
import ReceiptDetails from "../ReceiptDetails";
import POrderDetails from "../POrderDetails";
import UtilityDetails from "../UtilityDetails";
import PassportDetails from "../PassportDetails";

import QueueDetails from "../QueueDetails";
import FormatInvoice from "../FormatInvoice";
import PaymentFailure from "../PaymentFail";
import DashboardForms from "../Dashboard/Forms";
import DashboardInvoice from "../Dashboard/Invoice";
import DashboardReceipt from "../Dashboard/Receipt";
import DashboardUtility from "../Dashboard/Utility";
import DashboardPOrder from "../Dashboard/POrder";
import DashboardPassport from "../Dashboard/Passport";

import AdminLanding from "../AdminLanding";
import TermsConditions from "../TermsConditions/TermsConditions";
import ShippingPolicy from "../TermsConditions/ShippingPolicy";
import RefundPolicy from "../TermsConditions/RefundPolicy";
import ReturnPolicy from "../TermsConditions/ReturnPolicy";
import CancellationPolicy from "../TermsConditions/CancellationPolicy";
import TrasnactionalPolicy from "../TermsConditions/TransactionalPolicy";
import SuperAdminReport from "../super-admin";
import AdminReport from "../admin";
import PrivacyPolicy from "../TermsConditions/PrivacyPolicy";
// Define the Routes functional component

const Routes = () => {
  // Render the routes for each component

  return (
    <>
      <Switch>
        <Route exact path="/" component={Landing} />
        <Route exact path="/Login" component={Login} />
        <Route exact path="/Forgot_Password" component={ForgotPassword} />
        <Route exact path="/reset/:token" component={ResetPassword} />
        <Route
          exact
          path="/document_details/:doc_id"
          component={DocumentDetails}
        />
        <Route exact path="/form_details/:doc_id" component={FormDetails} />
        <Route
          exact
          path="/receipt_details/:doc_id"
          component={ReceiptDetails}
        />
        <Route
          exact
          path="/utility_details/:doc_id"
          component={UtilityDetails}
        />
        <Route
          exact
          path="/porder_details/:doc_id"
          component={POrderDetails}
        />
        <Route
          exact
          path="/passport_details/:doc_id"
          component={PassportDetails}
        />
        <Route exact path="/sign_up" component={SignUp} />
        <Route exact path="/admin_landing" component={AdminLanding} />
        <Route exact path="/superadmin_report" component={SuperAdminReport} />
        <Route exact path="/admin_report" component={AdminReport} />
        <Route exact path="/dashboard_forms" component={DashboardForms} />
        <Route exact path="/dashboard_invoices" component={DashboardInvoice} />
        <Route exact path="/dashboard_receipts" component={DashboardReceipt} />
        <Route exact path="/dashboard_utilities" component={DashboardUtility} />
        <Route exact path="/dashboard_porders" component={DashboardPOrder} />
        <Route exact path="/dashboard_passports" component={DashboardPassport} />

        <Route
          exact
          path="/verify_payment_status/:token/:user"
          component={PaymentFailure}
        />
        <Route exact path="/queue_details" component={QueueDetails} />
        <Route exact path="/account" component={Account} />
        {/* // new imp */}
        <Route exact path="/report" component={Report} />
        <Route exact path="/manage_models" component={TabPanel} />
        {/* new implementation for admin report  */}
        <Route exact path="/billings_details" component={AdminReport} />
        <Route exact path="/user_details" component={UserDetails} />

        <Route exact path="/format_invoice" component={FormatInvoice} />
        <Route exact path="/terms_conditions" component={TermsConditions} />
        <Route exact path="/shipping_policy" component={ShippingPolicy} />
        <Route exact path="/refund_policy" component={RefundPolicy} />
        <Route exact path="/return_policy" component={ReturnPolicy} />
        <Route
          exact
          path="/cancellation_policy"
          component={CancellationPolicy}
        />
        <Route
          exact
          path="/transaction_policy"
          component={TrasnactionalPolicy}
        />
        <Route exact path="/privacy_policy" component={PrivacyPolicy} />
        <Route component={NotFoundPage} />
      </Switch>
    </>
  );
};

export default Routes;
