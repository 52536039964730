import React from "react";
import "./terms.module.scss";
import Images from "../../assets/asset_imports";
import FooterComponent from "../../components/footer-component";

export default function CancellationPolicy(props) {
  // Returns the JSX to render the component

  return (
    <div className="col-12 mains_container p-0">
      <nav className="navbar px-5 py-3" style={{ backgroundColor: "#031B40" }}>
        <a className="navbar-brand" href="/">
          <img
            src={Images.app_logo}
            className="img-responsive"
            style={{ width: "75%", height: "40px" }}
            alt=""
          />
        </a>
      </nav>
      <div className="col-12 tnc_container px-5">
        <br />
        <h1 className="c5" id="h.tp8acb6p5hsf">
          <span className="c2 c7">Cancellation Policy</span>
        </h1>
        <p className="c5">
          <span className="c0">All the services offered by</span>
          <span className="c3 c0">&nbsp;Extricator</span>
          <span className="c0">
            &nbsp;are developed through a stringent process of analysis and
            monitoring to ensure optimum understanding of the applications and
            utilization at the customer&rsquo;s end. The tools go through
            several quality checks to guarantee error-free solutions and
            eliminate possibilities of cancellation, return, dispute or
            reversal.
          </span>
        </p>
        <p className="c4">
          <span className="c0"></span>
        </p>
        <p className="c5">
          <span className="c0">
            We deal in SaaS-based solutions that are designed and developed to
            provide comprehensive resolutions to reduce human effort and improve
            the efficiency and effectiveness of business processes. The services
            offered are software-based solutions, hence, the criteria for
            canceling subscribed services is restricted. This is mainly because
            once the service is sold there is no way of retrieving it back from
            the customer.
          </span>
        </p>
        <p className="c4">
          <span className="c0"></span>
        </p>
        <p className="c5">
          <span className="c0">Our cancellation policy is as follows:</span>
        </p>
        <p className="c4">
          <span className="c0"></span>
        </p>
        <ul className="c10 lst-kix_hcepmk5ado4p-0 start">
          <li className="c5 c6 li-bullet-0">
            <span className="c0">
              To cancel your subscription you can write back to us at
            </span>
            &nbsp;
            <a href="mailto: support@neuralwave.ai">
              <span className="c9">support@neuralwave.ai</span>
            </a>
            <span className="c0">
              &nbsp;within 7 days from the date of subscribing.
            </span>
          </li>
          <li className="c5 c6 li-bullet-0">
            <span className="c0">
              Based on the usage of services, and date of subscribing, the
              cancellation is subject to zero or partial penalty.
            </span>
          </li>
        </ul>
        <p className="c4">
          <span className="c0"></span>
        </p>
        <p className="c5">
          <span className="c0">
            Please read our cancellation and return policy before making a
            payment. In case of any queries feel free to write back to us at
          </span>
          &nbsp;
          <a href="mailto: support@neuralwave.ai">
            <span className="c9">support@neuralwave.ai</span>
          </a>
          <span className="c0"></span>
        </p>
        <p className="c5">
          <span className="c0">&nbsp;</span>
        </p>
      </div>
      <FooterComponent />
    </div>
  );
}
