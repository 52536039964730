import React, { useState, useEffect } from "react";
import "./adminReport.module.scss";
import "../../assets/css/style.scss";
import {
  Box,
  Paper,
  makeStyles,
  withStyles,
  TableHead,
  Collapse,
  Switch,
  Table,
  TableBody,
  TableCell,
  TableRow,
  TableContainer,
} from "@material-ui/core";
import axios from "axios";
import {
  fetchAuthToken,
  fetchOrganisationId,
  paginateTable,
} from "../../utils/Helpers";
import { EXTRICATOR_BASE_URL } from "../../utils/constants";
import PaginationComponent from "../../components/PaginatorComponent";
// function to format a date as a string without the timezone offset

const formatDate = (data) => {
  return new Date(data).toString().split(" GMT")[0];
};
// component to create a custom iOS-style switch

const IOSSwitch = withStyles((theme) => ({
  root: {
    width: 42,
    height: 26,
    padding: 0,
    margin: theme.spacing(1),
  },
  switchBase: {
    padding: 1,
    "&$checked": {
      transform: "translateX(16px)",
      color: theme.palette.common.white,
      "& + $track": {
        backgroundColor: "#52d869",
        opacity: 1,
        border: "none",
      },
    },
    "&$focusVisible $thumb": {
      color: "#52d869",
      border: "6px solid #fff",
    },
  },
  thumb: {
    width: 24,
    height: 24,
  },
  track: {
    borderRadius: 26 / 2,
    border: `1px solid ${theme.palette.grey[400]}`,
    backgroundColor: theme.palette.grey[50],
    opacity: 1,
    transition: theme.transitions.create(["background-color", "border"]),
  },
  checked: {},
  focusVisible: {},
}))(({ classes, ...props }) => {
  // return a Switch component with custom styles

  return (
    <Switch
      focusVisibleClassName={classes.focusVisible}
      disableRipple
      classes={{
        root: classes.root,
        switchBase: classes.switchBase,
        thumb: classes.thumb,
        track: classes.track,
        checked: classes.checked,
      }}
      {...props}
    />
  );
});
// hook to create custom styles for table rows

const useRowStyles = makeStyles({
  root: {
    "& > *": {
      borderBottom: "unset",
    },
    backgroundColor: "#04234F",
    border: "1px solid #103261",
  },
  cellRoot: {
    color: "#fff",
    fontWeight: "800",
    padding: "0 16px 0px 20px",
  },
  cellRootLink: {
    color: "#fff",
    fontWeight: "800",
    width: "100px",
  },
  cellRootSwitch: {
    color: "#fff",
    fontWeight: "100",
    fontSize: "12px",
    padding: "0 2em 0 0",
  },
  labelRoot: {
    maxWidth: "unset !important",
    width: "unset !important",
    margin: "0px",
  },
  rowChild: {
    backgroundColor: "rgb(19, 86, 177)",
  },
  table: {
    minWidth: 650,
  },
  childTableHeadRoot: {
    backgroundColor: "#0E4B9C",
  },
  tableBody: {
    backgroundColor: "#0E3C7A",
  },
  fileTableRoot: {
    color: "#fff",
  },
  noFileTableRoot: {
    border: "none",
    color: "#fff",
  },
  fileTableRootHead: {
    color: "#fff",
    fontWeight: "800",
    fontSize: "12px",
  },
});

const tableStyles = makeStyles((theme) => ({
  tcRoot: {
    borderRadius: "0px",
    overflowY: "auto",
    overflowX: "hidden",
    height: "35em",
    backgroundColor: "#04234F",
  },
  thRoot: {
    backgroundColor: "#01132B",
  },
  tcHead: {
    color: "#fff",
    fontSize: "14px",
    fontWeight: "800",
    width: "180px",
    paddingLeft: "0px",
  },
  tCellHead: {
    padding: "0 16px 0px 20px",
  },
  tcHeadMin: {
    color: "#fff",
    fontSize: "14px",
    fontWeight: "800",
    width: "70px",
  },
  tcHeadAction: {
    color: "#fff",
    fontSize: "14px",
    fontWeight: "800",
    width: "70px",
  },
  circleColor: {
    stroke: "#35F8F1",
  },
  actionRoot: {
    padding: "8px 20px !important",
  },
}));

const OrganizationDetails = (props) => {
  const {
    loaderCallback,
    dateSet,
    sendClearDateCallback,
    handleErrorCallback,
  } = props;
  const date = new Date();
  const classes = tableStyles();
  const rowClasses = useRowStyles();
  const [rows, setRowsData] = useState([]);
  const [open, setOpen] = React.useState(false);
  const [internalTableData, setInternalTableData] = React.useState([]);
  const [expandedRow, setExpandedRow] = React.useState("");
  const [totalPages, setTotalPages] = React.useState([]);
  const [goInputFocused, setGoInputFocused] = React.useState(false);
  const [fullUsersList, setFullUsersList] = React.useState([]);
  const [page, setPage] = React.useState(1);
  const [pageRows, setPageRows] = React.useState(9);

  let authToken = fetchAuthToken();
  const ogi = fetchOrganisationId();
  // effect to fetch initial data on mount

  useEffect(() => {
    getroleManagementData();
  }, []);
  // effect to fetch data when dateSet or expandedRow changes

  useEffect(() => {
    if (dateSet && dateSet.from && dateSet.to) {
      if (expandedRow) {
        getRoleBillingDetails(expandedRow, dateSet.from, dateSet.to);
      } else {
        getroleManagementData(dateSet.from, dateSet.to);
      }
    }
  }, [expandedRow, dateSet]);
  // effect to format users list when page changes

  useEffect(() => {
    formatUsersList(fullUsersList);
  }, [page]);
  // function to fetch role management data

  const getroleManagementData = async (from = null, to = null) => {
    loaderCallback(true);
    axios({
      method: "GET",
      url: `${EXTRICATOR_BASE_URL}/organisation_details/?from=${from}&to=${to}`,
      headers: {
        Authorization: `Bearer ${authToken}`,
      },
    })
      .then(function (response) {
        loaderCallback(false);
        var orgDetails = response.data.org_data.map((item) => ({
          ...item,
          isExpanded: false,
        }));
        formatUsersList(orgDetails);
        setFullUsersList(orgDetails);
        setPage(1);
      })
      .catch(function (error) {
        if (error.response) {
          handleErrorCallback(error.response);
          loaderCallback(false);
        }
      });
  };
  // function to fetch role billing details

  const getRoleBillingDetails = async (org_id, from = null, to = null) => {
    let authToken = fetchAuthToken();
    loaderCallback(true);
    axios({
      method: "GET",
      url: `${EXTRICATOR_BASE_URL}/organisation_user_details/?ogi=${org_id}&from=${from}&to=${to}`,
      headers: {
        Authorization: `Bearer ${authToken}`,
      },
    })
      .then(function (response) {
        loaderCallback(false);
        setOpen(!open);
        setInternalTableData(response.data.org_data);
      })
      .catch(function (error) {
        if (error.response) {
          handleErrorCallback(error.response);
          loaderCallback(false);
        }
      });
  };
  // function to handle expanding/collapsing a row and fetching billing details

  const handleSetExpandedUser = (user) => {
    // create a copy of the rows data

    var userRowsTemp = [...rows];
    for (let i = 0; i < userRowsTemp.length; i++) {
      // find the row matching the user

      if (user.org_id == userRowsTemp[i].org_id) {
        // clear the date filter if expanding the row

        if (!rows[i].isExpanded) {
          sendClearDateCallback(true);
        }
        // set the expanded row and update its isExpanded flag

        setExpandedRow(!rows[i].isExpanded ? user.org_id : "");
        userRowsTemp[i].isExpanded = !userRowsTemp[i].isExpanded;
      } else {
        // collapse any other rows

        userRowsTemp[i].isExpanded = false;
      }
    }
    // update the rows data

    setRowsData(userRowsTemp);
    // fetch billing details for the expanded row

    getRoleBillingDetails(user.org_id);
    if (!user.isExpanded) {
      return;
    }
  };
  // function to handle blocking/unblocking a user

  const handleBlockUnblock = (event, row) => {
    loaderCallback(true);
    axios({
      method: "POST",
      url: `${EXTRICATOR_BASE_URL}/change_organisation_status/`,
      headers: {
        Authorization: `Bearer ${authToken}`,
      },
      data: {
        ogi: row.org_id,
      },
    })
      .then(function (response) {
        loaderCallback(false);
        var userRowsTemp = [...rows];
        for (let i = 0; i < userRowsTemp.length; i++) {
          if (row.org_id == userRowsTemp[i].org_id) {
            // update the status of the blocked/unblocked row

            userRowsTemp[i].status = !userRowsTemp[i].status;
          }
        }
        setRowsData(userRowsTemp);
      })
      .catch(function (error) {
        if (error.response) {
          handleErrorCallback(error.response);
          loaderCallback(false);
        }
      });
  };
  // function to handle page changes

  const handlePageChange = async (event, value) => {
    event.stopPropagation();
    setPage(value);
  };
  // function to handle pagination input focus

  const handlePaginationCallback = (focused) => setGoInputFocused(focused);
  // function to format the users list with pagination

  const formatUsersList = (userDetails) => {
    // paginate the user details

    var paginatedData = paginateTable(userDetails, page, pageRows);
    if (paginatedData.paginated_data.length > 0) {
      // set the rows data to the paginated data

      setRowsData(paginatedData.paginated_data);
    }
    // set the total number of pages

    setTotalPages(paginatedData.totalPages);
  };

  return (
    <div id="role_management">
      <TableContainer component={Paper} classes={{ root: classes.tcRoot }}>
        <Table aria-label="collapsible table">
          <TableHead classes={{ root: classes.thRoot }}>
            <TableRow>
              <TableCell
                classes={{ head: classes.tcHead, root: classes.tCellHead }}
                colSpan={8}
              >
                Organization Name
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {rows.length > 0
              ? rows.map((row) => (
                  <React.Fragment key={row.name}>
                    <TableRow className={rowClasses.root}>
                      <TableCell
                        className={rowClasses.cellRoot}
                        component="th"
                        scope="row"
                      >
                        <span
                          style={{ cursor: "pointer" }}
                          onClick={() => handleSetExpandedUser(row)}
                        >
                          <i
                            className={
                              row.isExpanded
                                ? "fa fa-caret-up mr-2"
                                : "fa fa-caret-down mr-2"
                            }
                            aria-hidden="true"
                          ></i>
                          {row.org_name}
                        </span>
                      </TableCell>
                      <TableCell
                        className={rowClasses.cellRootSwitch}
                        align="right"
                        colSpan={2}
                      >
                        Unblock
                        <Switch
                          checked={!row.status}
                          onChange={(event) => handleBlockUnblock(event, row)}
                          name="checkedA"
                          inputProps={{ "aria-label": "secondary checkbox" }}
                        />
                        Block
                      </TableCell>
                    </TableRow>
                    <TableRow
                      style={{ height: "0px", backgroundColor: "#1356B1" }}
                    >
                      <TableCell
                        style={{ border: "0px", padding: "0px" }}
                        colSpan={6}
                      >
                        <Collapse
                          in={row.isExpanded}
                          classes={{ wrapperInner: rowClasses.rowChild }}
                          timeout="auto"
                          unmountOnExit
                        >
                          <Box margin={1} style={{ margin: "0px" }}>
                            <div style={{ backgroundColor: "#0E4B9C" }}>
                              <TableContainer component={Paper}>
                                <Table
                                  className={rowClasses.table}
                                  size="small"
                                  aria-label="a dense table"
                                >
                                  <TableHead
                                    className={rowClasses.childTableHeadRoot}
                                  >
                                    <TableRow>
                                      <TableCell
                                        className={rowClasses.fileTableRootHead}
                                      >
                                        User name
                                      </TableCell>
                                      <TableCell
                                        className={rowClasses.fileTableRootHead}
                                        align="left"
                                      >
                                        Role
                                      </TableCell>
                                      <TableCell
                                        className={rowClasses.fileTableRootHead}
                                        align="left"
                                      >
                                        No. of Forms
                                      </TableCell>
                                      <TableCell
                                        className={rowClasses.fileTableRootHead}
                                        align="left"
                                      >
                                        No. of Invoices
                                      </TableCell>
                                      <TableCell
                                        className={rowClasses.fileTableRootHead}
                                        align="left"
                                      >
                                        No. of Receipts
                                      </TableCell>
                                    </TableRow>
                                  </TableHead>
                                  <TableBody className={rowClasses.tableBody}>
                                    {internalTableData.length > 0 ? (
                                      internalTableData.map((row) => (
                                        <TableRow key={row.name}>
                                          <TableCell
                                            className={rowClasses.fileTableRoot}
                                            component="th"
                                            scope="row"
                                          >
                                            {row.name}
                                          </TableCell>
                                          <TableCell
                                            className={rowClasses.fileTableRoot}
                                            align="left"
                                          >
                                            {row.role}
                                          </TableCell>
                                          <TableCell
                                            className={rowClasses.fileTableRoot}
                                            align="left"
                                          >
                                            {row.form}
                                          </TableCell>
                                          <TableCell
                                            className={rowClasses.fileTableRoot}
                                            align="left"
                                          >
                                            {row.invoice}
                                          </TableCell>
                                          <TableCell
                                            className={rowClasses.fileTableRoot}
                                            align="left"
                                          >
                                            {row.receipt}
                                          </TableCell>
                                        </TableRow>
                                      ))
                                    ) : (
                                      <>
                                        <TableRow>
                                          <TableCell
                                            className={
                                              rowClasses.noFileTableRoot
                                            }
                                            colSpan={6}
                                          ></TableCell>
                                        </TableRow>
                                        <TableRow>
                                          <TableCell
                                            align="center"
                                            className={
                                              rowClasses.noFileTableRoot
                                            }
                                            colSpan={6}
                                          >
                                            Nothing to show
                                          </TableCell>
                                        </TableRow>
                                        <TableRow>
                                          <TableCell
                                            className={
                                              rowClasses.noFileTableRoot
                                            }
                                            colSpan={6}
                                          ></TableCell>
                                        </TableRow>
                                      </>
                                    )}
                                  </TableBody>
                                </Table>
                              </TableContainer>
                            </div>
                          </Box>
                        </Collapse>
                      </TableCell>
                    </TableRow>
                  </React.Fragment>
                ))
              : null}
          </TableBody>
        </Table>
      </TableContainer>
      <div className="col-12">
        <PaginationComponent
          place="admin_users"
          onPageChange={(event, item) => handlePageChange(event, item)}
          data={totalPages}
          callback={handlePaginationCallback}
        />
      </div>
    </div>
  );
};
export default OrganizationDetails;
