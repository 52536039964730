import React, { lazy, Suspense } from "react";
// Defines a higher-order function that returns a dynamically loaded component

const loadable = (importFunc, { fallback = null } = { fallback: null }) => {
  // Defines a lazy component that loads the specified component when it's needed

  const LazyComponent = lazy(importFunc);
  // Returns a functional component that wraps the lazy component in a Suspense component

  return (props) => (
    <Suspense fallback={fallback}>
      <LazyComponent {...props} />
    </Suspense>
  );
};
// Exports the loadable function for use in other parts of the application

export default loadable;
