import React from "react";
import Snackbar from "@material-ui/core/Snackbar";
import MuiAlert from "@material-ui/lab/Alert";
// This is the Alert component, which is a customized version of the Material-UI Alert component with added elevation and variant props

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}
// This is the SnackbarComponent, which is a reusable component for displaying a notification message in the form of a Snackbar

const SnackbarComponent = ({ isOpen, message, color, handleClose }) => (
  <Snackbar
    open={isOpen}
    onClose={handleClose}
    autoHideDuration={4000}
    anchorOrigin={{ vertical: "top", horizontal: "right" }}
  >
    <Alert severity={color}>{message}</Alert>
  </Snackbar>
);
// Exporting the SnackbarComponent so that it can be used in other parts of the application

export default SnackbarComponent;
