import React, { useState, useEffect, useRef } from "react";
import "./dashboard.module.scss";
import "../../assets/css/style.scss";
import { Link } from "react-router-dom";
import Images from "../../assets/asset_imports";
import {
  Box,
  Tabs,
  Tab,
  Accordion,
  Button,
  Paper,
  AccordionSummary,
  CircularProgress,
  AccordionDetails,
  Backdrop,
  Tooltip,
  Modal,
  createMuiTheme,
  MenuItem,
  ThemeProvider,
  Checkbox,
  Menu,
  Select,
  Typography,
} from "@material-ui/core";
import MuiAlert from "@material-ui/lab/Alert";
import { makeStyles } from "@material-ui/core/styles";
import theme_colors from "../../utils/theme";
import PropTypes from "prop-types";
import $ from "jquery";
import axios from "axios";
import {
  fetchAuthToken,
  fetchLoggedInUserEmail,
  handleLogout,
  paginateTable,
  getTimeRecord,
  setActiveSection,
  fetchOrganisationId,
  checkOrganizationError,
  fetchActiveSection,
  fetchUserID,
} from "../../utils/Helpers";
import SnackbarComponent from "../../components/SnackbarComponent";
import ImageShowcase from "../../components/ImageShowcase";
import TopNav from "./components/TopNav";
import LogoutBar from "../../components/LogoutBar";
import DeleteDialogBox from "../../components/DeleteDialog/DeleteDialogBox";
import FileCategoryComponent from "./components/FileCategoryComponent";
import PaginationComponent from "../../components/PaginatorComponent";
import PayContainer from "../../components/PayContainer";
import CustomDropDown from "../../components/CustomDropDown";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { EXTRICATOR_BASE_URL, monthsSet } from "../../utils/constants";
import CheckMobileDevice from "../../components/CheckMobileDevice";
import SearchOnSelect from "./components/SearchCriteriaMenu";

// import DateRangePicker from '@wojtekmaj/react-daterange-picker';
// import '@wojtekmaj/react-daterange-picker/dist/DateRangePicker.css';
// import 'react-calendar/dist/Calendar.css';
// Define options for sorting files
const sortOptions = [
  {
    name: "By Name",
    value: "by_name",
  },
  {
    name: "By Upload",
    value: "by_upload",
  },
];
// TabPanel component for displaying content within a tab
function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`scrollable-auto-tabpanel-${index}`}
      aria-labelledby={`scrollable-auto-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};
// Styles for Accordion component

const accordionStyles = makeStyles((theme) => ({
  // Styling for accordion component

  root: {
    color: "#fff",
    width: "100%",
    background: "transparent",
    boxShadow: "none",
  },
  // Styling for accordion heading

  heading: {
    fontSize: theme.typography.pxToRem(15),
    fontWeight: theme.typography.fontWeightRegular,
    color: "#fff",
  },
  // Styling for accordion content

  content: {
    color: "#062241",
    background: "rgba(255,255,255,0.1)",
    fontWeight: "600",
    padding: "6px",
    alignItems: "center",
    cursor: "pointer",
    justifyContent: "space-between",
    paddingLeft: "40px",
  },
  // Styling for accordion list

  contentList: {
    backgroundColor: "transparent",
    fontSize: "12px",
  },
  // Styling for arrow icon in accordion

  arrowIcon: {
    padding: "0",
  },
  // Styling for badge root in accordion

  badgeRoot: {
    width: "33px",
    height: "17px",
    color: theme_colors.primary,
    background: "#2280FF",
  },
  // Styling for badge label in accordion

  badgeLabel: {
    color: theme_colors.primary,
  },
  // Styling for "Powered by" element in accordion

  poweredBy: {
    borderRight: "1px solid #c7c7c7",
  },
  // Styling for email chip element in accordion

  emailChip: {
    backgroundColor: "#0B346B",
    cursor: "pointer",
    borderRadius: 3,
    fontWeight: "600",
    fontFamily: "'Open Sans', sans-serif !important",
    width: "249px",
    height: "35px",
    marginRight: "10px",
  },
  // Styling for email logo in email chip element

  emailLogo: {
    background: theme_colors.secondary,
    color: `${theme_colors.primary} !important`,
  },
  // Styling for appbar root in accordion

  appbarRoot: {
    padding: "0 15px",
    background: "transparent",
    boxShadow: "none",
    marginTop: "15px",
  },
  // Styling for tab indicator in accordion

  indicator: {
    backgroundColor: "transparent !important",
    height: "0px !important",
  },
  // Styling for tab root in accordion

  tabRoot: {
    width: "180px",
    fontWeight: "600",
  },
  // Styling for import button

  importBtn: {
    backgroundColor: "#35F8F1",
    borderRadius: "6px",
  },
  // Styling for summary root

  summary_root: {
    minHeight: "32px !important",
  },
  // Styling for selected summary root

  summary_rootSelected: {
    backgroundColor: "#2280FF",
    minHeight: "22px !important",
    paddingRight: "5px !important",
  },
  // Styling for summary component

  summaryStyle: {
    alignItems: "center",
    display: "flex",
  },
  // Styling for summary component

  accordionRoot: {
    backgroundColor: "red",
  },
  // Styling for disabled summary component

  summaryDisabled: {
    opacity: "1 !important",
  },
  // Styling for "Go" button

  goBtn: {
    backgroundColor: "#031B40",
    borderRadius: "6px",
    "&:hover": {
      backgroundColor: theme_colors.primary,
      color: "#FFF",
    },
  },
  calendarList: {
    background: "green !important",
  },
  logOutBtn: {
    backgroundColor: theme_colors.primary,
    color: "#FFF",
    borderRadius: "3px",
    "&:hover": {
      backgroundColor: theme_colors.primary,
      color: "#FFF",
    },
  },

  reviewBtn: {
    backgroundColor: theme_colors.primary,
    background: "rgb(2,27,65)",
    background:
      "linear-gradient(0deg, rgba(2,27,65,1) 49%, rgba(35,67,115,1) 100%)",
    borderRadius: "10px",
    padding: "10px 20px",
    "&:hover": {
      backgroundColor: theme_colors.primary,
      color: "#FFF",
    },
  },
  backdrop: {
    zIndex: 10,
    color: "#fff",
  },
  myMenu: {
    height: "50px",
    width: "30px",
    color: "#fff !important",
  },
  menuPaper: {
    left: "870px !important",
    top: "52px !important",
    width: "114px !important",
  },
  spinner: {
    color: "#011B3E",
  },
  alertIcon: {
    display: "none",
  },
  alertMsg: {
    padding: "0px 0px",
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
}));
// Create custom theme overrides

const myTheme = createMuiTheme({
  overrides: {
    // Override styles for MuiMenu component

    MuiMenu: {
      paper: {
        padding: 0,
        borderRadius: "5px",
      },
      list: {
        paddingBottom: "0px !important",
      },
    },
    // Override styles for MuiPopover component

    MuiPopover: {
      paper: {
        "& ul": {
          background: "#12458A 0% 0% no-repeat padding-box !important",
        },
      },
    },
    // Override styles for MuiInput component

    MuiInput: {
      root: {
        padding: "0px",
      },
      underline: {
        cursor: "pointer",
      },
    },
    // Override styles for MuiSelect component

    MuiSelect: {
      select: {
        paddingRight: "0px !important",
      },
      icon: {
        height: "100%",
        marginTop: "0px !important",
        top: "0 !important",
      },
      selectMenu: {
        paddingLeft: "5px",
      },
    },
    // Override styles for MuiListItem component

    MuiListItem: {
      gutters: {
        paddingRight: "22px !important",
        cursor: "default",
      },
    },
    // Override styles for MuiSvgIcon component

    MuiSvgIcon: {
      root: {
        color: "#fff",
      },
    },
    // Override styles for MuiAccordionSummary component

    MuiAccordionSummary: {
      content: {
        Mui: {
          expanded: {
            minHeight: "0px !important",
            margin: "0px !important",
          },
        },
      },
    },
  },
});
// Define a custom tab component

const CustomTab = ({ title, icon, showChip, classes, thickness }) => (
  <div className="customs-tab review-div" style={{ fontWeight: thickness }}>
    <img src={icon} />
    {title}
  </div>
);
// Define an alert component using MuiAlert from Material-UI

function Alert(props) {
  const classes = accordionStyles();
  return (
    <MuiAlert
      classes={{
        root: classes.toastRoot,
        icon: classes.toastIcon,
        message: classes.toastMessage,
      }}
      elevation={6}
      variant="filled"
      {...props}
    />
  );
}

var timerVal;
var previewTime;
var tripper = 0;

const DashboardForms = (props) => {

  const [dateValue, setDateValue] = useState([new Date(), new Date()]);
  const authToken = fetchAuthToken();
  const ogi = fetchOrganisationId();
  const user_id = fetchUserID();
  const activeSection = fetchActiveSection();
  const [logOutBtn, showLogoutBtn] = useState(false);
  const [showModelOption, setShowModelOption] = useState(false);
  const [searchAttempt, setSearchAttempt] = useState(false);
  const [activeTab, setActiveTab] = useState(activeSection);
  const [testCounter, setTestCounter] = useState(0);
  const [userId, setUserId] = useState(null);
  const [fullProcessingFiles, setFullProcessingFiles] = useState([]);
  const [exportBuffer, setExportBuffer] = useState([]);
  const [reviewExportBuffer, setReviewExportBuffer] = useState([]);
  const [deletedInvoiceTab, setDeletedInvoiceTab] = useState(0);
  const [fileUploading, setFileUploading] = useState(false);
  const [deletableFileId, setDeletableFileId] = useState(null);
  const [deleteFileProgress, setDeleteFileProgress] = useState(false);
  const [fileMessage, setFileMessage] = useState(0);
  const [dateErrMessage, setDateErrMessage] = useState("");
  const [searchFocused, setSearchFocus] = useState(false);
  const [dateRange, setDateRange] = useState({ from: null, to: null });
  const [value, setValue] = React.useState(0);
  const [valueParsed, setParsedValue] = React.useState(0);
  const [profileMenuShow, setProfileMenuShow] = React.useState(false);
  const [successMsg, setSuccessMsg] = React.useState("");
  const [snackOpen, setSnackOpen] = React.useState(false);
  const [toastColor, setToastColor] = React.useState("success");
  const [open, setOpen] = React.useState(false);
  const [updateTable, setUpdateTable] = React.useState(false);
  const [sortOption, setSortOption] = React.useState("Sort by");
  const [calander, openCalander] = React.useState(false);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [anchorElSort, setAnchorElSort] = React.useState(null);
  const [anchorElLogout, setAnchorElLogout] = React.useState(null);
  const [vendorHTML, setVendorHTML] = React.useState([]);
  const [processingFiles, setProcessingFiles] = React.useState([]);
  const [vendorDetailsHTML, setVendorDetails] = React.useState([]);
  const [vendorsLength, setVendorsLength] = React.useState([]);
  const [amount, setAmount] = React.useState(0);
  const [expanded, setExpanded] = React.useState(false);
  const [userEmail, setEmail] = React.useState("");
  const [counterValue, setCounter] = React.useState(0);
  const [arrayPushed, setArray] = React.useState([]);
  const [status, setStatus] = React.useState("PARSED");
  const [formStatus, setFormStatus] = React.useState(activeSection);
  const [invoiceStatus, setInvoiceStatus] = React.useState("");
  const [receiptStatus, setReceiptStatus] = React.useState("");
  const [days, setDays] = React.useState([1]);
  const [fileId, setFileId] = React.useState(null);
  const [selectedModel, setSelectedModel] = React.useState(null);
  const [mainCheck, setMaincheck] = React.useState(false);
  const [toMonths, setToMonths] = React.useState(monthsSet);
  const [fromMonths, setFromMonths] = React.useState(monthsSet);
  const [searchSubject, setSearchSubject] = useState("file");
  const [years, setYears] = React.useState([2020]);
  const [id, setId] = React.useState();
  const [fullReviewedHtml, setFullReviewedHtml] = React.useState([]);
  //check if exceeded the max no. of pages or not
  const [checkMaxNoPages, setCheckMaxNoPages] = React.useState(true);


  // State for sorting option

  const [sortBy, setSortBy] = React.useState({
    name: "Sort By",
    value: null,
  });
  // State for 'from' date

  const [from, setFrom] = React.useState({
    day: null,
    month: null,
    year: null,
  });
  // State for 'to' date

  const [to, setTo] = React.useState({
    day: null,
    month: null,
    year: null,
  });
  var processingInterval = null;
  const [selectedVendorFiles, setSelectedVendorFiles] = React.useState("");
  const [selectedVendor, setSelectedVendor] = React.useState("");
  const [tooltip, setTooltip] = React.useState(false);
  const [tooltipDate, setTooltipDate] = React.useState("");
  const [vendorCounter, setVendorCounter] = React.useState(0);
  const [gif, setGif] = React.useState(require("./analysis.gif"));
  const [logout, showLogout] = React.useState(false);
  const [search, setSearch] = React.useState("");
  const [searchCounter, setSearchCounter] = React.useState(0);
  const [page, setPage] = React.useState(1);
  const [formPage, setFormPage] = React.useState(1);
  const [processingFilePage, setProcessingFilePage] = React.useState(1);
  const [formPageExported, setFormPageExported] = React.useState(1);
  const [formPageDeleted, setFormPageDeleted] = React.useState(1);
  const [formPageReview, setFormPageReview] = React.useState(1);
  const [receiptPage, setReceiptPage] = React.useState(1);
  const [rows, setRows] = React.useState(8);
  const [pagedData, setPagedData] = React.useState([]);
  const [totalPages, setTotalPages] = React.useState([]);
  const [totalReviewPages, setTotalReviewPages] = React.useState([]);
  const [totalFormPages, setTotalFormPages] = React.useState([]);
  const [totalExportedPages, setTotalExportedPages] = React.useState([]);
  const [totalDeletedPages, setTotalDeletedPages] = React.useState([]);
  const [totalReceiptPages, setTotalReceiptPages] = React.useState([]);
  const [totalVendorHtmlPages, setTotalVendorHtmlPages] = React.useState([]);
  const [totalProcessingPages, setTotalProcessingPages] = React.useState([]);
  const [uploadModal, setUploadModal] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const [fileName, setFilename] = React.useState("");
  const [fileLength, setFileLength] = React.useState(0);
  const [vendorChecked, setChecked] = React.useState(false);
  const [invoices, setInvoices] = React.useState(false);
  const [uploaded, setUploaded] = React.useState(false);
  const [fileType, setFileType] = React.useState("FORM");
  const [docType, setDocType] = React.useState("FORM");
  const [formList, setFormList] = React.useState([]);
  const [parsedForms, setParsedForms] = React.useState([]);
  const [fullParsedForms, setFullParsedForms] = React.useState([]);
  const [reviewForms, setReviewForms] = React.useState([]);
  const [fullReviewForms, setFullReviewForms] = React.useState([]);
  const [exportedForms, setExportedForms] = React.useState([]);
  const [fullExportedForms, setFullExportedForms] = React.useState([]);
  const [deletedForms, setDeletedForms] = React.useState([]);
  const [fullDeletedForms, setFullDeletedForms] = React.useState([]);
  const [showExcelBtn, setExcelBtn] = React.useState(false);
  const [showExcelBtnR, setExcelBtnR] = React.useState(false);
  const [formId, setFormId] = React.useState(0);
  const [selectedForms, setSelectedForms] = React.useState(0);
  const [selectedFormIds, setSelectedFormIds] = React.useState([]);
  const [receiptList, setReceiptList] = React.useState([]);
  const [receiptVendorDetails, setReceiptVendorDetails] = React.useState([]);
  const [fullReceiptVendorDetails, setFullReceiptVendorDetails] =
    React.useState([]);
  const [selectedReceipts, setSelectedReceipts] = React.useState(0);
  const [selectedReceiptIds, setSelectedReceiptIds] = React.useState([]);
  const [receiptId, setReceiptId] = React.useState(0);
  const [selectedFiles, setSelectedFiles] = React.useState([]);
  const [vendorTicked, setVendorTicked] = React.useState(false);
  const [activeVendor, setActiveVendor] = React.useState(null);
  const [focusedVendorId, setFocusedVendorId] = React.useState(null);
  const [expandedVendorId, setexpandedVendorId] = React.useState(null);
  const [goInputFocused, setGoInputFocused] = React.useState(false);
  const [pageInputValue, setPageInputValue] = React.useState(1);
  const [formPageValue, setFormPageValue] = React.useState(1);
  const [fullVendorDetails, setFullVendorDetails] = React.useState([]);
  const [fullVendorHtml, setFullVendorHtml] = React.useState([]);
  const [filesDefaultSelectedId, setFilesDefaultSelectedId] = React.useState(
    []
  );
  const [showTable, setShowTable] = React.useState(false);
  const [selectedVendorIds, setSelectedVendorIds] = React.useState([]);
  const [deletedTabValue, setDeletedTabValue] = React.useState(0);
  const [parsedInvoiceTab, setParsedInvoiceTab] = React.useState(0);
  const [parsedReceiptTab, setParsedReceiptTab] = React.useState(0);
  const [rejectedDeleteFiles, setRejectedDeleteFiles] = React.useState([]);
  const [processingPage, setProcessingPage] = React.useState(1);
  const [fileSearch, setFileSearch] = React.useState("");
  const [vendorSearch, setVendorSearch] = React.useState("");
  const planName = "forms";

  // Set up a ref to store the previous status value for comparison

  const prevStatusRef = useRef();
  // Use an effect hook to store the previous status value

  useEffect(() => {
    prevStatusRef.current = status;
  });
  // Retrieve the previous status value from the ref

  const prevStatus = prevStatusRef.current;
  // Set up a ref to reference the search input

  const searchInput = React.useRef(null);
  // Get the classes for the accordion styling

  const classes = accordionStyles();
  // Function to format the vendor details data for display in a table

  const formatInvoiceTable = (vendorDetails) => {
    // Paginate the table data

    var paginatedData = paginateTable(vendorDetails, page, rows);
    // Set the formatted vendor details to state for display

    if (paginatedData.paginated_data.length > 0) {
      setVendorDetails(paginatedData.paginated_data);
    }
    setTotalPages(paginatedData.totalPages);
  };
  // Function to format the vendor details data for display in HTML

  const formatVendorHtml = (vendorDetails) => {
    // Paginate the data for display

    var paginatedData = paginateTable(vendorDetails, page, rows);
    // Set the formatted vendor details to state for display

    setVendorHTML(paginatedData.paginated_data);
    setTotalVendorHtmlPages(paginatedData.totalPages);
  };
  // Function to format the processing files data for display in a table

  const formatProcessingFiles = (files) => {
    // Paginate the data for display

    var paginatedData = paginateTable(files, processingPage, rows);
    // Set the formatted data to state for display

    setProcessingFiles(paginatedData.paginated_data);
    setTotalProcessingPages(paginatedData.totalPages);
  };
  // Function to get the height of the wrapper element for display purposes

  const getWrapperHeight = () => {
    if (formStatus == "FORMS_REVIEW") {
      return "calc(100% - 235px)";
    } else {
      return "calc(100% - 166px)";
    }
  };
  // Function to handle batch deletion of selected files

  // const handleBatchDelete = () => {
  //   // Clear any existing timeouts

  //   var time = null;
  //   clearTimeout(time);
  //   // Create an array of the selected files to delete

  //   var selectedDeleteFilesSet = exportBuffer.map((item) => {
  //     if (item.isSelected) {
  //       return item.id;
  //     }
  //     return null;
  //   });
  //   // Remove any null values from the array

  //   selectedDeleteFilesSet = selectedDeleteFilesSet.filter(
  //     (item) => item !== null
  //   );
  //   // Confirm the deletion of the selected files

  //   confirmDelete(selectedDeleteFilesSet);
  //   // Reset the export buffer after a short delay

  //   if (docType === "INVOICE") {
  //     getVendor(null, null);
  //   }
  //   time = setTimeout(() => {
  //     setExportBuffer([]);
  //   }, 2000);
  // };
  const handleBatchDelete = () => {
    // Clear timeout if set
    console.log("handleBatchDelete export buffer", exportBuffer)
    var time = null;
    clearTimeout(time);
    // Confirm delete

    confirmDelete(exportBuffer);
    // Set timeout to clear export buffer after 2 seconds

    time = setTimeout(() => {
      setExportBuffer([]);
    }, 2000);
  };
  // Function to handle clicks on the vendor details checkbox

  const handleVendorCheckbox = async (event, vendor) => {
    // Stop the event from propagating

    event.stopPropagation();
    // Copy the current vendor HTML data to a temporary variable

    var vendorHTMLTemp = [...vendorHTML];
    var exportBufferTemp = [...exportBuffer];
    var fullVendorDetailsTemp = [...fullVendorDetails];
    var check = 0;
    // Loop through the vendor HTML data to find the clicked vendor

    for (let i = 0; i < vendorHTMLTemp.length; i++) {
      if (vendorHTMLTemp[i].id == vendor.id) {
        check = i;
        vendorHTMLTemp[i].isSelected = !vendorHTMLTemp[i].isSelected;
      } else {
        vendorHTMLTemp[i].isAccordianOpen = false;
      }
    }
    await setVendorHTML(vendorHTMLTemp);
    if (vendorHTMLTemp[check].isAccordianOpen) {
      getVendorDetails(vendor);
      for (let i = 0; i < fullVendorDetailsTemp.length; i++) { 
        exportBufferTemp.push(fullVendorDetailsTemp[i]);   
        }
    }
    const uniqueElements = [];
    const uniqueIdsSet = new Set();
    
    for (const element of exportBufferTemp) {
      if (!uniqueIdsSet.has(element.id)) {
        uniqueElements.push(element);
        uniqueIdsSet.add(element.id);
      }
    }
        
    setExportBuffer(uniqueElements);
    fetchExportFiles(vendor, formStatus);
  };
  // Resets the counter to zero

  const setCounterInitial = () => {
    setCounter(0);
    return counterValue;
  };
  // Returns the count of selected vendors

  const getVendorCounter = () => {
    var temp = vendorHTML.map((item) => item.isSelected);
    temp = temp.filter((item) => item == true);
    return {
      isVisible: temp.length > 0 ? true : false,
      vendorCounter: temp.length,
    };
  };
  // Returns the count of selected files

  const getFileCounter = () => {
    if (formStatus == "FORMS_REVIEW") {
      var temp = fullReviewedHtml.map((item) => item.isSelected);
      temp = temp.filter((item) => item == true);
      return {
        isVisible: temp.length > 0 ? true : false,
        fileCounter: temp.length,
      };
    } else {
      var temp = fullVendorDetails.map((item) => item.isSelected);
      temp = temp.filter((item) => item == true);
      return {
        isVisible: temp.length > 0 ? true : false,
        fileCounter: temp.length,
      };
  }
  };

  // Formats the data for review forms table and updates the state

  const formatReviewFormsTable = (forms) => {
    var paginatedData = paginateTable(forms, formPageReview, rows);
    setReviewForms(paginatedData.paginated_data);
    setTotalReviewPages(paginatedData.totalPages);
  };
  // Formats the data for parsed forms table and updates the state

  const formatFormsTable = (forms) => {
    var paginatedData = paginateTable(forms, formPage, rows);
    setParsedForms(paginatedData.paginated_data);
    setTotalFormPages(paginatedData.totalPages);
  };
  // Formats the data for exported forms table and updates the state

  const formatExportedForms = (exportedForms) => {
    var paginatedData = paginateTable(exportedForms, formPageExported, rows);
    setExportedForms(paginatedData.paginated_data);
    setTotalExportedPages(paginatedData.totalPages);
  };
  // Formats the data for deleted forms table and updates the state

  const formatDeletedForms = (deletedForms) => {
    var paginatedData = paginateTable(deletedForms, formPageDeleted, rows);
    setDeletedForms(paginatedData.paginated_data);
    setTotalDeletedPages(paginatedData.totalPages);
  };
  // Function to format receipt data for table display

  const formatReceiptData = (receiptData) => {
    var paginatedData = paginateTable(receiptData, receiptPage, rows);
    setReceiptVendorDetails(paginatedData.paginated_data);
    setTotalReceiptPages(paginatedData.totalPages);
  };
  // Function to handle sort option selection

  const handleSortOptionSelect = (option) => {
    // Determine active vendor index

    if (docType === "RECEIPT") {
      var activeVendorIndex = receiptList.findIndex(
        (vendor) => vendor.isAccordianOpen == true
      );
    } else {
      var activeVendorIndex = vendorHTML.findIndex(
        (vendor) => vendor.isAccordianOpen == true
      );
    }
    // Close sort menu and update state

    handleCloseSort();
    setActiveVendor(activeVendorIndex);
    setSortBy(option);
    // Retrieve form details if docType is FORM

    if (docType === "FORM") {
      getFormDetails("FORM", option.value, null, null, null);
    }
  };
  // Function to handle page changes

  const handlePageChange = async (event, value) => {
    event.stopPropagation();
    // Set page state depending on form status

    if (formStatus == "FORMS_PROCESSING") {
      setProcessingPage(value);
    } else if (formStatus == "FORMS_REVIEW") {
      setFormPageReview(value);
    } else if (formStatus == "FORMS_PARSED") {
      setPage(value);
    } else if (formStatus == "FORMS_EXPORTED") {
      setFormPageExported(value);
    } else if (formStatus == "FORMS_DELETED") {
      setFormPageDeleted(value);
    }
  };
  // Function to display file image

  const showFileImage = (data) => {
    setFilename(data.original_file_name);
    setFileId(data.id);
  };
  // Function to check if user is authenticated and retrieve email

  const myFunction = async () => {
    if (!authToken) {
      props.history.replace({ pathname: "/Login" });
    }
    var loggedInUserEmail = fetchLoggedInUserEmail();
    setEmail(loggedInUserEmail);
  };
  // Function to set year range

  const setYearset = async () => {
    var date = new Date();
    var registerYear = await localStorage.getItem("register_year");
    if (!registerYear) {
      registerYear = await sessionStorage.getItem("register_year");
    }
    var yearSet = [];
    for (let i = registerYear - 1; i < date.getFullYear(); i++) {
      yearSet.push(i + 1);
    }
    setYears(yearSet);
  };

  useEffect(() => {
    // This effect calls the `myFunction` function when the `status` state changes.
    // It has no cleanup function.
    myFunction();
  }, [status]);

  useEffect(() => {
    // This effect gets triggered when the `processingFiles` state changes.
    // It sets up an interval that calls the `getProcessedFiles` function every 6 seconds.
    // It has a cleanup function that clears the interval when the component unmounts.
    if (processingFiles.length > 0) {
      const interval = setInterval(() => {
        getProcessedFiles();
      }, 6000);
      return () => clearInterval(interval);
    }
  }, [processingFiles]);

  useEffect(() => {
    // This effect gets triggered when the `dateErrMessage` state changes.
    // It sets a timeout to clear the `dateErrMessage` state after 3 seconds.
    // It has no cleanup function.
    if (dateErrMessage) {
      setTimeout(() => {
        setDateErrMessage("");
      }, 3000);
    }
  }, [dateErrMessage]);

  useEffect(() => {
    // This effect gets triggered when the `formStatus` state changes.
    // It calls the appropriate function based on the value of `formStatus`.
    // It has no cleanup function.
    if (formStatus) {
      if (formStatus === "FORMS_PROCESSING") {
        getProcessingFiles();
      } else {
        if (formStatus == "FORMS_PARSED" || formStatus == "FORMS_EXPORTED") {
          getParsedForms(null, null, sortBy.value, null);
        } else {
          getFormDetails("FORM", null, null, null, null);
        }
      }
    }
  }, [formStatus]);

  useEffect(() => {
    // This effect gets triggered when `searchFocused`, `searchCounter`, `search`, `fileSearch`, or `vendorSearch` change.
    // If `searchFocused` is true and `searchCounter` is greater than 0, it blurs the search input and triggers a search for the appropriate data.
    // It has no cleanup function.
    if (searchFocused && searchCounter > 0) {
      searchInput.current.blur();
      if (["FORMS_PARSED", "FORMS_EXPORTED"].includes(formStatus)) {
        getParsedForms(null, null, sortBy.value, null);
      } else {
        getFormDetails("FORM", null, null, null, null);
      }
      setSearchAttempt(false);
    }
  }, [searchFocused, search, fileSearch, vendorSearch]);

  useEffect(() => {
    // This effect gets triggered when `page` changes.
    // It calls the appropriate function to format the data for the invoice table.
    // It has no cleanup function.
    if (invoiceStatus == "INVOICE_REJECTED") {
      formatVendorHtml(fullVendorHtml);
    } else {
      formatInvoiceTable(fullVendorDetails);
    }
  }, [page]);

  useEffect(() => {
    // This effect gets triggered when `formPage` changes.
    // It calls the `formatFormsTable` function to format the data for the parsed forms table.
    // It has no cleanup function.
    formatFormsTable(fullParsedForms);
  }, [formPage]);
  // This useEffect hook formats the review forms table based on the `formPageReview` state.

  useEffect(() => {
    formatReviewFormsTable(fullReviewForms);
  }, [formPageReview]);

  // This useEffect hook formats the exported forms table based on the `formPageExported` state.

  useEffect(() => {
    formatExportedForms(fullExportedForms);
  }, [formPageExported]);
  // This useEffect hook formats the processing files table based on the `processingPage` state.

  useEffect(() => {
    formatProcessingFiles(fullProcessingFiles);
  }, [processingPage]);
  // This useEffect hook formats the deleted forms table based on the `formPageDeleted` state.

  useEffect(() => {
    formatDeletedForms(fullDeletedForms);
  }, [formPageDeleted]);
  // This useEffect hook fetches vendor details if a vendor is selected or
  // if a date range is selected and `docType` is not "FORM".
  useEffect(() => {
    var openListIndex = vendorHTML.findIndex(
      (vendor) => vendor.isAccordianOpen === true
    );
    if (openListIndex > -1) {
      getVendorDetails(vendorHTML[openListIndex]);
    } else {
      if (dateRange.from !== null && dateRange.to !== null) {
        if (docType === "FORM") {
        } else {
        }
      }
    }
  }, [dateRange]);
  // This useEffect sets days, year set and user id in local state on component mount.

  useEffect(() => {
    let days = [];
    const date = new Date();

    for (let i = 1; i < 32; i++) {
      if (i < 10) days.push(`0${i}`);
      else days.push(i);
    }

    setDays(days);
    setYearset();
    var user = localStorage.getItem("user_id");
    if (!user) {
      user = sessionStorage.getItem("user_id");
    }
    setUserId(user);
  }, []);
  // This function returns the canvas height for invoice based on the form status.

  const getInvoiceCanvasHeight = () => {
    switch (formStatus) {
      case "FORMS_PARSED":
        return "100%";
        break;
      case "FORMS_REVIEW":
        return "calc(100% - 260px)";
        break;
      default:
        return "100%";
    }
  };
  // This function reads section_active from local storage and sets it to activeTab and formStatus.

  const getActiveSection = () => {
    asyncLocalStorage("section_active")
      .then(function () {
        return asyncLocalStorage("section_active");
      })
      .then(function (value) {
        if (value) {
          if (value.includes("FORM")) {
            setActiveTab(value);
            setFormStatus(value);
          }
        }
      });
  };
  // A function that returns a promise that resolves to the value of 'section_active' in local storage

  const asyncLocalStorage = () => {
    return Promise.resolve().then(function () {
      let activeSect = localStorage.getItem("section_active");
      if (!activeSect) {
        activeSect = sessionStorage.getItem("section_active");
      }
      return activeSect;
    });
  };

  // A function that initializes the WebSocket session

  const initWebSocketSession = () => {
    const socket = new WebSocket(
      `ws://103.46.239.133:8808/ws/?user_id=${userId}&file_type=${docType}`
    );
    // Set up the WebSocket

    socket.onopen = () => {
      timerVal = setInterval(() => {
        socket.send("hi");
      }, 2000);
    };

    socket.onmessage = (evt) => {
      // listen to data sent from the websocket server
      var processingFiles = JSON.parse(evt.data);
      formatProcessingFiles(processingFiles);
      setFullProcessingFiles(processingFiles);
    };

    socket.onclose = () => {
      // automatically try to reconnect on connection loss
    };
  };
  // A function that closes the profile menu

  const closeProfileMenu = () => {
    if (profileMenuShow || $(".dropdown-menu").hasClass("show")) {
      setProfileMenuShow(false);
      $(".dropdown-menu").removeClass("show");
    }
  };
  // A function that handles adding an invoice

  const addInvoice = (event) => {
    let fileData = null;
    if (event.target.files.length) {
      fileData = event.target.files;
      const formData = new FormData();
      const checkNoPagesData = new FormData();
      for (let i = 0; i < fileData.length; i++) {
        formData.append("file", fileData[i]);
        checkNoPagesData.append("file", fileData[i]);
      }
      checkNoPagesData.append("user", sessionStorage.getItem('user_id'));
      checkNoPagesData.append("company_name", ogi);
      checkNoPagesData.append("plan_name", planName);
      axios({
        method: "post",
        url: `${EXTRICATOR_BASE_URL}/check-max-number-of-pages/`,
        data: checkNoPagesData,
        headers: {
          Authorization: `Bearer ${authToken}`,
        },
      })
        .then(function (response) {
          if (response.data.status) {
            setToastColor("success");
            setSuccessMsg(response.data.message);
            setSnackOpen(true);
            formData.append("file_type", docType);
            formData.append("ogi", ogi);
            formData.append("plan_name", planName);
            setInvoices(formData);
            setCheckMaxNoPages(false)
            setFileLength(fileData.length);
            setFileMessage("");
          }
          else{
            setFileMessage(response.data.message);
            setSnackOpen(true);
            setInvoices(false);
            setCheckMaxNoPages(true)   
            setFileLength(0);
            setUploaded(false);
            setFileType("");
            
          }
        });

    }
  };
  // Handle showing the file preview after a delay

  const handleShowPreview = (fileDetail) => {
    // Use a timeout to delay showing the preview

    previewTime = setTimeout(() => {
      showFileImage(fileDetail);
    }, [2000]);
  };
  // Handle hiding the file preview and clearing the timeout

  const handleHidePreview = () => {
    setFilename("");
    setFileId("");
    clearTimeout(previewTime);
  };
  // Handle toggling the checkbox for a file

  const handleCheckbox = (file) => {
    // Create a copy of the vendor HTML array

    var vendorHTMLTemp = [...fullVendorHtml];
    // Find the index of the file to be rejected

    var rejectedFileIndex = vendorHTMLTemp.findIndex(
      (item) => item.id === file.id
    );
    // Invert the selection for the file

    vendorHTMLTemp[rejectedFileIndex].isSelected = !file.isSelected;
    // Update the vendor HTML array with the changed selection

    setFullVendorHtml(vendorHTMLTemp);
  };
  // Handle changing the expanded/collapsed state of the vendor accordion

  const handleAccordianChange = (vendor) => (event, isExpanded) => {
    // Create copies of the vendor HTML and receipt list arrays

    var vendorHTMLTemp = [...vendorHTML];
    var receiptListTemp = [...receiptList];
    // Only proceed if the input field is not currently focused

    if (!goInputFocused) {
      // Loop through the vendor HTML array

      for (let i = 0; i < vendorHTMLTemp.length; i++) {
        // Toggle the expanded/collapsed state for the current vendor

        if (vendor.id == vendorHTMLTemp[i].id) {
          vendorHTMLTemp[i].isAccordianOpen =
            !vendorHTMLTemp[i].isAccordianOpen;
        } else {
          vendorHTMLTemp[i].isAccordianOpen = false;
        }
      }
      // Update the vendor HTML array with the changed state

      setVendorHTML(vendorHTMLTemp);
      if (!vendor.isAccordianOpen) {
        return;
      }
      // If the vendor is being expanded, retrieve its details

      getVendorDetails(vendor);
    }
  };
  // Handle selecting a file for export

  const toggleFileSelect = (event, fileDetail, vendor) => {
    // Create copies of the vendor HTML, full vendor details, and export buffer arrays

    var vendorHTMLTemp = [...vendorHTML];
    var fullVendorDetailsTemp = [...fullVendorDetails];
    var exportBufferTemp = [...exportBuffer];
    // Loop through the full vendor details array

    for (let i = 0; i < fullVendorDetailsTemp.length; i++) {
      // Toggle the selection for the current file

      if (fullVendorDetailsTemp[i].id === fileDetail.id) {
        fullVendorDetailsTemp[i].isSelected =
          !fullVendorDetailsTemp[i].isSelected;
        // If the file is being selected, add it to the export buffer

        if (fullVendorDetailsTemp[i].isSelected) {
          exportBufferTemp.push(fullVendorDetailsTemp[i]);
          // If the file is being deselected, remove it from the export buffer
        } else {
          exportBufferTemp = exportBufferTemp.filter(
            (item) => item.id != fullVendorDetailsTemp[i].id
          );
        }
      }
    }
    // Update the export buffer with the changed selection

    setExportBuffer(exportBufferTemp);
    // Check if all files for the vendor are selected

    var uncheckedIndex = fullVendorDetailsTemp.findIndex(
      (file) => file.isSelected == false
    );

    if (uncheckedIndex < 0) {
      handleVendorCheckbox(event, vendor);
    } else {
      for (let i = 0; i < vendorHTMLTemp.length; i++) {
        vendorHTMLTemp[i].isSelected = false;
      }
      setVendorHTML(vendorHTMLTemp);
    }
    formatInvoiceTable(fullVendorDetailsTemp);
  };

  const getVendorColor = (classes, val) => {
    // Get the vendor IDs of selected vendors

    let temp = selectedVendor.split(",");
    let newArr = temp.map((item) => Number(item));
    // If the current vendor's ID is in the selected vendors array, use a selected color class

    if (newArr.includes(val)) {
      return classes.summary_rootSelected;
    } else {
      return classes.summary_root;
    }
  };

  const checkUserSubscription = () => {
    const reqData = {
      user: user_id,
      company_name: ogi,
      plan_name: planName 
    };
    axios({
      method: "post",
      url: `${EXTRICATOR_BASE_URL}/check-subscription/`,
      data: reqData,
      headers: {
        Authorization: `Bearer ${authToken}`,
      },
    })
      .then(function (response) {
        if (response.data.status) {
          setToastColor("success");
          setSuccessMsg(response.data.message);
          setSnackOpen(true);

          setUploadModal(true)
        }
        else{
          setToastColor("error");
          setSuccessMsg(response.data.message);

          setSnackOpen(true);
          setUploadModal(false)
        }
      });
  }

  const uploadInvoice = () => {
    if (invoices) {
      setCheckMaxNoPages(false)
      setFileUploading(true);
      axios({
        method: "post",
        url: `${EXTRICATOR_BASE_URL}/file_upload/`,
        data: invoices,
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: `Bearer ${authToken}`,
        },
      })
        .then(function (response) {
          setToastColor("success");
          setSuccessMsg(response.data.message);
          setSnackOpen(true);
          setExpanded(false);
          setUpdateTable(!updateTable);
          setUploaded(true);
          setFileUploading(false);
          setUploadModal(false);
          setInvoices(false);
          setFileMessage("");
          setFileLength(0);
          setFormStatus("FORMS_PROCESSING");
          setActiveTab("FORMS_PROCESSING");
        })
        .catch(function (error) {
          if (error.response) {
            if (error.response.data && error.response.data.non_field_errors) {
              setToastColor("error");
              setSuccessMsg(error.response.data.non_field_errors[0]);
              setSnackOpen(true);
              setFileUploading(false);
              setUploadModal(false);
              setInvoices(false);
              setFileMessage("");
              setFileLength(0);
            }
          }
        });
    } else {
      // Show error message if no files selected
      setCheckMaxNoPages(true)
      setFileMessage("Please select a file to proceed.");
    }
  };
  // Deletes a file from the server

  const confirmDelete = (file_id) => {
    setDeleteFileProgress(true);
    let reqBody = {};
    // If `file_id` is a number, delete a single file
    // Otherwise, delete multiple files
    let idTemp = ""
    if (typeof file_id == "number") {
      reqBody = { file_id: file_id, ogi: ogi };
    } else {
      idTemp = file_id.map((item) => item.id);
      reqBody = { file_id: idTemp.join(","), ogi: ogi };
        }
    axios({
      method: "post",
      url: `${EXTRICATOR_BASE_URL}/delete_file/?ogi=${ogi}`,
      data: reqBody,
      headers: { Authorization: `Bearer ${authToken}` },
    })
      .then((res) => {
        // Refresh the forms list

        if (formStatus === "FORMS_PROCESSING") {
        } else {
          if (formStatus == "FORMS_PARSED" || formStatus == "FORMS_EXPORTED") {
            getParsedForms(null, null, sortBy.value, null);
          } else {
            getFormDetails("FORM", null, null, null, null);
          }
        }
        setDeleteFileProgress(false);
        setDeletableFileId(null);
        setToastColor("success");
        setSuccessMsg(res.data.message);
        setSnackOpen(true);
      })
      .catch((error) => {
        if (error.response) {
          if (error.response.data.non_field_errors) {
            if (
              error.response.data.non_field_errors[0].includes(
                "Kindly pay the bill"
              )
            ) {
              setToastColor("error");
              setSuccessMsg(error.response.data.non_field_errors[0]);
              setSnackOpen(true);
            } else {
              checkOrganizationError(
                error.response.data.non_field_errors[0],
                props.history
              );
            }
          }
        }
        setDeletableFileId(null);
        setDeleteFileProgress(false);
      });
  };
  // Handle change of sort option

  const handleSortChange = (e) => {
    setSortOption(e.target.value);
  };
  // Handle click on calendar icon to open/close the date range selector

  const onCalandarClick = (event) => {
    setAnchorEl(event.currentTarget);
    openCalander(!calander);
    setTooltip(calander);
  };
  // Handle closing of the date range selector

  const handleCalandarClose = (flag) => {
    setAnchorEl(null);
    if (flag == "reset" || dateErrMessage) {
      // Reset the date range and from/to objects

      setFrom({
        ...from,
        day: null,
        month: null,
        year: null,
      });

      setTo({
        ...to,
        day: null,
        month: null,
        year: null,
      });
      setTooltipDate("");
      setDateRange({ from: null, to: null });
      getFormDetails("FORM", null, null, null, null);
    }
    if (tooltipDate) {
      setTooltip(true);
    }
  };
  // fetches vendor details based on the filter options selected

  const getVendor = async (
    from = null,
    to = null,
    sortBy = null,
    search = null
  ) => {
    setLoading(true);
    if (
      invoiceStatus === "INVOICE_REJECTED" ||
      receiptStatus === "RECEIPT_REJECTED"
    ) {
      // fetches rejected files if invoice/receipt rejected status is selected

      axios({
        method: "POST",
        url: `${EXTRICATOR_BASE_URL}/rejected/?file_type=${docType}&from=${from}&to=${to}&type=${sortBy}&search=${search}&ogi=${ogi}`,
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: `Bearer ${authToken}`,
        },
      })
        .then(function (response) {
          var rejectedFiles = response.data;
          // sets isSelected field to false for each rejected file

          rejectedFiles = rejectedFiles.map((item) => ({
            ...item,
            isSelected: false,
          }));
          // formats and updates the vendorHtml with the rejected files

          formatVendorHtml(rejectedFiles);
          setFullVendorHtml(rejectedFiles);
          setPage(1);
          setLoading(false);
        })
        .catch(function (error) {
          setLoading(false);
        });
    } else {
      // fetches vendor details if parsed form status is selected

      setReceiptPage(1);
      setPage(1);
      axios({
        method: "GET",
        url: `${EXTRICATOR_BASE_URL}/vendor_details/?status=PARSED&search=null&from=null&to=null&file_type=FORM&search_on=file&type=null&ogi=${ogi}`,
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: `Bearer ${authToken}`,
        },
      })
        .then(function (response) {
          var vendors = response.data;
          // sets isSelected and isAccordianOpen field to false for each vendor

          vendors = vendors.map((item) => ({
            ...item,
            isSelected: false,
            isAccordianOpen: false,
          }));
          // updates the vendorHtml with the parsed form details

          setVendorHTML(vendors);
          setVendorsLength(vendors.length);
          setLoading(false);
        })
        .catch(function (error) {
          setLoading(false);

          if (error.response) {
            if (error.response.status === 401) {
              handleLogout(props.history);
            }
            if (error.response.data.non_field_errors) {
              if (
                error.response.data.non_field_errors[0].includes(
                  "Kindly pay the bill"
                )
              ) {
                setToastColor("error");
                setSuccessMsg(error.response.data.non_field_errors[0]);
                setSnackOpen(true);
              } else {
                checkOrganizationError(
                  error.response.data.non_field_errors[0],
                  props.history
                );
              }
            }
          }
        });
    }
  };
  // Returns whether filters should be disabled or not

  const getFiltersDisabled = () => {
    var sortEnabled = vendorHTML.findIndex(
      (vendor) => vendor.isAccordianOpen == true
    );
    if (sortEnabled > -1) {
      return false;
    }
    return true;
  };
  // Changes the form status and sets search, export buffer, and parsed value to default values

  const handleFormStatusChange = (newValue) => {
    setFormStatus(newValue == 0 ? "FORMS_PARSED" : "FORMS_EXPORTED");
    setSearch("");
    setExportBuffer([]);
    setParsedValue(newValue);
  };
  // Fetches vendor details for a particular vendor and sets vendor details and loading state

  const getVendorDetails = (vendor) => {
    setVendorDetails([]);
    var exportBufferTemp = [...exportBuffer];
    var from = dateRange.from,
      to = dateRange.to,
      type = sortBy.value;
    setLoading(true);
    axios({
      method: "GET",
      url: `${EXTRICATOR_BASE_URL}/vendor_file_details/?id=${
        vendor.id
      }&from=${from}&to=${to}&type=${type}&status=${
        formStatus.split("_")[1]
      }&search=${search.length ? search : null}&ogi=${ogi}`,
      headers: {
        "Content-Type": "multipart/form-data",
        Authorization: `Bearer ${authToken}`,
      },
    })
      .then(function (response) {
        var vendorDetails = response.data;
        if (vendor.isSelected && vendor.isAccordianOpen) {
          vendorDetails = vendorDetails.map((item) => ({
            ...item,
            isSelected: true,
          }));
        } else {
          vendorDetails = vendorDetails.map((item) => ({
            ...item,
            isSelected: false,
          }));
        }
        formatInvoiceTable(vendorDetails);
        setFullVendorDetails(vendorDetails);
        setPage(1);
        setLoading(false);
      })
      .catch((error) => {
        if (error.response.data.non_field_errors) {
          if (
            error.response.data.non_field_errors[0].includes(
              "Kindly pay the bill"
            )
          ) {
            setToastColor("error");
            setSuccessMsg(error.response.data.non_field_errors[0]);
            setSnackOpen(true);
          } else {
            checkOrganizationError(
              error.response.data.non_field_errors[0],
              props.history
            );
          }
        }
        setLoading(false);
      });
  };
  // Retrieve processed files

  const getProcessedFiles = () => {
    // Create a copy of processingFiles state

    var processingFilesTemp = [...processingFiles];
    // Get an array of file ids

    let statusFiles = processingFilesTemp.map((item) => item.id);
    // Make a GET request to fetch the processed files for the given file ids

    axios({
      method: "GET",
      url: `${EXTRICATOR_BASE_URL}/processed_files/?ogi=${ogi}&file_id=${statusFiles.join(
        ","
      )}`,
      headers: {
        "Content-Type": "multipart/form-data",
        Authorization: `Bearer ${authToken}`,
      },
    })
      .then(function (response) {
        // Format the processing files data

        formatProcessingFiles(response.data.file_data);
        // Set the full processing files state

        setFullProcessingFiles(response.data.file_data);
      })
      .catch(function (error) {
        // Handle errors

        if (error.response) {
          if (error.response.status === 401) {
            handleLogout(props.history);
          }
          if (error.response.data.non_field_errors) {
            if (
              error.response.data.non_field_errors[0].includes(
                "Kindly pay the bill"
              )
            ) {
              setToastColor("error");
              setSuccessMsg(error.response.data.non_field_errors[0]);
              setSnackOpen(true);
            } else {
              checkOrganizationError(
                error.response.data.non_field_errors[0],
                props.history
              );
            }
          }
        }
      });
  };
  // Retrieve processing files

  const getProcessingFiles = () => {
    setLoading(true);
    // Make a GET request to fetch the processing files

    axios({
      method: "GET",
      url: `${EXTRICATOR_BASE_URL}/processing_files/?file_type=form&ogi=${ogi}`,
      headers: {
        "Content-Type": "multipart/form-data",
        Authorization: `Bearer ${authToken}`,
      },
    })
      .then(function (response) {
        // Format the processing files data

        formatProcessingFiles(response.data.file_data);
        // Set the full processing files state

        setFullProcessingFiles(response.data.file_data);
        setLoading(false);
      })
      .catch(function (error) {
        // Handle errors

        if (error.response) {
          if (error.response.status === 401) {
            handleLogout(props.history);
          }
          if (error.response.data.non_field_errors) {
            if (
              error.response.data.non_field_errors[0].includes(
                "Kindly pay the bill"
              )
            ) {
              setToastColor("error");
              setSuccessMsg(error.response.data.non_field_errors[0]);
              setSnackOpen(true);
            } else {
              checkOrganizationError(
                error.response.data.non_field_errors[0],
                props.history
              );
            }
          }
        }
        setLoading(false);
      });
  };
  /**
   * Fetches details of forms from the server based on the given parameters.
   *
   * @param {string} fileTypes - The type of file to fetch details for (default is 'FORM').
   * @param {string} sortBy - The field to sort the results by (default is null).
   * @param {string} from - The starting date of the range to filter results by (default is null).
   * @param {string} to - The ending date of the range to filter results by (default is null).
   * @param {string} search - The search query to filter results by (default is null).
   */
  const getFormDetails = (
    fileTypes = "FORM",
    sortBy = null,
    from = null,
    to = null,
    search = null
  ) => {
    setLoading(true);
    setParsedForms([]);
    axios({
      method: "GET",
      url: `${EXTRICATOR_BASE_URL}/doc_details/?file_type=${fileTypes}&status=${
        formStatus.split("_")[1]
      }&sort=${sortBy}&from=${from}&to=${to}&search=${search}&ogi=${ogi}`,
      headers: {
        "Content-Type": "multipart/form-data",
        Authorization: `Bearer ${authToken}`,
      },
    })
      .then(function (response) {
        if (formStatus == "FORMS_REVIEW") {
          // Filter and format the response data for the 'REVIEW' status.

          let reviewArr = response.data.filter(
            (item) => item.status === "REVIEW"
          );
          reviewArr = reviewArr.map((item) => ({ ...item, isSelected: false }));
          formatReviewFormsTable(reviewArr);
          setFullReviewForms(reviewArr);
          setShowModelOption(true);
        } else if (formStatus == "FORMS_PARSED") {
          // Filter and format the response data for the 'PARSED' status.

          let parsedArr = response.data.filter(
            (item) => item.status === "PARSED"
          );
          parsedArr = parsedArr.map((item) => ({ ...item, isSelected: false }));
          formatFormsTable(parsedArr);
          setFullParsedForms(parsedArr);
        } else if (formStatus == "FORMS_EXPORTED") {
          // Filter and format the response data for the 'EXPORTED' status.

          let exportedArr = response.data.filter(
            (item) => item.status === "EXPORTED"
          );
          exportedArr = exportedArr.map((item) => ({
            ...item,
            isSelected: false,
          }));
          formatExportedForms(exportedArr);
          setFullExportedForms(exportedArr);
        } else if (formStatus == "FORMS_DELETED") {
          // Filter and format the response data for the 'DELETE' status.

          let deletedArr = response.data.filter(
            (item) => item.status === "DELETE"
          );
          deletedArr = deletedArr.map((item) => ({
            ...item,
            isSelected: false,
          }));
          formatDeletedForms(deletedArr);
          setFullDeletedForms(deletedArr);
        }
        setLoading(false);
      })
      .catch(function (error) {
        // Handle any errors that occur during the request.

        if (error.response) {
          if (error.response.status === 401) {
            handleLogout(props.history);
          }
          if (error.response.data.non_field_errors) {
            if (
              error.response.data.non_field_errors[0].includes(
                "Kindly pay the bill"
              )
            ) {
              setToastColor("error");
              setSuccessMsg(error.response.data.non_field_errors[0]);
              setSnackOpen(true);
            } else {
              checkOrganizationError(
                error.response.data.non_field_errors[0],
                props.history
              );
            }
          }
        }

        setLoading(false);
      });
  };
  // Function to export selected form data to Excel
  const exportFormToExcel = () => {
    // Clear any existing timeout

    var time = null;
    clearTimeout(time);
    // Get an array of the IDs of the selected forms from the exportBuffer array

    var selectedReceiptFilesSet = exportBuffer.map((item) => {
      if (item.isSelected) {
        return item.id;
      }
      return null;
    });
    // Remove any null values from the selectedReceiptFilesSet array

    selectedReceiptFilesSet = selectedReceiptFilesSet.filter(
      (item) => item !== null
    );
    // Construct the URL for the Excel export, including the IDs of the selected forms and the organization ID
    var xlsUrl = `${EXTRICATOR_BASE_URL}/export_to_excel/?id=${selectedReceiptFilesSet.join(
      ","
    )}&ogi=${ogi}`;
    // Open a new window with the Excel export URL

    window.open(xlsUrl);
    // Call the getParsedForms function to update the form data with any changes

    getParsedForms(null, null, sortBy.value, null);
    // Set a timeout to clear the exportBuffer array after 3 seconds

    time = setTimeout(() => {
      setExportBuffer([]);
    }, 3000);
  };
  // Function to handle downloading a single form as an Excel file

  const handleSingleXlsDownload = (fileId) => {
    // Construct the URL for the Excel export, including the ID of the selected form and the organization ID

    var xlsUrl = `${EXTRICATOR_BASE_URL}/export_to_excel/?id=${fileId}&ogi=${ogi}`;
    // Open a new window with the Excel export URL

    window.open(xlsUrl);
    // Call the getParsedForms function to update the form data with any changes

    getParsedForms(null, null, sortBy.value, null);
  };
  // Function to handle the callback for the pagination component

  const handlePaginationCallback = (focused) => setGoInputFocused(focused);
  // Function to format a date string to YYYY-MM-DD format

  const formatDate = (data) => {
    let data1 = data.slice(0, 10);
    return data1;
  };
  // Function to handle changes to the "from" date form input

  const handleFormChange = (event, value) => {
    // Update the "from" state object with the new input value

    setFrom({
      ...from,
      [event.target.name]: event.target.value,
    });
    // Check if the "day" field was changed

    if (event.target.name == "day") {
      // If the new value is greater than 28, filter the monthsSet array to exclude February

      if (event.target.value > 28) {
        if (event.target.value == 31) {
          let monthsTemp = monthsSet.filter((item) => item.total_days == 31);
          setFromMonths(monthsTemp);
        } else {
          // If the new value is 28 or less, use the full monthsSet array

          let monthsTemp = monthsSet.filter((item) => item.month !== "Feb");
          setFromMonths(monthsTemp);
        }
      } else {
        setFromMonths(monthsSet);
      }
    }
  };
  // Function to handle changes to the "to" date form input

  const handleToChange = (event, value) => {
    // Update the "to" state object with the new input value

    setTo({
      ...to,
      [event.target.name]: event.target.value,
    });
    // Check if the "day" field was changed

    if (event.target.name == "day") {
      // If the new value is greater than 28, filter the monthsSet array to exclude February

      if (event.target.value > 28) {
        let monthsTemp = monthsSet.filter((item) => item.month !== "Feb");
        setToMonths(monthsTemp);
        if (event.target.value == 31) {
          let monthsTemp = monthsSet.filter((item) => item.total_days == 31);
          setToMonths(monthsTemp);
        } else {
          let monthsTemp = monthsSet.filter((item) => item.month !== "Feb");
          setToMonths(monthsTemp);
        }
      } else {
        // If the new value is 28 or less, use the full monthsSet array

        setToMonths(monthsSet);
      }
    }
  };
  // Function to handle the search form submission

  const handleSearch = (e) => {
    e.preventDefault();
    // Close the calendar component

    handleCalandarClose(null);
    // Set a flag indicating that a search attempt was made

    setSearchAttempt(true);
    let fromDate;
    let toDate;
    // Check that all "from" and "to" fields have values

    if (from.month && from.day && from.year && to.month && to.day && to.year) {
      if (from.month < 10) {
        fromDate = `0${from.month} ${from.day} ${from.year}`;
      } else {
        fromDate = `${from.month} ${from.day} ${from.year}`;
      }
      if (to.month < 10) {
        toDate = `0${to.month} ${to.day} ${to.year}`;
      } else {
        toDate = `${to.month} ${to.day} ${to.year}`;
      }
      if (formatDate && toDate) {
        // Check if vendorChecked is false

        if (!vendorChecked) {
          getVendor(fromDate, toDate);
        } else {
          // If vendorChecked is true, call the getVendorDetails function with the vendor ID and from and to dates

          getVendorDetails(id, fromDate, toDate, null);
        }
      }
      // Set the tooltipDate and tooltip states to show the selected date range

      setTooltipDate(
        `${from.day}/${from.month}/${from.year} to ${to.day}/${to.month}/${to.year}`
      );
      setTooltip(true);
    } else {
      if (["FORMS_PARSED", "FORMS_EXPORTED"].includes(formStatus)) {
        if (searchSubject == "file") {
          getParsedForms(null, null, sortBy.value, fileSearch);
        } else {
          getParsedForms(null, null, sortBy.value, vendorSearch);
        }
      } else {
        getFormDetails("FORM", "by_name", null, null, search);
      }
    }
  };
  // Function to handle the date search form submission

  const handleDateSearch = (date, from) => {
    // Set the date range state to the selected date range

    setDateRange(date);
    // Set the tooltipDate state to show the selected date range

    setTooltipDate(
      `${from.day}/${from.month}/${from.year} to ${to.day}/${to.month}/${to.year}`
    );
    // Set the tooltip state to true to show the tooltip

    setTooltip(true);
    // Close the calendar component

    handleCalandarClose(null);
    // Call the getFormDetails function to retrieve form data for the selected date range

    getFormDetails("FORM", null, date.from, date.to, null);
  };
  // Function to handle the filter search form submission

  const filterSearch = (e) => {
    e.preventDefault();
    // Reset the search, vendorSearch, and fileSearch states to empty strings

    setSearch("");
    setVendorSearch("");
    setFileSearch("");

    let todayDate = new Date();
    let fromDate;
    let toDate;
    // Check that all "from" and "to" fields have values

    if (from.month && from.day && from.year && to.month && to.day && to.year) {
      // Format the from and to dates as strings

      if (from.month < 10) {
        fromDate = `0${from.month} ${from.day} ${from.year}`;
      } else {
        fromDate = `${from.month} ${from.day} ${from.year}`;
      }
      if (to.month < 10) {
        toDate = `0${to.month} ${to.day} ${to.year}`;
      } else {
        toDate = `${to.month} ${to.day} ${to.year}`;
      }
      // Create an object with the "from" and "to" date values

      var date = {
        from: fromDate,
        to: toDate,
      };
      // Replace spaces in the date strings with slashes for compatibility with the Date constructor

      var fromDateString = date.from.split(" ").join("/");
      var toDateString = date.to.split(" ").join("/");

      // Create Date objects for the "from" and "to" dates
      var fromDateObject = new Date(fromDateString);
      var toDateObject = new Date(toDateString);
      // Check that the "to" date is not in the future

      if (fromDateObject <= toDateObject) {
        if (to.year == todayDate.getFullYear()) {
          if (to.month <= 1 + todayDate.getMonth()) {
            if (to.month == 1 + todayDate.getMonth()) {
              if (to.day <= todayDate.getDate()) {
                handleDateSearch(date, from);
              } else {
                setDateErrMessage("You can not select a future date");
              }
            } else {
              handleDateSearch(date, from);
            }
          } else {
            setDateErrMessage("You can not select a future date");
          }
        } else {
          handleDateSearch(date, from);
        }
      } else {
        setDateErrMessage(
          "Start date should be less than or equal to End date"
        );
      }
    }
  };
  // useEffect hook to split the selectedVendor state string into an array of vendors when it changes

  useEffect(() => {
    let ssr = selectedVendor.split(",");
  }, [selectedVendor]);
  // Function to fetch the export files for a vendor

  const fetchExportFiles = (vendor, docStatus) => {
    // Create a temporary copy of the exportBuffer state

    var exportBufferTemp = [...exportBuffer];
    // Initialize a variable to count the number of vendors in the export buffer with the same ID as the selected vendor

    var sameVendorCount = 0;
    var from = dateRange.from,
      to = dateRange.to,
      type = sortBy.value;
    // Set the loading state to true

    setLoading(true);
    // Make a GET request to the extricator API to retrieve the vendor's file details

    axios({
      method: "GET",
      url: `${EXTRICATOR_BASE_URL}/vendor_file_details/?id=${
        vendor.id
      }&from=${from}&to=${to}&type=${type}&status=${
        docStatus.split("_")[1]
      }&search=${search.length ? search : null}&ogi=${ogi}`,
      headers: {
        "Content-Type": "multipart/form-data",
        Authorization: `Bearer ${authToken}`,
      },
    })
      .then(function (response) {
        // If the response is successful, update the exportFiles variable with the response data

        var exportFiles = response.data;
        // If the vendor is selected, add a "isSelected" property to each export file and add the export files to the export buffer state

        if (vendor.isSelected) {
          exportFiles = exportFiles.map((item) => ({
            ...item,
            isSelected: true,
          }));

          for (let i = 0; i < exportBufferTemp.length; i++) {
            if (exportBufferTemp[i].vendor_id == vendor.id) {
              sameVendorCount++;
            }
          }
          if (sameVendorCount < 1) {
            exportBufferTemp = [...exportBufferTemp, ...exportFiles];
            setExportBuffer(exportBufferTemp);
          }
        } else {
          exportFiles = exportFiles.map((item) => ({
            ...item,
            isSelected: false,
          }));
          exportBufferTemp = exportBufferTemp.filter(
            (item) => item.vendor_id != vendor.id
          );
          setExportBuffer(exportBufferTemp);
        }
        setLoading(false);
      })
      .catch((error) => {
        // If there is an error in the API response, handle the error based on the error message

        if (error.response.data.non_field_errors) {
          if (
            error.response.data.non_field_errors[0].includes(
              "Kindly pay the bill"
            )
          ) {
            setToastColor("error");
            setSuccessMsg(error.response.data.non_field_errors[0]);
            setSnackOpen(true);
          } else {
            checkOrganizationError(
              error.response.data.non_field_errors[0],
              props.history
            );
          }
        }
        setLoading(false);
      });
  };
  // Function to close the sort menu

  const handleCloseSort = () => {
    setAnchorElSort(null);
  };
  // Function to clear the download state variables

  const clearDowload = () => {
    setVendorCounter(0);
    setCounter(0);
    setSelectedVendor("");
    setSelectedVendor("");
  };
  // Function to handle the click on a form section and update the form status state

  const handleSectionClick = async (newStatus) => {
    // If the new status is different from the current form status and the new status includes "FORMS", update the active section, form status, and other related states

    if (formStatus !== newStatus && newStatus.includes("FORMS")) {
      await setActiveSection(newStatus);
      setFormStatus(newStatus);
      setParsedValue(0);
      handleClearParameters(newStatus);
      setExportBuffer([]);
      setVendorDetails([]);
      setProcessingFiles([]);
      // If the new status is not "FORMS_PARSED" or "FORMS_EXPORTED", set the search subject state to "file"

      if (!["FORMS_PARSED", "FORMS_EXPORTED"].includes(newStatus)) {
        setSearchSubject("file");
      }
    }
  };
  // Function to handle the user re-authentication

  const handleReAuth = (isReAuth) => {
    if (isReAuth) {
      // If the isReAuth parameter is true, log the user out

      handleLogout(props.history);
    }
  };
  // Function to retrieve the parsed forms data from the extricator API

  const getParsedForms = async (
    from = null,
    to = null,
    sortBy = null,
    searchKeywords = null
  ) => {
    setLoading(true);
    // Make a GET request to the extricator API to retrieve the parsed forms data

    axios({
      method: "GET",
      url: `${EXTRICATOR_BASE_URL}/vendor_details/?file_type=${docType}&status=${
        formStatus.split("_")[1]
      }&search=${searchKeywords}&from=${from}&to=${to}&search_on=${searchSubject}&type=${sortBy}&ogi=${ogi}`,
      headers: {
        "Content-Type": "multipart/form-data",
        Authorization: `Bearer ${authToken}`,
      },
    })
      .then(function (response) {
        // If the response is successful, update the vendors state with the response data

        var vendors = response.data;
        vendors = vendors.map((item) => ({
          ...item,
          isSelected: false,
          isAccordianOpen: false,
        }));
        setVendorHTML(vendors);
        setVendorsLength(vendors.length);
        setLoading(false);
      })
      .catch(function (error) {
        setLoading(false);
        // If there is an error in the API response, handle the error based on the error message

        if (error.response) {
          if (error.response && error.response.status === 401) {
            handleLogout(props.history);
          }
          if (error.response.data.non_field_errors) {
            if (
              error.response.data.non_field_errors[0].includes(
                "Kindly pay the bill"
              )
            ) {
              setToastColor("error");
              setSuccessMsg(error.response.data.non_field_errors[0]);
              setSnackOpen(true);
            } else {
              checkOrganizationError(
                error.response.data.non_field_errors[0],
                props.history
              );
            }
          }
        }
      });
  };
  // Clears the search and other parameters and resets the state values when a new form status is selected

  const handleClearParameters = (newStatus) => {
    setTooltipDate("");
    setSearch("");
    setActiveVendor(null);
    setSortBy({ name: "Sort By", value: null });
    if (newStatus === "PARSED") {
      setParsedValue(0);
    }
    setFrom({
      ...from,
      day: null,
      month: null,
      year: null,
    });

    setTo({
      ...from,
      day: null,
      month: null,
      year: null,
    });
    setDeletedInvoiceTab(0);
    setParsedInvoiceTab(0);
    setDeletedTabValue(0);
    setStatus(newStatus);
    setVendorHTML([]);
    clearDowload();
    setDateErrMessage("");
    setDateRange({ from: null, to: null });
  };
  // Toggles the selection of a parsed form file

  const toggleFormSelect = (event, formFile) => {
    var fullParsedFormsTemp = [...fullParsedForms];

    for (let i = 0; i < fullParsedFormsTemp.length; i++) {
      if (fullParsedFormsTemp[i].id === formFile.id) {
        fullParsedFormsTemp[i].isSelected = !fullParsedFormsTemp[i].isSelected;
      } else {
        fullParsedFormsTemp[i].isSelected = false;
      }
    }
    formatFormsTable(fullParsedFormsTemp);
  };
  // Toggles the selection of an exported form file

  const toggleReviewFormSelect = (event, formFile) => {
    var fullReviewFormsTemp = [...fullReviewForms];
    setFullReviewedHtml(fullReviewFormsTemp)
    var exportBufferTemp = [...exportBuffer];

    for (let i = 0; i < fullReviewFormsTemp.length; i++) {
      if (fullReviewFormsTemp[i].id === formFile.id) {
        fullReviewFormsTemp[i].isSelected = !fullReviewFormsTemp[i].isSelected;
      }
      const existingItem = exportBufferTemp.find(
        (item) => item.id === fullReviewFormsTemp[i].id
      ); 
      if(fullReviewFormsTemp[i].isSelected) {
        if (!existingItem) {
          exportBufferTemp.push(fullReviewFormsTemp[i]);
        } else {
          existingItem.isSelected = true;
        }
        // exportBufferTemp.push(fullReviewFormsTemp[i]);
      }else if (existingItem) {
        // fullReviewFormsTemp[i].isSelected = false;
        exportBufferTemp = exportBufferTemp.filter(
          (item) => item.id !== fullReviewFormsTemp[i].id
        );
      }
    }
    setExportBuffer(exportBufferTemp);

    formatReviewFormsTable(fullReviewFormsTemp);
  };
  // This function toggles the isSelected state of an exported form and sets the isSelected state of all other forms to false

  const toggleExportedFormSelect = (event, exportedFile) => {
    // Copy the fullExportedForms array using the spread operator to avoid mutating the original array

    var fullExportedFormsTemp = [...fullExportedForms];
    // Loop through the copied array to toggle the isSelected state of the selected form and set isSelected to false for all other forms

    for (let i = 0; i < fullExportedFormsTemp.length; i++) {
      if (fullExportedFormsTemp[i].id === exportedFile.id) {
        fullExportedFormsTemp[i].isSelected =
          !fullExportedFormsTemp[i].isSelected;
      } else {
        fullExportedFormsTemp[i].isSelected = false;
      }
    }
    // Call the formatExportedForms function with the updated fullExportedForms array

    formatExportedForms(fullExportedFormsTemp);
  };
  // This function toggles the isSelected state of a deleted form and sets the isSelected state of all other forms to false

  const toggleDeletedFormSelect = (event, deletedFile) => {
    // Copy the deletedForms array using the spread operator to avoid mutating the original array

    var deletedFormsTemp = [...deletedForms];
    // Loop through the copied array to toggle the isSelected state of the selected form and set isSelected to false for all other forms

    for (let i = 0; i < deletedFormsTemp.length; i++) {
      if (deletedFormsTemp[i].id === deletedFile.id) {
        deletedFormsTemp[i].isSelected = !deletedFormsTemp[i].isSelected;
      } else {
        deletedFormsTemp[i].isSelected = false;
      }
    }
    formatExportedForms(deletedFormsTemp);
  };
  // This function handles the search input by setting the file or vendor search state variables and updating the search counter

  const handleSearchInput = (event) => {
    // Prevent the default form submission behavior

    event.preventDefault();
    // Check if the form is in FORMS_PARSED or FORMS_EXPORTED status

    if (["FORMS_PARSED", "FORMS_EXPORTED"].includes(formStatus)) {
      if (event.target.value) {
        // Set the file search state variable if the search subject is "file", otherwise set the vendor search state variable

        if (searchSubject == "file") {
          setFileSearch(event.target.value);
        } else {
          setVendorSearch(event.target.value);
        }
      } else {
        // Clear the search state variables and update the search counter

        setFileSearch("");
        setVendorSearch("");
        setSearchCounter(searchCounter + 1);
      }
    } else {
      if (event.target.value) {
        // Set the general search state variable

        setSearch(event.target.value);
      } else {
        setSearch("");
        setSearchCounter(searchCounter + 1);
      }
    }
  };
  // This function handles the search blur by setting the search focus state variable and resetting the search counter

  const handleSearchBlur = () => {
    // Set the search focus state variable to false

    setSearchFocus(false);
    if (searchCounter > 0) {
      setSearchCounter(0);
    }
  };
  // This function handles a change in the document type by resetting various state variables and updating the form, invoice, or receipt status

  const handleDocType = ({ val, selectedRoute }) => {
    if (docType !== val) {
      // Reset various state variables

      handleResetInterval();
      setActiveVendor(null);
      setExportBuffer([]);
      setSortBy({ name: "Sort By", value: null });
      setFrom({
        ...from,
        day: null,
        month: null,
        year: null,
      });

      setTo({
        ...to,
        day: null,
        month: null,
        year: null,
      });
      setTooltipDate("");
      setDateRange({ from: null, to: null });
      setDateErrMessage("");
      setFormStatus("");
      setInvoiceStatus("");
      setReceiptStatus("");
      if (val === "FORM") {
        setFormStatus("FORMS_PARSED");
      } else if (val === "INVOICE") {
        setInvoiceStatus("INVOICE_REVIEW");
      } else if (val === "RECEIPT") {
        setReceiptStatus("RECEIPT_REVIEW");
      }
      // Update state variables for the document type, status, and search

      setStatus("REVIEW");
      setDocType(val);
      setFileType(val);
      if (search) {
        setSearch("");
      }
      if (fileSearch) {
        setFileSearch("");
      }
      if (vendorSearch) {
        setVendorSearch("");
      } // Navigate to the selected route

      props.history.push(selectedRoute);
    }
  };
  // This function returns an object with a visibility flag and a file counter for selected forms

  const getSelectedFormCounter = () => {
    // Map the fullReviewForms array to an array of isSelected values, then filter the array to include only true values

    var temp = fullReviewForms.map((item) => item.isSelected);
    temp = temp.filter((item) => item == true);
    // Return an object with a visibility flag and the length of the filtered array

    return {
      isVisible: temp.length > 0 ? true : false,
      fileCounter: temp.length,
    };
  };
  // This function maps the selected forms to a selected model and calls the ExT extractor API

  const handleMapReviewForms = () => {
    // Check if there are any selected forms and a selected model

    if (getSelectedFormCounter().isVisible && selectedModel) {
      // Filter the fullReviewForms array to include only selected objects and map the selected files to an array

      var selectedObjSet = fullReviewForms.filter(
        (item) => item.isSelected == true
      );
      var selectedFile = selectedObjSet.map((item) => item.id);
      // Call the ExT extractor API with the selected files, model, and OGI

      setLoading(true);
      axios({
        method: "post",
        url: `${EXTRICATOR_BASE_URL}/mapping_multiple_files/`,
        data: {
          file_id: selectedFile.join(","),
          model_id: String(selectedModel.id),
          ogi: ogi,
        },
        headers: { Authorization: `Bearer ${authToken}` },
      })
        .then(function (response) {
          setToastColor("success");
          setSuccessMsg(response.data.message);
          setSnackOpen(true);
          setLoading(false);
          getFormDetails("FORM", null, null, null, null);
        })
        .catch(function (error) {
          if (error.response) {
            if (error.response.data && error.response.data.non_field_errors) {
              setToastColor("error");
              setSuccessMsg(error.response.data.non_field_errors[0]);
              setSnackOpen(true);
              setLoading(false);
            }
          }
        });
    }
  };
  // This function handles closing the document type modal and resetting various state variables

  const handleDocTypeModalClose = () => {
    setUploadModal(false);
    setFileLength(0);
    setUploaded(false);
    setFileType("");
    setInvoices(false);
    setFileMessage("");
    setCheckMaxNoPages(true)
  };
  // This function handles closing the snack bar

  const handleSnackClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setSnackOpen(false);
    setSuccessMsg("");
  };
  // This function returns an array of selected parsed forms

  const getSelectedParsedForms = () => {
    // Map the fullVendorDetails array to an array of selected form IDs and filter out null values

    var selectedForm = fullVendorDetails.map((form) =>
      form.isSelected == true ? form.id : null
    );
    selectedForm = selectedForm.filter((selected) => selected !== null);
    return selectedForm;
  };
  // This function returns an array of selected forms depending on the current form status

  const getSelectedForms = () => {
    if (formStatus === "FORMS_REVIEW") {
      var selectedForm = reviewForms.map((form) =>
        form.isSelected == true ? form.id : null
      );
      selectedForm = selectedForm.filter((selected) => selected !== null);
      return selectedForm;
    } else if (
      formStatus === "FORMS_PARSED" ||
      formStatus === "FORMS_EXPORTED"
    ) {
      var selectedForm = vendorDetailsHTML.map((form) =>
        form.isSelected == true ? form.id : null
      );
      selectedForm = selectedForm.filter((selected) => selected !== null);
      return selectedForm;
    } else if (formStatus === "FORMS_DELETED") {
      var selectedForm = deletedForms.map((form) =>
        form.isSelected == true ? form.id : null
      );
      selectedForm = selectedForm.filter((selected) => selected !== null);
      return selectedForm;
    }
    return [];
  };
  // This function returns an array of selected forms depending on the current form status

  const showHideLogoutBtn = (flag) => {
    if (flag) {
      showLogoutBtn(!logOutBtn);
    } else {
      if (logOutBtn) {
        showLogoutBtn(false);
      }
    }
  };
  // This function resets various state variables when called

  const handleResetInterval = () => {
    tripper = 0;
    setTestCounter(0);
    setProcessingFiles([]);
    setFullProcessingFiles([]);
    clearInterval(timerVal);
  };
  // This function handles dropping files onto the page by appending the files to a FormData object

  const dropHandler = (ev) => {
    ev.preventDefault();
    let filesSet = [];
    if (ev.dataTransfer.items) {
      for (var i = 0; i < ev.dataTransfer.items.length; i++) {
        if (ev.dataTransfer.items[i].kind === "file") {
          var file = ev.dataTransfer.items[i].getAsFile();
          filesSet.push(file);
        }
      }
    } else {
      for (var i = 0; i < ev.dataTransfer.files.length; i++) {
        filesSet.push(ev.dataTransfer.files[i]);
      }
    }
    const formData = new FormData();
    const checkNoPagesData = new FormData();

    for (let i = 0; i < filesSet.length; i++) {
      formData.append("file", filesSet[i]);
      checkNoPagesData.append("file", filesSet[i]);
    }
    checkNoPagesData.append("user", sessionStorage.getItem('user_id'));
    checkNoPagesData.append("company_name", ogi);
    checkNoPagesData.append("plan_name", planName);
    axios({
      method: "post",
      url: `${EXTRICATOR_BASE_URL}/check-max-number-of-pages/`,
      data: checkNoPagesData,
      headers: {
        Authorization: `Bearer ${authToken}`,
      },
    })
      .then(function (response) {
        if (response.data.status) {
          setToastColor("success");
          setSuccessMsg(response.data.message);
          setSnackOpen(true);
          formData.append("file_type", docType);
          formData.append("ogi", ogi);
          formData.append("plan_name", planName);
          setInvoices(formData);
          setCheckMaxNoPages(false)
          setFileLength(filesSet.length);
          setFileMessage("");
        }
        else{
          setFileMessage(response.data.message);
          setSnackOpen(true);
          setInvoices(false);
          setCheckMaxNoPages(true)   
          setFileLength(0);
          setUploaded(false);
          setFileType("");

        }
      });
    
  };
  // This function handles drag over events by preventing the default action

  function dragOverHandler(ev) {
    ev.preventDefault();
  }
  // This function returns the appropriate search value depending on the current form status and search subject

  const getSearchValue = () => {
    if (["FORMS_PARSED", "FORMS_EXPORTED"].includes(formStatus)) {
      if (searchSubject == "file") {
        return fileSearch;
      } else {
        return vendorSearch;
      }
    } else {
      return search;
    }
  };


  return (
    <>
      <CheckMobileDevice />
      <DeleteDialogBox
        open={deletableFileId}
        handleClose={() => setDeletableFileId(null)}
        content="Are you sure you want to delete this document?"
        inProgress={deleteFileProgress}
        handleConfirm={() => confirmDelete(deletableFileId)}
        dialogTitle="Confirmation"
      />

      <Backdrop className={classes.backdrop} open={loading}>
        <CircularProgress color="inherit" />
      </Backdrop>
      <Backdrop className={classes.backdrop} open={open}>
        <Modal
          open={open}
          style={{ top: 100, left: 479, width: 408, height: 496 }}
          aria-labelledby="simple-modal-title"
          aria-describedby="simple-modal-description"
        >
          <div>
            <img style={{ opacity: 1, borderRadius: 11 }} src={gif} />
          </div>
        </Modal>

        <Modal
          open={uploadModal}
          onClose={() => (!fileUploading ? handleDocTypeModalClose() : null)}
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
          aria-labelledby="simple-modal-title"
          aria-describedby="simple-modal-description"
        >
          <div
            style={{
              backgroundColor: "#FFFFFF",
              width: 520,
              height: 246,
              borderRadius: 6,
            }}
            align="center"
          >
            <>
              {!fileUploading ? (
                <>
                  <img
                    src={Images.close}
                    onClick={() => handleDocTypeModalClose()}
                    style={{
                      cursor: "pointer",
                      width: 19,
                      height: 19,
                      marginTop: 12,
                      marginLeft: "94%",
                      marginTop: 6,
                    }}
                  />
                  <img
                    src={Images.upload}
                    style={{ width: 35, height: 33, marginTop: 12 }}
                  />

                  <div
                    align="center"
                    id="drop_zone"
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                    }}
                    onDrop={dropHandler}
                    onDragOver={dragOverHandler}
                  >
                    <div
                      style={{
                        color: "#1C5784",
                        font: "normal normal bold 15px/26px Open Sans",
                        letterSpacing: "0px",
                        opacity: 1,
                      }}
                    >
                      Drop files here
                    </div>

                    <div
                      style={{
                        color: "#1C5784",
                        font: "normal normal bold 10px/26px Open Sans",
                        opacity: 1,
                        letterSpacing: 0,
                      }}
                    >
                      or click here
                      <span
                        style={{
                          background: "#1177FD 0% 0% no-repeat padding-box",
                          borderRadius: 5,
                          marginLeft: 4,
                          padding: 2,
                        }}
                      >
                        <label
                          htmlFor="contained-button-file"
                          style={{ cursor: "pointer" }}
                        >
                          <span
                            style={{
                              font: "normal normal 600 10px/26px Open Sans",
                              textAlign: "center",
                              letterSpacing: 0,
                              opacity: 1,
                              color: "#fff",
                            }}
                          >
                            Choose files
                          </span>
                        </label>
                      </span>
                    </div>
                    <div>
                      {fileLength && (
                        <>
                          <span
                            style={{
                              color: "#1C5784",
                              font: "normal normal bold 10px/26px Open Sans",
                              letterSpacing: 0,
                              opacity: 1,
                            }}
                          >
                            {fileLength} Files selected
                          </span>
                          <i
                            className="fa fa-check-circle ml-1"
                            style={{ fontSize: "12px", color: "#40FF7F" }}
                          ></i>
                        </>
                      )
                       }
                        {fileMessage && (
                        <>
                          <span
                            style={{
                              color: "red",
                              font: "normal normal bold 10px/26px Open Sans",
                              letterSpacing: 0,
                              opacity: 1,
                            }}
                          >
                            {fileMessage}
                          </span>
                        
                        </>
                      )
                       }
                    </div>
                    <button
                      onClick={uploadInvoice}
                      disabled={checkMaxNoPages}
                      className="btn btn-lg sign-btn get-btn mt-4"
                      style={{
                        display: "flex",
                        alignContent: "center",
                        justifyContent: "center",
                        background: !checkMaxNoPages? "#35F8F1 0% 0% no-repeat padding-box" : "gray 0% 0% no-repeat padding-box",
                        boxShadow: "0px 3px 6px #14141429",
                        borderRadius: 3,
                        opacity: 1,
                        width: 142,
                      }}
                    >
                      Upload
                    </button>
                  </div>
                </>
              ) : (
                <div className="col-12">
                  <div
                    className="d-flex justify-content-center align-items-center"
                    style={{ height: "200px" }}
                  >
                    <svg
                      className="spinner"
                      width="65px"
                      height="65px"
                      viewBox="0 0 66 66"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <circle
                        className="path"
                        fill="none"
                        stroke-width="6"
                        stroke-linecap="round"
                        cx="33"
                        cy="33"
                        r="30"
                      ></circle>
                    </svg>
                  </div>
                  <h6 style={{ color: "#011B3E", fontSize: "14px" }}>
                    Please wait while your files upload.
                  </h6>
                </div>
              )}
            </>
          </div>
        </Modal>
      </Backdrop>
      <div
        className="dashboard_container"
        onClick={() => showHideLogoutBtn(null)}
      >
        {fileId && (
          <ImageShowcase
            fileId={fileId}
            fileName={fileName}
            handleClose={() => setFileId(null)}
          />
        )}
        <SnackbarComponent
          isOpen={snackOpen}
          message={successMsg}
          color={toastColor}
          handleClose={handleSnackClose}
        />
        <div
          className="menu-side col-2"
          style={{ backgroundImage: Images.data_center, }}
        >
          <div>
            <div className="image-full" >
              <div className="form-group d-flex justify-content-center mt-4 banner-head" >
                <Link to="/" >
                  <img
                    src={Images.app_logo}
                    className="img-responsive img-fluid"
                    alt=""
                  />
                </Link>
              </div>
            </div>
            {}
            <FileCategoryComponent
              handleCategoryClick={handleSectionClick}
              docType={"FORM"}
              docStatus={formStatus}
              reAuthenticate={handleReAuth}
              defaultActiveTab={activeTab}
            />
          </div>
          {/* <PayContainer /> */}
          <PayContainer planName={planName} file_type={docType}/>

        </div>

        <div className="invoice-side pt-2" onClick={closeProfileMenu}>
          <div className="col-12">
            <div className="row">
              <div className="col-6" style={{ paddingLeft: "11px" }}>
                <TopNav callback={handleDocType} defaultActiveTab={1} />
                <div className="col-md-12 col-sm-8 basic-search mt-3">
                  <div className="input-field">
                    <form
                      autoComplete="off"
                      onSubmit={handleSearch}
                      className="d-flex"
                      style={{
                        borderRadius: 6,
                        opacity: 1,
                        background: "#0B346B 0% 0% no-repeat padding-box",
                        width: "100%",
                      }}
                    >
                      <div className="d-flex search_container col-11 ml-2" >
                        {["FORMS_PARSED", "FORMS_EXPORTED"].includes(
                          formStatus
                        ) ? (
                          <SearchOnSelect
                            getValue={(option) => setSearchSubject(option)}
                          />
                        ) : null}

                        <div className="d-flex search_container col-12">
                          <img
                            src={Images.dashboard_search}
                            width={14}
                            height={14}
                            style={{ margin: "10px 5px" }}
                          />
                          {/* change search input width at form page */}
                          <input
                            id="search"
                            type="text"
                            placeholder="Search"
                            style={{ width: "350px" }}
                            value={getSearchValue()}
                            onFocus={() => setSearchFocus(true)}
                            onBlur={() => handleSearchBlur()}
                            ref={searchInput}
                            onChange={handleSearchInput}
                            onEnter
                          />
                        </div>
                      </div>

                      <button className="btn search_btn">
                        <i
                          style={{ color: "#01132B" }}
                          className="fa fa-search"
                        ></i>
                      </button>
                    </form>
                  </div>
                </div>
              </div>
              <div className="col-6 pr-3">
                <div className="row align-items-center justify-content-end">
                  <Tooltip
                    open={tooltip}
                    title={tooltipDate}
                    placement="bottom"
                    arrow
                  >
                    <img
                      src={Images.calendar}
                      className="calendar"
                      onClick={onCalandarClick}
                      style={{
                        width: 25,
                        height: 29,
                        color: "#FFFFFF 0% 0% no-repeat padding-box",
                        marginLeft: "26.45px",
                        cursor: "pointer",
                        zIndex: 1310,
                      }}
                    />
                  </Tooltip>
                  <div className="dashboard_calendar mx-3">
                  {/* <div className="date_picker  text-light h5 border-0">
                      <DateRangePicker onChange={setDateValue} value={dateValue} calendarClassName=" text-dark bg-secondary border-0 h5"/>
                    </div>  */}
                    <ThemeProvider theme={myTheme}>
                      {calander && (
                        <>
                          <Menu
                            id="simple-menu"
                            anchorEl={anchorEl}
                            keepMounted
                            open={Boolean(anchorEl)}
                            className="mt-5"
                            onClose={() => handleCalandarClose(null)}
                            style={{ left: "0px", overflowY: "hidden" }}
                          >
                            <MenuItem
                              style={{
                                background:
                                  "#12458A 0% 0% no-repeat padding-box",
                                borderRadius: 5,
                                opacity: 1,
                              }}
                            >
                              <div>
                          
                                <div className="date-band">
                                  <label>From</label>
                                  <Select
                                    labelId="demo-simple-select-outlined-label"
                                    id="demo-simple-select-outlined"
                                    onChange={handleFormChange}
                                    className="to-day"
                                    value={from.day}
                                    name="day"
                                    style={{
                                      display: "flex",
                                      width: "42px",
                                      height: "19px",
                                      color: "#FFF",
                                      fontSize: "12px",
                                      marginLeft: "16px", 
                                    }}
                                  >
                                    {days.map((item) => (
                                      <MenuItem
                                        style={{
                                          color: "#FFF",
                                          fontSize: "12px",
                                        }}
                                        value={item}
                                        id={item}
                                      >
                                        {item}
                                      </MenuItem>
                                    ))}
                                  </Select>
                                  <Select
                                    labelId="demo-simple-select-outlined-label"
                                    id="demo-simple-select-outlined"
                                    className="to-day"
                                    value={from.month}
                                    onChange={handleFormChange}
                                    name="month"
                                    style={{
                                      display: "flex",
                                      width: "51px",
                                      height: "19px",
                                      fontSize: "12px",
                                      color: "#FFF",
                                      marginLeft: 25,
                                    }}
                                  >
                                    {fromMonths.map((item) => (
                                      <MenuItem
                                        style={{
                                          color: "#FFF",
                                          fontSize: "12px",
                                        }}
                                        value={item.index}
                                        id={item.index}
                                      >
                                        {item.month}
                                      </MenuItem>
                                    ))}
                                  </Select>
                                  <Select
                                    labelId="demo-simple-select-outlined-label"
                                    id="demo-simple-select-outlined"
                                    className="to-day"
                                    onChange={handleFormChange}
                                    value={from.year}
                                    name="year"
                                    style={{
                                      display: "flex",
                                      width: "58px",
                                      height: "19px",
                                      color: "#FFF",
                                      fontSize: "12px",
                                      marginLeft: 22,
                                    }}
                                  >
                                    {years.map((item) => (
                                      <MenuItem
                                        style={{
                                          color: "#FFF",
                                          fontSize: "12px",
                                        }}
                                        value={item}
                                        key={item}
                                      >
                                        {item}
                                      </MenuItem>
                                    ))}
                                  </Select>
                                </div>

                                <div
                                  className="date-band"
                                  style={{ marginTop: "19px" }}
                                >
                                  <label>To</label>
                                  <Select
                                    labelId="demo-simple-select-outlined-label"
                                    id="demo-simple-select-outlined"
                                    className="to-day"
                                    onChange={handleToChange}
                                    name="day"
                                    value={to.day}
                                    style={{ 
                                      display: "flex",
                                      width: "42px",
                                      height: "19px",
                                      color: "#FFF",
                                      fontSize: "12px",
                                      marginLeft: "16px",
                                    }}
                                  >
                                    {days.map((item) => (
                                      <MenuItem
                                        style={{
                                          color: "#FFF",
                                          fontSize: "12px",
                                        }}
                                        value={item}
                                        id={item}
                                      >
                                        {item}
                                      </MenuItem>
                                    ))}
                                  </Select>
                                  <Select
                                    labelId="demo-simple-select-outlined-label"
                                    id="demo-simple-select-outlined"
                                    className="to-day"
                                    value={to.month}
                                    onChange={handleToChange}
                                    name="month"
                                    style={{
                                      display: "flex",
                                      width: "51px",
                                      height: "19px",
                                      color: "#FFF",
                                      fontSize: "12px",
                                      marginLeft: 25,
                                    }}
                                  >
                                    {toMonths.map((item) => (
                                      <MenuItem
                                        style={{
                                          color: "#FFF",
                                          fontSize: "12px",
                                        }}
                                        value={item.index}
                                        id={item.index}
                                      >
                                        {item.month}
                                      </MenuItem>
                                    ))}
                                  </Select>
                                  <Select
                                    labelId="demo-simple-select-outlined-label"
                                    id="demo-simple-select-outlined"
                                    className="to-day"
                                    onChange={handleToChange}
                                    name="year"
                                    value={to.year}
                                    style={{
                                      display: "flex",
                                      width: "58px",
                                      height: "19px",
                                      color: "#FFF",
                                      fontSize: "12px",
                                      marginLeft: 22,
                                    }}
                                  >
                                    {years.map((item) => (
                                      <MenuItem
                                        style={{
                                          color: "#FFF",
                                          fontSize: "12px",
                                        }}
                                        value={item}
                                      >
                                        {item}
                                      </MenuItem>
                                    ))}
                                  </Select>
                                </div>
                              </div>
                            </MenuItem>
                            <MenuItem>
                              <div className="col-12">
                                <div className="row justify-content-between">
                                  <span
                                    onClick={() => handleCalandarClose("reset")}
                                    style={{
                                      font: "normal normal 600 10px/42px Open Sans",
                                      marginLeft: "10px",
                                      opacity: 1,
                                      cursor: "pointer",
                                      textDecoration: "underline",
                                    }}
                                  >
                                    Clear Search
                                  </span>
                                  <Button
                                    component="span"
                                    variant="contained"
                                    classes={{ root: classes.logOutBtn }}
                                    style={{
                                      height: 28,
                                      borderRadius: 2,
                                      marginLeft: "53px",
                                      background:
                                        "#35F8F1 0% 0% no-repeat padding-box",
                                      width: "110px",
                                      marginTop: 5,
                                    }}
                                    onClick={filterSearch}
                                  >
                                    <span
                                      style={{
                                        paddingLeft: 10,
                                        paddingRight: 10,
                                        font: "normal normal bold 10px/25px Open Sans",
                                        color: " #011B3E",
                                        opacity: 1,
                                        letterSpacing: 0,
                                      }}
                                    >
                                      Search
                                    </span>
                                  </Button>
                                </div>
                              </div>
                            </MenuItem>
                            {dateErrMessage && (
                              <Alert
                                classes={{
                                  icon: classes.alertIcon,
                                  message: classes.alertMsg,
                                }}
                                style={{ fontSize: "12px", padding: "6px" }}
                                severity="error"
                              >
                                {dateErrMessage}
                              </Alert>
                            )}
                          </Menu>
                        </>
                      )}
                      <div
                        className="d-flex align-items-center"
                        style={{ marginLeft: "10px" }}
                      >
                        <h6 className="mb-0" style={{ fontSize: "15px" }}>
                          Sort by :
                        </h6>
                        <div className="dropdown ml-2">
                          <button
                            className="btn dropdown-toggle sort-btn"
                            type="button"
                            id="dropdownMenuButton"
                            data-toggle="dropdown"
                            aria-haspopup="true"
                            aria-expanded="false"
                          >
                            {sortBy.name}
                          </button>
                          <div
                            className="dropdown-menu"
                            aria-labelledby="dropdownMenuButton"
                          >
                            {sortOptions.map((option) => (
                              <a
                                className="dropdown-item"
                                onClick={() => handleSortOptionSelect(option)}
                                key={option.value}
                                href="#"
                              >
                                {option.name}
                              </a>
                            ))}
                          </div>
                        </div>
                      </div>
                    </ThemeProvider>
                  </div>
                  <div style={{ position: "relative", marginRight: "12px" }}>
                    <LogoutBar
                      btnShow={logOutBtn}
                      callback={showHideLogoutBtn}
                    ></LogoutBar>
                  </div>
                </div>
                <div className="d-flex align-items-center justify-content-end">
                  {formStatus == "FORMS_REVIEW" && showModelOption && (
                    <div
                      className="d-flex model_input my-2"
                      style={{ marginLeft: "15px" }}
                    >
                      <CustomDropDown
                        screen="dashboard"
                        modelType={"FORM"}
                        getSelectedModel={(model) => setSelectedModel(model)}
                        listOffset={{ top: "144px", left: "255px" }}
                      />
                      <label
                        onClick={() => handleMapReviewForms()}
                        style={{
                          backgroundColor:
                            getSelectedFormCounter().isVisible && selectedModel
                              ? "#35F8F1"
                              : "gray",
                        }}
                        className="parse_label ml-3"
                      >
                        <span>Parse</span>
                      </label>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>

          {docType == "FORM" &&
          (formStatus === "FORMS_PARSED" || formStatus === "FORMS_EXPORTED") ? (
            <Paper
              square
              style={{
                maxWidth: 320,
                backgroundColor: "#04244f",
                marginLeft: 13,
              }}
            >
              <Tabs
                value={valueParsed}
                onChange={(e, newValue) => handleFormStatusChange(newValue)}
                variant="fullWidth"
                indicatorColor="primary"
                textColor="primary"
                aria-label="icon tabs example"
                style={{ marginBottom: 10, backgroundColor: "#04244f" }}
              >
                <Tab
                  aria-label="phone"
                  style={{ maxWidth: 92 }}
                  label={
                    <CustomTab
                      icon={
                        valueParsed === 0
                          ? Images.dashboard_parsed_checked
                          : Images.dashboard_checked
                      }
                      title="Parsed"
                    />
                  }
                />
                <Tab
                  label={
                    <CustomTab
                      icon={
                        valueParsed === 1
                          ? Images.exported_active
                          : Images.exported
                      }
                      title="Exported"
                    />
                  }
                />
              </Tabs>
            </Paper>
          ) : null}

          {docType === "FORM" && (
            <div
              className="startAcc_form"
              style={{
                height: getWrapperHeight(),
                position: "relative",
                overflowY: "auto",
              }}
            >
              {formStatus === "FORMS_PROCESSING" && (
                <>
                  <div className="col-12 mt-2 p-0" style={{ height: "415px" }}>
                    <table className="table forms-table">
                      <thead>
                        <tr style={{ backgroundColor: "#01132B"  }}>
                          <th
                            scope="col"
                            style={{
                              textAlign: "left",
                              width: processingFiles.length > 0 ? "7%" : "12%",
                              fontSize: '1rem',
                            }}
                          >
                            Files
                          </th>
                          <th
                            scope="col"
                            style={{
                              textAlign: "left",
                              padding: ".75rem 0",
                              width: "17%",
                              fontSize: '1rem'

                            }}
                          >
                            Received at
                          </th>
                          <th
                            scope="col"
                            style={{
                              textAlign: "left",
                              padding: ".75rem 0",
                              width: "17%",
                              fontSize: '1rem'
                            }}
                          >
                            Modified at
                          </th>
                          <th
                            scope="col"
                            style={{
                              textAlign: "left",
                              padding: ".75rem 0",
                              width: "10%",
                              fontSize: '1rem'
                            }}
                          >
                            Status
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {processingFiles.map((item) => (
                          <tr key={item.id}>
                            <td style={{ padding: "10px" }}>
                              <div
                                className="form-check d-flex align-items-center"
                                style={{ paddingLeft: "3px" }}
                              >
                                {}
                                <i className="fa fa-file-o"></i>
                                <label
                                  className="form-check-label-processing ml-2"
                                  for=""
                                >
                                  {}
                                  {item.original_file_name}
                                  {}
                                </label>
                              </div>
                            </td>
                            <td className="text-left">
                              {getTimeRecord(item.created_at)}
                            </td>
                            <td className="text-left">
                              {getTimeRecord(item.modified_at)}
                            </td>
                            <td className="text-left">{item.file_status}</td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                  {fullProcessingFiles.length > 0 && (
                    <div className="d-flex col-12">
                      <div className="col-7"></div>
                      <div className="col-5 p-0">
                        <PaginationComponent
                          onPageChange={(event, item) =>
                            handlePageChange(event, item)
                          }
                          data={totalProcessingPages}
                          callback={handlePaginationCallback}
                        />
                      </div>
                    </div>
                  )}
                </>
              )}

              {formStatus === "FORMS_REVIEW" && (
                <>
                  <div className="col-12 p-0">
                    <table
                      className={
                        reviewForms.length < 1
                          ? "table forms-table no_rows mt-2"
                          : "table forms-table"
                      }
                      
                    >
                      <thead>
                        <tr style={{ backgroundColor: "#01132B" }}>
                          <th
                            scope="col"
                            style={{
                              textAlign: "left",
                              paddingLeft: "11px",
                              width: "20%",
                              fontSize: '1rem'
                            }}
                          >
                            Files
                          </th>
                          <th
                            scope="col"
                            style={{
                              paddingRight: "21px",
                              textAlign: "left",
                              width: "17%",
                              padding: ".75rem 0",
                              fontSize: '1rem'
                            }}
                          >
                            Received at
                          </th>
                          <th
                            scope="col"
                            style={{
                              paddingRight: "21px",
                              textAlign: "left",
                              width: "17%",
                              padding: ".75rem 0",
                              fontSize: '1rem'
                            }}
                          >
                            Modified at
                          </th>
                          <th
                            scope="col"
                            style={{
                              paddingRight: "32px",
                              textAlign: "left",
                              width: "8%",
                              padding: ".75rem 0",
                              fontSize: '1rem'
                            }}
                          >
                            Delete
                          </th>
                          <th
                            scope="col"
                            style={{
                              paddingRight: "38px",
                              textAlign: "left",
                              width: "8%",
                              padding: ".75rem 0",
                              fontSize: '1rem'
                            }}
                          >
                            Download
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {reviewForms.length > 0 ? (
                          reviewForms.map((item) => (
                            <tr
                              key={item.id}
                              style={{
                                backgroundColor: item.isSelected
                                  ? "#017BFF"
                                  : "transparent",
                              }}
                            >
                              <td
                                className="p-0"
                                cellspacing="0"
                                cellpadding="0"
                                style={{ width: "200px" }}
                              >
                                <div
                                  className="form-check d-flex align-items-center"
                                  style={{ paddingLeft: "0px" }}
                                >
                                  <ThemeProvider theme={myTheme}>
                                    <Checkbox
                                      checked={item.isSelected}
                                      onClick={(event) =>
                                        toggleReviewFormSelect(event, item)
                                      }
                                      value="checkedForm"
                                      inputProps={{
                                        "aria-label": "checkbox_form",
                                      }}
                                    />
                                  </ThemeProvider>
                                  <label
                                    className="form-check-label-review"
                                    for=""
                                  >
                                    <Link
                                      className="testLabel"
                                      to={{
                                        pathname: `/form_details/${item.id}`,
                                        state: formStatus,
                                      }}
                                      onMouseEnter={() =>
                                        handleShowPreview(item)
                                      }
                                      onMouseLeave={() => handleHidePreview()}
                                    >
                                      {item.file_name}
                                    </Link>
                                  </label>
                                </div>
                              </td>
                              <td
                                className="text-left p-0"
                                style={{ width: "17%" }}
                              >
                                {getTimeRecord(item.created_at)}
                              </td>
                              <td
                                className="text-left p-0"
                                style={{ width: "17%" }}
                              >
                                {getTimeRecord(item.modified_at)}
                              </td>
                              <td
                                className="text-left p-0"
                                style={{ width: "8%" }}
                              >
                                <span
                                  className="fileaction-span"
                                  onClick={() => setDeletableFileId(item.id)}
                                >
                                  <i className="fa fa-trash"></i>&nbsp;
                                  <span>Delete</span>
                                </span>
                              </td>
                              <td
                                className="text-left p-0"
                                style={{ width: "8%" }}
                              >
                                <span
                                  className="fileaction-span"
                                  onClick={() => {
                                    window.open(
                                      `${EXTRICATOR_BASE_URL}/download_file/?id=${item.id}&ogi=${ogi}`
                                    );
                                  }}
                                >
                                  <i className="fa fa-download mr-1"></i>
                                  <span>Download</span>
                                </span>
                              </td>
                            </tr>
                          ))
                        ) : (
                          <>
                            <tr></tr>
                            <tr></tr>
                            <tr></tr>
                            <tr>
                              <td align="center" colSpan={6}>
                                Nothing to show
                              </td>
                            </tr>
                            <tr></tr>
                            <tr></tr>
                            <tr></tr>
                          </>
                        )}
                      </tbody>
                    </table>
                  </div>
                </>
              )}

              {(formStatus == "FORMS_PARSED" ||
                formStatus == "FORMS_EXPORTED") && (
                <>

                  <div
                    className="startAcc_invoice"
                    style={{ height: getInvoiceCanvasHeight() }}
                  >
                    <Accordion className=" text-white" style={{ height: 37 }}>
                      <div className="col-12">
                        <ul>
                          <li>
                            <span
                              style={{
                                font: "normal normal 600 14px/35px Open Sans",
                                color: "#fff",
                                fontSize: '0.9rem' 
                              }}
                            >
                              {" "}
                              Vendors name{" "}
                            </span>
                          </li>
                        </ul>
                      </div>
                    </Accordion>
                    {vendorHTML.length > 0 ? (
                      vendorHTML
                        .filter((e) => e.total_file !== 0)
                        .map((vendor, index) => (
                          <Accordion
                            expanded={vendor.isAccordianOpen}
                            onChange={handleAccordianChange(vendor)}
                            style={{ marginBottom: 5 }}
                          >
                            <AccordionSummary
                              classes={{
                                root: getVendorColor(classes, vendor.id),
                              }}
                              expandIcon={<ExpandMoreIcon />}
                              aria-controls="panel2a-content"
                              id="panel2a-header"
                              className="vendorNameDiv"
                            >
                              <div className="left-vendor-div d-flex col-7">
                                <ThemeProvider theme={myTheme}>
                                  <Checkbox
                                    value={`vendor${vendor.id}`}
                                    name={`vendor_check${vendor.id}`}
                                    id={`vendor_id${vendor.id}`}
                                    checked={vendor.isSelected}
                                    onClick={(event) =>
                                      handleVendorCheckbox(event, vendor)
                                    }
                                  />
                                </ThemeProvider>
                                <span
                                  className=" d-flex align-items-center"
                                  onClick={setCounterInitial}
                                >
                                  <span style={{ fontWeight: "600px", fontSize: '1rem' }}>
                                    {vendor.supplier_name}
                                  </span>
                                  {expanded === `panel${index}` ? (
                                    <i
                                      className="fa fa-caret-up ml-1"
                                      style={{ fontSize: "18px" }}
                                      color="#fff"
                                    ></i>
                                  ) : (
                                    <i
                                      className="fa fa-caret-down ml-1"
                                      style={{ fontSize: "18px" }}
                                      color="#fff"
                                    ></i>
                                  )}

                                  {counterValue > 0 &&
                                  expanded === `panel${index}` ? (
                                    <span className="selectedCount ml-1">
                                      ({counterValue} Selected)
                                    </span>
                                  ) : (
                                    ""
                                  )}
                                </span>
                              </div>

                              {vendor.isAccordianOpen &&
                                vendorDetailsHTML.length > 0 && (
                                  <PaginationComponent
                                    place={"vendor"}
                                    onPageChange={(event, item) =>
                                      handlePageChange(event, item)
                                    }
                                    data={totalPages}
                                    callback={(flag) => setGoInputFocused(flag)}
                                  />
                                )}
                            </AccordionSummary>

                            <AccordionDetails
                              style={{ paddingBottom: 0 }}
                              className="details-accordian width-100"
                            >
                              <>
                                <ul
                                  id="file-list"
                                  style={{ backgroundColor: "#04244F" }}
                                  className="nav upNav pl-3"
                                >
                                  <li
                                    className="nav-item sub-nav-vendor py-2"
                                    style={{
                                      whiteSpace: "nowrap",
                                      overflow: "hidden",
                                      textOverflow: "ellipsis",
                                      width: "250px",
                                      height: "100%",
                                    }}
                                  >
                                    <span className="ml-2 review-div">
                                      File
                                    </span>
                                  </li>
                                  <li
                                    className="nav-item sub-nav left"
                                    style={{ fontWeight: "600" }}
                                  >
                                    Created at
                                  </li>
                                  <li
                                    className="nav-item sub-nav left"
                                    style={{ fontWeight: "600" }}
                                  >
                                    Modified at
                                  </li>

                                  <li className="nav-item eyeIcon pl-2 text-center">
                                    <span className="fileaction-span">
                                      <span>Delete</span>
                                    </span>
                                  </li>

                                  <li
                                    className="nav-item sub-nav pl-2"
                                    style={{ width: "10%" }}
                                  >
                                    <span className="fileaction-span">
                                      <span>Download</span>
                                    </span>
                                  </li>
                                  <li
                                    className="nav-item sub-nav pl-2"
                                    style={{ width: "14%", fontWeight: "600" }}
                                  >
                                    Download.xls
                                  </li>
                                </ul>
                                {vendorDetailsHTML.length > 0 ? (
                                  vendorDetailsHTML.map((fileDetail) => (
                                    <ul
                                      id="file-list"
                                      style={{
                                        backgroundColor: fileDetail.isSelected
                                          ? "#017BFF"
                                          : "transparent",
                                      }}
                                      className="nav upNav pl-3"
                                    >
                                      <li
                                        className="nav-item sub-nav-vendor"
                                        style={{
                                          whiteSpace: "nowrap",
                                          overflow: "hidden",
                                          textOverflow: "ellipsis",
                                          width: "250px",
                                          height: "100%",
                                        }}
                                        title={fileDetail.original_file_name}
                                      >
                                        <ThemeProvider theme={myTheme}>
                                          <Checkbox
                                            checked={fileDetail.isSelected}
                                            onClick={(event) =>
                                              toggleFileSelect(
                                                event,
                                                fileDetail,
                                                vendor
                                              )
                                            }
                                            value="checkedA"
                                            inputProps={{
                                              "aria-label": "Checkbox A",
                                            }}
                                          />
                                        </ThemeProvider>

                                        <i className="fa fa-file-o"></i>
                                        <Link
                                          to={{
                                            pathname: `/form_details/${fileDetail.id}`,
                                            state: invoiceStatus,
                                          }}
                                          style={{ textDecoration: "none" }}
                                        >
                                          {value !== 2 ? (
                                            <span
                                              className="ml-2 review-div"
                                              onMouseEnter={() =>
                                                handleShowPreview(fileDetail)
                                              }
                                              onMouseLeave={() =>
                                                handleHidePreview()
                                              }
                                            >
                                              {fileDetail.original_file_name}
                                            </span>
                                          ) : (
                                            <span className="ml-2 review-div">
                                              {fileDetail.original_file_name}
                                            </span>
                                          )}
                                        </Link>
                                      </li>
                                      <li className="nav-item sub-nav left">
                                        {getTimeRecord(fileDetail.created_at)}
                                      </li>
                                      <li className="nav-item sub-nav left">
                                        {getTimeRecord(fileDetail.modified_at)}
                                      </li>

                                      {value !== 2 &&
                                        invoiceStatus !== "INVOICE_DELETE" && (
                                          <li className="nav-item eyeIcon pl-2 text-center">
                                            <span
                                              className="fileaction-span"
                                              onClick={() =>
                                                setDeletableFileId(
                                                  fileDetail.id
                                                )
                                              }
                                            >
                                              <i className="fa fa-trash"></i>&nbsp;
                                              <span>Delete</span>
                                            </span>
                                          </li>
                                        )}
                                      <li
                                        className="nav-item sub-nav pl-2"
                                        style={{ width: "10%" }}
                                      >
                                        <span
                                          className="fileaction-span"
                                          onClick={() => {
                                            window.open(
                                              `${EXTRICATOR_BASE_URL}/download_file/?id=${fileDetail.id}&ogi=${ogi}`
                                            );
                                          }}
                                        >
                                          <i className="fa fa-download mr-1"></i>
                                          <span>Download</span>
                                        </span>
                                      </li>
                                      <li
                                        className="nav-item sub-nav xls-btn-row pl-2"
                                        style={{ width: "14%" }}
                                        onClick={() =>
                                          handleSingleXlsDownload(fileDetail.id)
                                        }
                                      >
                                        <i className="fa fa-download mr-1"></i>
                                        Download.xls
                                      </li>
                                    </ul>
                                  ))
                                ) : (
                                  <div className="col-12 no-files">
                                    Nothing to show
                                  </div>
                                )}
                              </>
                            </AccordionDetails>
                          </Accordion>
                        ))
                    ) : (
                      <div className="col-12 no-vendors">Nothing to show</div>
                    )}
                  </div>

                  {fullParsedForms.length > 0 && (
                    <div className="d-flex col-12">
                      <div className="col-7"></div>
                      <div className="col-5">
                        <PaginationComponent
                          onPageChange={(event, item) =>
                            handlePageChange(event, item)
                          }
                          data={totalFormPages}
                          callback={handlePaginationCallback}
                        />
                      </div>
                    </div>
                  )}
                </>
              )}

              {formStatus === "FORMS_DELETED" && (
                <>
                  <div className="col-12 p-0">
                    <table
                      className={
                        deletedForms.length > 0
                          ? "table mt-2 forms-table"
                          : "table mt-2 no_rows"
                      }
                    >
                      <thead>
                        <tr style={{ backgroundColor: "#01132B" }}>
                          <th
                            scope="col"
                            style={{
                              textAlign: "left",
                              paddingLeft: "10px",
                              width: "20%",
                              fontSize: '1rem',
                            }}
                          >
                            Files
                          </th>
                          <th
                            scope="col"
                            style={{
                              paddingRight: "16px",
                              width: "17%",
                              textAlign: "left",
                              padding: ".75em 0",
                              fontSize: '1rem',
                            }}
                          >
                            Received at
                          </th>
                          <th
                            scope="col"
                            style={{
                              paddingRight: "16px",
                              width: "17%",
                              textAlign: "left",
                              padding: ".75em 0",
                              fontSize: '1rem',
                            }}
                          >
                            Modified at
                          </th>
                          <th
                            scope="col"
                            style={{
                              paddingRight: "33px",
                              width: "17%",
                              textAlign: "left",
                              padding: ".75em 0",
                              fontSize: '1rem',
                            }}
                          >
                            Download
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {deletedForms.length > 0 ? (
                          deletedForms.map((item) => (
                            <tr key={item.id}>
                              <td>
                                <div
                                  className="d-flex align-items-center"
                                  style={{ paddingLeft: "11px" }}
                                >
                                  <i className="fa fa-file-o mr-2"></i>
                                  {formStatus !== "FORMS_DELETED" && (
                                    <ThemeProvider theme={myTheme}>
                                      <Checkbox
                                        checked={item.isSelected}
                                        onClick={(event) =>
                                          toggleDeletedFormSelect(event, item)
                                        }
                                        value="checkedExportedForm"
                                        inputProps={{
                                          "aria-label": "checkbox_form_deleted",
                                        }}
                                      />
                                    </ThemeProvider>
                                  )}
                                  {}
                                  <span
                                    className="form-check-label-review delete-file"
                                    for=""
                                    style={{
                                      fontFamily: "'Open Sans', sans-serif",
                                      fontSize: "15px",
                                    }}
                                    onMouseEnter={() => handleShowPreview(item)}
                                    onMouseLeave={() => handleHidePreview()}
                                  >
                                    {item.file_name}
                                  </span>
                                </div>
                              </td>
                              <td
                                className="text-left"
                                style={{ width: "17%", textAlign: "left" }}
                              >
                                {getTimeRecord(item.created_at)}
                              </td>
                              <td
                                className="text-left"
                                style={{ width: "17%", textAlign: "left" }}
                              >
                                {getTimeRecord(item.modified_at)}
                              </td>
                              <td
                                className="text-left"
                                style={{ width: "17%", textAlign: "left" }}
                              >
                                <span
                                  className="fileaction-span"
                                  onClick={() => {
                                    window.open(
                                      `${EXTRICATOR_BASE_URL}/download_file/?id=${item.id}&ogi=${ogi}`
                                    );
                                  }}
                                >
                                  <i className="fa fa-download mr-1"></i>
                                  <span>Download</span>
                                </span>
                              </td>
                            </tr>
                          ))
                        ) : (
                          <>
                            <tr></tr>
                            <tr></tr>
                            <tr></tr>
                            <tr align="center">
                              <td align="center" colSpan={6}>
                                Nothing to show
                              </td>
                            </tr>
                            <tr></tr>
                            <tr></tr>
                            <tr></tr>
                          </>
                        )}
                      </tbody>
                    </table>
                  </div>
                </>
              )}
            </div>
          )}
          <div
            className="col-12"
            style={{
              position: "absolute",
              bottom: "10px",
              paddingRight: "16px",
            }}
          >
            {formStatus == "FORMS_REVIEW" && fullReviewForms.length > 0 && (
              <div className="d-flex col-12 p-0 mb-2">
                <div className="col-7"></div>
                <div className="col-5 p-0">
                  <PaginationComponent
                    onPageChange={(event, item) =>
                      handlePageChange(event, item)
                    }
                    data={totalReviewPages}
                    callback={handlePaginationCallback}
                  />
                </div>
              </div>
            )}

            {formStatus == "FORMS_DELETED" && fullDeletedForms.length > 5 && (
              <div className="d-flex col-12 p-0">
                <div className="col-7"></div>
                <div className="col-5" style={{ marginLeft: "17px" }}>
                  <PaginationComponent
                    onPageChange={(event, item) =>
                      handlePageChange(event, item)
                    }
                    data={totalDeletedPages}
                    callback={handlePaginationCallback}
                  />
                </div>
              </div>
            )}
            <div className="d-flex justify-content-end">
              {["FORMS_PARSED", "FORMS_EXPORTED"].includes(formStatus) &&
              (getFileCounter().isVisible || getVendorCounter().isVisible) &&
              exportBuffer.length > 0  ? (
                <div className="d-flex action-group" style={{ width: formStatus == "FORMS_DELETED" ? "unset" : "390px",  justifyContent:
                formStatus == "FORMS_REVIEW"
                  ? "flex-end"
                  : "space-between", }}>
                  <label
                    className="xls_label mr-2"
                    onClick={() => handleBatchDelete()}
                  >
                    <i
                      className="fa fa-trash"
                      style={{ fontSize: "17px", color: theme_colors.primary }}
                    ></i>
                    <span
                      style={{
                        marginLeft: 15,
                        font: "normal normal bold 14px/35px Open Sans",
                        color: "#011B3E",
                      }}
                    >
                      {}
                      ({exportBuffer.length})
                      &nbsp;
                      Delete
                    </span>
                  </label>
                  { ["FORMS_PARSED", "FORMS_EXPORTED"].includes(formStatus) &&
                    <label className="xls_label" onClick={() => exportFormToExcel()}>
                      <img src={Images.dashboard_download_button} style={{marginLeft:-3}} />
                      <span
                        style={{
                          marginLeft: 15,
                          font: 'normal normal bold 14px/35px Open Sans',
                          color:'#011B3E'
                        }}>
                        ({exportBuffer.length})
                        &nbsp;
                        Download.xls
                      </span>
                    </label>
                  }

                </div>
              ) : null}
              {formStatus == "FORMS_REVIEW" && getSelectedFormCounter().isVisible &&
              (getFileCounter().isVisible || getVendorCounter().isVisible) &&
              exportBuffer.length > 0 ? (
                <div className="d-flex action-group" style={{ width: formStatus == "FORMS_DELETED" ? "unset" : "390px",  justifyContent:
                formStatus == "FORMS_REVIEW"
                  ? "flex-end"
                  : "space-between", }}>
                  <label
                    className="xls_label mr-2"
                    onClick={() => handleBatchDelete()}
                  >
                    <i
                      className="fa fa-trash"
                      style={{ fontSize: "17px", color: theme_colors.primary }}
                    ></i>
                    <span
                      style={{
                        marginLeft: 15,
                        font: "normal normal bold 14px/35px Open Sans",
                        color: "#011B3E",
                      }}
                    >
                      {}
                      ({exportBuffer.length})
                      &nbsp;
                      Delete
                    </span>
                  </label>
                  { ["FORMS_PARSED", "FORMS_EXPORTED"].includes(formStatus) &&
                    <label className="xls_label" onClick={() => exportFormToExcel()}>
                      <img src={Images.dashboard_download_button} style={{marginLeft:-3}} />
                      <span
                        style={{
                          marginLeft: 15,
                          font: 'normal normal bold 14px/35px Open Sans',
                          color:'#011B3E'
                        }}>
                        {exportBuffer.length}
                        &nbsp;
                        Download.xls
                      </span>
                    </label>
                  }

                </div>
              ) : null}

              {formStatus == "FORMS_REVIEW" ? (
                <label
                  className="upload_btn"
                  onClick={() => checkUserSubscription()}
                >
                  <img
                    src={Images.dashboard_upload}
                    style={{
                      marginRight: "7px",
                      width: "15px",
                      height: "15px",
                    }}
                  />
                  <span
                    style={{
                      font: "normal normal bold 14px/35px Open Sans",
                      color: "#011B3E",
                    }}
                  >
                    Upload
                  </span>
                </label>
              ) : null}
              {uploadModal && (
                <input
                  multiple
                  className="uploadBtn"
                  id="contained-button-file"
                  style={{ display: "none" }}
                  type={"file"}
                  accept="image/*,application/pdf"
                  onChange={addInvoice}
                />
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default DashboardForms;
