import React, { useEffect } from "react";
import Images from "../../assets/asset_imports";
import "./paginator.module.scss";
import CustomNumericInput from "../custom-numeric-input";

const PaginationComponent = (props) => {
  // Destructuring props
  let { onPageChange, data, callback, place, customClass } = props;
  // State variables to store the sub-array to be displayed, the start and end offsets, and the number of times the user has slide left

  const [subArr, setSubArr] = React.useState(data.slice(0, 3));
  const [startOffset, setStartOffset] = React.useState(0);
  const [endOffset, setEndOffset] = React.useState(3);
  const [leftSlideCount, setLeftSlideCount] = React.useState(0);
  const [pageInputValue, setPageInputValue] = React.useState(1);

  // Function to handle the slide left button

  const handleSlideLeft = (event) => {
    event.stopPropagation();
    let startTemp = startOffset - 1;
    let endTemp = endOffset - 1;
    var subArrTemp = data.slice(startTemp, endTemp);
    setSubArr(subArrTemp);
    setLeftSlideCount(leftSlideCount + 1);
    setStartOffset(startTemp);
    setEndOffset(endTemp);
  };

  useEffect(() => {
    if (["vendor", "vendor_rcpt", "admin_users"].includes(place)) {
      let subArrTemp = data.slice(startOffset, endOffset);
      setSubArr(subArrTemp);
    }
  }, [data]);
  // Function to handle the slide right button

  const handleSlideRight = (event) => {
    event.stopPropagation();
    let startTemp = startOffset + 1;
    let endTemp = endOffset + 1;

    var subArrTemp = data.slice(startTemp, endTemp);

    setStartOffset(startTemp);
    setEndOffset(endTemp);
    setSubArr(subArrTemp);
  };
  // Function to get the CSS class name based on the "place" prop

  const getClass = () => {
    switch (place) {
      case "vendor":
        return (
          "col-5 d-flex right-vendor-div justify-content-end " + customClass
        );
        break;
      case "vendor_rcpt":
        return "d-flex right-vendor-div justify-content-end";
        break;
      case "admin_users":
        return "col-12 d-flex right-vendor-div justify-content-end p-0";
      default:
        return "col-12 d-flex right-vendor-div justify-content-end p-0";
    }
  };
  // JSX to render the pagination component

  return (
    <div className={getClass()}>
      <div>Page</div>
      <div id="wrapper">
        {subArr[0] !== data[0] ? (
          <span
            onClick={(event) => handleSlideLeft(event)}
            style={{ cursor: "pointer" }}
          >
            <img src={Images.left_chevron} className="left_chev" />
          </span>
        ) : (
          <span style={{ width: "20px", height: "10px" }}></span>
        )}

        <ul id="nav">
          {subArr &&
            subArr.map((item, index) => {
              return (
                <li
                  className="page-item selected-page"
                  onClick={(event) => onPageChange(event, item)}
                >
                  <span>{item < 10 ? `0${item}` : item}</span>
                </li>
              );
            })}
        </ul>
        {subArr[subArr.length - 1] !== data[data.length - 1] ? (
          <span
            onClick={(event) => handleSlideRight(event)}
            style={{ cursor: "pointer" }}
          >
            <img src={Images.right_chevron} className="right_chev" />
          </span>
        ) : (
          <span style={{ width: "20px", height: "10px" }}></span>
        )}
      </div>
      of {data.length}
      <div className="ml-2">Go to Page</div>
      <div style={{ width: "40px" }} className="pagination ml-2">
        <CustomNumericInput
          maxOffset={String(data.length)}
          minOffset="1"
          handleFocus={() => callback(true)}
          handleBlur={() => callback(false)}
          inputId="form_pagination_input"
          handleChange={(event) =>
            setPageInputValue(Number(event.target.value))
          }
        />
      </div>
      <button
        type="button"
        className="btn btn-primary go-btn"
        onClick={(event) =>
          pageInputValue ? onPageChange(event, pageInputValue) : null
        }
      >
        Go
      </button>
    </div>
  );
};

export default PaginationComponent;
