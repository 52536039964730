import React, { useState, useEffect } from "react";
import "./adminReport.module.scss";
import "../../assets/css/style.scss";
import { Link } from "react-router-dom";
import Images from "../../assets/asset_imports";
import {
  Chip,
  Tab,
  Tabs,
  Box,
  CircularProgress,
  Backdrop,
  createMuiTheme,
  ThemeProvider,
} from "@material-ui/core";
import MuiAlert from "@material-ui/lab/Alert";
import theme_colors from "../../utils/theme";
import PropTypes from "prop-types";
import $ from "jquery";
import axios from "axios";
import {
  fetchAuthToken,
  handleLogout,
  paginateTable,
  fetchOrganisationId,
  checkOrganizationError,
  getDashboardRoutePromise,
} from "../../utils/Helpers";
import SnackbarComponent from "../../components/SnackbarComponent";
import MenuItem from "@material-ui/core/MenuItem";
import { makeStyles } from "@material-ui/core/styles";
import Menu from "@material-ui/core/Menu";
import Select from "@material-ui/core/Select";
import Tooltip from "@material-ui/core/Tooltip";
import Typography from "@material-ui/core/Typography";
import AppBar from "@material-ui/core/AppBar";
import Button from "@material-ui/core/Button";
import LogoutBar from "../../components/LogoutBar";
import DeleteDialogBox from "../../components/DeleteDialog/DeleteDialogBox";
import GlobalReport from "./GlobalReport";
import RoleManagement from "./RoleManagement";
import UserDetails from "./OrganizationDetails";
import { EXTRICATOR_BASE_URL } from "../../utils/constants";
import { useHistory } from "react-router-dom";
import CheckMobileDevice from "../../components/CheckMobileDevice";

const monthsSet = [
  {
    month: "Jan", // Three-letter abbreviation of the month
    index: 1, // Month index starting from 1
    total_days: 31, // Total number of days in the month
  },
  {
    month: "Feb",
    index: 2,
    total_days: 28,
  },
  {
    month: "Mar",
    index: 3,
    total_days: 31,
  },
  {
    month: "Apr",
    index: 4,
    total_days: 30,
  },
  {
    month: "May",
    index: 5,
    total_days: 31,
  },
  {
    month: "Jun",
    index: 6,
    total_days: 30,
  },
  {
    month: "Jul",
    index: 7,
    total_days: 31,
  },
  {
    month: "Aug",
    index: 8,
    total_days: 31,
  },
  {
    month: "Sep",
    index: 9,
    total_days: 30,
  },
  {
    month: "Oct",
    index: 10,
    total_days: 31,
  },
  {
    month: "Nov",
    index: 11,
    total_days: 30,
  },
  {
    month: "Dec",
    index: 12,
    total_days: 31,
  },
];
// Create a theme for sorting menu

const myThemeSort = createMuiTheme({
  overrides: {
    MuiMenu: {
      paper: {
        padding: 0,
        height: 56,
        borderRadius: 6,
        width: "100px",
      },
    },
    MuiPopover: {
      paper: {
        "& ul": {
          background: "#12458A 0% 0% no-repeat padding-box !important",
          textAlign: "center",
          height: "100%",
          overflow: "hidden",
        },
        "& li": {
          paddingLeft: "22px !important",
          width: "100% !important",
          height: "28px !important",
        },
        "& li:hover": {
          backgroundColor: "#5F88BF",
        },
      },
    },
    MuiList: {
      padding: {
        padding: "0px !important",
      },
    },
    MuiListItem: {
      root: {
        width: "114px !important",
        height: "22px !important",
        fontSize: "12px !important",
      },
    },
  },
});
// Create a theme for the menu

const myTheme = createMuiTheme({
  overrides: {
    MuiMenu: {
      paper: {
        padding: 0,
        borderRadius: "5px",
      },
      list: {
        paddingBottom: "0px !important",
      },
    },
    MuiPopover: {
      paper: {
        "& ul": {
          background: "#12458A 0% 0% no-repeat padding-box !important",
        },
      },
    },
    MuiInput: {
      root: {
        padding: "0px",
      },
      underline: {
        cursor: "pointer",
      },
    },
    MuiSelect: {
      select: {
        paddingRight: "0px !important",
      },
      icon: {
        height: "100%",
        marginTop: "0px !important",
        top: "0 !important",
      },
      selectMenu: {
        paddingLeft: "5px",
      },
    },
    MuiListItem: {
      gutters: {
        paddingRight: "22px !important",
      },
    },
    MuiSvgIcon: {
      root: {
        color: "#fff",
      },
    },
    MuiAccordionSummary: {
      content: {
        Mui: {
          expanded: {
            minHeight: "0px !important",
            margin: "0px !important",
          },
        },
      },
    },
  },
});
// Create a custom MUI theme to override default styles

const themeStyle = createMuiTheme({
  overrides: {
    Mui: {
      "&$disabled": {
        background: "gray",
      },
    },
  },
});
// TabPanel component to display the content of each tab

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`scrollable-auto-tabpanel-${index}`}
      aria-labelledby={`scrollable-auto-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}
// Prop types for TabPanel component

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};
// Function to return accessibility props for a tab

function a11yProps(index) {
  return {
    id: `scrollable-auto-tab-${index}`,
    "aria-controls": `scrollable-auto-tabpanel-${index}`,
  };
}
// An array of document data

const documents_data = [
  { total_docs: 0, status: "uploaded", count: 3 },
  { total_docs: 0, status: "confirmed", count: 3 },
];
const accordionStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  root: {
    color: "#fff",
    width: "100%",
    background: "transparent",
    boxShadow: "none",
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    fontWeight: theme.typography.fontWeightRegular,
    color: "#fff",
  },
  content: {
    color: "#062241",
    background: "rgba(255,255,255,0.1)",
    fontWeight: "600",
    padding: "6px",
    alignItems: "center",
    cursor: "pointer",
    justifyContent: "space-between",
    paddingLeft: "40px",
  },
  contentList: {
    backgroundColor: "transparent",
    fontSize: "12px",
  },
  arrowIcon: {
    padding: "0",
  },
  badgeRoot: {
    background: theme_colors.secondary,
    width: "33px",
    height: "17px",
    color: theme_colors.primary,
  },
  backdrop: {
    zIndex: 1000,
    color: "#fff",
  },
  badgeLabel: {
    color: theme_colors.primary,
  },
  poweredBy: {
    borderRight: "1px solid #c7c7c7",
  },
  emailChip: {
    backgroundColor: "#0B346B",
    cursor: "pointer",
    borderRadius: 3,
  },
  emailLogo: {
    background: "#86b5e0",
    color: `${theme_colors.primary} !important`,
  },
  appbarRoot: {
    padding: "0 5px",
    background: "transparent",
    boxShadow: "none",
  },
  indicator: {
    backgroundColor: "transparent !important",
    height: "0px !important",
  },
  tabRoot: {},
  importBtn: {
    backgroundColor: "#35F8F1",
    borderRadius: "6px",
    "&:hover": {
      backgroundColor: theme_colors.primary,
      color: "#FFF",
    },
  },
  saveBtn: {
    background: "#35F8F1 0% 0% no-repeat padding-box",
    borderRadius: "3px",
    opacity: 1,
    height: "38px",
    width: "132px",
  },
  disabledBtn: {
    border: " 1px solid #35F8F1",
  },
  removeBtn: {
    backgroundColor: "transparent",
    color: "#35F8F1",
    border: "1px solid #35F8F1",
    width: "132px",
    height: "38px",
    borderRadius: "3px",
    "&:hover": {
      backgroundColor: theme_colors.primary,
      color: "#FFF",
    },
  },

  logOutBtn: {
    backgroundColor: theme_colors.primary,
    color: "#FFF",
    borderRadius: "2px",
    "&:hover": {
      backgroundColor: theme_colors.primary,
      color: "#FFF",
    },
  },

  reviewBtn: {
    backgroundColor: theme_colors.primary,
    background: "rgb(2,27,65)",
    background:
      "linear-gradient(0deg, rgba(2,27,65,1) 49%, rgba(35,67,115,1) 100%)",
    borderRadius: "10px",
    padding: "10px 20px",
    "&:hover": {
      backgroundColor: theme_colors.primary,
      color: "#FFF",
    },
  },
  accountTab: {
    height: "26px",
    minHeight: "unset",
  },
  tabGroup: {
    height: "30px",
  },
  tabGroupRoot: {
    minHeight: "unset !important",
  },
  panelRoot: {
    padding: "0px",
  },
}));

const moreIconStyles = makeStyles((theme) => ({
  root: {
    padding: "0",
  },
}));
/**
 * CustomTab component to render a custom tab with an icon, title, and optional badge.
 *
 * @param {string} title - The title of the tab.
 * @param {string} icon - The source URL of the icon.
 * @param {boolean} showChip - Whether to show a badge.
 * @param {object} classes - Overrides for the Material-UI styles.
 * @returns The CustomTab component.
 */
const CustomTab = ({ title, icon, showChip, classes }) => (
  <div className="custom-tab">
    <img src={icon} />
    {title}
    {showChip && (
      <Chip
        size="small"
        label="1"
        style={{ marginLeft: "25px" }}
        classes={{ root: classes.badgeRoot, label: classes.badgeLabel }}
      />
    )}
  </div>
);

const SuperAdminReport = (props) => {
  const useStyles = makeStyles((theme) => ({
    root: {
      "& .MuiTextField-root": {
        margin: theme.spacing(1),
        width: "25ch",
      },
    },
  }));

  const history = useHistory();
  const date = new Date();
  const [deleteFileProgress, setDeleteFileProgress] = useState(false);
  const [newUserDialogOpen, setNewUserDialogOpen] = useState(false);
  const [chartYearsList, setchartYearsList] = useState([]);
  const [chartMonthsList, setChartMonthsList] = useState([]);
  const [defaultOption, setDefaultOption] = React.useState("");
  const [sortOption, setSortOption] = React.useState("Sort by");
  const [sortBy, setSortBy] = React.useState({
    name: "Sort By",
    value: null,
  });
  const [dateErrMessage, setDateErrMessage] = useState("");
  const [dateRange, setDateRange] = useState({ from: null, to: null });
  const [value, setValue] = React.useState(0);
  const [anchorElSort, setAnchorElSort] = React.useState(null);
  const [userEmail, setEmail] = React.useState("");
  const [days, setDays] = React.useState([1]);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [calander, openCalander] = React.useState(false);
  const [isUploading, setUploading] = React.useState(false);
  const [avatarUpdated, setAvatarUpdated] = React.useState(false);
  const [userCompany, setUserCompany] = React.useState(0);
  const [renameId, setRenameId] = React.useState(null);
  const [rows, setRows] = React.useState(5);
  const [fullInvoiceModelsList, setFullInvoiceModelsList] = React.useState([]);
  const [totalModelPages, setTotalModelPages] = React.useState([]);
  const [modelsPage, setModelsPage] = React.useState(1);
  const [logOutBtn, showLogoutBtn] = React.useState(0);
  const [modelValue, setModelValue] = React.useState(0);
  const [profileMenuShow, setProfileMenuShow] = React.useState(false);
  const [authToken, setAuthToken] = React.useState("");
  const [successMsg, setSuccessMsg] = React.useState("");
  const [snackOpen, setSnackOpen] = React.useState(false);
  const [toastColor, setToastColor] = React.useState("");
  const [open, setOpen] = React.useState(false);
  const [profilePic, setImage] = React.useState(Images.person);
  const [dateObj, setDateObj] = React.useState(null);
  const classes = accordionStyles();
  const [firstName, setFirstName] = React.useState("");
  const [lastName, setLastName] = React.useState("");
  const [activeSection, setActiveSection] = React.useState("");
  const [modelType, setModelType] = React.useState("INVOICE");
  const [invoiceModels, setInvoiceModels] = React.useState([]);
  const [formErrors, setFormErrors] = React.useState({});
  const [barUpdate, setBarUpdate] = useState(false);
  const [updateNames, setUpdateNames] = useState(false);
  const [isLoadingPassword, setLoadingPassword] = React.useState(false);
  const [imageFile, setImageFile] = React.useState({});
  const [deletableFileId, setDeletableFileId] = useState(null);
  const [goInputFocused, setGoInputFocused] = React.useState(false);
  const [tooltip, setTooltip] = React.useState(false);
  const [tooltipDate, setTooltipDate] = React.useState("");
  const [isLoading, setLoading] = React.useState(false);
  const [searchFocused, setSearchFocus] = useState(false);
  const [year, setYear] = React.useState("");
  const [years, setYears] = React.useState([]);
  const [month, setMonth] = React.useState(date.getMonth() + 1);
  const [report, setReport] = React.useState([]);
  const [toMonths, setToMonths] = React.useState(monthsSet);
  const [fromMonths, setFromMonths] = React.useState(monthsSet);
  const [backRoute, setBackroute] = React.useState("/dashboard_forms");
  const [months, setMonths] = React.useState([
    {
      month: "Jan",
      index: 1,
    },
    {
      month: "Feb",
      index: 2,
    },
    {
      month: "Mar",
      index: 3,
    },
    {
      month: "Apr",
      index: 4,
    },
    {
      month: "May",
      index: 5,
    },
    {
      month: "Jun",
      index: 6,
    },
    {
      month: "July",
      index: 7,
    },
    {
      month: "Aug",
      index: 8,
    },
    {
      month: "Sep",
      index: 9,
    },
    {
      month: "Oct",
      index: 10,
    },
    {
      month: "Nov",
      index: 11,
    },
    {
      month: "Dec",
      index: 12,
    },
  ]);
  /**
   * This useEffect function checks the history action and pushes to the /superadmin_report route if history action is "POP"
   */
  useEffect(() => {
    return () => {
      if (history.action === "POP") {
        props.history.push("/superadmin_report");
      }
    };
  }, [history]);
  /**
   * Initializes state for the form with empty strings and an empty object for date values
   */
  const [form, setState] = useState({
    firstName: "",
    lastName: "",
    oldPassword: "",
    newPassword: "",
    confirmPassword: "",
    email: "",
  });

  const [from, setFrom] = React.useState({
    day: null,
    month: null,
    year: null,
  });

  const [to, setTo] = React.useState({
    day: null,
    month: null,
    year: null,
  });
  /**
   * Formats the data for invoice models by paginating the data and setting the paginated data and total number of pages
   */
  const formatModelsData = (modelData) => {
    var paginatedData = paginateTable(modelData, modelsPage, rows);
    setInvoiceModels(paginatedData.paginated_data);
    setTotalModelPages(paginatedData.totalPages);
  };
  /**
   * Handles changes for the "to" date object, updates the "to" state, and updates the "toMonths" state based on the selected day value
   */
  const handleToChange = (event, value) => {
    setTo({
      ...to,
      [event.target.name]: event.target.value,
    });
    if (event.target.name == "day") {
      if (event.target.value > 28) {
        let monthsTemp = monthsSet.filter((item) => item.month !== "Feb");
        setToMonths(monthsTemp);
        if (event.target.value == 31) {
          let monthsTemp = monthsSet.filter((item) => item.total_days == 31);
          setToMonths(monthsTemp);
        } else {
          let monthsTemp = monthsSet.filter((item) => item.month !== "Feb");
          setToMonths(monthsTemp);
        }
      } else {
        setToMonths(monthsSet);
      }
    }
  };
  /**
   * Retrieves the dashboard route using a promise and sets the backroute state to the retrieved route
   */
  const getDashboardRoute = () => {
    var sectionPromise = getDashboardRoutePromise();
    sectionPromise
      .then((section) => setBackroute(section))
      .catch((e) => console.log("GET_DASHBOARD_ROUTE_ERR---", e));
  };
  /**
   * This useEffect function checks for the existence of a date error message and sets a timeout to clear it after 3 seconds
   */
  useEffect(() => {
    if (dateErrMessage) {
      setTimeout(() => {
        setDateErrMessage("");
      }, 3000);
    }
  }, [dateErrMessage]);
  /**

useEffect to initialize component state
Sets days, years and gets dashboard route
*/
  useEffect(() => {
    let days = [];
    const date = new Date();
    // loop through days of month and add to days array

    for (let i = 1; i < 32; i++) {
      if (i < 10) days.push(`0${i}`);
      else days.push(i);
    }
    // set component state with days array, set yearset and get dashboard route

    setDays(days);
    setYearset();
    getDashboardRoute();
  }, []);
  /**

Function to set yearSet state with array of years based on register year
*/
  const setYearset = async () => {
    var date = new Date();
    var registerYear = await localStorage.getItem("register_year");
    if (!registerYear) {
      registerYear = await sessionStorage.getItem("register_year");
    }
    var yearSet = [];
    for (let i = registerYear - 1; i < date.getFullYear(); i++) {
      yearSet.push(i + 1);
    }
    setYears(yearSet);
  };
  /**

Function to handle date search
Sets dateRange, tooltipDate, dateObj and tooltip states
Closes calendar
*/
  const handleDateSearch = (date, from) => {
    setDateRange(date);
    setTooltipDate(
      `${from.day}/${from.month}/${from.year} to ${to.day}/${to.month}/${to.year}`
    );
    setDateObj({ from: from, to: to });
    setTooltip(true);
    handleCalandarClose(null);
  };
  /**

Function to handle filter search form submission
Validates input dates and sets dateRange and tooltip states
*/
  const filterSearch = (e) => {
    e.preventDefault();
    let todayDate = new Date();
    let fromDate;
    let toDate;
    // check if both from and to dates are selected

    if (from.month && from.day && from.year && to.month && to.day && to.year) {
      // format from and to dates

      if (from.month < 10) {
        fromDate = `0${from.month} ${from.day} ${from.year}`;
      } else {
        fromDate = `${from.month} ${from.day} ${from.year}`;
      }
      if (to.month < 10) {
        toDate = `0${to.month} ${to.day} ${to.year}`;
      } else {
        toDate = `${to.month} ${to.day} ${to.year}`;
      }

      var date = {
        from: fromDate,
        to: toDate,
      };
      var fromDateString = date.from.split(" ").join("/");
      var toDateString = date.to.split(" ").join("/");

      var fromDateObject = new Date(fromDateString);
      var toDateObject = new Date(toDateString);
      // check if to date is not in future and is within this year

      if (fromDateObject <= toDateObject) {
        if (to.year == todayDate.getFullYear()) {
          if (to.month <= 1 + todayDate.getMonth()) {
            if (to.month == 1 + todayDate.getMonth()) {
              if (to.day <= todayDate.getDate()) {
                handleDateSearch(date, from);
              } else {
                setDateErrMessage("You can not select a future date");
              }
            } else {
              handleDateSearch(date, from);
            }
          } else {
            setDateErrMessage("You can not select a future date");
          }
        } else {
          handleDateSearch(date, from);
        }
      } else {
        setDateErrMessage(
          "Start date should be less than or equal to End date"
        );
      }
    }
  };

  const onCalandarClick = (event) => {
    // Set anchor element to the current target element

    setAnchorEl(event.currentTarget);
    // Toggle calendar visibility state

    openCalander(!calander);
    // Set tooltip visibility state to the inverse of calendar visibility state

    setTooltip(calander);
  };

  const handleCalandarClose = (flag) => {
    // Reset anchor element

    setAnchorEl(null);
    // Reset form values and tooltip visibility state if flag is 'reset' or date error message is present

    if (flag == "reset" || dateErrMessage) {
      setFrom({
        ...from,
        day: null,
        month: null,
        year: null,
      });

      setTo({
        ...to,
        day: null,
        month: null,
        year: null,
      });
      setTooltipDate("");
      setDateRange({ from: null, to: null });
      setDateErrMessage("");
    }
    // If tooltipDate is present, set tooltip visibility state to true

    if (tooltipDate) {
      setTooltip(true);
    }
  };

  const handleFormChange = (event, value) => {
    // Update from state with form value

    setFrom({
      ...from,
      [event.target.name]: event.target.value,
    });
    if (event.target.name == "day") {
      if (event.target.value > 28) {
        if (event.target.value == 31) {
          let monthsTemp = monthsSet.filter((item) => item.total_days == 31);
          setFromMonths(monthsTemp);
        } else {
          let monthsTemp = monthsSet.filter((item) => item.month !== "Feb");
          setFromMonths(monthsTemp);
        }
      } else {
        setFromMonths(monthsSet);
      }
    }
  };

  function Alert(props) {
    // Custom alert component with styles

    const classes = accordionStyles();
    return (
      <MuiAlert
        classes={{
          root: classes.toastRoot,
          icon: classes.toastIcon,
          message: classes.toastMessage,
        }}
        elevation={6}
        variant="filled"
        {...props}
      />
    );
  }

  const handleTabChange = (event, newValue) => {
    // If the new tab value is 3, fetch rejected invoice models

    if (newValue === 3) {
      getRejectedModels("INVOICE");
    }
    // Set tab value and reset form errors and date range

    setValue(newValue);
    setFormErrors({});
    handleCalandarClose("reset");
  };

  const getRejectedModels = (model_type) => {
    // Fetch auth token and organisation ID

    let authToken = fetchAuthToken();
    let ogi = fetchOrganisationId();
    // Call API to get rejected models

    axios({
      method: "GET",
      url: `${EXTRICATOR_BASE_URL}/get_rejected_model/?model_type=${model_type}&ogi=${ogi}`,
      headers: {
        Authorization: `Bearer ${authToken}`,
      },
    })
      .then(function (response) {
        // Format and set the response data

        formatModelsData(response.data);
        setFullInvoiceModelsList(response.data);
      })
      .catch(function (error) {
        // Handle error response

        if (error.response.data.non_field_errors) {
          if (
            error.response.data.non_field_errors[0].includes(
              "Kindly pay the bill"
            )
          ) {
            // Display error message for unpaid bill

            setToastColor("error");
            setSuccessMsg(error.response.data.non_field_errors[0]);
            setSnackOpen(true);
          } else {
            // Check for organisation error and redirect to login if necessary

            checkOrganizationError(
              error.response.data.non_field_errors[0],
              props.history
            );
          }
        }
      });
  };

  const closeProfileMenu = () => {
    // Close profile menu

    if (profileMenuShow || $(".account-menu").hasClass("show")) {
      setProfileMenuShow(false);
      $(".account-menu").removeClass("show");
    }
  };

  const handleScreenClick = (flag) => {
    // Show/hide logout button based on flag

    if (flag) {
      showLogoutBtn(!logOutBtn);
    } else {
      if (logOutBtn) {
        showLogoutBtn(false);
      }
    }
  };

  const handleSnackClose = (event, reason) => {
    // Handle close of snack bar

    if (reason === "clickaway") {
      return;
    }
    setSuccessMsg("");
    setSnackOpen(false);
  };

  const showHideLogoutBtn = (flag) => {
    // Show/hide logout button based on flag

    if (flag) {
      showLogoutBtn(!logOutBtn);
    } else {
      if (logOutBtn) {
        showLogoutBtn(false);
      }
    }
  };
  // Handle errors in API requests

  const onError = (error) => {
    // Handle error message for unpaid bills

    if (error.status) {
      if (error.status == 401) {
        if (error.data.non_field_errors) {
          if (
            error.response.data.non_field_errors[0].includes(
              "Kindly pay the bill"
            )
          ) {
            setToastColor("error");
            setSuccessMsg(error.response.data.non_field_errors[0]);
            setSnackOpen(true);
          } else {
            // Redirect to login page if the organization is not authorized

            checkOrganizationError(
              error.data.non_field_errors[0],
              props.history
            );
          }
        } else {
          // Handle generic error and logout

          handleLogout(props.history);
        }
      }
    }
  };
  // Delete rejected models based on the given model ID

  const confirmDelete = (modelId) => {
    setDeleteFileProgress(true);
    axios({
      method: "post",
      url: `${EXTRICATOR_BASE_URL}/delete_rejected_model/`,
      data: { model_id: modelId },
      headers: { Authorization: `Bearer ${authToken}` },
    })
      .then((res) => {
        if (modelType === "INVOICE") {
          getRejectedModels("INVOICE");
        } else if (modelType === "RECEIPT") {
          getRejectedModels("RECEIPT");
        }
        setDeleteFileProgress(false);
        setDeletableFileId(null);
        setToastColor("success");
        setSuccessMsg(res.data.message);
        setSnackOpen(true);
      })
      .catch((error) => {
        if (error.response) {
          if (error.response.data && error.response.data.non_field_errors) {
            // Display error message and redirect to login page if the organization is not authorized

            setToastColor("error");
            setSuccessMsg(error.response.data.non_field_errors[0]);
            setSnackOpen(true);
            checkOrganizationError(
              error.response.data.non_field_errors[0],
              props.history
            );
          }
        }
        setDeletableFileId(null);
        setDeleteFileProgress(false);
      });
  };
  // Handle changes in the date set

  const handleChangeDateSet = (val) => {
    if (val) {
      handleCalandarClose("reset");
    }
  };

  const handleShowLoader = (val) => setOpen(val);

  return (
    <div style={{ overflow: "auto" }} onClick={() => handleScreenClick()}>
      <Backdrop
        className={classes.backdrop}
        open={open}
        onClick={() => setOpen(false)}
      >
        <CircularProgress color="inherit" />
      </Backdrop>

      <CheckMobileDevice />

      <DeleteDialogBox
        open={deletableFileId}
        handleClose={() => setDeletableFileId(null)}
        content="Are you sure you want to delete this document?"
        inProgress={deleteFileProgress}
        handleConfirm={() => confirmDelete(deletableFileId)}
        dialogTitle="Confirmation"
      />

      <div className="dashboard_container">
        <SnackbarComponent
          isOpen={snackOpen}
          message={successMsg}
          color={toastColor}
          handleClose={handleSnackClose}
        />
        <div
          className="menu-side"
          style={{ backgroundImage: Images.data_center }}
        >
          <div>
            <div className="image-full">
              <div className="form-group d-flex justify-content-center mt-4 banner-head">
                <Link to={backRoute} style={{ width: "80%" }}>
                  <img
                    src={Images.app_logo}
                    className="img-responsive"
                    alt=""
                  />
                </Link>
              </div>
            </div>
            <AppBar position="static" classes={{ root: classes.appbarRoot }}>
              <div
                style={{
                  width: "9px",
                  position: "absolute",
                  height: "34px",
                  left: 0,
                  background: "#35F8F1 0% 0% no-repeat padding-box",
                  borderRadius: "0px 2px 2px 0px",
                  opacity: 1,
                  top:
                    value === 0
                      ? 115
                      : value === 1
                      ? 165
                      : value === 3
                      ? 262
                      : 211,
                }}
              ></div>
              <Tabs
                value={value}
                orientation="vertical"
                onChange={handleTabChange}
                textColor="primary"
                variant="scrollable"
                scrollButtons="auto"
                fullWidth
                centered
                classes={{
                  indicator: classes.indicator,
                }}
                aria-label="scrollable auto tabs example"
              >
                <Tab
                  classes={{ root: classes.tabRoot }}
                  label={
                    <CustomTab
                      classes={classes}
                      icon={
                        value === 0
                          ? Images.interface_icon
                          : Images.interface_icon
                      }
                      title="Global Report"
                    />
                  }
                  {...a11yProps(0)}
                />

                <Tab
                  classes={{ root: classes.tabRoot }}
                  label={
                    <CustomTab
                      icon={value === 1 ? Images.users_icon : Images.users_icon}
                      title="Organization Management"
                    />
                  }
                  {...a11yProps(1)}
                />
              </Tabs>
            </AppBar>
          </div>
        </div>
        <div
          className="invoice-side"
          onClick={closeProfileMenu}
          style={{ height: "100vh", overflow: "hidden" }}
        >
          <div
            className="basic-search my-3"
            style={{ position: "relative", padding: "0px 10px" }}
          >
            <div className="control-bar">
              <span
                className="d-flex align-items-center"
                style={{ fontSize: "14px", cursor: "pointer" }}
              ></span>
              <div className="d-flex align-items-center">
                {value !== 1 && (
                  <>
                    <Tooltip
                      open={tooltip}
                      title={tooltipDate}
                      placement="bottom"
                      arrow
                    >
                      <img
                        src={Images.calendar}
                        className="calendar mr-3"
                        onClick={onCalandarClick}
                        style={{
                          width: 25,
                          height: 29,
                          color: "#FFFFFF 0% 0% no-repeat padding-box",
                          zIndex: 1310,
                        }}
                      />
                    </Tooltip>
                    <div className="dashboard_calendar">
                      <ThemeProvider theme={myTheme}>
                        {calander && (
                          <>
                            <Menu
                              id="simple-menu"
                              anchorEl={anchorEl}
                              keepMounted
                              open={Boolean(anchorEl)}
                              className="mt-5"
                              onClose={() => handleCalandarClose(null)}
                              style={{ left: "0px", overflowY: "hidden" }}
                            >
                              <MenuItem
                                style={{
                                  background:
                                    "#12458A 0% 0% no-repeat padding-box",
                                  borderRadius: 5,
                                  opacity: 1,
                                }}
                              >
                                <div>
                                  <div className="date-band">
                                    <label>From</label>
                                    <Select
                                      labelId="demo-simple-select-outlined-label"
                                      id="demo-simple-select-outlined"
                                      onChange={handleFormChange}
                                      className="to-day"
                                      value={from.day}
                                      name="day"
                                      style={{
                                        display: "flex",
                                        width: "42px",
                                        height: "19px",
                                        color: "#FFF",
                                        fontSize: "12px",
                                        marginLeft: "16px",
                                      }}
                                    >
                                      {days.map((item) => (
                                        <MenuItem
                                          style={{
                                            color: "#FFF",
                                            fontSize: "12px",
                                          }}
                                          value={item}
                                          id={item}
                                        >
                                          {item}
                                        </MenuItem>
                                      ))}
                                    </Select>
                                    <Select
                                      labelId="demo-simple-select-outlined-label"
                                      id="demo-simple-select-outlined"
                                      className="to-day"
                                      value={from.month}
                                      onChange={handleFormChange}
                                      name="month"
                                      style={{
                                        display: "flex",
                                        width: "51px",
                                        height: "19px",
                                        fontSize: "12px",
                                        color: "#FFF",
                                        marginLeft: 25,
                                      }}
                                    >
                                      {fromMonths.map((item) => (
                                        <MenuItem
                                          style={{
                                            color: "#FFF",
                                            fontSize: "12px",
                                          }}
                                          value={item.index}
                                          id={item.index}
                                        >
                                          {item.month}
                                        </MenuItem>
                                      ))}
                                    </Select>
                                    <Select
                                      labelId="demo-simple-select-outlined-label"
                                      id="demo-simple-select-outlined"
                                      className="to-day"
                                      onChange={handleFormChange}
                                      value={from.year}
                                      name="year"
                                      style={{
                                        display: "flex",
                                        width: "58px",
                                        height: "19px",
                                        color: "#FFF",
                                        fontSize: "12px",
                                        marginLeft: 22,
                                      }}
                                    >
                                      {years.map((item) => (
                                        <MenuItem
                                          style={{
                                            color: "#FFF",
                                            fontSize: "12px",
                                          }}
                                          value={item}
                                          key={item}
                                        >
                                          {item}
                                        </MenuItem>
                                      ))}
                                    </Select>
                                  </div>

                                  <div
                                    className="date-band"
                                    style={{ marginTop: "19px" }}
                                  >
                                    <label>To</label>
                                    <Select
                                      labelId="demo-simple-select-outlined-label"
                                      id="demo-simple-select-outlined"
                                      className="to-day"
                                      onChange={handleToChange}
                                      name="day"
                                      value={to.day}
                                      style={{
                                        display: "flex",
                                        width: "42px",
                                        height: "19px",
                                        color: "#FFF",
                                        fontSize: "12px",
                                        marginLeft: "16px",
                                      }}
                                    >
                                      {days.map((item) => (
                                        <MenuItem
                                          style={{
                                            color: "#FFF",
                                            fontSize: "12px",
                                          }}
                                          value={item}
                                          id={item}
                                        >
                                          {item}
                                        </MenuItem>
                                      ))}
                                    </Select>
                                    <Select
                                      labelId="demo-simple-select-outlined-label"
                                      id="demo-simple-select-outlined"
                                      className="to-day"
                                      value={to.month}
                                      onChange={handleToChange}
                                      name="month"
                                      style={{
                                        display: "flex",
                                        width: "51px",
                                        height: "19px",
                                        color: "#FFF",
                                        fontSize: "12px",
                                        marginLeft: 25,
                                      }}
                                    >
                                      {toMonths.map((item) => (
                                        <MenuItem
                                          style={{
                                            color: "#FFF",
                                            fontSize: "12px",
                                          }}
                                          value={item.index}
                                          id={item.index}
                                        >
                                          {item.month}
                                        </MenuItem>
                                      ))}
                                    </Select>
                                    <Select
                                      labelId="demo-simple-select-outlined-label"
                                      id="demo-simple-select-outlined"
                                      className="to-day"
                                      onChange={handleToChange}
                                      name="year"
                                      value={to.year}
                                      style={{
                                        display: "flex",
                                        width: "58px",
                                        height: "19px",
                                        color: "#FFF",
                                        fontSize: "12px",
                                        marginLeft: 22,
                                      }}
                                    >
                                      {years.map((item) => (
                                        <MenuItem
                                          style={{
                                            color: "#FFF",
                                            fontSize: "12px",
                                          }}
                                          value={item}
                                        >
                                          {item}
                                        </MenuItem>
                                      ))}
                                    </Select>
                                  </div>
                                </div>
                              </MenuItem>
                              <MenuItem>
                                <div className="col-12">
                                  <div className="row justify-content-between">
                                    <span
                                      onClick={() =>
                                        handleCalandarClose("reset")
                                      }
                                      style={{
                                        font: "normal normal 600 10px/42px Open Sans",
                                        marginLeft: "10px",
                                        opacity: 1,
                                        textDecoration: "underline",
                                      }}
                                    >
                                      Clear Search
                                    </span>
                                    <Button
                                      component="span"
                                      variant="contained"
                                      classes={{ root: classes.logOutBtn }}
                                      style={{
                                        height: 28,
                                        borderRadius: 2,
                                        marginLeft: "53px",
                                        background:
                                          "#35F8F1 0% 0% no-repeat padding-box",
                                        width: "110px",
                                        marginTop: 5,
                                      }}
                                      onClick={filterSearch}
                                    >
                                      <span
                                        style={{
                                          paddingLeft: 10,
                                          paddingRight: 10,
                                          font: "normal normal bold 10px/25px Open Sans",
                                          color: " #011B3E",
                                          opacity: 1,
                                          letterSpacing: 0,
                                        }}
                                      >
                                        Search
                                      </span>
                                    </Button>
                                  </div>
                                </div>
                              </MenuItem>
                              {dateErrMessage && (
                                <Alert
                                  classes={{
                                    icon: classes.alertIcon,
                                    message: classes.alertMsg,
                                  }}
                                  style={{ fontSize: "12px", padding: "6px" }}
                                  severity="error"
                                >
                                  {dateErrMessage}
                                </Alert>
                              )}
                            </Menu>
                          </>
                        )}
                      </ThemeProvider>
                    </div>
                  </>
                )}
                <LogoutBar
                  btnShow={logOutBtn}
                  isAccounts={true}
                  callback={showHideLogoutBtn}
                  updateBar={barUpdate}
                  sendCallback={(val) => setAvatarUpdated(val)}
                ></LogoutBar>
              </div>
            </div>
          </div>
          {value == 0 && (
            <GlobalReport
              dateSet={dateRange}
              loaderCallback={(val) => handleShowLoader(val)}
              sendClearDateCallback={handleChangeDateSet}
              handleErrorCallback={onError}
            />
          )}
          {value == 1 && (
            <UserDetails
              dateSet={dateRange}
              loaderCallback={(val) => handleShowLoader(val)}
              sendClearDateCallback={handleChangeDateSet}
              handleErrorCallback={onError}
            />
          )}
          {value == 2 && (
            <RoleManagement
              dateSet={dateObj}
              loaderCallback={(val) => handleShowLoader(val)}
            />
          )}
        </div>
      </div>
    </div>
  );
};
export default SuperAdminReport;
