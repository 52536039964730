import React, { useEffect } from "react";
import {
  Button,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Chip,
  Avatar,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  ThemeProvider,
  createMuiTheme,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import "../assets/css/style.scss";
import { withRouter } from "react-router";
import Images from "../assets/asset_imports";
import { Link } from "react-router-dom";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";
import {
  fetchAuthToken,
  fetchLoggedInUserName,
  getIsSuperUser,
  handleLogout,
} from "../utils/Helpers";
import axios from "axios";
import { useLocation } from "react-router-dom";
import { EXTRICATOR_BASE_URL } from "../utils/constants";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    position: "absolute",
  },
  heading: {
    color: "black",
    fontSize: theme.typography.pxToRem(15),
    fontWeight: theme.typography.fontWeightRegular,
  },
  listButton: {
    justifyContent: "flex-start !important",
    backgroundColor: "#0B346B !important",
    zIndex: 10,
    paddingLeft: "16px !important",
  },
  rootList: {
    backgroundColor: "#0B346B", // Background color of the root list
    height: "auto !important",
    "box-shadow": "-3px 6px 38px -6px rgba(0,0,0,0.75)", // Background color of the account root
    "-webkit-box-shadow": "-3px 6px 38px -6px rgba(0,0,0,0.75)",
    "-moz-box-shadow": "-3px 6px 38px -6px rgba(0,0,0,0.75)",
  },
  accRoot: {
    backgroundColor: "#011B3E", // Background color of the account root
    zIndex: 10,
    padding: "0px !important",
  },
  emailChip: {
    width: "260px !important", // Width of the email chip
    paddingLeft: "25px",
  },
  logOutBtn: {
    padding: "0px !important", // Padding of the logout button
    background: "#04244F !important", // Background color of the logout button
  },
  spanPrimary: {
    color: "#fff !important", // Color of the primary span
    fontWeight: "100 !important",
  },
}));

// Create a custom theme with MUI (Material-UI)
const myTheme = createMuiTheme({
  // Apply overrides to default Material UI styles

  overrides: {
    // Override the styles for the collapse component's wrapper element

    MuiCollapse: {
      wrapperInner: {
        zIndex: "100!important", // add z-index to inner wrapper of MUI Collapse component
      },
    },
    MuiAccordion: {
      root: {
        background: "transparent", // make MUI Accordion component's background transparent
      },
    },
    MuiAccordionSummary: {
      content: {
        margin: "0px !important", // remove margin from the content section of MUI AccordionSummary component
        padding: "10px 0px !important", // add padding to the content section of MUI AccordionSummary component
      },
      root: {
        minHeight: "unset !important",
        padding: "0px !important",
      },
    },
    // Override the styles for the Paper component's elevation 1

    MuiPaper: {
      elevation1: {
        boxShadow: "unset !important",
      },
    },
    // Override the styles for the ListItem component's button

    MuiListItem: {
      button: {
        justifyContent: "flex-start !important",
      },
    },
  },
});
// Create a custom MUI theme for the chips

const chipTheme = createMuiTheme({
  overrides: {
    MuiChip: {
      // Override the root class of the chip component

      root: {
        backgroundColor: "#0B346B !important", // Set the background color
      },
      clickable: {
        // Override the clickable class of the chip component
        "&:hover": {
          backgroundColor: "#0B346B", // Set the background color on hover
        },
        "&:focus": {
          backgroundColor: "#0B346B", // Set the background color on focus
        },
      },
      deleteIcon: {
        // Override the deleteIcon class of the chip component

        height: "50px", // Set the height of the delete icon
        width: "31px !important", // Set the width of the delete icon
      },
      label: {
        // Override the label class of the chip component

        color: "#fff !important", // Set the text color
      },
    },
  },
});
// Component definition

const LogoutBar = (props) => {
  // State variables for the component

  const isSuperUser = getIsSuperUser();
  // Hooks

  const location = useLocation();
  const classes = useStyles();
  const [logout, showLogout] = React.useState(false);
  const [userEmail, setUserEmail] = React.useState("");
  const [userAvatar, setAvatar] = React.useState(Images.person);
  // Fetch user's avatar image

  const getImage = async () => {
    var auth = await fetchAuthToken();
    axios({
      method: "GET",
      url: `${EXTRICATOR_BASE_URL}/image_upload/`,
      headers: {
        Authorization: `Bearer ${auth}`,
      },
    })
      .then(function (response) {
        if (response.data.image_url !== "") {
          setAvatar(response.data.image_url);
          props.sendCallback(true);
        } else {
          setAvatar(Images.person);
        }
      })
      .catch(function (error) {
        //handle error
      });
  };
  // Fetch logged in user's email

  const getLoggedInUser = () => {
    var loggedInUserEmail = fetchLoggedInUserName();
    setUserEmail(loggedInUserEmail);
  };
  // useEffect hooks

  useEffect(() => {
    getImage();
    getLoggedInUser();
  }, []);

  useEffect(() => {
    if (props.updateBar) {
      getImage();
      getLoggedInUser();
    }
  }, [props.updateBar]);
  // Return JSX

  return (
    <div style={{ position: "relative", height: "50px", width: "285px" }}>
      <div className={classes.root} >
        <ThemeProvider theme={myTheme}>
          <Accordion expanded={props.btnShow}>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              <ThemeProvider theme={chipTheme} >
                <Chip
                  aria-controls="simple-menu2"
                  classes={{ label: classes.emailChip }}
                  onClick={() => props.callback("open")}
                  avatar={
                    <Avatar
                      classes={{ root: classes.emailLogo }}
                      alt="user_avatar"
                      src={userAvatar}
                    ></Avatar>
                  }
                  label={userEmail}
                  deleteIcon={<ArrowDropDownIcon />}
                />
              </ThemeProvider>
            </AccordionSummary>
            <AccordionDetails classes={{ root: classes.accRoot }}>
              <List
                classes={{ root: classes.rootList }}
                component="nav"
                aria-label="main mailbox folders"
              >
                {!props.isAccounts ? (
                  props.isAdmin ? (
                    <ListItem
                      button
                      classes={{ root: classes.listButton }}
                      onClick={() => props.history.push("/account")}
                    >
                      <ListItemIcon>
                        <img
                          src={Images.settings}
                          style={{
                            cursor: "pointer",
                            width: 14,
                            height: 14,
                            color: "#fff",
                            opacity: 1,
                            marginLeft: "16.3px",
                            marginRight: "11.3px",
                          }}
                        />
                      </ListItemIcon>
                      <ListItemText
                        classes={{ primary: classes.spanPrimary }}
                        primary="Account Settings"
                        style={{fontSize: '2rem !important'}}
                      />
                    </ListItem>
                  ) : (
                    <Link
                      to={{ pathname: `/account`, state: props.optionStatus }}
                      style={{ textDecoration: "none" }}
                    >
                      <ListItem button classes={{ root: classes.listButton }}>
                        <ListItemIcon>
                          <img
                            src={Images.settings}
                            style={{
                              cursor: "pointer",
                              width: 14,
                              height: 14,
                              color: "#fff",
                              opacity: 1,
                              marginLeft: "16.3px",
                              marginRight: "11.3px",
                            }}
                          />
                        </ListItemIcon>
                        <ListItemText
                          classes={{ primary: classes.spanPrimary }}
                          primary="Account Settings"
                        />
                      </ListItem>
                    </Link>
                  )
                ) : null}
                {isSuperUser == "false" ? (
                  !location.pathname.includes("admin_landing") ? (
                    <ListItem
                      button
                      onClick={() =>
                        props.history.replace({ pathname: "/admin_landing" })
                      }
                      classes={{ root: classes.listButton }}
                    >
                      {/* <ListItemIcon>
                        <img
                          src={Images.organization}
                          style={{
                            cursor: "pointer",
                            width: 17,
                            color: "#fff",
                            opacity: 1,
                            marginLeft: "16.3px",
                            marginRight: "11.3px",
                          }}
                        />
                      </ListItemIcon>
                      <ListItemText
                        classes={{ primary: classes.spanPrimary }}
                        primary="Change Organization"
                      /> */}
                    </ListItem>
                  ) : null
                ) : null}

                <ListItem
                  style={{ cursor: "default" }}
                  button
                  classes={{ root: classes.listButton }}
                  onClick={(event) => event.stopPropagation()}
                >
                  <Button
                    component="span"
                    variant="contained"
                    classes={{ root: classes.logOutBtn }}
                    onClick={() => handleLogout(props.history)}
                    style={{
                      height: 35,
                      width: 68,
                      zIndex: 200,
                      borderRadius: 3,
                      background: "#0B346B 0% 0% no-repeat padding-box",
                      boxShadow: "0px 3px 6px #10101029",
                    }}
                  >
                    <div
                      style={{
                        color: "#f8f9fa",
                        font: "normal normal 600 12px/35px Open Sans",
                      }}
                    >
                      Logout
                    </div>
                  </Button>
                </ListItem>
              </List>
            </AccordionDetails>
          </Accordion>
        </ThemeProvider>
      </div>
    </div>
  );
};

export default withRouter(LogoutBar);
