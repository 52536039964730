// Sets the EXTRICATOR_BASE_URL constant to the appropriate value based on the environment

export const EXTRICATOR_BASE_URL = window.ENV
  ? window.ENV.REACT_APP_BASE_URL
  : process.env.REACT_APP_BASE_URL;
// Defines an array of month objects with properties for month name, index, and total number of days

export const monthsSet = [
  {
    month: "Jan",
    index: 1,
    total_days: 31,
  },
  {
    month: "Feb",
    index: 2,
    total_days: 28,
  },
  {
    month: "Mar",
    index: 3,
    total_days: 31,
  },
  {
    month: "Apr",
    index: 4,
    total_days: 30,
  },
  {
    month: "May",
    index: 5,
    total_days: 31,
  },
  {
    month: "Jun",
    index: 6,
    total_days: 30,
  },
  {
    month: "Jul",
    index: 7,
    total_days: 31,
  },
  {
    month: "Aug",
    index: 8,
    total_days: 31,
  },
  {
    month: "Sep",
    index: 9,
    total_days: 30,
  },
  {
    month: "Oct",
    index: 10,
    total_days: 31,
  },
  {
    month: "Nov",
    index: 11,
    total_days: 30,
  },
  {
    month: "Dec",
    index: 12,
    total_days: 31,
  },
];
// Defines an array of month objects with properties for month value, label, index, and total number of days, used in admin views

export const adminMonthsSet = [
  {
    value: "Jan",
    label: "Jan",
    index: 1,
    total_days: 31,
  },
  {
    value: "Feb",
    label: "Feb",
    index: 2,
    total_days: 28,
  },
  {
    value: "Mar",
    label: "Mar",
    index: 3,
    total_days: 31,
  },
  {
    value: "Apr",
    label: "Apr",
    index: 4,
    total_days: 30,
  },
  {
    value: "May",
    label: "May",
    index: 5,
    total_days: 31,
  },
  {
    value: "Jun",
    label: "Jun",
    index: 6,
    total_days: 30,
  },
  {
    value: "Jul",
    label: "Jul",
    index: 7,
    total_days: 31,
  },
  {
    value: "Aug",
    label: "Aug",
    index: 8,
    total_days: 31,
  },
  {
    value: "Sep",
    label: "Sep",
    index: 9,
    total_days: 30,
  },
  {
    value: "Oct",
    label: "Oct",
    index: 10,
    total_days: 31,
  },
  {
    value: "Nov",
    label: "Nov",
    index: 11,
    total_days: 30,
  },
  {
    value: "Dec",
    label: "Dec",
    index: 12,
    total_days: 31,
  },
];
// Defines a regular expression for matching invalid model names

export const modelNameRegex = /[/\\*'?[\]:]+/;
// Defines a regular expression for matching email addresses

export const emailRegex =
  /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
