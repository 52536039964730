import React, { useState, useEffect } from "react";
import "./dashboard.module.scss";
import "../../assets/css/style.scss";
import { Link } from "react-router-dom";
import Images from "../../assets/asset_imports";
import {
  Box,
  Button,
  CircularProgress,
  Backdrop,
  createMuiTheme,
  ThemeProvider,
} from "@material-ui/core";
import MuiAlert from "@material-ui/lab/Alert";

import theme_colors from "../../utils/theme";
import PropTypes from "prop-types";
import $, { event } from "jquery";
import axios from "axios";
import {
  fetchAuthToken,
  fetchLoggedInUserEmail,
  handleLogout,
  paginateTable,
  getTimeRecord,
  setActiveSection,
  fetchOrganisationId,
  checkOrganizationError,
  fetchActiveSection,
  fetchUserID,
} from "../../utils/Helpers";
import SnackbarComponent from "../../components/SnackbarComponent";
import MenuItem from "@material-ui/core/MenuItem";
import { makeStyles } from "@material-ui/core/styles";
import Menu from "@material-ui/core/Menu";
import Select from "@material-ui/core/Select";
import Accordion from "@material-ui/core/Accordion";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import Typography from "@material-ui/core/Typography";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import Checkbox from "@material-ui/core/Checkbox";
import CustomNumericInput from "../../components/custom-numeric-input";
import Tooltip from "@material-ui/core/Tooltip";
import Paper from "@material-ui/core/Paper";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Modal from "@material-ui/core/Modal";
import ImageShowcase from "../../components/ImageShowcase";
import TopNav from "./components/TopNav";
import CustomDropDown from "../../components/CustomDropDown";
import LogoutBar from "../../components/LogoutBar";
import DeleteDialogBox from "../../components/DeleteDialog/DeleteDialogBox";
import FileCategoryComponent from "./components/FileCategoryComponent";
import PaginationComponent from "../../components/PaginatorComponent";
import PayContainer from "../../components/PayContainer";
import SearchOnSelect from "./components/SearchCriteriaMenu";
import { EXTRICATOR_BASE_URL, monthsSet } from "../../utils/constants";
import CheckMobileDevice from "../../components/CheckMobileDevice";

var invoiceCategory = "";
// Sort options for file sorting

const sortOptions = [
  {
    name: "By Name",
    value: "by_name",
  },
  {
    name: "By Upload",
    value: "by_upload",
  },
];
// Tab panel component

function TabPanel(props) {
  // Extracting values from props using object destructuring

  const { children, value, index, ...other } = props;
  // Returns a div element that is hidden when the value prop is not equal to the index prop

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`scrollable-auto-tabpanel-${index}`}
      aria-labelledby={`scrollable-auto-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}
// Typechecking the props for the TabPanel component

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};
// Define the styles using makeStyles function from Material-UI

const accordionStyles = makeStyles((theme) => ({
  root: {
    color: "#fff",
    width: "100%",
    background: "transparent",
    boxShadow: "none",
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    fontWeight: theme.typography.fontWeightRegular,
    color: "#fff",
  },
  content: {
    color: "#062241",
    background: "rgba(255,255,255,0.1)",
    fontWeight: "600",
    padding: "6px",
    alignItems: "center",
    cursor: "pointer",
    justifyContent: "space-between",
    paddingLeft: "40px",
  },
  contentList: {
    backgroundColor: "transparent",
    fontSize: "12px",
  },
  arrowIcon: {
    padding: "0",
  },
  badgeRoot: {
    width: "33px",
    height: "17px",
    color: theme_colors.primary,
    background: "#2280FF",
  },
  badgeLabel: {
    color: theme_colors.primary,
  },
  poweredBy: {
    borderRight: "1px solid #c7c7c7",
  },
  emailChip: {
    backgroundColor: "#0B346B",
    cursor: "pointer",
    borderRadius: 3,
    fontWeight: "600",
    fontFamily: "'Open Sans', sans-serif !important",
    width: "249px",
    height: "35px",
    marginRight: "10px",
  },
  emailLogo: {
    background: theme_colors.secondary,
    color: `${theme_colors.primary} !important`,
  },
  appbarRoot: {
    padding: "0 15px",
    background: "transparent",
    boxShadow: "none",
    marginTop: "15px",
  },
  indicator: {
    backgroundColor: "transparent !important",
    height: "0px !important",
  },
  tabRoot: {
    width: "180px",
    fontWeight: "600",
  },
  importBtn: {
    backgroundColor: "#35F8F1",
    borderRadius: "6px",
  },

  summary_root: {
    minHeight: "32px !important",
  },
  summary_rootSelected: {
    backgroundColor: "#2280FF",
    minHeight: "22px !important",
  },
  summaryStyle: {
    alignItems: "center",
    display: "flex",
  },
  accordionRoot: {
    backgroundColor: "red",
  },
  summaryDisabled: {
    opacity: "1 !important",
  },
  goBtn: {
    backgroundColor: "#031B40",
    borderRadius: "6px",
    "&:hover": {
      backgroundColor: theme_colors.primary,
      color: "#FFF",
    },
  },
  calendarList: {
    background: "green !important",
  },
  logOutBtn: {
    backgroundColor: theme_colors.primary,
    color: "#FFF",
    borderRadius: "3px",
    "&:hover": {
      backgroundColor: theme_colors.primary,
      color: "#FFF",
    },
  },

  reviewBtn: {
    backgroundColor: theme_colors.primary,
    background: "rgb(2,27,65)",
    background:
      "linear-gradient(0deg, rgba(2,27,65,1) 49%, rgba(35,67,115,1) 100%)",
    borderRadius: "10px",
    padding: "10px 20px",
    "&:hover": {
      backgroundColor: theme_colors.primary,
      color: "#FFF",
    },
  },
  backdrop: {
    zIndex: 10,
    color: "#fff",
  },
  myMenu: {
    height: "50px",
    width: "30px",
    color: "#fff !important",
  },
  menuPaper: {
    left: "868px !important",
    top: "52px !important",
    width: "114px !important",
  },
  spinner: {
    color: "#011B3E",
  },
  alertIcon: {
    display: "none",
  },
  alertMsg: {
    padding: "0px 0px",
  },
}));

const myTheme = createMuiTheme({
  overrides: {
    MuiMenu: {
      paper: {
        padding: 0,
        borderRadius: "5px",
      },
      list: {
        paddingBottom: "0px !important",
      },
    },
    MuiPopover: {
      paper: {
        "& ul": {
          background: "#12458A 0% 0% no-repeat padding-box !important",
        },
      },
    },
    MuiInput: {
      root: {
        padding: "0px",
      },
      underline: {
        cursor: "pointer",
      },
    },
    MuiSelect: {
      select: {
        paddingRight: "0px !important",
      },
      icon: {
        height: "100%",
        marginTop: "0px !important",
        top: "0 !important",
      },
      selectMenu: {
        paddingLeft: "5px",
      },
    },
    MuiListItem: {
      gutters: {
        paddingRight: "22px !important",
        cursor: "default",
      },
    },
    MuiSvgIcon: {
      root: {
        color: "#fff",
      },
    },
  },
});

const myThemeSort = createMuiTheme({
  overrides: {
    MuiMenu: {
      paper: {
        padding: 0,
        height: 56,
        borderRadius: 6,
        width: "100px",
      },
    },
    MuiPopover: {
      paper: {
        "& ul": {
          background: "#12458A 0% 0% no-repeat padding-box !important",
          textAlign: "center",
          height: "100%",
          overflow: "hidden",
        },
        "& li": {
          paddingLeft: "22px !important",
          width: "100% !important",
          height: "28px !important",
        },
        "& li:hover": {
          backgroundColor: "#5F88BF",
        },
      },
    },
    MuiList: {
      padding: {
        padding: "0px !important",
      },
    },
    MuiListItem: {
      root: {
        width: "114px !important",
        height: "22px !important",
        fontSize: "12px !important",
      },
    },
  },
});
// A functional component that renders a custom tab

const CustomTab = ({ title, icon, showChip, classes, thickness }) => (
  <div className="customs-tab review-div" style={{ fontWeight: thickness }}>
    <img src={icon} />
    {title}
  </div>
);
// A functional component that renders an alert using Material UI's MuiAlert component

function Alert(props) {
  // Get styles for the accordion

  const classes = accordionStyles();
  // Render the MuiAlert component with customized styles and props

  return (
    <MuiAlert
      classes={{
        root: classes.toastRoot,
        icon: classes.toastIcon,
        message: classes.toastMessage,
      }}
      elevation={6}
      variant="filled"
      {...props}
    />
  );
}

var timerVal;
var previewTime;
var tripper = 0;

const DashboardPOrder = (props) => {
  const authToken = fetchAuthToken();
  const ogi = fetchOrganisationId();
  const user_id = fetchUserID();
  const activeSection = fetchActiveSection();

  const [logOutBtn, showLogoutBtn] = useState(false);
  const [searchSubject, setSearchSubject] = useState("file");
  const [searchAttempt, setSearchAttempt] = useState(false);
  const [activeTab, setActiveTab] = useState(activeSection);
  const [testCounter, setTestCounter] = useState(0);
  const [userId, setUserId] = useState(null);
  const [fullProcessingFiles, setFullProcessingFiles] = useState([]);
  const [exportBuffer, setExportBuffer] = useState([]);
  const [deletedInvoiceTab, setDeletedInvoiceTab] = useState(0);
  const [fileUploading, setFileUploading] = useState(false);
  const [deletableFileId, setDeletableFileId] = useState(null);
  const [deleteFileProgress, setDeleteFileProgress] = useState(false);
  const [fileMessage, setFileMessage] = useState(0);
  const [dateErrMessage, setDateErrMessage] = useState("");
  const [searchFocused, setSearchFocus] = useState(false);
  const [dateRange, setDateRange] = useState({ from: null, to: null });
  const [value, setValue] = React.useState(0);
  const [valueParsed, setParsedValue] = React.useState(0);
  const [profileMenuShow, setProfileMenuShow] = React.useState(false);
  const [successMsg, setSuccessMsg] = React.useState("");
  const [snackOpen, setSnackOpen] = React.useState(false);
  const [toastColor, setToastColor] = React.useState("success");
  const [open, setOpen] = React.useState(false);
  const [updateTable, setUpdateTable] = React.useState(false);
  const [sortOption, setSortOption] = React.useState("Sort by");
  const [calander, openCalander] = React.useState(false);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [anchorElSort, setAnchorElSort] = React.useState(null);
  const [anchorElLogout, setAnchorElLogout] = React.useState(null);
  const [vendorHTML, setVendorHTML] = React.useState([]);
  const [processingFiles, setProcessingFiles] = React.useState([]);
  const [vendorDetailsHTML, setVendorDetails] = React.useState([]);
  const [vendorsLength, setVendorsLength] = React.useState([]);
  const [expanded, setExpanded] = React.useState(false);
  const [userEmail, setEmail] = React.useState("");
  const [counterValue, setCounter] = React.useState(0);
  const [status, setStatus] = React.useState("PARSED");
  const [formStatus, setFormStatus] = React.useState("");
  const [POrderStatus, setPOrderStatus] = React.useState(activeSection);
  const [days, setDays] = React.useState([1]);
  const [fileId, setFileId] = React.useState(null);
  const [selectedModel, setSelectedModel] = React.useState(null);
  const [mainCheck, setMaincheck] = React.useState(false);
  const [toMonths, setToMonths] = React.useState(monthsSet);
  const [fromMonths, setFromMonths] = React.useState(monthsSet);
  const [years, setYears] = React.useState([2020]);
  const [id, setId] = React.useState();
  const [sortBy, setSortBy] = React.useState({
    name: "Sort By",
    value: null,
  });
  //check if exceeded the max no. of pages or not
  const [checkMaxNoPages, setCheckMaxNoPages] = React.useState(true);
  // A function to handle the "Main Check" checkbox

  const handleMainCheck = (event) => {
    // Update the "Main Check" state based on the checkbox value

    if (event.target.checked) {
      setMaincheck(true);
    } else {
      setMaincheck(false);
    }
  };
  // A function to handle sliding the navigation bar to the left

  const handleSlideLeft = (event) => {
    event.stopPropagation();
    // If the document type is "PORDER", get the navigation width element

    if (docType === "PORDER") {
      var navwidth = $("#invo_nav");
    }
    // Scroll the navigation width element to the left

    navwidth.scrollLeft(navwidth.scrollLeft() - 10);
  };
  // A function to handle sliding the navigation bar to the right

  const handleSlideRight = (event) => {
    event.stopPropagation();
    // If the document type is "PORDER", get the navigation width element

    if (docType === "PORDER") {
      var navwidth = $("#invo_nav");
    }
    // Scroll the navigation width element to the right

    navwidth.scrollLeft(navwidth.scrollLeft() + 10);
  };
  // A function to get the height of the list container based on the porder status

  const getListHeight = () => {
    if (POrderStatus == "PORDER_PARSED") {
      return "65vh";
    } else if (POrderStatus == "PORDER_DELETE") {
      return "calc(100% - 230px)";
    } else if (POrderStatus == "PORDER_EXPORTED") {
      return "calc(100% - 220px)";
    } else if (POrderStatus == "PORDER_REVIEW") {
      return "calc(100% - 165px)";
    } else if (POrderStatus == "PORDER_REJECTED") {
      return "calc(100% - 215px)";
    }
    return "75vh";
  };

  const [from, setFrom] = React.useState({
    day: null,
    month: null,
    year: null,
  });

  const [to, setTo] = React.useState({
    day: null,
    month: null,
    year: null,
  });

  const [selectedVendorFiles, setSelectedVendorFiles] = React.useState("");
  const [selectedVendor, setSelectedVendor] = React.useState("");
  const [tooltip, setTooltip] = React.useState(false);
  const [tooltipDate, setTooltipDate] = React.useState("");
  const [vendorCounter, setVendorCounter] = React.useState(0);
  const [gif, setGif] = React.useState(require("./analysis.gif"));
  const [logout, showLogout] = React.useState(false);
  const [search, setSearch] = React.useState("");
  const [fileSearch, setFileSearch] = React.useState("");
  const [vendorSearch, setVendorSearch] = React.useState("");
  const [searchCounter, setSearchCounter] = React.useState(0);
  const [page, setPage] = React.useState(1);
  const [formPage, setFormPage] = React.useState(1);
  const [processingFilePage, setProcessingFilePage] = React.useState(1);
  const [formPageExported, setFormPageExported] = React.useState(1);
  const [formPageDeleted, setFormPageDeleted] = React.useState(1);
  const [receiptPage, setReceiptPage] = React.useState(1);
  const [rows, setRows] = React.useState(8);
  const [pagedData, setPagedData] = React.useState([]);
  const [totalPages, setTotalPages] = React.useState([]);
  const [totalFormPages, setTotalFormPages] = React.useState([]);
  const [totalExportedPages, setTotalExportedPages] = React.useState([]);
  const [totalDeletedPages, setTotalDeletedPages] = React.useState([]);
  const [totalReceiptPages, setTotalReceiptPages] = React.useState([]);
  const [totalVendorHtmlPages, setTotalVendorHtmlPages] = React.useState([]);
  const [totalProcessingPages, setTotalProcessingPages] = React.useState([]);
  const [uploadModal, setUploadModal] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const [fileName, setFilename] = React.useState("");
  const [fileLength, setFileLength] = React.useState(0);
  const [vendorChecked, setChecked] = React.useState(false);
  const [invoices, setInvoices] = React.useState(false);
  const [uploaded, setUploaded] = React.useState(false);
  const [fileType, setFileType] = React.useState("FORM");
  const [docType, setDocType] = React.useState("PORDER");
  const [formList, setFormList] = React.useState([]);
  const [parsedForms, setParsedForms] = React.useState([]);
  const [fullParsedForms, setFullParsedForms] = React.useState([]);
  const [exportedForms, setExportedForms] = React.useState([]);
  const [fullExportedForms, setFullExportedForms] = React.useState([]);
  const [deletedForms, setDeletedForms] = React.useState([]);
  const [fullDeletedForms, setFullDeletedForms] = React.useState([]);
  const [showExcelBtn, setExcelBtn] = React.useState(false);
  const [showExcelBtnR, setExcelBtnR] = React.useState(false);
  const [formId, setFormId] = React.useState(0);
  const [selectedForms, setSelectedForms] = React.useState(0);
  const [selectedFormIds, setSelectedFormIds] = React.useState([]);
  const [POList, setPOList] = React.useState([]);
  const [receiptVendorDetails, setReceiptVendorDetails] = React.useState([]);
  const [fullReceiptVendorDetails, setFullReceiptVendorDetails] =
    React.useState([]);
  const [selectedReceipts, setSelectedReceipts] = React.useState(0);
  const [selectedReceiptIds, setSelectedReceiptIds] = React.useState([]);
  const [receiptId, setReceiptId] = React.useState(0);
  const [selectedFiles, setSelectedFiles] = React.useState([]);
  const [vendorTicked, setVendorTicked] = React.useState(false);
  const [activeVendor, setActiveVendor] = React.useState(null);
  const [focusedVendorId, setFocusedVendorId] = React.useState(null);
  const [expandedVendorId, setexpandedVendorId] = React.useState(null);
  const [goInputFocused, setGoInputFocused] = React.useState(false);
  const [pageInputValue, setPageInputValue] = React.useState(1);
  const [formPageValue, setFormPageValue] = React.useState(1);
  const [fullVendorDetails, setFullVendorDetails] = React.useState([]);
  const [fullVendorHtml, setFullVendorHtml] = React.useState([]);
  const [filesDefaultSelectedId, setFilesDefaultSelectedId] = React.useState(
    []
  );
  const [showTable, setShowTable] = React.useState(false);
  const [selectedVendorIds, setSelectedVendorIds] = React.useState([]);
  const [myName, setMyName] = React.useState("akshay");
  const [deletedTabValue, setDeletedTabValue] = React.useState(0);
  const [parsedInvoiceTab, setParsedInvoiceTab] = React.useState(0);
  const [parsedReceiptTab, setParsedReceiptTab] = React.useState(0);
  const [rejectedDeleteFiles, setRejectedDeleteFiles] = React.useState([]);
  const planName = "porders";

  const searchInput = React.useRef(null);
  // A function to handle changes to the accordion state

  const handleAccordianChange = (vendor) => (event, isExpanded) => {
    // Make a copy of the vendorHTML and POList arrays

    var vendorHTMLTemp = [...vendorHTML];
    var receiptListTemp = [...POList];
    // If the "Go" input field is not focused

    if (!goInputFocused) {
      // If the document type is "PORDER"

      if (docType === "PORDER") {
        // Loop through the porder list and update the accordion state for the selected vendor

        for (let i = 0; i < receiptListTemp.length; i++) {
          if (vendor.id == receiptListTemp[i].id) {
            receiptListTemp[i].isAccordianOpen =
              !receiptListTemp[i].isAccordianOpen;
          } else {
            receiptListTemp[i].isAccordianOpen = false;
          }
        }
        // Update the porder list state

        setPOList(receiptListTemp);
        // If the accordion is being closed, return

        if (!vendor.isAccordianOpen) {
          return;
        }
      } else {
        // If the document type is not "PORDER"
        // Loop through the vendorHTML array and update the accordion state for the selected vendor
        for (let i = 0; i < vendorHTMLTemp.length; i++) {
          if (vendor.id == vendorHTMLTemp[i].id) {
            vendorHTMLTemp[i].isAccordianOpen =
              !vendorHTMLTemp[i].isAccordianOpen;
          } else {
            vendorHTMLTemp[i].isAccordianOpen = false;
          }
        }
        // Update the vendorHTML state

        setVendorHTML(vendorHTMLTemp);
        // If the accordion is being closed, return

        if (!vendor.isAccordianOpen) {
          return;
        }
        // Get the vendor details

        getVendorDetails(vendor);
      }
    }
  };

  const classes = accordionStyles();
  // A function to format the porder table with paginated data

  const formatPOTable = (vendorDetails) => {
    // Paginate the vendor details and set the vendor details state and total pages state

    var paginatedData = paginateTable(vendorDetails, page, rows);
    setVendorDetails(paginatedData.paginated_data);
    setTotalPages(paginatedData.totalPages);
  };
  // A function to get the number of rejected porder that have been selected

  const getRejectedPOCounter = () => {
    // Create a temporary array of selected items and filter it to only include true values

    var temp = fullVendorHtml.map((item) => item.isSelected);
    temp = temp.filter((item) => item == true);
    // Return an object with the visibility of the counter and the number of selected items

    return {
      isVisible: temp.length > 0 ? true : false,
      fileCounter: temp.length,
    };
  };
  // A function to format the vendor HTML table with paginated data

  const formatVendorHtml = (vendorDetails) => {
    // Paginate the vendor details and set the vendor HTML state and total pages state

    var paginatedData = paginateTable(vendorDetails, page, rows);
    setVendorHTML(paginatedData.paginated_data);
    setTotalVendorHtmlPages(paginatedData.totalPages);
  };
  // A function to format the processing files table with paginated data

  const formatProcessingFiles = (files) => {
    // Paginate the files and set the processing files state and total pages state

    var paginatedData = paginateTable(files, processingFilePage, rows);
    setProcessingFiles(paginatedData.paginated_data);
    setTotalProcessingPages(paginatedData.totalPages);
  };
  // A function to format the parsed forms table with paginated data

  const formatFormsTable = (forms) => {
    // Paginate the forms and set the parsed forms state and total pages state

    var paginatedData = paginateTable(forms, formPage, rows);
    setParsedForms(paginatedData.paginated_data);
    setTotalFormPages(paginatedData.totalPages);
  };
  // A function to format the exported forms table with paginated data

  const formatExportedForms = (exportedForms) => {
    // Paginate the exported forms and set the exported forms state and total pages state

    var paginatedData = paginateTable(exportedForms, formPageExported, rows);
    setExportedForms(paginatedData.paginated_data);
    setTotalExportedPages(paginatedData.totalPages);
  };
  // A function to format the deleted forms table with paginated data

  const formatDeletedForms = (deletedForms) => {
    // Paginate the deleted forms and set the deleted forms state and total pages state

    var paginatedData = paginateTable(deletedForms, formPageDeleted, rows);
    setDeletedForms(paginatedData.paginated_data);
    setTotalDeletedPages(paginatedData.totalPages);
  };
  // A function to format the porder data table with paginated data

  const formatReceiptData = (receiptData) => {
    // Paginate the porder data and set the porder vendor details state and total pages state

    var paginatedData = paginateTable(receiptData, receiptPage, rows);
    if (paginatedData.paginated_data.length > 0) {
      setReceiptVendorDetails(paginatedData.paginated_data);
    }
    setTotalReceiptPages(paginatedData.totalPages);
  };
  // A function to handle selecting a sorting option

  const handleSortOptionSelect = (option) => {
    var activeVendorIndex = POList.findIndex(
      (vendor) => vendor.isAccordianOpen == true
    );
    handleCloseSort();
    setActiveVendor(activeVendorIndex);
    setSortBy(option);
    if (docType === "PORDER") {
      if (POrderStatus === "PORDER_DELETE_REJECTED") {
        getRejectedDeleteFiles(null, null, option.value, "");
      } else {
        if (POrderStatus === "PORDER_REJECTED") {
          getVendor(null, null, option.value, null);
        }
      }
    }
  };
  // A function to handle changing the page number in the table

  const handlePageChange = async (event, value) => {
    event.stopPropagation();
    // If the porder status is "PORDER_REJECTED", update the page state

    if (POrderStatus == "PORDER_REJECTED") {
      setPage(value);
    } else {
      // If the porder status is "PORDER_PROCESSING", update the processing file page state

      if (POrderStatus == "PORDER_PROCESSING") {
        setProcessingFilePage(value);
      } else {
        // Otherwise, update the porder page state
        setReceiptPage(value);
      }
    }
  };
  // A function to show the image of a selected file

  const showFileImage = (data) => {
    setFilename(data.original_file_name);
    setFileId(data.id);
  };
  // A function to check if the user is authenticated and set the email state

  const myFunction = async () => {
    if (!authToken) {
      props.history.replace({ pathname: "/Login" });
    }

    var loggedInUserEmail = fetchLoggedInUserEmail();
    setEmail(loggedInUserEmail);
  };
  // A function to set the year set state

  const setYearset = async () => {
    var date = new Date();
    // Get the register year from local storage or session storage

    var registerYear = await localStorage.getItem("register_year");
    if (!registerYear) {
      registerYear = await sessionStorage.getItem("register_year");
    }
    // Create an array of years from the register year to the current year and set the years state

    var yearSet = [];
    for (let i = registerYear - 1; i < date.getFullYear(); i++) {
      yearSet.push(i + 1);
    }
    setYears(yearSet);
  };
  // A useEffect hook to call myFunction when the status state changes

  useEffect(() => {
    myFunction();
  }, [status]);
  // A useEffect hook to call getProcessedFiles every 6 seconds if there are processing files

  useEffect(() => {
    if (processingFiles.length > 0) {
      const interval = setInterval(() => {
        getProcessedFiles();
      }, 6000);
      return () => clearInterval(interval);
    }
  }, [processingFiles]);
  // A useEffect hook to remove the date error message after 3 seconds

  useEffect(() => {
    if (dateErrMessage) {
      setTimeout(() => {
        setDateErrMessage("");
      }, 3000);
    }
  }, [dateErrMessage]);
  // A useEffect hook to call the appropriate API function based on the porder status

  useEffect(() => {
    if (
      POrderStatus === "PORDER_PARSED" ||
      POrderStatus === "PORDER_DELETE"
    ) {
      getPOrderVendors(null, null, null);
    } else if (POrderStatus === "PORDER_EXPORTED") {
      getPOrderDetails("EXPORTED", null, null, null, "PORDER");
    } else if (POrderStatus === "PORDER_REVIEW") {
      getPOrderDetails("REVIEW", null, null, null, "PORDER");
    } else if (POrderStatus === "PORDER_DELETE_REJECTED") {
      getRejectedDeleteFiles(null, null, null, "");
    } else if (POrderStatus === "PORDER_REJECTED") {
      getVendor(null, null, null, null);
    } else if (POrderStatus === "PORDER_PROCESSING") {
      getProcessingFiles();
    }
  }, [POrderStatus]);
  // A useEffect hook to call formatProcessingFiles when the processing file page state changes

  useEffect(() => {
    formatProcessingFiles(fullProcessingFiles);
  }, [processingFilePage]);
  // A useEffect hook to handle the search input

  useEffect(() => {
    if (searchFocused && searchCounter > 0) {
      searchInput.current.blur();
      if (searchAttempt) {
        if (POrderStatus === "PORDER_DELETE_REJECTED") {
          getRejectedDeleteFiles(null, null, null, "");
        } else {
          if (POrderStatus === "PORDER_REJECTED") {
            getVendor(null, null, null, null);
          } else {
            getPOrderVendors(null, null, null);
          }
        }
        setSearchAttempt(false);
      }
    }
  }, [searchFocused, search, fileSearch, vendorSearch]);

  useEffect(() => {
    // Perform this effect when the 'sortBy' or 'activeVendor' variables change
    // and call the appropriate API function based on the status and sort option
    if (sortBy.value !== null && activeVendor !== null) {
      if (activeVendor > -1) {
        if (POrderStatus === "PORDER_REJECTED") {
          getVendor(null, null, sortBy.value, null);
        } else {
          getReceiptVendorDetails(
            POList[activeVendor],
            null,
            null,
            sortBy.value,
            POrderStatus.split("_")[1],
            search
          );
        }
      } else {
        getVendor(null, null, sortBy.value, search);
      }
    }
  }, [sortBy, activeVendor]);

  useEffect(() => {
    // Update vendor HTML table when the page changes

    if (POrderStatus == "PORDER_REJECTED") {
      formatVendorHtml(fullVendorHtml);
    }
  }, [page]);

  useEffect(() => {
    // Update parsed forms table when the page changes

    formatFormsTable(fullParsedForms);
  }, [formPage]);

  useEffect(() => {
    // Update exported forms table when the page changes

    formatExportedForms(fullExportedForms);
  }, [formPageExported]);

  useEffect(() => {
    // Update deleted forms table when the page changes

    formatDeletedForms(fullDeletedForms);
  }, [formPageDeleted]);

  useEffect(() => {
    // Update porder data table when the page changes

    formatReceiptData(fullReceiptVendorDetails);
  }, [receiptPage]);

  useEffect(() => {
    // Get details of the currently open vendor accordion

    var openListIndex = vendorHTML.findIndex(
      (vendor) => vendor.isAccordianOpen === true
    );
    if (openListIndex > -1) {
      getVendorDetails(vendorHTML[openListIndex]);
    }
  }, [dateRange]);

  useEffect(() => {
    // Set up days array, set years, and set user ID

    let days = [];
    const date = new Date();

    for (let i = 1; i < 32; i++) {
      if (i < 10) days.push(`0${i}`);
      else days.push(i);
    }

    setDays(days);
    setYearset();
    var user = localStorage.getItem("user_id");
    if (!user) {
      user = sessionStorage.getItem("user_id");
    }

    setUserId(user);
  }, []);
  // Helper function to retrieve 'section_active' value from local storage

  const asyncLocalStorage = () => {
    return Promise.resolve().then(function () {
      var sectActive = localStorage.getItem("section_active");
      if (!sectActive) {
        sectActive = sessionStorage.getItem("section_active");
      }
      return sectActive;
    });
  };
  // Effect to handle invoiceCategory changes and update section and value accordingly

  useEffect(() => {
    if (invoiceCategory) {
      if (["REVIEW", "PARSED", "rejected"].includes(invoiceCategory)) {
        // Set section and value based on invoiceCategory

        handleSectionClick(invoiceCategory);
        if (invoiceCategory == "REVIEW") {
          setValue(0);
        } else if (invoiceCategory == "PARSED") {
          setValue(1);
        } else if (invoiceCategory == "rejected") {
          setValue(2);
        }
      } else {
        // Set section and value for 'DELETE' category

        handleSectionClick("DELETE");
        setValue(3);
      }
    }
  }, [invoiceCategory]);
  // Function to close profile menu

  const closeProfileMenu = () => {
    if (profileMenuShow || $(".dropdown-menu").hasClass("show")) {
      setProfileMenuShow(false);
      $(".dropdown-menu").removeClass("show");
    }
  };
  // Function to handle adding invoice files

  const addInvoice = (event) => {
    let fileData = null;
    if (event.target.files.length) {
      fileData = event.target.files;
      const formData = new FormData();
      const checkNoPagesData = new FormData();

      for (let i = 0; i < fileData.length; i++) {
        formData.append("file", fileData[i]);
        checkNoPagesData.append("file", fileData[i]);
      }
      checkNoPagesData.append("user", sessionStorage.getItem('user_id'));
      checkNoPagesData.append("company_name", ogi);
      checkNoPagesData.append("plan_name", planName);
      axios({
        method: "post",
        url: `${EXTRICATOR_BASE_URL}/check-max-number-of-pages/`,
        data: checkNoPagesData,
        headers: {
          Authorization: `Bearer ${authToken}`,
        },
      })
        .then(function (response) {
          if (response.data.status) {
            setToastColor("success");
            setSuccessMsg(response.data.message);
            setSnackOpen(true);
            formData.append("file_type", docType);
            formData.append("ogi", ogi);
            formData.append("plan_name", planName);
            setInvoices(formData);
            setCheckMaxNoPages(false)
            setFileLength(fileData.length);
            setFileMessage("");
          }
          else{
            setFileMessage(response.data.message);
            setSnackOpen(true);
            setInvoices(false);
            setCheckMaxNoPages(true)       
            setFileLength(0);
            setUploaded(false);
            setFileType("");
          }
        });
    }
  };
  // Function to show file preview on hover

  const handleShowPreview = (fileDetail) => {
    // Set timeout to show file preview after 2 seconds

    previewTime = setTimeout(() => {
      showFileImage(fileDetail);
    }, [2000]);
  };
  // This function hides the file preview and clears the timeout

  const handleHidePreview = () => {
    setFilename("");
    setFileId("");
    clearTimeout(previewTime);
  };
  // This function gets the processed files from the server

  const getProcessedFiles = () => {
    var processingFilesTemp = [...processingFiles];
    let statusFiles = processingFilesTemp.map((item) => item.id);
    axios({
      method: "GET",
      url: `${EXTRICATOR_BASE_URL}/processed_files/?ogi=${ogi}&file_id=${statusFiles.join(
        ","
      )}`,
      headers: {
        "Content-Type": "multipart/form-data",
        Authorization: `Bearer ${authToken}`,
      },
    })
      .then(function (response) {
        formatProcessingFiles(response.data.file_data);
        setFullProcessingFiles(response.data.file_data);
      })
      .catch(function (error) {
        if (error.response) {
          if (error.response.status === 401) {
            handleLogout(props.history);
          }
          if (error.response.data.non_field_errors) {
            if (
              error.response.data.non_field_errors[0].includes(
                "Kindly pay the bill"
              )
            ) {
              setToastColor("error");
              setSuccessMsg(error.response.data.non_field_errors[0]);
              setSnackOpen(true);
            } else {
              checkOrganizationError(
                error.response.data.non_field_errors[0],
                props.history
              );
            }
          }
        }
      });
  };

  
  const getProcessingFiles = () => {
    setLoading(true);
    axios({
      method: "GET",
      url: `${EXTRICATOR_BASE_URL}/processing_files/?file_type=porder&ogi=${ogi}`,
      headers: {
        "Content-Type": "multipart/form-data",
        Authorization: `Bearer ${authToken}`,
      },
    })
      .then(function (response) {
        formatProcessingFiles(response.data.file_data);
        setFullProcessingFiles(response.data.file_data);
        setLoading(false);
      })
      .catch(function (error) {
        if (error.response) {
          if (error.response.status === 401) {
            handleLogout(props.history);
          }
          if (error.response.data.non_field_errors) {
            if (
              error.response.data.non_field_errors[0].includes(
                "Kindly pay the bill"
              )
            ) {
              setToastColor("error");
              setSuccessMsg(error.response.data.non_field_errors[0]);
              setSnackOpen(true);
            } else {
              checkOrganizationError(
                error.response.data.non_field_errors[0],
                props.history
              );
            }
          }
        }
        setLoading(false);
      });
  };
  /**

Uploads invoices to the server.
If invoices exist, sets fileUploading to true and makes a POST request to the server to upload the file.
On success, sets toastColor to success, sets successMsg to the response message, sets snackOpen to true,
sets expanded to false, updates the table, sets uploaded to true, sets fileUploading to false,
sets uploadModal to false, sets invoices to false, sets POrderStatus to "PORDER_PROCESSING",
sets activeTab to "PORDER_PROCESSING", sets fileMessage to an empty string and sets fileLength to 0.
On error, if the response contains a non_field_error, sets toastColor to error, sets successMsg to the error message,
sets snackOpen to true, sets fileUploading to false, sets uploadModal to false, sets invoices to false,
sets fileMessage to an empty string and sets fileLength to 0.
*/
  const checkUserSubscription = () => {
    const reqData = {
      user: user_id,
      company_name: ogi,
      plan_name: planName
    };
    axios({
      method: "post",
      url: `${EXTRICATOR_BASE_URL}/check-subscription/`,
      data: reqData,
      headers: {
        Authorization: `Bearer ${authToken}`,
      },
    })
      .then(function (response) {
        if (response.data.status) {
          setToastColor("success");
          setSuccessMsg(response.data.message);
          setSnackOpen(true);

          setUploadModal(true)
        }
        else{
          setToastColor("error");
          setSuccessMsg(response.data.message);

          setSnackOpen(true);
          setUploadModal(false)
        }
      });
  }

  const uploadInvoice = () => {
    if (invoices) {
      setCheckMaxNoPages(false)
      setFileUploading(true);
      axios({
        method: "post",
        url: `${EXTRICATOR_BASE_URL}/file_upload/`,
        data: invoices,
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: `Bearer ${authToken}`,
        },
      })
        .then(function (response) {
          setToastColor("success");
          setSuccessMsg(response.data.message);
          setSnackOpen(true);
          setExpanded(false);
          setUpdateTable(!updateTable);
          setUploaded(true);
          setFileUploading(false);
          setUploadModal(false);
          setInvoices(false);
          setPOrderStatus("PORDER_PROCESSING");
          setActiveTab("PORDER_PROCESSING");
          setFileMessage("");
          setFileLength(0);
        })
        .catch(function (error) {
          if (error.response) {
            if (error.response.data && error.response.data.non_field_errors) {
              setToastColor("error");
              setSuccessMsg(error.response.data.non_field_errors[0]);
              setSnackOpen(true);
              setFileUploading(false);
              setUploadModal(false);
              setInvoices(false);
              setFileMessage("");
              setFileLength(0);
            }
          }
        });
    } else {
      setFileMessage("Please select a file to proceed.");
    }
  };

  const confirmDelete = (file_id) => {
    // Set delete file progress to true

    setDeleteFileProgress(true);
    // Define request body

    let reqBody = {};
    if (typeof file_id == "number") {
      reqBody = { file_id: file_id, ogi: ogi };
    } else {
      reqBody = { file_id: file_id.join(","), ogi: ogi };
    }
    // Send DELETE request to server

    axios({
      method: "post",
      url: `${EXTRICATOR_BASE_URL}/delete_file/`,
      data: reqBody,
      headers: { Authorization: `Bearer ${authToken}` },
    })
      .then((res) => {
        // Update state and display success message

        if (docType === "PORDER") {
          if (POrderStatus == "PORDER_REJECTED") {
            var vendorHTMLTemp = [...fullVendorHtml];
            vendorHTMLTemp = vendorHTMLTemp.filter(
              (item) => item.id !== file_idporder
            );
            getVendor(null, null, null, null);
          } else {
            var receiptVendorDetailsTemp = [...fullReceiptVendorDetails];
            var filteredSet = receiptVendorDetailsTemp.filter(
              (item) => item.id !== file_id
            );
            formatReceiptData(filteredSet);
            setFullReceiptVendorDetails(filteredSet);
          }
        }
        setDeleteFileProgress(false);
        setDeletableFileId(null);
        setToastColor("success");
        setSuccessMsg(res.data.message);
        setSnackOpen(true);
      })
      .catch((error) => {
        // Display error message and update state

        if (error.response) {
          if (error.response.data && error.response.data.non_field_errors) {
            setToastColor("error");
            setSuccessMsg(error.response.data.non_field_errors[0]);
            setSnackOpen(true);
          }
        }
        setDeletableFileId(null);
        setDeleteFileProgress(false);
      });
  };
  // Handle mapping multiple rejected files to selected model

  const handleMapMultipleRejectedFiles = () => {
    var selectedObjSet = fullVendorHtml.filter(
      (item) => item.isSelected == true
    );
    var selectedFiles = selectedObjSet.map((item) => item.id);
    setLoading(true);
    axios({
      method: "post",
      url: `${EXTRICATOR_BASE_URL}/mapping_multiple_files/`,
      data: {
        file_id: selectedFiles.join(","),
        model_id: String(selectedModel.id),
        ogi: ogi,
      },
      headers: { Authorization: `Bearer ${authToken}` },
    })
      .then(function (response) {
        setToastColor("success");
        setSuccessMsg(response.data.message);
        setSnackOpen(true);
        setLoading(false);
        getVendor(null, null);
      })
      .catch(function (error) {
        if (error.response) {
          if (error.response.data && error.response.data.non_field_errors) {
            setToastColor("error");
            setSuccessMsg(error.response.data.non_field_errors[0]);
            setSnackOpen(true);
            setLoading(false);
          }
        }
      });
  };
  // Handle mapping multiple explicitly rejected files

  const handleMapMultipleExplicitRejectedFiles = () => {
    var selectedFiles = exportBuffer.map((item) => item.id);

    setLoading(true);
    axios({
      method: "get",
      url: `${EXTRICATOR_BASE_URL}/reject_document/?ogi=${ogi}&file_id=${selectedFiles.join(
        ","
      )}`,
      headers: { Authorization: `Bearer ${authToken}` },
    })
      .then(function (response) {
        setToastColor("success");
        setSuccessMsg(response.data.message);
        setSnackOpen(true);
        setLoading(false);
        getRejectedInvoices(null, null);
      })
      .catch(function (error) {
        if (error.response) {
          if (error.response.data && error.response.data.non_field_errors) {
            setToastColor("error");
            setSuccessMsg(error.response.data.non_field_errors[0]);
            setSnackOpen(true);
            setLoading(false);
          }
        }
      });
  };
  // Handle click event for calendar icon

  const onCalandarClick = (event) => {
    // Set anchor element for calendar popover

    setAnchorEl(event.currentTarget);
    // Toggle calendar state

    openCalander(!calander);
    // Set tooltip state to match calendar state

    setTooltip(calander);
  };
  // Handle close event for calendar popover

  const handleCalandarClose = (flag) => {
    // Close popover

    setAnchorEl(null);
    // Reset date range and clear any error messages

    if (flag == "reset" || dateErrMessage) {
      setFrom({
        ...from,
        day: null,
        month: null,
        year: null,
      });

      setTo({
        ...to,
        day: null,
        month: null,
        year: null,
      });
      setTooltipDate("");
      setDateRange({ from: null, to: null });
      setDateErrMessage("");
      // Retrieve data based on document type and porder status

      if (docType === "PORDER") {
        if (POrderStatus == "PORDER_DELETE_REJECTED") {
          getRejectedDeleteFiles(null, null, null, "");
        } else {
          if (POrderStatus === "PORDER_REJECTED") {
            getVendor(null, null, null, null);
          } else {
            getPOrderVendors(null, null, null);
          }
        }
      }
    }
    if (tooltipDate) {
      setTooltip(true);
    }
  };

  /**

A function that retrieves vendor data from the server based on the specified parameters
@param {string} from - the start date for filtering data (optional)
@param {string} to - the end date for filtering data (optional)
@param {string} sortBy - the field to sort the data by (optional)
@param {string} search - the search term to filter data by (optional)
*/
  const getVendor = async (
    from = null,
    to = null,
    sortBy = null,
    search = null
  ) => {
    setLoading(true);
    // If POrderStatus is "PORDER_REJECTED", retrieve rejected files

    if (POrderStatus === "PORDER_REJECTED") {
      axios({
        method: "POST",
        url: `${EXTRICATOR_BASE_URL}/rejected/?file_type=${docType}&from=${from}&to=${to}&type=${sortBy}&search=${search}&ogi=${ogi}`,
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: `Bearer ${authToken}`,
        },
      })
        .then(function (response) {
          var rejectedFiles = response.data;
          // Add isSelected field to each rejected file and format vendor HTML

          rejectedFiles = rejectedFiles.map((item) => ({
            ...item,
            isSelected: false,
          }));
          formatVendorHtml(rejectedFiles);
          setFullVendorHtml(rejectedFiles);
          setPage(1);
          setLoading(false);
        })
        .catch(function (error) {
          setLoading(false);
          if (error.response) {
            if (error.response.data.non_field_errors) {
              if (
                error.response.data.non_field_errors[0].includes(
                  "Kindly pay the bill"
                )
              ) {
                // Show error toast if the user needs to pay the bill

                setToastColor("error");
                setSuccessMsg(error.response.data.non_field_errors[0]);
                setSnackOpen(true);
              } else {
                // Check for organization error and handle it accordingly

                checkOrganizationError(
                  error.response.data.non_field_errors[0],
                  props.history
                );
              }
            }
          }
        });
    } else {
      // If POrderStatus is not "PORDER_REJECTED", retrieve vendor details

      setReceiptPage(1);
      setPage(1);
      axios({
        method: "GET",
        url: `${EXTRICATOR_BASE_URL}/vendor_details/?file_type=${docType}&status=${POrderStatus.split("_")[1]
          }&search=${search ? (search.length ? search : null) : null
          }&from=${from}&to=${to}&search_on=${searchSubject}&type=${sortBy}&ogi=${ogi}`,
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: `Bearer ${authToken}`,
        },
      })
        .then(function (response) {
          var vendors = response.data;
          // Add isSelected and isAccordianOpen fields to each vendor and set state variables

          vendors = vendors.map((item) => ({
            ...item,
            isSelected: false,
            isAccordianOpen: false,
          }));
          setVendorHTML(vendors);
          setPOList(vendors);
          setVendorsLength(vendors.length);
          setLoading(false);
        })
        .catch(function (error) {
          setLoading(false);
          if (error.response) {
            if (error.response.status === 401) {
              handleLogout(props.history);
            }
            if (error.response.data.non_field_errors) {
              if (
                error.response.data.non_field_errors[0].includes(
                  "Kindly pay the bill"
                )
              ) {
                setToastColor("error");
                setSuccessMsg(error.response.data.non_field_errors[0]);
                setSnackOpen(true);
              } else {
                checkOrganizationError(
                  error.response.data.non_field_errors[0],
                  props.history
                );
              }
            }
          }
        });
    }
  };
  /**

A function that retrieves porder vendors from the server based on the specified parameters
@param {string} from - the start date for filtering data
@param {string} to - the end date for filtering data
@param {string} sortBy - the field to sort the data by (optional)
*/
  const getPOrderVendors = async (from, to, sortBy = null) => {
    setLoading(true);
    setVendorHTML([]);
    setReceiptPage(1);
    axios({
      method: "GET",
      url: `${EXTRICATOR_BASE_URL}/vendor_details/?file_type=PORDER&status=${POrderStatus.split("_")[1]
        }&search=${search.length ? search : null
        }&from=${from}&to=${to}&search_on=${searchSubject}&type=${sortBy}&ogi=${ogi}`,
      headers: {
        "Content-Type": "multipart/form-data",
        Authorization: `Bearer ${authToken}`,
      },
    })
      .then(function (response) {
        var vendors = response.data;
        // Add isSelected and isAccordianOpen fields to each vendor and set state variables

        vendors = vendors.map((item) => ({
          ...item,
          isSelected: false,
          isAccordianOpen: false,
        }));
        setVendorHTML(vendors);
        setPOList(vendors);
        setVendorsLength(vendors.length);
        setLoading(false);
      })
      .catch(function (error) {
        setLoading(false);
        if (error.response) {
          if (error.response.status === 401) {
            // Handle unauthorized user by logging out

            handleLogout(props.history);
          }
          if (error.response.data.non_field_errors) {
            if (
              error.response.data.non_field_errors[0].includes(
                "Kindly pay the bill"
              )
            ) {
              // Show error toast if the user needs to pay the bill

              setToastColor("error");
              setSuccessMsg(error.response.data.non_field_errors[0]);
              setSnackOpen(true);
            } else {
              // Check for organization error and handle it accordingly

              checkOrganizationError(
                error.response.data.non_field_errors[0],
                props.history
              );
            }
          }
        }
      });
  };

  /**

A function that handles the checkbox for selecting a file
@param {object} file - the file object that was clicked
*/
  const handleCheckbox = (file) => {
    var vendorHTMLTemp = [...fullVendorHtml];
    var rejectedFileIndex = vendorHTMLTemp.findIndex(
      (item) => item.id === file.id
    );
    vendorHTMLTemp[rejectedFileIndex].isSelected = !file.isSelected;
    setFullVendorHtml(vendorHTMLTemp);
  };
  /**

A function that handles downloading a single XLS file
@param {string} fileId - the ID of the file to download
*/
  const handleSingleXlsDownload = (fileId) => {
    var xlsUrl = `${EXTRICATOR_BASE_URL}/export_to_excel/?id=${fileId}&ogi=${ogi}`;
    // Open the XLS file in a new window and refresh the vendor list

    window.open(xlsUrl);
    getPOrderVendors(null, null, null);
  };
  /**

A function that retrieves vendor details from the server based on the specified vendor object
@param {object} vendor - the vendor object for which to retrieve details
*/
  const getVendorDetails = (vendor) => {
    setVendorDetails([]);
    var exportBufferTemp = [...exportBuffer];
    var from = dateRange.from,
      to = dateRange.to,
      type = sortBy.value;
    setLoading(true);
    axios({
      method: "GET",
      url: `${EXTRICATOR_BASE_URL}/vendor_file_details/?id=${vendor.id
        }&from=${from}&to=${to}&type=${type}&status=${POrderStatus.split("_")[1]
        }&search=${fileSearch.length ? fileSearch : null}&ogi=${ogi}`,
      headers: {
        "Content-Type": "multipart/form-data",
        Authorization: `Bearer ${authToken}`,
      },
    })
      .then(function (response) {
        var vendorDetails = response.data;
        if (vendor.isSelected && vendor.isAccordianOpen) {
          vendorDetails = vendorDetails.map((item) => ({
            ...item,
            isSelected: true,
          }));
        } else {
          vendorDetails = vendorDetails.map((item) => ({
            ...item,
            isSelected: false,
          }));
        }
        formatPOTable(vendorDetails);
        setFullVendorDetails(vendorDetails);
        setPage(1);
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
        if (error.response) {
          if (error.response.data.non_field_errors) {
            if (
              error.response.data.non_field_errors[0].includes(
                "Kindly pay the bill"
              )
            ) {
              // Show error toast if the user needs to pay the bill

              setToastColor("error");
              setSuccessMsg(error.response.data.non_field_errors[0]);
              setSnackOpen(true);
            } else {
              // Check for organization error and handle it accordingly

              checkOrganizationError(
                error.response.data.non_field_errors[0],
                props.history
              );
            }
          }
        }
      });
  };

  /**

A function that retrieves porder details from the server based on the specified parameters
@param {string} status - the status of the porder to retrieve
@param {string} searchKeyword - the search keyword to filter results (optional)
@param {string} from - the start date for filtering data (optional)
@param {string} to - the end date for filtering data (optional)
@param {string} fileTypes - the file types to retrieve (default is "PORDER")
@param {string} sortBy - the field to sort the data by (optional)
*/
  const getPOrderDetails = (
    status = "REVIEW",
    searchKeyword = null,
    from = null,
    to = null,
    fileTypes = "PORDER",
    sortBy = null
  ) => {
    setLoading(true);
    setReceiptPage(1);
    axios({
      method: "GET",
      url: `${EXTRICATOR_BASE_URL}/vendor_details/?status=${POrderStatus.split("_")[1]
        }&search=${searchKeyword}&from=${from}&to=${to}&file_type=${fileTypes}&search_on=${searchSubject}&type=${sortBy}&ogi=${ogi}`,
      headers: {
        "Content-Type": "multipart/form-data",
        Authorization: `Bearer ${authToken}`,
      },
    })
      .then(function (response) {
        let receiptFiles = response.data.map((item) => ({
          ...item,
          isSelected: false,
          isAccordianOpen: false,
        }));
        setPOList(receiptFiles);
        setLoading(false);
      })
      .catch(function (error) {
        setLoading(false);
        if (error.response) {
          if (error.response.data.non_field_errors) {
            if (
              error.response.data.non_field_errors[0].includes(
                "Kindly pay the bill"
              )
            ) {
              // Show error toast if the user needs to pay the bill

              setToastColor("error");
              setSuccessMsg(error.response.data.non_field_errors[0]);
              setSnackOpen(true);
            } else {
              // Check for organization error and handle it accordingly

              checkOrganizationError(
                error.response.data.non_field_errors[0],
                props.history
              );
            }
          }
        }
      });
  };
  /**

A function that retrieves details for a specific porder vendor from the server based on the specified parameters
@param {Object} vendor - the vendor object to retrieve data for
@param {string} fromDate - the start date for filtering data (optional)
@param {string} toDate - the end date for filtering data (optional)
@param {string} type - the type of data to retrieve (optional)
@param {string} status - the status of the porder to retrieve (default is "REVIEW")
@param {string} search - the search keyword to filter results (optional)
*/
  const getReceiptVendorDetails = (
    vendor,
    fromDate = null,
    toDate = null,
    type = null,
    status = "REVIEW",
    search = null
  ) => {
    var exportBufferTemp = [...exportBuffer];
    var sameVendorCount = 0;
    setLoading(true);
    axios({
      method: "GET",
      url: `${EXTRICATOR_BASE_URL}/vendor_file_details/?id=${vendor.id}&from=${fromDate}&to=${toDate}&type=${type}&status=${status}&search=${search}&ogi=${ogi}`,
      headers: {
        "Content-Type": "multipart/form-data",
        Authorization: `Bearer ${authToken}`,
      },
    })
      .then(function (response) {
        var receiptVendors = response.data;
        if (vendor.isSelected && vendor.isAccordianOpen) {
          receiptVendors = receiptVendors.map((item) => ({
            ...item,
            isSelected: true,
          }));
        } else {
          receiptVendors = receiptVendors.map((item) => ({
            ...item,
            isSelected: false,
          }));
        }
        formatReceiptData(receiptVendors);
        setFullReceiptVendorDetails(receiptVendors);
        setPage(1);
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
        if (error.response) {
          if (error.response.data.non_field_errors) {
            if (
              error.response.data.non_field_errors[0].includes(
                "Kindly pay the bill"
              )
            ) {
              // Show error toast if the user needs to pay the bill

              setToastColor("error");
              setSuccessMsg(error.response.data.non_field_errors[0]);
              setSnackOpen(true);
            } else {
              // Check for organization error and handle it accordingly

              checkOrganizationError(
                error.response.data.non_field_errors[0],
                props.history
              );
            }
          }
        }
      });
  };

  const getRejectedDeleteFiles = (
    from = null,
    to = null,
    sortBy = null,
    search = ""
  ) => {
    // Make a GET request to the server to fetch a list of rejected delete files

    axios({
      method: "GET",
      url: `${EXTRICATOR_BASE_URL}/rejected_delete_files/?&from=${from}&to=${to}&file_type=${docType}&type=${sortBy}&search=${search.length ? search : null
        }&ogi=${ogi}`,
      headers: {
        Authorization: `Bearer ${authToken}`,
      },
    })
      .then(function (response) {
        //handle success
        setRejectedDeleteFiles(response.data);
      })
      .catch(function (error) {
        //handle error
        setLoading(false);
        if (error.response) {
          if (error.response.data.non_field_errors) {
            if (
              error.response.data.non_field_errors[0].includes(
                "Kindly pay the bill"
              )
            ) {
              // Display error message in snack bar

              setToastColor("error");
              setSuccessMsg(error.response.data.non_field_errors[0]);
              setSnackOpen(true);
            } else {
              // Check for organization error and redirect if necessary

              checkOrganizationError(
                error.response.data.non_field_errors[0],
                props.history
              );
            }
          }
        }
      });
  };
  // Handle tab change in delete section

  const handleDeleteTabChange = (event, newValue) => {
    // Reset export buffer

    setExportBuffer([]);
    // Set porder status based on selected tab value

    if (newValue === 0) {
      setPOrderStatus("PORDER_DELETE");
    } else {
      setPOrderStatus("PORDER_DELETE_REJECTED");
    }
    // Set selected tab value

    setDeletedTabValue(newValue);
  };
  // Handle porder status change in parsed/exported section

  const handlePOrderStatusChange = (event, newValue) => {
    // Reset export buffer

    setExportBuffer([]);
    // Set porder status based on selected tab value

    if (newValue === 0) {
      setPOrderStatus("PORDER_PARSED");
    } else {
      setPOrderStatus("PORDER_EXPORTED");
    }
    // Set selected tab value

    setParsedReceiptTab(newValue);
  };
  // Get pagination class based on porder status

  const getPaginationClass = () => {
    switch (POrderStatus) {
      case "PORDER_PARSED":
        return "d-flex justify-content-end align-items-center col-5 pr-4";
        break;
      case "PORDER_EXPORTED":
        return "d-flex justify-content-end align-items-center col-5 pr-2";
        break;
      case "PORDER_DELETE":
        return "d-flex justify-content-end align-items-center col-5 pr-4";
        break;
      case "PORDER_REVIEW":
        return "d-flex justify-content-end align-items-center col-5 pr-4";
        break;
      default:
        return "col-12 d-flex right-vendor-div justify-content-end p-0";
    }
  };
  // Export selected porder files to Excel

  const exportReceipToExcel = (fileId) => {
    var time = null;
    clearTimeout(time);
    // Get array of selected porder files

    var selectedReceiptFilesSet = exportBuffer.map((item) => {
      if (item.isSelected) {
        return item.id;
      }
      return null;
    });
    // Filter out null values from selected porder files array

    selectedReceiptFilesSet = selectedReceiptFilesSet.filter(
      (item) => item !== null
    );
    // Generate Excel export URL

    var xlsUrl = `${EXTRICATOR_BASE_URL}/export_to_excel/?id=${selectedReceiptFilesSet.join(
      ","
    )}&ogi=${ogi}`;
    // Open Excel export in new window

    window.open(xlsUrl);
    // Refresh porder vendors list and reset export buffer after 3 seconds

    getPOrderVendors(null, null, null);
    time = setTimeout(() => {
      setExportBuffer([]);
    }, 3000);
  };

  /**

Async function to handle the batch deletion of selected files in the rejected files tab
*/
  const handleRejectedBatchDelete = () => {
    var selectedObjSet = fullVendorHtml.filter(
      (item) => item.isSelected == true
    );
    var idSet = selectedObjSet.map((item) => item.id);
    confirmDelete(idSet);
  };
  /**

Callback function to handle pagination changes in the porder list table
@param {boolean} focused - Whether the go-to-page input field is focused or not
*/
  const handlePaginationCallback = (focused) => setGoInputFocused(focused);
  /**

Function to format a date string to a standard format
@param {string} data - Date string in yyyy-mm-dd format
@returns {string} - Formatted date string in yyyy-mm-dd format
*/
  const formatDate = (data) => {
    let data1 = data.slice(0, 10);
    return data1;
  };
  /**

Function to reset the counter value to 0
@returns {number} - Initial counter value of 0
*/
  const setCounterInitial = () => {
    setCounter(0);
    return counterValue;
  };
  /**

Function to handle changes in the date range filter form
@param {object} event - Event object containing the target input field and its value
@param {string} value - Value of the input field
*/

  const handleFormChange = (event, value) => {
    setFrom({
      ...from,
      [event.target.name]: event.target.value,
    });
    if (event.target.name == "day") {
      if (event.target.value > 28) {
        if (event.target.value == 31) {
          let monthsTemp = monthsSet.filter((item) => item.total_days == 31);
          setFromMonths(monthsTemp);
        } else {
          let monthsTemp = monthsSet.filter((item) => item.month !== "Feb");
          setFromMonths(monthsTemp);
        }
      } else {
        setFromMonths(monthsSet);
      }
    }
  };

  const handleToChange = (event, value) => {
    setTo({
      ...to,
      [event.target.name]: event.target.value,
    });
    if (event.target.name == "day") {
      if (event.target.value > 28) {
        let monthsTemp = monthsSet.filter((item) => item.month !== "Feb");
        setToMonths(monthsTemp);
        if (event.target.value == 31) {
          let monthsTemp = monthsSet.filter((item) => item.total_days == 31);
          setToMonths(monthsTemp);
        } else {
          let monthsTemp = monthsSet.filter((item) => item.month !== "Feb");
          setToMonths(monthsTemp);
        }
      } else {
        setToMonths(monthsSet);
      }
    }
  };

  const handleSearch = (e) => {
    e.preventDefault();
    handleCalandarClose(null);
    setSearchAttempt(true);
    let fromDate;
    let toDate;
    // Check if all date fields have been filled

    if (from.month && from.day && from.year && to.month && to.day && to.year) {
      // Format the from and to dates

      if (from.month < 10) {
        fromDate = `0${from.month} ${from.day} ${from.year}`;
      } else {
        fromDate = `${from.month} ${from.day} ${from.year}`;
      }
      if (to.month < 10) {
        toDate = `0${to.month} ${to.day} ${to.year}`;
      } else {
        toDate = `${to.month} ${to.day} ${to.year}`;
      }
      // Fetch data based on whether vendorChecked is true or false

      if (formatDate && toDate) {
        if (!vendorChecked) {
          getVendor(fromDate, toDate);
        } else {
          getVendorDetails(id, fromDate, toDate, null);
        }
      }

      setTooltipDate(
        `${from.day}/${from.month}/${from.year} to ${to.day}/${to.month}/${to.year}`
      );
      setTooltip(true);
    } else {
      // If all date fields have not been filled, fetch data based on docType and POrderStatus

      if (docType === "PORDER") {
        if (POrderStatus === "PORDER_DELETE_REJECTED") {
          getRejectedDeleteFiles(null, null, null, search);
        } else {
          if (POrderStatus === "PORDER_REJECTED") {
            getVendor(null, null, null, fileSearch);
          } else {
            if (searchSubject == "file") {
              getPOrderDetails("REVIEW", fileSearch, null, null, "PORDER");
            } else {
              getPOrderDetails("REVIEW", vendorSearch, null, null, "PORDER");
            }
          }
        }
      }
    }
  };
  // Function to handle search with date range

  const handleDateSearch = (date, from) => {
    // Update state with selected date range

    setDateRange(date);
    // Update tooltip message with selected date range

    setTooltipDate(
      `${from.day}/${from.month}/${from.year} to ${to.day}/${to.month}/${to.year}`
    );
    // Show tooltip

    setTooltip(true);
    // Close calendar

    handleCalandarClose(null);
    // Clear any existing date error message

    setDateErrMessage("");
    // Check document type

    if (docType === "PORDER") {
      if (POrderStatus == "PORDER_DELETE_REJECTED") {
        // Call API to get rejected and delete files for selected date range

        getRejectedDeleteFiles(date.from, date.to, null, "");
      } else {
        if (POrderStatus === "PORDER_REJECTED") {
          // Call API to get vendor details for selected date range

          getVendor(date.from, date.to, null, null);
        } else {
          // Call API to get porder vendors for selected date range

          getPOrderVendors(date.from, date.to, null);
        }
      }
    }
  };

  const filterSearch = (e) => {
    e.preventDefault();
    setSearch("");
    setVendorSearch("");
    setFileSearch("");
    let todayDate = new Date();
    let fromDate;
    let toDate;
    // Check if both "from" and "to" dates are selected

    if (from.month && from.day && from.year && to.month && to.day && to.year) {
      // Format the dates to match the API format

      if (from.month < 10) {
        fromDate = `0${from.month} ${from.day} ${from.year}`;
      } else {
        fromDate = `${from.month} ${from.day} ${from.year}`;
      }
      if (to.month < 10) {
        toDate = `0${to.month} ${to.day} ${to.year}`;
      } else {
        toDate = `${to.month} ${to.day} ${to.year}`;
      }
      // Create date object to check if "to" date is in the future

      var date = {
        from: fromDate,
        to: toDate,
      };
      var fromDateString = date.from.split(" ").join("/");
      var toDateString = date.to.split(" ").join("/");

      var fromDateObject = new Date(fromDateString);
      var toDateObject = new Date(toDateString);
      // Check if the selected dates are valid

      if (fromDateObject <= toDateObject) {
        if (to.year == todayDate.getFullYear()) {
          if (to.month <= 1 + todayDate.getMonth()) {
            if (to.month == 1 + todayDate.getMonth()) {
              if (to.day <= todayDate.getDate()) {
                handleDateSearch(date, from);
              } else {
                setDateErrMessage("You can not select a future date");
              }
            } else {
              handleDateSearch(date, from);
            }
          } else {
            setDateErrMessage("You can not select a future date");
          }
        } else {
          handleDateSearch(date, from);
        }
      } else {
        setDateErrMessage(
          "Start date should be less than or equal to End date"
        );
      }
    }
  };
  // Function to handle opening vendor details

  const handleVendorDetails = (vendor) => {
    if (docType === "PORDER") {
      getReceiptVendorDetails(
        vendor,
        null,
        null,
        null,
        POrderStatus.split("_")[1],
        search
      );
    } else {
      getVendorDetails(vendor.id);
      setId(vendor.id);
    }
  };
  // Function to get the number of selected porder files

  const getReceiptCounter = () => {
    var temp = fullReceiptVendorDetails.map((item) => item.isSelected);
    temp = temp.filter((item) => item == true);
    return {
      isVisible: temp.length > 0 ? true : false,
      fileCounter: temp.length,
    };
  };
  // Function to get the number of selected porder vendors

  const getReceiptVendorCounter = () => {
    var temp = POList.map((item) => item.isSelected);
    temp = temp.filter((item) => item == true);
    return {
      isVisible: temp.length > 0 ? true : false,
      vendorCounter: temp.length,
    };
  };
  // Function to get the number of selected rejected porder vendors

  const getRejectedReceiptsCounter = () => {
    var temp = vendorHTML.map((item) => item.isSelected);
    temp = temp.filter((item) => item == true);
    let data = {
      isVisible: temp.length > 0 ? true : false,
      vendorCounter: temp.length,
    };
    return data;
  };
  // Function to handle batch delete for porder files

  const handleBatchDelete = () => {
    var time = null;
    clearTimeout(time);

    var selectedDeleteFilesSet = exportBuffer.map((item) => {
      if (item.isSelected) {
        return item.id;
      }
      return null;
    });

    selectedDeleteFilesSet = selectedDeleteFilesSet.filter(
      (item) => item !== null
    );
    confirmDelete(selectedDeleteFilesSet);

    getPOrderDetails(
      POrderStatus.split("_")[1],
      search,
      null,
      null,
      "PORDER"
    );

    time = setTimeout(() => {
      setExportBuffer([]);
    }, 2000);
  };
  useEffect(() => {
    // Split the selectedVendor string into an array

    let ssr = selectedVendor.split(",");
  }, [selectedVendor]);

  const handleVendorCheckbox = async (event, vendor) => {
    event.stopPropagation();
    // Create a copy of vendorHTML and POList

    var vendorHTMLTemp = [...vendorHTML];
    var receiptListTemp = [...POList];
    var exportBufferTemp = [...exportBuffer];

    var fullVendorDetailsTemp = [...fullReceiptVendorDetails];

    var check = 0;

    if (docType == "PORDER") {
      // Loop through the POList to find the selected vendor

      for (let i = 0; i < receiptListTemp.length; i++) {
        if (receiptListTemp[i].id == vendor.id) {
          check = i;
          receiptListTemp[i].isSelected = !receiptListTemp[i].isSelected;
        }
        else {
          receiptListTemp[i].isAccordianOpen = false;

        }
      }
      // Update the vendorHTML state with the modified POList

      await setVendorHTML(receiptListTemp);
      // If the vendor's accordion is open, fetch the porder details for that vendor

      if (receiptListTemp[check].isAccordianOpen) {
        getReceiptVendorDetails(
          vendor,
          null,
          null,
          null,
          POrderStatus.split("_")[1],
          null
        );
        for (let i = 0; i < fullVendorDetailsTemp.length; i++) {
          exportBufferTemp.push(fullVendorDetailsTemp[i]);
        }
      }
      const uniqueElements = [];
      const uniqueIdsSet = new Set();

      for (const element of exportBufferTemp) {
        if (!uniqueIdsSet.has(element.id)) {
          uniqueElements.push(element);
          uniqueIdsSet.add(element.id);
        }
      }
      // Fetch the export files for the selected vendors
      setExportBuffer(uniqueElements);

      fetchExportFiles(vendor, POrderStatus);
    } else {
      // Loop through the vendorHTML to find the selected vendor

      for (let i = 0; i < vendorHTMLTemp.length; i++) {
        if (vendorHTMLTemp[i].id == vendor.id) {
          check = i;
          vendorHTMLTemp[i].isSelected = !vendorHTMLTemp[i].isSelected;
        } else {
          vendorHTMLTemp[i].isAccordianOpen = false;
        }
      }
      // Update the vendorHTML state with the modified vendorHTML

      await setVendorHTML(vendorHTMLTemp);
      // If the vendor's accordion is open, fetch the vendor details for that vendor

      if (vendorHTMLTemp[check].isAccordianOpen) {
        getVendorDetails(vendor);
      }
      // Fetch the export files for the selected vendors

      fetchExportFiles(vendor, POrderStatus);
    }
  };
  // Fetches export files for a given vendor and document status

  const fetchExportFiles = (vendor, docStatus) => {
    // Initialize variables

    var exportBufferTemp = [...exportBuffer];
    var sameVendorCount = 0;
    var from = dateRange.from,
      to = dateRange.to,
      type = sortBy.value;
    setLoading(true);
    // Make API call to retrieve export files

    axios({
      method: "GET",
      url: `${EXTRICATOR_BASE_URL}/vendor_file_details/?id=${vendor.id
        }&from=${from}&to=${to}&type=${type}&status=${docStatus.split("_")[1]
        }&search=${fileSearch.length ? fileSearch : null}&ogi=${ogi}`,
      headers: {
        "Content-Type": "multipart/form-data",
        Authorization: `Bearer ${authToken}`,
      },
    })
      .then(function (response) {
        // Parse retrieved export files

        var exportFiles = response.data;
        // If the vendor is selected, mark all retrieved export files as selected

        if (vendor.isSelected) {
          exportFiles = exportFiles.map((item) => ({
            ...item,
            isSelected: true,
          }));
          // Check if there are already export files for the same vendor in the export buffer

          for (let i = 0; i < exportBufferTemp.length; i++) {
            if (exportBufferTemp[i].vendor_id == vendor.id) {
              sameVendorCount++;
            }
          }
          // Add retrieved export files to the export buffer if they haven't been added yet

          if (sameVendorCount < 1) {
            exportBufferTemp = [...exportBufferTemp, ...exportFiles];
            setExportBuffer(exportBufferTemp);
          }
        } else {
          exportFiles = exportFiles.map((item) => ({
            ...item,
            isSelected: false,
          }));
          // Remove previously added export files for the same vendor from the export buffer

          exportBufferTemp = exportBufferTemp.filter(
            (item) => item.vendor_id != vendor.id
          );
          setExportBuffer(exportBufferTemp);
        }
        setLoading(false);
      })
      .catch((error) => {
        // Handle errors

        setLoading(false);
        if (error.response) {
          if (error.response.data.non_field_errors) {
            if (
              error.response.data.non_field_errors[0].includes(
                "Kindly pay the bill"
              )
            ) {
              setToastColor("error");
              setSuccessMsg(error.response.data.non_field_errors[0]);
              setSnackOpen(true);
            } else {
              checkOrganizationError(
                error.response.data.non_field_errors[0],
                props.history
              );
            }
          }
        }
      });
  };
  // Function to close the sort dropdown menu

  const handleCloseSort = () => {
    setAnchorElSort(null);
  };
  // Function to clear download counter and selected vendor

  const clearDowload = () => {
    setVendorCounter(0);
    setCounter(0);
    setSelectedVendor("");
    setSelectedVendor("");
  };
  // Function to get color for selected vendor

  const getVendorColor = (classes, val) => {
    let temp = selectedVendor.split(",");
    let newArr = temp.map((item) => Number(item));

    if (newArr.includes(val)) {
      return classes.summary_rootSelected;
    } else {
      return classes.summary_root;
    }
  };
  // Function to handle reauthentication

  const handleReAuth = (isReAuth) => {
    if (isReAuth) {
      handleLogout(props.history);
    }
  };
  // Function to handle section click

  const handleSectionClick = async (newStatus) => {
    // Check if the new status is different than the current porder status and includes the word "PORDER"

    if (POrderStatus !== newStatus && newStatus.includes("PORDER")) {
      setPOrderStatus(newStatus);
      if (newStatus == "PORDER_PARSED") {
        setParsedReceiptTab(0);
      }
      handleClearParameters(newStatus);
      setReceiptVendorDetails([]);
      setFullReceiptVendorDetails([]);
      setExportBuffer([]);
      setFullVendorHtml([]);
      setActiveSection(newStatus);
      setProcessingFiles([]);
      if (
        [
          "PORDER_REJECTED",
          "PORDER_DELETE_REJECTED",
          "PORDER_PROCESSING",
        ].includes(newStatus)
      ) {
        setSearchSubject("file");
      }
    }
  };
  // This function clears search and vendor selections and resets some state variables

  const handleClearParameters = (newStatus) => {
    setTooltipDate("");
    setSearch("");
    setVendorSearch("");
    setFileSearch("");
    setActiveVendor(null);
    setSortBy({ name: "Sort By", value: null });
    if (newStatus === "PARSED") {
      setParsedValue(0);
    }
    setFrom({
      ...from,
      day: null,
      month: null,
      year: null,
    });

    setTo({
      ...from,
      day: null,
      month: null,
      year: null,
    });
    setDeletedInvoiceTab(0);
    setParsedInvoiceTab(0);
    setDeletedTabValue(0);
    setStatus(newStatus);
    setVendorHTML([]);
    clearDowload();
    setDateErrMessage("");
    setDateRange({ from: null, to: null });
  };
  // This function toggles the selection of a porder and updates the export buffer accordingly

  const toggleFileSelectReceipt = (event, porder, vendor) => {
    var receiptListTemp = [...POList];
    var fullReceiptVendorDetailsTemp = [...fullReceiptVendorDetails];
    var exportBufferTemp = [...exportBuffer];

    for (let i = 0; i < fullReceiptVendorDetailsTemp.length; i++) {
      if (fullReceiptVendorDetailsTemp[i].id === porder.id) {
        fullReceiptVendorDetailsTemp[i].isSelected =
          !fullReceiptVendorDetailsTemp[i].isSelected;

        if (fullReceiptVendorDetailsTemp[i].isSelected) {
          exportBufferTemp.push(fullReceiptVendorDetailsTemp[i]);
        } else {
          exportBufferTemp = exportBufferTemp.filter(
            (item) => item.id != fullReceiptVendorDetailsTemp[i].id
          );
        }
      }
    }
    setExportBuffer(exportBufferTemp);

    var uncheckedIndex = fullReceiptVendorDetailsTemp.findIndex(
      (file) => file.isSelected == false
    );

    if (uncheckedIndex < 0) {
      handleVendorCheckbox(event, vendor);
    } else {
      for (let i = 0; i < receiptListTemp.length; i++) {
        receiptListTemp[i].isSelected = false;
      }
      setPOList(receiptListTemp);
    }
    formatReceiptData(fullReceiptVendorDetailsTemp);
  };

  const handleSearchInput = (event) => {
    // Check if search input value exists

    event.preventDefault();
    if (event.target.value) {
      // Check if search subject is file or vendor and set the respective state

      if (searchSubject == "file") {
        setFileSearch(event.target.value);
      } else {
        setVendorSearch(event.target.value);
      }
    } else {
      // Clear search input and reset search counter

      setFileSearch("");
      setVendorSearch("");
      setSearchCounter(searchCounter + 1);
    }
  };

  const handleSearchBlur = () => {
    setSearchFocus(false);
    if (searchCounter > 0) {
      setSearchCounter(0);
    }
  };

  const handleDeleteTabSwitch = () => {
    if (status !== "DELETE") {
      setStatus("DELETE");
      setVendorHTML([]);
      clearDowload();
    }
  };

  const handleDocType = ({ val, selectedRoute }) => {
    // Check if file type has changed

    if (docType !== val) {
      handleResetInterval();
      setActiveVendor(null);
      setExportBuffer([]);
      setSortBy({ name: "Sort By", value: null });
      setFrom({
        ...from,
        day: null,
        month: null,
        year: null,
      });

      setTo({
        ...to,
        day: null,
        month: null,
        year: null,
      });
      setTooltipDate("");
      setDateRange({ from: null, to: null });
      setDateErrMessage("");
      setFormStatus("");
      // setPOrderStatus("");
      // Set porder status to review if file type is porder

      if (val === "PORDER") {
        setPOrderStatus("PORDER_REVIEW");
      }
      setStatus("REVIEW");
      setFileType(val);
      if (fileSearch) {
        setFileSearch("");
      }
      if (vendorSearch) {
        setVendorSearch("");
      }
      // Navigate to the selected route

      props.history.push(selectedRoute);
    }
  };
  /**
   * Closes the document type modal and clears related information
   */
  const handleDocTypeModalClose = () => {
    setUploadModal(false);
    setFileLength(0);
    setUploaded(false);
    setFileType("");
    setInvoices(false);
    setFileMessage("");
    setCheckMaxNoPages(true)
  };
  /**
   * Handles closing the snack bar message
   * @param {Object} event - The event object
   * @param {string} reason - The reason for closing the message
   */
  const handleSnackClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setSuccessMsg("");
    setSnackOpen(false);
  };
  /**
   * Shows or hides the logout button
   * @param {boolean} flag - The flag to determine whether to show or hide the button
   */
  const showHideLogoutBtn = (flag) => {
    if (flag) {
      showLogoutBtn(!logOutBtn);
    } else {
      if (logOutBtn) {
        showLogoutBtn(false);
      }
    }
  };
  /**
   * Resets the file processing interval
   */
  const handleResetInterval = () => {
    tripper = 0;
    setTestCounter(0);
    setProcessingFiles([]);
    setFullProcessingFiles([]);
    clearInterval(timerVal);
  };
  /**
   * Handles dropping files onto the page
   * @param {Object} ev - The event object
   */
  const dropHandler = (ev) => {
    ev.preventDefault();
    let filesSet = [];
    if (ev.dataTransfer.items) {
      for (var i = 0; i < ev.dataTransfer.items.length; i++) {
        if (ev.dataTransfer.items[i].kind === "file") {
          var file = ev.dataTransfer.items[i].getAsFile();
          filesSet.push(file);
        }
      }
    } else {
      for (var i = 0; i < ev.dataTransfer.files.length; i++) {
        filesSet.push(ev.dataTransfer.files[i]);
      }
    }
    const formData = new FormData();
    const checkNoPagesData = new FormData();

    for (let i = 0; i < filesSet.length; i++) {
      formData.append("file", filesSet[i]);
      checkNoPagesData.append("file", filesSet[i]);
    }
    checkNoPagesData.append("user", sessionStorage.getItem('user_id'));
    checkNoPagesData.append("company_name", ogi);
    checkNoPagesData.append("plan_name", planName);
    axios({
      method: "post",
      url: `${EXTRICATOR_BASE_URL}/check-max-number-of-pages/`,
      data: checkNoPagesData,
      headers: {
        Authorization: `Bearer ${authToken}`,
      },
    })
      .then(function (response) {
        if (response.data.status) {
          setToastColor("success");
          setSuccessMsg(response.data.message);
          setSnackOpen(true);
          formData.append("file_type", docType);
          formData.append("ogi", ogi);
          formData.append("plan_name", planName);
          setInvoices(formData);
          setCheckMaxNoPages(false)
          setFileLength(filesSet.length);
          setFileMessage("");
        }
        else{
          setFileMessage(response.data.message);
          setSnackOpen(true);
          setInvoices(false);
          setCheckMaxNoPages(true)
          setFileLength(0);
          setUploaded(false);
          setFileType("");

        }
      });
  };
  /**
   * Function that prevents the default action of the dragover event.
   *
   * @param {Object} ev - The event object.
   */
  function dragOverHandler(ev) {
    ev.preventDefault();
  }

  return (
    <>
      <CheckMobileDevice />
      <DeleteDialogBox
        open={deletableFileId}
        handleClose={() => setDeletableFileId(null)}
        content="Are you sure you want to delete this document?"
        inProgress={deleteFileProgress}
        handleConfirm={() => confirmDelete(deletableFileId)}
        dialogTitle="Confirmation"
      />

      <Backdrop className={classes.backdrop} open={loading}>
        <CircularProgress color="inherit" />
      </Backdrop>
      <Backdrop className={classes.backdrop} open={open}>
        <Modal
          open={open}
          style={{ top: 100, left: 479, width: 408, height: 496 }}
          aria-labelledby="simple-modal-title"
          aria-describedby="simple-modal-description"
        >
          <div>
            <img style={{ opacity: 1, borderRadius: 11 }} src={gif} />
          </div>
        </Modal>

        <Modal
          open={uploadModal}
          onClose={() => (!fileUploading ? handleDocTypeModalClose() : null)}
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
          aria-labelledby="simple-modal-title"
          aria-describedby="simple-modal-description"
        >
          <div
            style={{
              backgroundColor: "#FFFFFF",
              width: 520,
              height: 246,
              borderRadius: 6,
            }}
            align="center"
          >
            <>
              {!fileUploading ? (
                <>
                  <img
                    src={Images.close}
                    onClick={() => handleDocTypeModalClose()}
                    style={{
                      cursor: "pointer",
                      width: 19,
                      height: 19,
                      marginTop: 12,
                      marginLeft: "94%",
                      marginTop: 6,
                    }}
                  />
                  <img
                    src={Images.upload}
                    style={{ width: 35, height: 33, marginTop: 12 }}
                  />

                  <div
                    align="center"
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                    }}
                    id="drop_zone"
                    onDrop={dropHandler}
                    onDragOver={dragOverHandler}
                  >
                    <div
                      style={{
                        color: "#1C5784",
                        font: "normal normal bold 15px/26px Open Sans",
                        letterSpacing: "0px",
                        opacity: 1,
                      }}
                    >
                      Drop files here
                    </div>

                    <div
                      style={{
                        color: "#1C5784",
                        font: "normal normal bold 10px/26px Open Sans",
                        opacity: 1,
                        letterSpacing: 0,
                      }}
                    >
                      or click here
                      <span
                        style={{
                          background: "#1177FD 0% 0% no-repeat padding-box",
                          borderRadius: 5,
                          marginLeft: 4,
                          padding: 2,
                        }}
                      >
                        <label
                          htmlFor="contained-button-file"
                          style={{ cursor: "pointer" }}
                        >
                          <span
                            style={{
                              font: "normal normal 600 10px/26px Open Sans",
                              textAlign: "center",
                              letterSpacing: 0,
                              opacity: 1,
                              color: "#fff",
                            }}
                          >
                            Choose files
                          </span>
                        </label>
                      </span>
                    </div>
                    <div>
                      {fileLength && (
                        <>
                          <span
                            style={{
                              color: "#1C5784",
                              font: "normal normal bold 10px/26px Open Sans",
                              letterSpacing: 0,
                              opacity: 1,
                            }}
                          >
                            {fileLength} Files selected
                          </span>
                          <i
                            className="fa fa-check-circle ml-1"
                            style={{ fontSize: "12px", color: "#40FF7F" }}
                          ></i>
                        </>
                      )
                    }
                    {fileMessage && (
                    <>
                      <span
                        style={{
                          color: "red",
                          font: "normal normal bold 10px/26px Open Sans",
                          letterSpacing: 0,
                          opacity: 1,
                        }}
                      >
                        {fileMessage}
                      </span>
                    
                    </>
                  )
                   }
                    </div>

                    <button
                      onClick={uploadInvoice}
                      disabled={checkMaxNoPages}
                      className="btn btn-lg sign-btn get-btn mt-4"
                      style={{
                        display: "flex",
                        alignContent: "center",
                        justifyContent: "center",
                        background: !checkMaxNoPages? "#35F8F1 0% 0% no-repeat padding-box" : "gray 0% 0% no-repeat padding-box",
                        boxShadow: "0px 3px 6px #14141429",
                        borderRadius: 3,
                        opacity: 1,
                        width: 142,
                      }}
                    >
                      Upload
                    </button>
                   
                  </div>
                </>
              ) : (
                <div className="col-12">
                  <div
                    className="d-flex justify-content-center align-items-center"
                    style={{ height: "200px" }}
                  >
                    <svg
                      className="spinner"
                      width="65px"
                      height="65px"
                      viewBox="0 0 66 66"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <circle
                        className="path"
                        fill="none"
                        stroke-width="6"
                        stroke-linecap="round"
                        cx="33"
                        cy="33"
                        r="30"
                      ></circle>
                    </svg>
                  </div>
                  <h6 style={{ color: "#011B3E", fontSize: "14px" }}>
                    Please wait while your files upload.
                  </h6>
                </div>
              )}
            </>
          </div>
        </Modal>
      </Backdrop>
      <div
        className="dashboard_container"
        onClick={() => showHideLogoutBtn(null)}
      >
        {fileId && (
          <ImageShowcase
            fileId={fileId}
            fileName={fileName}
            handleClose={() => setFileId(null)}
          />
        )}
        <SnackbarComponent
          isOpen={snackOpen}
          message={successMsg}
          color={toastColor}
          handleClose={handleSnackClose}
        />
        <div
          className="menu-side col-2"
          style={{ backgroundImage: Images.data_center }}
        >
          <div>
            <div className="image-full">
              <div className="form-group d-flex justify-content-center mt-4 banner-head">
                <Link to="/">
                  <img
                    src={Images.app_logo}
                    className="img-responsive img-fluid"
                    alt=""
                  />
                </Link>
              </div>
            </div>
            <FileCategoryComponent
              reAuthenticate={handleReAuth}
              handleCategoryClick={handleSectionClick}
              docType={"PORDER"}
              docStatus={POrderStatus}
              defaultActiveTab={activeTab}
            />
          </div>
          {/* <PayContainer /> */}
          <PayContainer planName={planName} file_type={docType}/>

        </div>

        <div className="invoice-side pt-2" onClick={closeProfileMenu}>
          <div className="col-12">
            <div className="row">
              <div className="col-6" style={{ paddingLeft: "11px" }}>
                <TopNav callback={handleDocType} defaultActiveTab={5} />
                <div className="col-md-12 basic-search mt-3">
                  <div className="input-field">
                    <form
                      autoComplete="off"
                      onSubmit={handleSearch}
                      className="col-6 d-flex"
                      style={{
                        borderRadius: 6,
                        opacity: 1,
                        background: "#0B346B 0% 0% no-repeat padding-box",
                        padding: "0px",
                      }}
                    >
                      <div className="d-flex search_container col-11 ml-2">
                        {![
                          "PORDER_REJECTED",
                          "PORDER_DELETE_REJECTED",
                          "PORDER_PROCESSING",
                        ].includes(POrderStatus) && (
                            <SearchOnSelect
                              getValue={(option) => setSearchSubject(option)}
                            />
                          )}
                        <div className="d-flex search_container">
                          <img
                            src={Images.dashboard_search}
                            width={14}
                            height={14}
                            style={{ margin: "10px 5px" }}
                          />
                          <input
                            id="search"
                            type="text"
                            placeholder="Search"
                            style={{ width: "245px" }}
                            value={
                              searchSubject == "file"
                                ? fileSearch
                                : vendorSearch
                            }
                            onFocus={() => setSearchFocus(true)}
                            onBlur={() => handleSearchBlur()}
                            ref={searchInput}
                            onChange={handleSearchInput}
                            onEnter
                          />
                          <button className="btn search_btn">
                            <i
                              style={{ color: "#01132B" }}
                              className="fa fa-search"
                            ></i>
                          </button>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
              <div className="col-6" style={{ paddingRight: "23px" }}>
                <div className="row justify-content-end align-items-center">
                  <Tooltip
                    open={tooltip}
                    title={tooltipDate}
                    placement="bottom"
                    arrow
                  >
                    <img
                      src={Images.calendar}
                      className="calendar"
                      onClick={onCalandarClick}
                      style={{
                        width: 25,
                        height: 29,
                        color: "#FFFFFF 0% 0% no-repeat padding-box",
                        marginLeft: "26.45px",
                        cursor: "pointer",
                        zIndex: 1310,
                      }}
                    />
                  </Tooltip>
                  <div className="dashboard_calendar mx-3">
                    <ThemeProvider theme={myTheme}>
                      {calander && (
                        <>
                          <Menu
                            id="simple-menu"
                            anchorEl={anchorEl}
                            keepMounted
                            open={Boolean(anchorEl)}
                            className="mt-5"
                            onClose={() => handleCalandarClose(null)}
                            style={{ left: "0px", overflowY: "hidden" }}
                          >
                            <MenuItem
                              style={{
                                background:
                                  "#12458A 0% 0% no-repeat padding-box",
                                borderRadius: 5,
                                opacity: 1,
                              }}
                            >
                              <div>
                                <div className="date-band">
                                  <label>From</label>
                                  <Select
                                    labelId="demo-simple-select-outlined-label"
                                    id="demo-simple-select-outlined"
                                    onChange={handleFormChange}
                                    className="to-day"
                                    value={from.day}
                                    name="day"
                                    style={{
                                      display: "flex",
                                      width: "42px",
                                      height: "19px",
                                      color: "#FFF",
                                      fontSize: "12px",
                                      marginLeft: "16px",
                                    }}
                                  >
                                    {days.map((item) => (
                                      <MenuItem
                                        style={{
                                          color: "#FFF",
                                          fontSize: "12px",
                                        }}
                                        value={item}
                                        id={item}
                                      >
                                        {item}
                                      </MenuItem>
                                    ))}
                                  </Select>
                                  <Select
                                    labelId="demo-simple-select-outlined-label"
                                    id="demo-simple-select-outlined"
                                    className="to-day"
                                    value={from.month}
                                    onChange={handleFormChange}
                                    name="month"
                                    style={{
                                      display: "flex",
                                      width: "51px",
                                      height: "19px",
                                      fontSize: "12px",
                                      color: "#FFF",
                                      marginLeft: 25,
                                    }}
                                  >
                                    {fromMonths.map((item) => (
                                      <MenuItem
                                        style={{
                                          color: "#FFF",
                                          fontSize: "12px",
                                        }}
                                        value={item.index}
                                        id={item.index}
                                      >
                                        {item.month}
                                      </MenuItem>
                                    ))}
                                  </Select>
                                  <Select
                                    labelId="demo-simple-select-outlined-label"
                                    id="demo-simple-select-outlined"
                                    className="to-day"
                                    onChange={handleFormChange}
                                    value={from.year}
                                    name="year"
                                    style={{
                                      display: "flex",
                                      width: "58px",
                                      height: "19px",
                                      color: "#FFF",
                                      fontSize: "12px",
                                      marginLeft: 22,
                                    }}
                                  >
                                    {years.map((item) => (
                                      <MenuItem
                                        style={{
                                          color: "#FFF",
                                          fontSize: "12px",
                                        }}
                                        value={item}
                                        key={item}
                                      >
                                        {item}
                                      </MenuItem>
                                    ))}
                                  </Select>
                                </div>

                                <div
                                  className="date-band"
                                  style={{ marginTop: "19px" }}
                                >
                                  <label>To</label>
                                  <Select
                                    labelId="demo-simple-select-outlined-label"
                                    id="demo-simple-select-outlined"
                                    className="to-day"
                                    onChange={handleToChange}
                                    name="day"
                                    value={to.day}
                                    style={{
                                      display: "flex",
                                      width: "42px",
                                      height: "19px",
                                      color: "#FFF",
                                      fontSize: "12px",
                                      marginLeft: "16px",
                                    }}
                                  >
                                    {days.map((item) => (
                                      <MenuItem
                                        style={{
                                          color: "#FFF",
                                          fontSize: "12px",
                                        }}
                                        value={item}
                                        id={item}
                                      >
                                        {item}
                                      </MenuItem>
                                    ))}
                                  </Select>
                                  <Select
                                    labelId="demo-simple-select-outlined-label"
                                    id="demo-simple-select-outlined"
                                    className="to-day"
                                    value={to.month}
                                    onChange={handleToChange}
                                    name="month"
                                    style={{
                                      display: "flex",
                                      width: "51px",
                                      height: "19px",
                                      color: "#FFF",
                                      fontSize: "12px",
                                      marginLeft: 25,
                                    }}
                                  >
                                    {toMonths.map((item) => (
                                      <MenuItem
                                        style={{
                                          color: "#FFF",
                                          fontSize: "12px",
                                        }}
                                        value={item.index}
                                        id={item.index}
                                      >
                                        {item.month}
                                      </MenuItem>
                                    ))}
                                  </Select>
                                  <Select
                                    labelId="demo-simple-select-outlined-label"
                                    id="demo-simple-select-outlined"
                                    className="to-day"
                                    onChange={handleToChange}
                                    name="year"
                                    value={to.year}
                                    style={{
                                      display: "flex",
                                      width: "58px",
                                      height: "19px",
                                      color: "#FFF",
                                      fontSize: "12px",
                                      marginLeft: 22,
                                    }}
                                  >
                                    {years.map((item) => (
                                      <MenuItem
                                        style={{
                                          color: "#FFF",
                                          fontSize: "12px",
                                        }}
                                        value={item}
                                      >
                                        {item}
                                      </MenuItem>
                                    ))}
                                  </Select>
                                </div>
                              </div>
                            </MenuItem>
                            <MenuItem>
                              <div className="col-12">
                                <div className="row justify-content-between">
                                  <span
                                    onClick={() => handleCalandarClose("reset")}
                                    style={{
                                      font: "normal normal 600 10px/42px Open Sans",
                                      marginLeft: "10px",
                                      opacity: 1,
                                      cursor: "pointer",
                                      textDecoration: "underline",
                                    }}
                                  >
                                    Clear Search
                                  </span>
                                  <Button
                                    component="span"
                                    variant="contained"
                                    classes={{ root: classes.logOutBtn }}
                                    style={{
                                      height: 28,
                                      borderRadius: 2,
                                      marginLeft: "53px",
                                      background:
                                        "#35F8F1 0% 0% no-repeat padding-box",
                                      width: "110px",
                                      marginTop: 5,
                                    }}
                                    onClick={filterSearch}
                                  >
                                    <span
                                      style={{
                                        paddingLeft: 10,
                                        paddingRight: 10,
                                        font: "normal normal bold 10px/25px Open Sans",
                                        color: " #011B3E",
                                        opacity: 1,
                                        letterSpacing: 0,
                                      }}
                                    >
                                      Search
                                    </span>
                                  </Button>
                                </div>
                              </div>
                            </MenuItem>
                            {dateErrMessage && (
                              <Alert
                                classes={{
                                  icon: classes.alertIcon,
                                  message: classes.alertMsg,
                                }}
                                style={{ fontSize: "12px", padding: "6px" }}
                                severity="error"
                              >
                                {dateErrMessage}
                              </Alert>
                            )}
                          </Menu>
                        </>
                      )}

                      <div
                        className="d-flex align-items-center"
                        style={{ marginLeft: "10px" }}
                      >
                        <h6 className="mb-0" style={{ fontSize: "15px" }}>
                          Sort by :
                        </h6>
                        <div className="dropdown ml-2">
                          <button
                            className="btn dropdown-toggle sort-btn"
                            type="button"
                            id="dropdownMenuButton"
                            data-toggle="dropdown"
                            aria-haspopup="true"
                            aria-expanded="false"
                          >
                            {sortBy.name}
                          </button>
                          <div
                            className="dropdown-menu"
                            aria-labelledby="dropdownMenuButton"
                          >
                            {sortOptions.map((option) => (
                              <a
                                className="dropdown-item"
                                onClick={() => handleSortOptionSelect(option)}
                                key={option.value}
                                href="#"
                              >
                                {option.name}
                              </a>
                            ))}
                          </div>
                        </div>
                      </div>
                    </ThemeProvider>
                  </div>
                  <div style={{ position: "relative", marginRight: "15px" }}>
                    <LogoutBar
                      btnShow={logOutBtn}
                      callback={showHideLogoutBtn}
                    ></LogoutBar>
                  </div>
                </div>
                <div className="d-flex align-items-center justify-content-end mt-2">
                  {/* exportBuffer refers to selected files */}
                  {POrderStatus !== "PORDER_PROCESSING" &&
                    POrderStatus !== "PORDER_REJECTED" &&
                    POrderStatus !== "PORDER_DELETE" && (
                      <div
                        className="d-flex model_input"
                        style={{ marginLeft: "16px" }}
                      >
                        <label
                          onClick={() =>
                            exportBuffer.length > 0
                              ? handleMapMultipleExplicitRejectedFiles()
                              : null
                          }
                          style={{
                            backgroundColor:
                              exportBuffer.length > 0 ? "#35F8F1" : "gray",
                            paddingBottom: "10px",
                          }}
                          className="btn parse_label ml-3"
                        >
                          <span
                            style={{ alignItems: "center", paddingTop: "6px" }}
                          >
                            Explicit Reject
                          </span>
                        </label>
                      </div>
                    )}
                  {POrderStatus == "PORDER_REJECTED" && (
                    <div
                      className="d-flex model_input"
                      style={{ marginLeft: "16px" }}
                    >
                      <CustomDropDown
                        screen="dashboard"
                        modelType={"PORDER"}
                        getSelectedModel={(model) => setSelectedModel(model)}
                        listOffset={{ top: "144px", left: "255px" }}
                      />
                      <label
                        onClick={() =>
                          getRejectedReceiptsCounter().isVisible &&
                            selectedModel
                            ? handleMapMultipleRejectedFiles()
                            : null
                        }
                        style={{
                          backgroundColor:
                            getRejectedReceiptsCounter().isVisible &&
                              selectedModel
                              ? "#35F8F1"
                              : "gray",
                        }}
                        className="parse_label ml-3"
                      >
                        <span>Parse</span>
                      </label>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>

          {docType == "PORDER" &&
            (POrderStatus === "PORDER_PARSED" ||
              POrderStatus === "PORDER_EXPORTED") && (
              <Paper
                square
                style={{
                  maxWidth: 320,
                  backgroundColor: "#04244f",
                  marginLeft: 12,
                }}
              >
                <Tabs
                  value={parsedReceiptTab}
                  onChange={(e, newValue) =>
                    handlePOrderStatusChange(e, newValue)
                  }
                  variant="fullWidth"
                  indicatorColor="primary"
                  textColor="primary"
                  aria-label="icon tabs example"
                  style={{ marginBottom: 10, backgroundColor: "#04244f" }}
                >
                  <Tab
                    aria-label="porder"
                    style={{ maxWidth: 92 }}
                    label={
                      <CustomTab
                        icon={
                          valueParsed === 0
                            ? Images.dashboard_parsed_checked
                            : Images.dashboard_checked
                        }
                        title="Parsed"
                      />
                    }
                  />
                  <Tab
                    label={
                      <CustomTab
                        icon={
                          valueParsed === 1
                            ? Images.exported_active
                            : Images.exported
                        }
                        title="Exported"
                      />
                    }
                  />
                </Tabs>
              </Paper>
            )}

          {/* Deleted & Rejected Receipts */}
          {docType === "PORDER" &&
            (POrderStatus === "PORDER_DELETE" ||
              POrderStatus === "PORDER_DELETE_REJECTED") ? (
            <Paper
              square
              style={{ backgroundColor: "#04244f", marginLeft: 12 }}
            >
              <Tabs
                value={deletedTabValue}
                onChange={(e, newValue) => handleDeleteTabChange(e, newValue)}
                variant="fullWidth"
                indicatorColor="primary"
                textColor="primary"
                aria-label="icon tabs example"
                style={{ marginBottom: 10, backgroundColor: "#04244f" }}
              >
                <Tab
                  aria-label="phone"
                  style={{ maxWidth: 92 }}
                  label={
                    <CustomTab
                      icon={
                        valueParsed === 0
                          ? Images.dashboard_parsed_checked
                          : Images.dashboard_checked
                      }
                      title="Deleted"
                    />
                  }
                />
                <Tab
                  style={{ maxWidth: 92 }}
                  label={
                    <CustomTab
                      icon={
                        valueParsed === 1
                          ? Images.exported_active
                          : Images.exported
                      }
                      title="Rejected"
                    />
                  }
                />
              </Tabs>
            </Paper>
          ) : null}

          {/* Rejected Receipts */}
          {value === 3 &&
            deletedTabValue === 1 &&
            POrderStatus === "PORDER_DELETE" &&
            docType === "PORDER" && (
              <div
                style={{
                  width: "100%",
                  marginBottom: "25px",
                  marginLeft: "38px",
                }}
              >
                <table className="table">
                  <thead>
                    <tr style={{ backgroundColor: "#01132B" }}>
                      <th>File</th>
                      <th>Create At</th>
                      <th>Modified At</th>
                    </tr>
                  </thead>
                  <tbody>
                    {rejectedDeleteFiles.length > 0 ? (
                      rejectedDeleteFiles.map((item) => (
                        <tr key={item.id}>
                          <td>
                            <Link
                              to={`/document_details/${item.id}`}
                              onMouseEnter={() => handleShowPreview(item)}
                              onMouseLeave={() => handleHidePreview()}
                            >
                              {item.file_name}
                            </Link>
                          </td>
                          <td>{getTimeRecord(item.created_at)}</td>
                          <td>{getTimeRecord(item.modified_at)}</td>
                        </tr>
                      ))
                    ) : (
                      <>
                        <tr></tr>
                        <tr></tr>
                        <tr></tr>
                        <tr align="center">
                          <td></td>
                          <td></td>
                          <td align="center">Nothing to show</td>
                          <td></td>
                          <td></td>
                        </tr>
                        <tr></tr>
                        <tr></tr>
                        <tr></tr>
                      </>
                    )}
                  </tbody>
                </table>
              </div>
            )}

          {docType === "PORDER" && (
            <>
              {!["PORDER_DELETE_REJECTED", "PORDER_PROCESSING"].includes(
                POrderStatus
              ) ? (
                <div
                  style={{
                    height: getListHeight(),
                    overflowX:
                      POrderStatus !== "PORDER_REJECTED" ? "hidden" : "unset",
                    overflowY:
                      POrderStatus !== "PORDER_REJECTED" ? "auto" : "unset",
                  }}
                  className={
                    POrderStatus == "PORDER_DELETE" ||
                      POrderStatus == "PORDER_DELETE_REJECTED"
                      ? "startAcc rejected-receipt"
                      : "startAcc mt-2"
                  }
                >
                  {POrderStatus !== "PORDER_REJECTED" && (
                    <Accordion className="text-white" style={{ height: 37 }}>
                      <div className="col-12">
                        <ul>
                          <li>
                            <span
                              style={{
                                font: "normal normal 600 14px/35px Open Sans",
                                color: "#fff",
                                fontSize: '0.9rem' 
                              }}
                            >
                              Vendor name
                            </span>
                          </li>
                        </ul>
                      </div>
                    </Accordion>
                  )}
                  {POrderStatus !== "PORDER_REJECTED" ? (
                    POList.length > 0 ? (
                      POList
                        .filter((e) => e.total_file !== 0)
                        .map((vendor, index) => (
                          <Accordion
                            expanded={vendor.isAccordianOpen}
                            onChange={handleAccordianChange(vendor)}
                            style={{ marginBottom: 5, zIndex: 0 }}
                          >
                            <AccordionSummary
                              classes={{
                                root: getVendorColor(classes, vendor.id),
                              }}
                              expandIcon={<ExpandMoreIcon />}
                              aria-controls="panel2a-content"
                              id="panel2a-header"
                              className="vendorNameDiv"
                            >
                              <div
                                className="left-vendor-div d-flex col-7"
                                onClick={(e) => handleVendorDetails(vendor)}
                              >
                                {POrderStatus !== "PORDER_DELETE" ? (
                                  <ThemeProvider theme={myTheme}>
                                    <Checkbox
                                      value={`vendor${vendor.id}`}
                                      name={`vendor_check${vendor.id}`}
                                      id={`vendor_id${vendor.id}`}
                                      checked={vendor.isSelected}
                                      inputProps={{
                                        "aria-label": "Checkbox A",
                                      }}
                                      onClick={(event) =>
                                        handleVendorCheckbox(event, vendor)
                                      }
                                    />
                                  </ThemeProvider>
                                ) : (
                                  <span>&nbsp;&nbsp;&nbsp;&nbsp;</span>
                                )}
                                <span
                                  className="d-flex align-items-center"
                                  onClick={setCounterInitial}
                                >
                                  <span style={{ fontWeight: "600", fontSize: '1rem' }}>
                                    {vendor.supplier_name}
                                  </span>
                                  {expanded === `panel${index}` ? (
                                    <i
                                      className="fa fa-caret-up ml-1"
                                      style={{ fontSize: "18px" }}
                                      color="#fff"
                                    ></i>
                                  ) : (
                                    <i
                                      className="fa fa-caret-down ml-1"
                                      style={{ fontSize: "18px" }}
                                      color="#fff"
                                    ></i>
                                  )}
                                  {counterValue > 0 &&
                                    expanded === `panel${index}` ? (
                                    <span className="selectedCount ml-1">
                                      ({counterValue} Selected)
                                    </span>
                                  ) : (
                                    ""
                                  )}
                                </span>
                              </div>

                              <div className={getPaginationClass()}>
                                {vendor.isAccordianOpen &&
                                  receiptVendorDetails.length > 0 && (
                                    <PaginationComponent
                                      place={"vendor_rcpt"}
                                      onPageChange={(event, item) =>
                                        handlePageChange(event, item)
                                      }
                                      data={totalReceiptPages}
                                      callback={(flag) =>
                                        handlePaginationCallback(flag)
                                      }
                                    />
                                  )}
                              </div>
                            </AccordionSummary>

                            <AccordionDetails
                              style={{ paddingBottom: 0 }}
                              className="details-accordian width-100"
                            >
                              <>
                                <ul
                                  className="nav upNav pl-3"
                                  style={{ backgroundColor: "#011B3E" }}
                                >
                                  <li
                                    className="nav-item sub-nav-vendor head-file"
                                    style={{ width: "21%" }}
                                    title="Files"
                                  >
                                    <span className="review-div disableHover">
                                      Files
                                    </span>
                                  </li>
                                  <li className="nav-item sub-nav text-left time-heading">
                                    Received at
                                  </li>
                                  <li className="nav-item sub-nav text-left time-heading">
                                    Modified at
                                  </li>

                                  {value !== 2 &&
                                    POrderStatus !== "PORDER_DELETE" && (
                                      <li className="nav-item eyeIcon text-left">
                                        <span className="fileaction-span disableHover">
                                          <span>Delete</span>
                                        </span>
                                      </li>
                                    )}
                                  <li
                                    className="nav-item sub-nav pl-2"
                                    style={{ width: "10%" }}
                                  >
                                    <span className="fileaction-span disableHover">
                                      <span>Download</span>
                                    </span>
                                  </li>
                                  {POrderStatus !== "PORDER_DELETE" && (
                                    <>
                                      <li
                                        className="nav-item sub-nav pl-2"
                                        style={{
                                          width: "14%",
                                          fontWeight: "600",
                                        }}
                                      >
                                        Download.xls
                                      </li>
                                    </>
                                  )}
                                </ul>

                                {receiptVendorDetails.length > 0 ? (
                                  receiptVendorDetails.map((item) => (
                                    <ul
                                      className="nav upNav pl-3"
                                      style={{
                                        backgroundColor: item.isSelected
                                          ? "#017BFF"
                                          : "transparent",
                                      }}
                                    >
                                      <li
                                        className={
                                          POrderStatus !== "PORDER_DELETE"
                                            ? "nav-item sub-nav-vendor"
                                            : "nav-item sub-nav"
                                        }
                                        style={{
                                          whiteSpace: "nowrap",
                                          overflow: "hidden",
                                          textOverflow: "ellipsis",
                                          width: "21%",
                                          height: "100%",
                                          marginTop: "1px",
                                        }}
                                        title={item.original_file_name}
                                      >
                                        {POrderStatus !== "PORDER_DELETE" ? (
                                          <ThemeProvider theme={myTheme}>
                                            <Checkbox
                                              checked={item.isSelected}
                                              onClick={(event) =>
                                                toggleFileSelectReceipt(
                                                  event,
                                                  item,
                                                  vendor
                                                )
                                              }
                                              value="checkedA"
                                              inputProps={{
                                                "aria-label": "Checkbox A",
                                              }}
                                            />
                                          </ThemeProvider>
                                        ) : (
                                          <span></span>
                                        )}
                                        <i className="fa fa-file-o"></i>
                                        {![
                                          "PORDER_DELETE_REJECTED",
                                          "PORDER_DELETE",
                                        ].includes(POrderStatus) ? (
                                          <Link
                                            to={{
                                              pathname: `/porder_details/${item.id}`,
                                              state: POrderStatus,
                                            }}
                                            style={{ textDecoration: "none" }}
                                          >
                                            {value !== 2 ? (
                                              <span
                                                className="ml-2 review-div"
                                                onMouseEnter={() =>
                                                  handleShowPreview(item)
                                                }
                                                onMouseLeave={() =>
                                                  handleHidePreview()
                                                }
                                              >
                                                {item.original_file_name}
                                              </span>
                                            ) : (
                                              <span className="ml-2 review-div">
                                                {item.original_file_name}
                                              </span>
                                            )}
                                          </Link>
                                        ) : (
                                          <span
                                            className="ml-2 review-div"
                                            onMouseEnter={() =>
                                              handleShowPreview(item)
                                            }
                                            onMouseLeave={() =>
                                              handleHidePreview()
                                            }
                                          >
                                            {item.original_file_name}
                                          </span>
                                        )}
                                      </li>
                                      <li className="nav-item sub-nav text-left">
                                        {getTimeRecord(item.created_at)}
                                      </li>
                                      <li className="nav-item sub-nav text-left">
                                        {getTimeRecord(item.modified_at)}
                                      </li>

                                      {value !== 2 &&
                                        POrderStatus !== "PORDER_DELETE" && (
                                          <li className="nav-item eyeIcon pl-2 text-left">
                                            <span
                                              className="fileaction-span"
                                              onClick={() =>
                                                setDeletableFileId(item.id)
                                              }
                                            >
                                              <i className="fa fa-trash"></i>&nbsp;
                                              <span>Delete</span>
                                            </span>
                                          </li>
                                        )}
                                      <li
                                        className="nav-item sub-nav pl-2"
                                        style={{ width: "10%" }}
                                      >
                                        <span
                                          className="fileaction-span"
                                          onClick={() => {
                                            window.open(
                                              `${EXTRICATOR_BASE_URL}/download_file/?id=${item.id}&ogi=${ogi}`
                                            );
                                          }}
                                        >
                                          <i className="fa fa-download mr-1"></i>
                                          <span>Download</span>
                                        </span>
                                      </li>
                                      {POrderStatus !== "PORDER_DELETE" && (
                                        <>
                                          <li
                                            className="nav-item sub-nav xls-btn-row pl-2"
                                            style={{ width: "14%" }}
                                            onClick={() =>
                                              handleSingleXlsDownload(item.id)
                                            }
                                          >
                                            <i className="fa fa-download mr-1"></i>
                                            Download.xls
                                          </li>
                                        </>
                                      )}
                                    </ul>
                                  ))
                                ) : (
                                  <div className="col-12 no-files">
                                    Nothing to show
                                  </div>
                                )}
                              </>
                            </AccordionDetails>
                          </Accordion>
                        ))
                    ) : (
                      <div className="col-12 no-vendors">Nothing to show</div>
                    )
                  ) : null}

                  {POrderStatus === "PORDER_REJECTED" &&
                    docType === "PORDER" && (
                      <div>
                        <ul
                          id="rejected-header"
                          className="nav upNav headNav justify-content-between "
                        >
                          <li
                            className="nav-item sub-nav pl-3"
                            style={{
                              whiteSpace: "nowrap",
                              overflow: "hidden",
                              textOverflow: "ellipsis",
                              width: "300px",
                              height: "100%",
                            }}
                          >
                            <span className="fileaction-span noHover">
                              <span>Files</span>
                            </span>
                          </li>
                          <li className="nav-item sub-nav text-left">
                            <span className="fileaction-span noHover">
                              <span>Received at</span>
                            </span>
                          </li>
                          <li className="nav-item sub-nav text-left">
                            <span className="fileaction-span noHover">
                              <span>Modified at</span>
                            </span>
                          </li>
                          <li className="nav-item eyeIcon pl-2 text-left">
                            <span className="fileaction-span noHover">
                              <span>Delete</span>
                            </span>
                          </li>
                          <li className="nav-item sub-nav pl-2">
                            <span className="fileaction-span d-flex justify-content-start mr-4 noHover">
                              <span>Download</span>
                            </span>
                          </li>
                        </ul>
                        {vendorHTML.length > 0 ? (
                          vendorHTML.map((item) => (
                            <ul
                              className="nav upNav justify-content-between"
                              style={{
                                backgroundColor: item.isSelected
                                  ? "#017BFF"
                                  : "transparent",
                              }}
                            >
                              <li
                                className="nav-item sub-nav-vendor pl-2"
                                style={{
                                  whiteSpace: "nowrap",
                                  overflow: "hidden",
                                  textOverflow: "ellipsis",
                                  width: "300px",
                                  height: "100%",
                                  padding: "2px 0",
                                }}
                                title={item.original_file_name}
                              >
                                <ThemeProvider theme={myTheme}>
                                  <Checkbox
                                    checked={item.isSelected}
                                    onClick={(event) => handleCheckbox(item)}
                                    value="checkedA"
                                    inputProps={{ "aria-label": "Checkbox A" }}
                                  />
                                </ThemeProvider>
                                <i className="fa fa-file-o"></i>
                                <Link
                                  to={{
                                    pathname: `/porder_details/${item.id}`,
                                    state: POrderStatus,
                                  }}
                                >
                                  {value !== 2 ? (
                                    <span
                                      className="ml-2 review-div"
                                      onMouseEnter={() =>
                                        handleShowPreview(item)
                                      }
                                      onMouseLeave={() => handleHidePreview()}
                                    >
                                      {item.original_file_name}
                                    </span>
                                  ) : (
                                    <span
                                      className="ml-2 review-div"
                                      onMouseEnter={() =>
                                        handleShowPreview(item)
                                      }
                                      onMouseLeave={() => handleHidePreview()}
                                    >
                                      {item.original_file_name}
                                    </span>
                                  )}
                                </Link>
                              </li>
                              <li className="nav-item sub-nav text-left">
                                {getTimeRecord(item.created_at)}
                              </li>
                              <li className="nav-item sub-nav text-left">
                                {getTimeRecord(item.modified_at)}
                              </li>
                              {value !== 2 && (
                                <li className="nav-item eyeIcon pl-2 text-left">
                                  <span
                                    className="fileaction-span"
                                    onClick={() => setDeletableFileId(item.id)}
                                  >
                                    <i className="fa fa-trash"></i>&nbsp;
                                    <span>Delete</span>
                                  </span>
                                </li>
                              )}
                              { }
                              <li className="nav-item sub-nav pl-2">
                                <span
                                  className="fileaction-span"
                                  onClick={() => {
                                    window.open(
                                      `${EXTRICATOR_BASE_URL}/download_file/?id=${item.id
                                      }&ogi=${fetchOrganisationId()}`
                                    );
                                  }}
                                >
                                  <i className="fa fa-download mr-1"></i>
                                  <span>Download</span>
                                </span>
                              </li>
                            </ul>
                          
                          ))
                        ) : (
                          <div
                            style={{ height: "calc(100vh - 270px)" }}
                            className="col-12 no-vendors"
                          >
                            Nothing to show
                          </div>
                        )}
                      </div>
                    )}
                </div>
              ) : POrderStatus !== "PORDER_PROCESSING" ? (
                <div
                  className="col-12 p-0"
                  style={{ height: "calc(100% - 218px)", overflow: "auto" }}
                >
                  <table className="table forms-table">
                    <thead>
                      <tr style={{ backgroundColor: "#01132B" }}>
                        <th
                          scope="col"
                          className="text-left py-1"
                          style={{ paddingLeft: "19px" }}
                        >
                          Files
                        </th>
                        <th scope="col" className="text-left px-0 py-1">
                          Created at
                        </th>
                        <th scope="col" className="text-left px-0 py-1">
                          Modified at
                        </th>
                        <th scope="col" className="text-left px-0 py-1">
                          Download
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {rejectedDeleteFiles.length > 0 ? (
                        rejectedDeleteFiles.map((item) => (
                          <tr key={item.id} style={{ height: "40px" }}>
                            <td>
                              <div className="form-check d-flex align-items-center">
                                <i className="fa fa-file-o mr-2"></i>
                                <Link
                                  to={`/document_details/${item.id}`}
                                  className="delete_reject"
                                  onMouseEnter={() => handleShowPreview(item)}
                                  onMouseLeave={() => handleHidePreview()}
                                >
                                  {item.original_file_name}
                                </Link>
                              </div>
                            </td>
                            <td className="text-left">
                              {getTimeRecord(item.created_at)}
                            </td>
                            <td className="text-left">
                              {getTimeRecord(item.modified_at)}
                            </td>
                            <td className="text-left">
                              <span
                                className="fileaction-span"
                                onClick={() => {
                                  window.open(
                                    `${EXTRICATOR_BASE_URL}/download_file/?id=${item.id
                                    }&ogi=${fetchOrganisationId()}`
                                  );
                                }}
                              >
                                <i className="fa fa-download mr-1"></i>
                                <span>Download</span>
                              </span>
                            </td>
                          </tr>
                        ))
                      ) : (
                        <>
                          <tr></tr>
                          <tr></tr>
                          <tr></tr>
                          <tr align="center">
                            <td colSpan={6} align="center">
                              Nothing to show
                            </td>
                          </tr>
                          <tr></tr>
                          <tr></tr>
                          <tr></tr>
                        </>
                      )}
                    </tbody>
                  </table>
                </div>
              ) : (
                <>
                  <div className="col-12 mt-2 p-0" style={{ height: "415px" }}>
                    <table className="table forms-table">
                      <thead>
                        <tr style={{ backgroundColor: "#01132B" }}>
                          { }
                          <th
                            scope="col"
                            style={{ textAlign: "left", width: "12%" }}
                          >
                            Files
                          </th>
                          <th
                            scope="col"
                            style={{
                              textAlign: "left",
                              width: "17%",
                              padding: ".75rem 0",
                            }}
                          >
                            Received at
                          </th>
                          <th
                            scope="col"
                            style={{
                              textAlign: "left",
                              width: "17%",
                              padding: ".75rem 0",
                            }}
                          >
                            Modified at
                          </th>
                          <th
                            scope="col"
                            style={{
                              textAlign: "left",
                              width: "10%",
                              padding: ".75rem 0",
                            }}
                          >
                            Status
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {processingFiles.length > 0 ? (
                          processingFiles.map((item) => (
                            <tr key={item.id}>
                              <td>
                                <div
                                  className="d-flex align-items-center"
                                  style={{ marginLeft: "10px" }}
                                >
                                  <i className="fa fa-file-o"></i>
                                  <label
                                    className="form-check-label-processing ml-2"
                                    for=""
                                  >
                                    {item.original_file_name}
                                  </label>
                                </div>
                              </td>
                              <td className="text-left">
                                {formatDate(item.created_at)}
                              </td>
                              <td className="text-left">
                                {formatDate(item.modified_at)}
                              </td>
                              <td className="text-left">{item.file_status}</td>
                            </tr>
                          ))
                        ) : (
                          <>
                            <tr></tr>
                            <tr></tr>
                            <tr></tr>
                            <tr>
                              <td align="center" colSpan={6}>
                                Nothing to show
                              </td>
                            </tr>
                            <tr></tr>
                            <tr></tr>
                            <tr></tr>
                          </>
                        )}
                      </tbody>
                    </table>
                  </div>
                  {fullProcessingFiles.length > 0 && (
                    <div className="d-flex col-12">
                      <div className="col-7"></div>
                      <div className="col-5">
                        <PaginationComponent
                          onPageChange={(event, item) =>
                            handlePageChange(event, item)
                          }
                          data={totalProcessingPages}
                          callback={handlePaginationCallback}
                        />
                      </div>
                    </div>
                  )}
                </>
              )}
            </>
          )}
          <div
            className="col-12 mt-2"
            style={{
              position: "absolute",
              bottom:
                (getReceiptCounter().isVisible ||
                  getReceiptVendorCounter().isVisible) &&
                  exportBuffer.length > 0
                  ? "2px"
                  : "10px",
            }}
          >
            {POrderStatus === "PORDER_REJECTED" &&
              fullVendorHtml.length > 0 && (
                <div className="d-flex col-12 mb-2 p-0">
                  <div className="col-7"></div>
                  <div className="col-5 pr-2">
                    <div className="pagination-container">
                      <div>Pages</div>
                      <div id="invoice_wrapper" style={{ width: "35%" }}>
                        <span
                          onClick={(event) => handleSlideLeft(event)}
                          style={{ cursor: "pointer" }}
                        >
                          <img
                            src={Images.left_chevron}
                            className="left_chev"
                          />
                        </span>
                        <ul id="rejectedInvo_nav">
                          {totalVendorHtmlPages &&
                            totalVendorHtmlPages.map((item) => {
                              return (
                                <li
                                  className="page-item selected-page"
                                  onClick={(event) =>
                                    handlePageChange(event, item)
                                  }
                                >
                                  <span>{item < 10 ? `0${item}` : item}</span>
                                </li>
                              );
                            })}
                        </ul>
                        <span
                          onClick={(event) => handleSlideRight(event)}
                          style={{ cursor: "pointer" }}
                        >
                          <img
                            src={Images.right_chevron}
                            className="right_chev"
                          />
                        </span>
                      </div>
                      of {totalVendorHtmlPages.length}
                      <div className="ml-2">Go to Page</div>
                      <div
                        style={{ width: "40px" }}
                        className="pagination ml-2"
                      >
                        <CustomNumericInput
                          maxOffset={String(totalPages.length)}
                          minOffset="1"
                          handleFocus={() => setGoInputFocused(true)}
                          handleBlur={() => setGoInputFocused(false)}
                          inputId="pagination_input"
                          handleChange={(event) =>
                            setFormPageValue(Number(event.target.value))
                          }
                        />
                      </div>
                      <button
                        type="button"
                        className="btn btn-primary go-btn mr-2"
                        onClick={(event) =>
                          handlePageChange(event, formPageValue)
                        }>
                        Go
                      </button>
                    </div>
                  </div>
                </div>
              )}
            <div className="d-flex justify-content-end mr-2">
              {docType === "PORDER" &&
                POrderStatus !== "PORDER_DELETE_REJECTED" &&
                (getReceiptCounter().isVisible ||
                  getReceiptVendorCounter().isVisible) &&
                exportBuffer.length > 0 ? (
                <div
                  className="d-flex action-group justify-content-end"
                  style={{
                    marginRight:
                      POrderStatus == "PORDER_REVIEW" ? "7px" : "0px",
                  }}
                >
                  <label
                    className="xls_label"
                    onClick={() => handleBatchDelete()}
                  >
                    <i
                      className="fa fa-trash"
                      style={{ fontSize: "17px", color: theme_colors.primary }}
                    ></i>
                    <span
                      style={{
                        marginLeft: 15,
                        font: "normal normal bold 14px/35px Open Sans",
                        color: "#011B3E",
                      }}
                    >
                      ({exportBuffer.length})
                      &nbsp;
                      Delete
                    </span>
                  </label>
                  {POrderStatus !== "PORDER_DELETE" && (
                    <label
                      className="download_excel ml-2"
                      onClick={() => exportReceipToExcel(receiptId)}
                    >
                      <img
                        src={Images.dashboard_download_button}
                        style={{ marginLeft: -3 }}
                      />
                      {POrderStatus === "PORDER_REJECTED" ? (
                        <span
                          style={{
                            marginLeft: 15,
                            font: "normal normal bold 14px/35px Open Sans",
                            color: "#011B3E",
                          }}
                        >
                          ({getRejectedReceiptsCounter().vendorCounter})
                          Download.xls
                        </span>
                      ) : (
                        <span
                          style={{
                            marginLeft: 15,
                            font: "normal normal bold 14px/35px Open Sans",
                            color: "#011B3E",
                          }}
                        >
                          ({exportBuffer.length})
                          &nbsp;
                          Download.xls
                        </span>
                      )}
                    </label>
                  )}
                </div>
              ) : null}

              {docType == "PORDER" && getRejectedPOCounter().isVisible ? (
                <div className="d-flex delete-group">
                  <label
                    className="xls_label"
                    onClick={() => handleRejectedBatchDelete()}
                  >
                    <i
                      className="fa fa-trash"
                      style={{ fontSize: "17px", color: theme_colors.primary }}
                    ></i>
                    <span
                      style={{
                        marginLeft: 15,
                        font: "normal normal bold 14px/35px Open Sans",
                        color: "#011B3E",
                      }}
                    >
                      {getRejectedPOCounter().fileCounter + " Delete"}
                    </span>
                  </label>
                </div>
              ) : null}
              {POrderStatus == "PORDER_REVIEW" ? (
                <label
                  className="upload_btn"
                  onClick={() => checkUserSubscription()}
                >
                  <img
                    src={Images.dashboard_upload}
                    width="30px"
                    style={{
                      marginRight: "7px",
                      width: "15px",
                      height: "15px",
                    }}
                  />
                  <span
                    style={{
                      font: "normal normal bold 14px/35px Open Sans",
                      color: "#011B3E",
                    }}
                  >
                    Upload
                  </span>
                </label>
              ) : null}
              {uploadModal && (
                <input
                  multiple
                  className="uploadBtn"
                  id="contained-button-file"
                  style={{ display: "none" }}
                  type={"file"}
                  accept="image/*,application/pdf"
                  onChange={addInvoice}
                />
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default DashboardPOrder;
